import { Log } from 'ng2-logger';
import React from 'react';
import { connect } from 'react-redux';
import APlanHeader from '../../components/templates/aplan-header';
import MetabaseReport from './metabase-report';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { DashboardCoursePlanningInitialValues, DashboardCoursePlanningTabs } from '../../store/constants/dashboard-const';
import * as Types from '../../store/types';
import Spinner from '../../components/templates/spinner';
import { routes as Routes } from '../../store/constants/routes';
import * as GT from '../../tools/general-tools';
import MainLoyout from '../layouts/main-layout';
import Translator from '../../services/translate-factory';

const T = Translator.create();
const L = Log.create('DashboardCoursePlanningPage');

function getInitialState(): Types.IDashboardReportsPageState {
  const initialValues: Types.IDashboardReportsPageState = {
    tabs: { ...DashboardCoursePlanningInitialValues },
    model: { ...DashboardCoursePlanningInitialValues.model },
  };
  return { ...initialValues };
}

class DashboardCoursePlanningPage extends React.Component<Types.IDashboardReportsPageProps, Types.IDashboardReportsPageState> {
  state: Types.IDashboardReportsPageState = getInitialState();
  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
        this.tabRedirection(1);
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };
  componentWillMount() {
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.DASHBOARD_COURSE_PLANNING), 0));
  }
  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.tabRedirection(1);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  selectActiveTab = (tabName: string) => {
    let tabs =
      this.state.tabs.tabManager &&
      this.state.tabs.tabManager.map((tab) => {
        return tab.key == tabName ? { key: tab.key, value: true } : { key: tab.key, value: false };
      });

    let activeTab = tabs && tabs.find((item) => item.value == true);
    let tabConstant = activeTab && activeTab.key;
    this.state.tab_reloaded = true;
    this.state.tabs.tabManager = tabs;
    this.setState(this.state);

    let report_type = -1;

    switch (tabConstant) {
      case 'DASHBOARD_COURSE_PLANNING_INSTRUCTOR_COURSE_LOADS': report_type = 1; break;
      case 'DASHBOARD_COURSE_PLANNING_CLASSROOM_USAGES_WEEKLY': report_type = 2; break;
      case 'DASHBOARD_COURSE_PLANNING_CLASSROOM_USAGES_DAILY': report_type = 3; break;
    }

    if (report_type >= 0) {
      this.props.dispatch(
        Actions.ApiRequest(Constants.dasboard.DASHBOARD_GET_METABASE_REPORT, report_type, 'dashboard-course-planning-tab-spin')
      );
    }
  };

  tabRedirection(tabNumber: number) {
    this.state.tab_number = tabNumber;

    let selectedTab = this.state.tab_number == DashboardCoursePlanningTabs.InstructorCourseLoads ?
      Constants.dasboard_constants.DASHBOARD_COURSE_PLANNING_INSTRUCTOR_COURSE_LOADS :
      this.state.tab_number == DashboardCoursePlanningTabs.ClassroomUsagesWeekly ?
        Constants.dasboard_constants.DASHBOARD_COURSE_PLANNING_CLASSROOM_USAGES_WEEKLY :
        this.state.tab_number == DashboardCoursePlanningTabs.ClassroomUsagesDaily ?
          Constants.dasboard_constants.DASHBOARD_COURSE_PLANNING_CLASSROOM_USAGES_DAILY :
          Constants.dasboard_constants.DASHBOARD_COURSE_PLANNING_INSTRUCTOR_COURSE_LOADS;

    if (selectedTab != null) {
      let tabs =
        this.state.tabs.tabManager &&
        this.state.tabs.tabManager.map((tab) => {
          return tab.key == selectedTab ? { key: tab.key, value: true } : { key: tab.key, value: false };
        });
      this.state.tabs.tabManager = tabs;
      this.isActiveTab(selectedTab);
      this.selectActiveTab(selectedTab);
    }
  }

  isActiveTab = (tabName: string) => {
    let activeTab = this.state.tabs.tabManager && this.state.tabs.tabManager.find((item) => item.key == tabName);

    if (activeTab) {
      return activeTab.value;
    } else return false;
  };

  static getDerivedStateFromProps(props: Types.IDashboardReportsPageProps, state: Types.IDashboardReportsPageState) {
    let hasNewState: boolean = false;

    if (props.model && state.model.report_name !== props.model.report_name) {
      hasNewState = true;
      state.model.report_name = props.model.report_name;
    }

    if (hasNewState) {
      return state;
    }
    else {
      return null;
    }
  }

  render() {
    let data = this.props.model != undefined ? this.props.model : DashboardCoursePlanningInitialValues.model!;

    return (
      <MainLoyout header={<APlanHeader />}>
        <React.Fragment>
          <div
            className="main editor-screen-main"
            style={{ display: 'block' }}
          >
            <div
              id="editorScreen"
              className="white-container mt-4 editor-screen collapse editor-screen"
              style={{
                display: 'block',
                minHeight: '100vh',
                marginTop: '0 !important'
              }}
            >
              <div className="row">
                <div className="col-10">
                  <h5 className="editor-title">
                    <i className="material-icons mr-2" style={{ verticalAlign: 'sub' }}>
                      event_note
                    </i>
                    {T.t('gen_course_planning')}
                  </h5>
                </div>
              </div>
              <div className="row">
                <Spinner name="dashboard-course-planning-tab-spin" />
                <div className="col-12">
                  <nav className="inner-page-nav">
                    <div className="nav nav-tabs nav-fill mb-4" id="nav-tab" role="tablist">
                      {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'p' || this.props.user.role === 'r') &&
                        (
                          <a
                            className={
                              this.isActiveTab(Constants.dasboard_constants.DASHBOARD_COURSE_PLANNING_INSTRUCTOR_COURSE_LOADS)
                                ? 'nav-item nav-link active'
                                : 'nav-item nav-link'
                            }
                            onClick={() => this.selectActiveTab(Constants.dasboard_constants.DASHBOARD_COURSE_PLANNING_INSTRUCTOR_COURSE_LOADS)}
                            id="dates-tab"
                            data-toggle="tab"
                            href="#dates"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                          >
                            {T.t('gen_instructor_course_loads')}
                          </a>
                        )}
                      {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'p' || this.props.user.role === 'r') &&
                        (
                          <a
                            className={
                              this.isActiveTab(Constants.dasboard_constants.DASHBOARD_COURSE_PLANNING_CLASSROOM_USAGES_WEEKLY)
                                ? 'nav-item nav-link active'
                                : 'nav-item nav-link'
                            }
                            onClick={() => this.selectActiveTab(Constants.dasboard_constants.DASHBOARD_COURSE_PLANNING_CLASSROOM_USAGES_WEEKLY)}
                            id="classrooms-tab"
                            data-toggle="tab"
                            href="#classrooms"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                          >
                            {T.t('gen_classroom_usages_weekly')}
                          </a>
                        )}
                      {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'p' || this.props.user.role === 'r') &&
                        (
                          <a
                            className={
                              this.isActiveTab(Constants.dasboard_constants.DASHBOARD_COURSE_PLANNING_CLASSROOM_USAGES_DAILY)
                                ? 'nav-item nav-link active'
                                : 'nav-item nav-link'
                            }
                            onClick={() => this.selectActiveTab(Constants.dasboard_constants.DASHBOARD_COURSE_PLANNING_CLASSROOM_USAGES_DAILY)}
                            id="course-tab"
                            data-toggle="tab"
                            href="#courses"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                          >
                            {T.t('gen_classroom_usages_daily')}
                          </a>
                        )}
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className={
                        this.isActiveTab(Constants.dasboard_constants.DASHBOARD_COURSE_PLANNING_INSTRUCTOR_COURSE_LOADS)
                          ? 'tab-pane fade show active'
                          : 'tab-pane fade'
                      }
                      id="dates"
                      role="tabpanel"
                      aria-labelledby="dates-tab"
                    >
                      {this.isActiveTab(Constants.dasboard_constants.DASHBOARD_COURSE_PLANNING_INSTRUCTOR_COURSE_LOADS) ? (
                        <MetabaseReport
                          dashboard_id={data.dashboard_id}
                          report_name={data.report_name}
                          url={data.url}
                          frame_width={data.frame_width}
                          frame_height={data.frame_height}
                        />
                      ) : null}
                    </div>
                    <div
                      className={
                        this.isActiveTab(Constants.dasboard_constants.DASHBOARD_COURSE_PLANNING_CLASSROOM_USAGES_WEEKLY)
                          ? 'tab-pane fade show active'
                          : 'tab-pane fade'
                      }
                      id="personnals"
                      role="tabpanel"
                      aria-labelledby="personnals-tab"
                    >
                      {this.isActiveTab(Constants.dasboard_constants.DASHBOARD_COURSE_PLANNING_CLASSROOM_USAGES_WEEKLY) ? (
                        <MetabaseReport
                          dashboard_id={data.dashboard_id}
                          report_name={data.report_name}
                          url={data.url}
                          frame_width={data.frame_width}
                          frame_height={data.frame_height}
                        />
                      ) : null}
                    </div>
                    <div
                      className={
                        this.isActiveTab(Constants.dasboard_constants.DASHBOARD_COURSE_PLANNING_CLASSROOM_USAGES_DAILY)
                          ? 'tab-pane fade show active'
                          : 'tab-pane fade'
                      }
                      id="classrooms"
                      role="tabpanel"
                      aria-labelledby="classrooms-tab"
                    >
                      {this.isActiveTab(Constants.dasboard_constants.DASHBOARD_COURSE_PLANNING_CLASSROOM_USAGES_DAILY) ? (
                        <MetabaseReport
                          dashboard_id={data.dashboard_id}
                          report_name={data.report_name}
                          url={data.url}
                          frame_width={data.frame_width}
                          frame_height={data.frame_height}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </MainLoyout>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.IDashboardReportsPageProps
): Types.IDashboardReportsPageProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IDashboardReportsPageProps = Object.assign({}, ownProps, {
    model:
      store.state.dashboard_page &&
      store.state.dashboard_page.model,
    name:
      store.state.dashboard_page &&
      store.state.dashboard_page.model &&
      store.state.dashboard_page.model.report_name,
    user: store.state.user,
    general_settings: store.state.general_settings,
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.dashboard_page) {
    return (
      !!equal(
        prev.state.dashboard_page,
        next.state.dashboard_page) &&
      !!equal(
        prev.state && prev.state.general_settings,
        next.state && next.state.general_settings
      ));
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(DashboardCoursePlanningPage);

export default container;
