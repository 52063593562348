import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import * as Constants from '../../../store/constants/all';
import * as Actions from '../../../store/actions/general';
import moment from 'moment';
import Spinner from '../../../components/templates/spinner';
import Flatpickr from 'react-flatpickr';
import { UncontrolledTooltip } from 'reactstrap';
import * as Types from '../../../store/types';
import Translator from '../../../services/translate-factory';
import * as GT from '../../../tools/general-tools';

const T = Translator.create();
const equal = require('deep-equal');
const L = Log.create('SolutionBatchChangeExamDatesModal');

class SolutionBatchChangeExamDatesModal extends Component<any, any> {
  state: any = {
    term_id: -1,
    solution_id: -1,
    locale: '',
    isCalendarCreated: false,
    isExpanded: false,
    isDateChanged: false,
    calendar: {
      times: [],
      days: [],
      schedule: {}
    },
    examDates: []
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };
  constructor(props: any) {
    super(props)
    this.state.locale = GT.getLocaleFromLangCode();
  }
  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.term_id = id;
    let newModel = {
      term_id: this.props.term_id,
      solution_id: this.props.solutionId
    };
    this.props.dispatch(Actions.ApiRequest(Constants.solution.SOLUTION_GET_EXAM_DATES, newModel, 'solution-exam-dates-spin'));
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  disableSaveButton = () => {
    let disableValue: boolean = true;
    if (this.state.examDates && this.state.examDates.length && this.state.examDates.filter((x: any) => x.hasOwnProperty('dateUpdated') && x.dateUpdated !== undefined).length) {
      disableValue = false;
    }
    return disableValue;
  };

  onBatchChangeExamDates = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e && e.target) {
      const resultCallback = (result: any, status: number) => {
        if (status === 200) {
          this.setClose(true);
        }
      };
      const onConfirm = () => {
        let model = {
          term_id: this.props.term_id,
          solution_id: this.props.solutionId,
          exam_dates: this.state.examDates
        }

        this.props.dispatch(
          Actions.ApiRequest(Constants.solution.SOLUTION_BATCH_CHANGE_EXAM_DATES, model, 'course-form-spin', resultCallback)
        );
      };
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_solution_batch_change_exam_dates_title'),
          body: T.t('gen_solution_batch_change_exam_dates_body'),
          name: 'solution_batch_change_exam_dates',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_no'),
          onConfirm: onConfirm
        })
      );
    }
  }

  static getDerivedStateFromProps(props: any, state: any) {
    let hasNewState: boolean = false;

    if (props.examDates && props.examDates.exam_dates && props.examDates.exam_dates.length) {
      hasNewState = true;
      const startDate = moment(props.examDates.start_date).toDate();
      const endDate = moment(props.examDates.end_date).toDate();
      const endHour = moment(props.examDates.end_hour, 'HH:mm').format('H');
      const max = parseInt(endHour, 10);

      state.minDate = moment(startDate).format('YYYY-MM-DD');
      state.minHour = props.examDates.start_hour;
      state.maxDate = moment(endDate).format('YYYY-MM-DD');
      const maxHour = moment(max, 'H').format('HH:mm');
      state.maxHour = maxHour;
      state.timePeriod = props.examDates.slot_duration;

      state.term_id = props.term_id;
      state.solution_id = props.solutionId;

      state.examDates = props.examDates.exam_dates;

      state.calendar.times = props.examDates.times;
      state.calendar.days = props.examDates.days;

      let schedule: any = {};
      props.examDates.days.map((day: any) => {
        let newArray: any = [];
        props.examDates.times.map((time: any) => {
          let obj = { hour: time, isActive: true };
          newArray.push(obj);
        });
        schedule[day] = newArray;
      });

      if (schedule) {
        state.calendar.schedule = schedule;
        state.isCalendarCreated = true;
      }
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }


  render() {
    let { times, days } = this.state.calendar;
    let tableColumnHeads = null;
    let timeTableRows = null;
    if (this.state.isCalendarCreated) {
      tableColumnHeads = days.map((day: any) => (
        <th key={day}>
          <div data-day={day}>
            {moment(day).format('D MMM ddd')}
          </div>
        </th>
      ));
      timeTableRows = times.map((time: string) => {
        let boxes = Array(days.length)
          .fill(undefined)
          .map((val, index) => {
            let day: string = days[index];
            let scheduleClone: any = this.state && this.state.calendar && this.state.calendar.schedule;
            return (
              <td key={index}>
                <div
                id= 'div_choose_hour'
                  data-day={day}
                  data-hour={time}
                  className={
                    scheduleClone[day] &&
                      scheduleClone[day].length &&
                      scheduleClone[day].find((item: any) => item.hour == time).isActive
                      ? 'on'
                      : this.state.model.emptyDurations.findIndex((ed: any) => ed.hour == time) !== -1
                        ? 'unavailable'
                        : ''
                  }
                ></div>
              </td>
            );
          });

        return (
          <tr key={time}>
            <td className="aplan-tm-sticky-col">
              <div data-time={time}>
                {time}
              </div>
            </td>
            {boxes}
          </tr>
        );
      });
    }

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_batch_change_exam_dates')}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <Spinner name="solution-exam-dates-spin" />
          <div className="modal-body">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12">
                  <h6 className="mb-3 ml-3">{T.t('gen_exam_dates_details')}</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-4 mb-3">
                  <div className="generic-wrapper">
                    {this.state.isCalendarCreated ? (
                      <div className="row">
                        <div className="col-md-12 form-input form-group with-icon">
                          <label
                            htmlFor="start-time-datepicker"
                            style={{
                              color: 'rgba(241, 13, 13, 0.93)'
                            }}
                          >
                            {moment(this.state.minDate).format('D MMMM dddd') +
                              ' - ' +
                              moment(this.state.maxDate).format('D MMMM dddd')}
                          </label>
                          <p>{T.t('gen_start_and_end_dates')}</p>
                        </div>
                        <div className="col-md-12 form-input form-group with-icon">
                          <label
                            htmlFor="start-time-datepicker"
                            style={{
                              color: 'rgba(241, 13, 13, 0.93)'
                            }}
                          >
                            {this.state.minHour}
                          </label>
                          <p>{T.t('gen_start_time')}</p>
                        </div>
                        <div className="col-md-12 form-input form-group with-icon">
                          <label
                            htmlFor="start-time-datepicker"
                            style={{
                              color: 'rgba(241, 13, 13, 0.93)'
                            }}
                          >
                            {this.state.maxHour}
                          </label>
                          <p>{T.t('gen_end_time')}</p>
                        </div>
                        <div className="col-md-12 form-input form-group with-icon">
                          <label
                            htmlFor="start-time-datepicker"
                            style={{
                              color: 'rgba(241, 13, 13, 0.93)'
                            }}
                          >
                            {this.state.timePeriod}
                          </label>
                          <p>
                            {T.t('gen_slot_duration')} {T.t('gen_minute_and_hour')}
                          </p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-8 collapse" id="aplan-time-matrix" style={{ display: 'block' }}>
                  <div className="generic-wrapper">
                    {this.state.isCalendarCreated ? (
                      <React.Fragment>
                        <div className="aplan-table-matrix-wrapper">
                          <div
                            className="aplan-table-matrix-scroller"
                            style={this.state.isExpanded ? { height: '100%' } : { height: '400px' }}
                          >
                            <table className="table table-borderless table-striped table-hover aplan-table-matrix mb-0">
                              <thead>
                                <tr>
                                  <th className="aplan-tm-sticky-col">
                                    <div>#</div>
                                  </th>
                                  {tableColumnHeads}
                                </tr>
                              </thead>
                              <tbody>{timeTableRows}</tbody>
                            </table>
                          </div>
                          <button
                            id='button_expand'
                            type="button"
                            className="btn btn-gray mt-2 mb-2 float-right mr-2"
                            onClick={() => {
                              this.state.isExpanded = !this.state.isExpanded;
                              this.setState(this.state);
                            }}
                          >
                            <i className="material-icons mr-2 text-left">{this.state.isExpanded ? 'remove' : 'add'}</i>
                            <span>{this.state.isExpanded ? T.t('gen_collapse') : T.t('gen_expand')}</span>
                          </button>
                        </div>
                      </React.Fragment>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <><h6 id="solution_date_change_operations" className="col-3 mb-3 ml-3">{T.t('gen_solution_date_change_operations')} <i className="material-icons mb-1">info_outlined</i></h6>
                    <UncontrolledTooltip placement="bottom" target="solution_date_change_operations">
                      {T.t('gen_solution_date_change_operations_info')}
                    </UncontrolledTooltip></>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">
                          {T.t('gen_order')}
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_planned_date')}
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_exam_count')}
                        </th>
                        <th scope="col" className="text-center" style={{ width: '15%' }}>
                          <span className="text-center">{T.t('gen_date_to_update')}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state && this.state.examDates.length
                        ? this.state.examDates.map((examDate: any) => (
                          <tr key={'examDate-' + examDate.order} data-title={examDate.order}>
                            <td scope="row" data-label={T.t('gen_order')} className="text-center">
                              {examDate.order}
                            </td>
                            <td data-label={T.t('gen_planned_date')} className="text-center">
                              {examDate.datePlanned}
                            </td>
                            <td data-label={T.t('gen_exam_count')} className="text-center">
                              {examDate.course_count}
                            </td>
                            <td data-label={T.t('gen_date_to_update')} className="text-center" style={{ width: '15%' }}>
                              <div className="react-select-container">
                                <div className="form-input form-group with-icon date-picker">
                                  <Flatpickr
                                    id='date_to_update'
                                    className="text-center"
                                    placeholder={T.t('gen_select_date')}
                                    value={this.state.start_date}
                                    options={{
                                      dateFormat: 'Y-d-m',
                                      clickOpens: true,
                                      allowInput: true,
                                      locale: this.state.locale
                                    }}
                                    onClose={(value) => {
                                      examDate.dateUpdated = value.map((item) => moment(item).format('YYYY-MM-DD'))[0];
                                      this.setState(this.state);
                                    }}
                                  />
                                  <i className="material-icons">insert_invitation</i>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6"></div>
                <div className="col-6 text-right">
                  <button
                    id='button_save'
                    type="button"
                    className="btn btn-green mt-md-0 mt-2 mb-md-0 mb-2"
                    disabled={this.disableSaveButton()}
                    onClick={(e) => {
                      this.onBatchChangeExamDates(e);
                    }}
                  >
                    {T.t('gen_save')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
    term_id: store.state.term_id,
    examDates: store.state.solution_page && store.state.solution_page.solutionExamDates,
  });
  return newProps;
};

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.solution_page) {
    return (
      !!equal(
        prev.state.solution_page && prev.state.solution_page.solution,
        next.state.solution_page && next.state.solution_page.solution
      ) &&
      !!equal(
        prev.state.solution_page && prev.state.solution_page.solutionExamDates,
        next.state.solution_page && next.state.solution_page.solutionExamDates
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(SolutionBatchChangeExamDatesModal);

export default container;
