import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, FormikActions, FormikProps } from 'formik';
import { ValueType } from 'react-select/lib/types';
import Select from 'react-select';
import { Log } from 'ng2-logger';
import { Modal, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import Paginate from '../../components/table/paginate';
import SortedColumn from '../../components/table/sorted-column';
import Announce from '../../components/templates/announce';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import {
  CourseSearchInitialValues,
  CourseHoursSearchInitialValues,
  ClassroomCombineTypes,
  ExamPeriodCourseTermLectureLocations,
  EducationTypeSelectOptions,
  ExamTypes,
  ExamEnvironments,
  ExamOverlapTypes,
  InvigilatorStatuses,
} from '../../store/constants/course-const';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import Translator from '../../services/translate-factory';
const T = Translator.create();

const L = Log.create('CourseAddModal');

function getInitialState(): Types.ICoursePageState {
  const initialValues: Types.ICoursePageState = {
    filters: Object.assign({}, CourseHoursSearchInitialValues),
    filterIsOpen: false,
    all_ids: [],
    selected_ids: [],
    courseFormIsOpen: false,
    courseId: undefined
  };
  initialValues.filters.onlySelectWithoutCourseHours = true;
  return Object.assign({}, initialValues);
}

class CourseAddModal extends Component<Types.ICoursePageProps, Types.ICoursePageState> {
  state = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.searchCourses();
    this.getCourseSelectOptions();
    this.getProgramsByFacultiesAtCourses([]);
  }
  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentDidUpdate() {
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
    let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    if (id && !this.state.filters.term_id) {
      this.state.filters.term_id = id;
      this.setState(this.state);
    }
  }

  searchCourses() {
    this.state.filters.status = [1];
    this.state.filters.onlySelectWithoutCourseHours = true;
    this.props.dispatch(Actions.ApiRequest(Constants.course.COURSE_LIST_SEARCH, this.state.filters, 'course-list-spin'));
  }

  getCourseSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.course.COURSE_GET_SELECT_OPTIONS, 'course-list-spin'));
  }

  getProgramsByFacultiesAtCourses = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.course.COURSE_GET_PROGRAMS_BY_FACULTIES, facultyIds, 'course-list-spin'));
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  onUpdateListFromModal = (refresh: boolean = false) => {
    if (this.props.onUpdateList) {
      this.props.onUpdateList(refresh);
    }
  };

  onSelectCourse = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
          this.state.filters.select_all = false;
        }
        this.setState({
          ...this.state,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList
      });
    }
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.searchCourses();
      } else {
        this.state.all_ids = [];
        this.state.selected_ids = [];
        this.state.filters.select_all = false;
        this.setState(this.state);
      }
    }
  };

  addCourses = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_courses_add_planning'),
          body: T.t('gen_add_courses_to_exam_schedule_question'),
          name: 'examPeriod_courses_add',
          icon: 'warning',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
              if (status == 200) {
                this.setClose();
                this.onUpdateListFromModal();
              }
            };
            /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
            let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
            var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
            let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
            this.state.filters.term_id = id;
            this.state.filters.add_list = this.state.selected_ids;

            this.props.dispatch(
              Actions.ApiRequest(
                Constants.exam_period.EXAM_PERIOD_ADD_COURSES_TO_PERIOD,
                this.state.filters,
                'ep-instructor-list',
                resultCallback
              )
            );
          }
        })
      );
    }
  };

  onInputChange = (inputValue: string, action: any) => {
    if (action.action === "input-change") {
      this.state.course_codes_filtered = this.props.selectOptions && this.props.selectOptions.course_codes_select.filter((item: any) => item.label.toLowerCase().includes(inputValue.toLowerCase()));
    }

    if (action.action === "set-value" && this.state.course_codes_filtered == undefined) {
      this.state.course_codes_filtered = this.props.selectOptions && this.props.selectOptions.course_codes_select;
    }

    if (action.action === "menu-close" && this.state.course_codes_filtered != undefined) {
      this.state.course_codes_filtered = undefined;
    }
  };

  filterOption = (option: Types.ISelectOption, inputValue: string) => {
    if (option.value === -1) {
      return true;
    } else if (inputValue) {
      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    } else {
      return true;
    }
  };

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.searchCourses();
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.searchCourses();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, CourseSearchInitialValues);
    this.state.all_ids = [];
    this.setState(this.state);
    this.searchCourses();
  };

  onFilterCourse(model: Types.IFilterCourse, FormActions: FormikActions<Types.IFilterCourse>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.searchCourses();
    FormActions.setSubmitting(false);
  }

  static getDerivedStateFromProps(props: Types.ICoursePageProps, state: Types.ICoursePageState) {
    let hasNewState: boolean = false;
    if (state.filters.select_all) {
      hasNewState = true;
      state.all_ids = props.all_ids;
      state.selected_ids = props.all_ids;
    }

    if (props.modalIsOpen && !state.courseFormIsOpen) {
      hasNewState = true;
      props.dispatch(Actions.ApiRequest(Constants.course.COURSE_LIST_SEARCH, state.filters, 'course-list-spin'));
      state.courseFormIsOpen = true;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    const courseCodeOptions = this.props.selectOptions && this.props.selectOptions.course_codes_select ? this.props.selectOptions.course_codes_select : []
    const courseCodeSelectOptions = [{ label: T.t('gen_select_all'), value: -1 }, ...courseCodeOptions];

    let courseList = this.props.results;
    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const CampusSelectOptions = [...ExamPeriodCourseTermLectureLocations(T), ...campusOptions];

    return (
      <Modal className="pt-0" style={{ maxWidth: '100%', padding: '0 15px' }} isOpen={this.props.modalIsOpen} toggle={this.setCloseModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_add_courses_to_list')}
            </h6>
            <button id='button_close' type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.setCloseModal}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12">
                  <div className="quick-actions">
                    <button
                      id='button_filter'
                      className="category-tag-square tag-glass float-right ml-3 mr-3"
                      style={{ margin: '5px' }}
                      onClick={() => {
                        this.state.filterIsOpen = !this.state.filterIsOpen;
                        this.setState(this.state);
                      }}
                    >
                      <i className="material-icons mr-2">filter_list</i>
                      <span>{T.t('gen_filter')}</span>
                    </button>
                    {this.state.selected_ids && this.state.selected_ids.length ? (
                      <React.Fragment>
                        <button
                          id='select_all'
                          className="category-tag-square tag-gray float-left pr-2 pl-2"
                          style={{ margin: '5px' }}
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              selected_ids: [],
                              all_ids: [],
                              filters: {
                                ...this.state.filters,
                                select_all: false
                              }
                            });
                          }}
                        >
                          <i className="material-icons mr-2">close</i>
                          <span>
                            {T.t('gen_cancel_selection')} <b>&nbsp;({this.state.selected_ids.length})</b>
                          </span>
                        </button>
                        <button
                          id='button_add_selecteds'
                          className="category-tag-square tag-green float-left pr-2 pl-2"
                          style={{ margin: '5px' }}
                          onClick={this.addCourses}
                        >
                          <i className="material-icons mr-2">input</i>
                          <span>{T.t('gen_add_selecteds')}</span>
                        </button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <button
                          id='button_cancel_selection'
                          className="category-tag-square tag-gray float-left pr-2 pl-2"
                          style={{ margin: '5px', cursor: 'not-allowed' }}
                          disabled={true}
                        >
                          <i className="material-icons mr-2">close</i>
                          <span>
                            {T.t('gen_cancel_selection')} <b>&nbsp;(0)</b>
                          </span>
                        </button>
                        <button
                          id='button_add'
                          className="category-tag-square tag-gray float-left pr-2 pl-2"
                          style={{ margin: '5px', cursor: 'not-allowed' }}
                          disabled={true}
                        >
                          <i className="material-icons mr-2">input</i>
                          <span>{T.t('gen_add_selecteds')}</span>
                        </button>
                      </React.Fragment>
                    )}
                  </div>
                </div>
                {/* // TODO: FILTER STARTS HERE   */}
                <div className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)} id="advance-search">
                  <div
                    className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                    id="advance-search"
                  >
                    <div className="advance-search d-block mt-3">
                      <Formik
                        initialValues={CourseSearchInitialValues}
                        enableReinitialize={true}
                        onSubmit={(values, actions) => {
                          this.onFilterCourse(values, actions);
                        }}
                        onReset={this.onFormReset}
                      >
                        {(props: FormikProps<Types.IFilterCourse>) => {
                          return (
                            <form onSubmit={props.handleSubmit}>
                              <div className="row">
                                <div className="col-12">
                                  <h6>{T.t('gen_filter_parameters')}</h6>
                                </div>
                                <div className="col-md-2">
                                  <div className="add-custom-tag mb-3">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_course_no')}</label>
                                      <Select
                                        id='select-course'
                                        className="react-select"
                                        isMulti={true}
                                        closeMenuOnSelect={false}
                                        options={
                                          this.props.selectOptions && this.props.selectOptions.course_ids_select
                                            ? this.props.selectOptions.course_ids_select
                                            : []
                                        }
                                        placeholder={T.t('gen_select_course_no')}
                                        value={props.values.course_ids_select}
                                        onChange={(
                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                        ) => {
                                          const list: Array<Types.ISelectOption> = options
                                            ? (options as Array<Types.ISelectOption>)
                                            : [];
                                          props.setFieldValue('course_ids_select', list);
                                          props.setFieldValue(
                                            'course_ids',
                                            list.map((item) => item.value)
                                          );
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_course_no')}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="add-custom-tag mb-3">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_course_code')}</label>
                                      <Select
                                        id='select_course_code'
                                        className="react-select"
                                        isMulti={true}
                                        closeMenuOnSelect={false}
                                        options={courseCodeSelectOptions}
                                        placeholder={T.t('gen_select_course_code')}
                                        value={props.values.course_codes_select}
                                        filterOption={this.filterOption}
                                        onInputChange={this.onInputChange}
                                        onChange={(
                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                        ) => {
                                          const list: Array<Types.ISelectOption> = options
                                            ? (options as Array<Types.ISelectOption>)
                                            : [];
                                          props.setFieldValue('course_codes_select', list.map(item => item.value).includes(-1) ? this.state.course_codes_filtered! : list);
                                          props.setFieldValue(
                                            'course_codes_ids',
                                            list.map(item => item.value).includes(-1) ? this.state.course_codes_filtered!.map((item: any) => item.value) : list.map(item => item.value)
                                          );
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_course_code')}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-input form-group date-picker mt-3">
                                    <input
                                      id="conjugate_course_code"
                                      name="conjugate_course_code"
                                      value={props.values.conjugate_course_code}
                                      onChange={props.handleChange}
                                      type="text"
                                      required
                                    />
                                    <span className="highlight" />
                                    <span className="bar" />
                                    <label htmlFor="conjugate_course_code">{T.t('gen_conjugate_course_code')}</label>
                                    <i className="material-icons">filter_1</i>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-input form-group date-picker mt-3">
                                    <input
                                      id="name"
                                      name="name"
                                      value={props.values.name}
                                      onChange={props.handleChange}
                                      type="text"
                                      required
                                    />
                                    <span className="highlight" />
                                    <span className="bar" />
                                    <label htmlFor="name">{T.t('gen_name')}</label>
                                    <i className="material-icons">title</i>
                                  </div>
                                </div>
                                <div className="col-md-2 form-input form-group with-icon mt-3">
                                  <input
                                    id="exam_session"
                                    name="exam_session"
                                    value={props.values.exam_session}
                                    onChange={props.handleChange}
                                    type="number"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="exam_session">{T.t('gen_session_count')}</label>
                                </div>
                                <div className="col-md-2">
                                  <div className="add-custom-tag mb-3">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_campus')}</label>
                                      <Select
                                        id='select_location'
                                        className="react-select"
                                        isMulti={true}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={false}
                                        options={CampusSelectOptions}
                                        placeholder={T.t('gen_select_location')}
                                        value={props.values.campuses ? props.values.campuses : null}
                                        onChange={(
                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                        ) => {
                                          const list: Array<Types.ISelectOption> = options
                                            ? (options as Array<Types.ISelectOption>)
                                            : [];
                                          props.setFieldValue('campuses', list);
                                          props.setFieldValue(
                                            'campus_ids',
                                            list.map((item) => item.value)
                                          );
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_campus')}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="add-custom-tag mb-3">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_education_type')}</label>
                                      <Select
                                        id='select_education_type'
                                        className="react-select"
                                        isMulti={true}
                                        closeMenuOnSelect={false}
                                        options={EducationTypeSelectOptions(T)}
                                        placeholder={T.t('gen_select_education_type')}
                                        value={props.values.education_types_select}
                                        onChange={(
                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                        ) => {
                                          const list: Array<Types.ISelectOption> = options
                                            ? (options as Array<Types.ISelectOption>)
                                            : [];
                                          props.setFieldValue('education_types_select', list);
                                          props.setFieldValue(
                                            'education_types',
                                            list.map((item) => item.value)
                                          );
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_education_type')}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="add-custom-tag mb-3">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_exam_duration')} ({T.t('gen_slot_count')})</label>
                                      <Select
                                        id='select_exam_duration'
                                        className="react-select"
                                        isMulti={true}
                                        closeMenuOnSelect={false}
                                        options={
                                          this.props.selectOptions && this.props.selectOptions.exam_durations_select
                                            ? this.props.selectOptions.exam_durations_select
                                            : []
                                        }
                                        placeholder={T.t('gen_exam_duration')}
                                        value={props.values.exam_durations_select ? props.values.exam_durations_select : null}
                                        onChange={(
                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                        ) => {
                                          const list: Array<Types.ISelectOption> = options
                                            ? (options as Array<Types.ISelectOption>)
                                            : [];
                                          props.setFieldValue('exam_durations_select', list);
                                          props.setFieldValue(
                                            'exam_durations',
                                            list.map((item) => item.value)
                                          );
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_exam_duration')}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="add-custom-tag mb-3">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_section')}</label>
                                      <Select
                                        id='select_section'
                                        className="react-select"
                                        isMulti={true}
                                        closeMenuOnSelect={false}
                                        options={
                                          this.props.selectOptions && this.props.selectOptions.sections_select
                                            ? this.props.selectOptions.sections_select
                                            : []
                                        }
                                        placeholder={T.t('gen_select_section')}
                                        value={props.values.sections_select ? props.values.sections_select : null}
                                        onChange={(
                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                        ) => {
                                          const list: Array<Types.ISelectOption> = options
                                            ? (options as Array<Types.ISelectOption>)
                                            : [];
                                          props.setFieldValue('sections_select', list);
                                          props.setFieldValue(
                                            'sections',
                                            list.map((item) => item.value)
                                          );
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_section')}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="add-custom-tag mb-3">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_grade')}</label>
                                      <Select
                                        id='select_grade'
                                        className="react-select"
                                        isMulti={true}
                                        closeMenuOnSelect={false}
                                        options={
                                          this.props.selectOptions && this.props.selectOptions.class
                                            ? this.props.selectOptions.class
                                            : []
                                        }
                                        placeholder={T.t('gen_select_grade')}
                                        value={props.values.class ? props.values.class : null}
                                        onChange={(
                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                        ) => {
                                          const list: Array<Types.ISelectOption> = options
                                            ? (options as Array<Types.ISelectOption>)
                                            : [];
                                          props.setFieldValue('class', list);
                                          props.setFieldValue(
                                            'class_ids',
                                            list.map((item) => item.value)
                                          );
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_grade')}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="add-custom-tag mb-3">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_faculty_college')}</label>
                                      <Select
                                        id='select_faculty'
                                        className="react-select"
                                        isMulti={true}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={false}
                                        options={
                                          this.props.selectOptions && this.props.selectOptions.faculties
                                            ? this.props.selectOptions.faculties
                                            : []
                                        }
                                        placeholder={T.t('gen_select_faculty')}
                                        value={props.values.faculties ? props.values.faculties : null}
                                        onChange={(
                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                        ) => {
                                          const list: Array<Types.ISelectOption> = options
                                            ? (options as Array<Types.ISelectOption>)
                                            : [];
                                          props.setFieldValue('faculties', list);
                                          props.setFieldValue(
                                            'faculty_ids',
                                            list.map((item) => item.value)
                                          );
                                          this.getProgramsByFacultiesAtCourses(list.map(item => item.value))
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="add-custom-tag mb-3">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_connected_programs')}</label>
                                      <Select
                                        id='select_program'
                                        className="react-select"
                                        isMulti={true}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={false}
                                        options={this.props.programs_related_faculty}
                                        placeholder={T.t('gen_select_program')}
                                        value={props.values.programs}
                                        onChange={(
                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                        ) => {
                                          const list: Array<Types.ISelectOption> = options
                                            ? (options as Array<Types.ISelectOption>)
                                            : [];
                                          props.setFieldValue('programs', list);
                                          props.setFieldValue(
                                            'program_ids',
                                            list.map((item) => item.value)
                                          );
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="add-custom-tag mb-3">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_classroom_combine_status')}</label>
                                      <Select
                                        id='select_classroom_combine_status'
                                        className="react-select"
                                        isMulti={true}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={false}
                                        options={ClassroomCombineTypes(T)}
                                        placeholder={T.t('gen_classroom_combine_status')}
                                        value={props.values.classroom_combine_status ? props.values.classroom_combine_status : null}
                                        onChange={(
                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                        ) => {
                                          const list: Array<Types.ISelectOption> = options
                                            ? (options as Array<Types.ISelectOption>)
                                            : [];
                                          props.setFieldValue('classroom_combine_status', list);
                                          props.setFieldValue(
                                            'classroom_combine_status_ids',
                                            list.map((item) => item.value)
                                          );
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="add-custom-tag mb-3">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_exam_type')}</label>
                                      <Select
                                        id='select_exam_type'
                                        className="react-select"
                                        isMulti={true}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={false}
                                        options={ExamTypes(T)}
                                        placeholder={T.t('gen_select_exam_type')}
                                        value={props.values.exam_type ? props.values.exam_type : null}
                                        onChange={(
                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                        ) => {
                                          const list: Array<Types.ISelectOption> = options
                                            ? (options as Array<Types.ISelectOption>)
                                            : [];
                                          props.setFieldValue('exam_type', list);
                                          props.setFieldValue(
                                            'exam_type_ids',
                                            list.map((item) => item.value)
                                          );
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_exam_type')}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="add-custom-tag mb-3">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_exam_environment')}</label>
                                      <Select
                                        id='select_exam_environmet'
                                        className="react-select"
                                        isMulti={true}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={false}
                                        options={ExamEnvironments(T)}
                                        placeholder={T.t('gen_select_exam_environment')}
                                        value={props.values.exam_environment ? props.values.exam_environment : null}
                                        onChange={(
                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                        ) => {
                                          const list: Array<Types.ISelectOption> = options
                                            ? (options as Array<Types.ISelectOption>)
                                            : [];
                                          props.setFieldValue('exam_environment', list);
                                          props.setFieldValue(
                                            'exam_environment_ids',
                                            list.map((item) => item.value)
                                          );
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_exam_environment')}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="add-custom-tag mb-3">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_exam_overlap_type')}</label>
                                      <Select
                                        id='select_exam_eoverlap_type'
                                        className="react-select"
                                        isMulti={true}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={false}
                                        options={ExamOverlapTypes(T)}
                                        placeholder={T.t('gen_select_exam_overlap_type')}
                                        value={props.values.exam_overlap_type ? props.values.exam_overlap_type : null}
                                        onChange={(
                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                        ) => {
                                          const list: Array<Types.ISelectOption> = options
                                            ? (options as Array<Types.ISelectOption>)
                                            : [];
                                          props.setFieldValue('exam_overlap_type', list);
                                          props.setFieldValue(
                                            'exam_overlap_type_ids',
                                            list.map((item) => item.value)
                                          );
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_exam_overlap_type')}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="add-custom-tag mb-3">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_instructors')}</label>
                                      <Select
                                        id='select_instructor'
                                        className="react-select"
                                        isMulti={true}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={false}
                                        options={
                                          this.props.selectOptions && this.props.selectOptions.instructors
                                            ? this.props.selectOptions.instructors
                                            : []
                                        }
                                        placeholder={T.t('gen_select_instructor')}
                                        value={props.values.instructors}
                                        onChange={(
                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                        ) => {
                                          const list: Array<Types.ISelectOption> = options
                                            ? (options as Array<Types.ISelectOption>)
                                            : [];
                                          props.setFieldValue('instructors', list);
                                          props.setFieldValue(
                                            'instructor_ids',
                                            list.map((item) => item.value)
                                          );
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="add-custom-tag mb-3">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_assistants')}</label>
                                      <Select
                                        id='select_assistant'
                                        className="react-select"
                                        isMulti={true}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={false}
                                        options={
                                          this.props.selectOptions && this.props.selectOptions.instructors
                                            ? this.props.selectOptions.instructors
                                            : []
                                        }
                                        placeholder={T.t('gen_select_assistant')}
                                        value={props.values.assistant_staff}
                                        onChange={(
                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                        ) => {
                                          const list: Array<Types.ISelectOption> = options
                                            ? (options as Array<Types.ISelectOption>)
                                            : [];
                                          props.setFieldValue('assistant_staff', list);
                                          props.setFieldValue(
                                            'assistant_staff_ids',
                                            list.map((item) => item.value)
                                          );
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_assistan')}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="add-custom-tag mb-3">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_course_language')}</label>
                                      <Select
                                        id='select_course_language'
                                        className="react-select"
                                        isMulti={true}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={true}
                                        options={
                                          this.props.selectOptions && this.props.selectOptions.course_languages
                                            ? this.props.selectOptions.course_languages
                                            : []
                                        }
                                        placeholder={T.t('gen_select_course_language')}
                                        value={props.values.course_languages ? props.values.course_languages : null}
                                        onChange={(
                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                        ) => {
                                          const list: Array<Types.ISelectOption> = options
                                            ? (options as Array<Types.ISelectOption>)
                                            : [];
                                          props.setFieldValue('course_languages', list);
                                          props.setFieldValue(
                                            'course_language_ids',
                                            list.map((item) => item.value.toString())
                                          );
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_course_language')}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="add-custom-tag mb-3">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_classroom_type')}</label>
                                      <Select
                                        id='select_type'
                                        className="react-select"
                                        isMulti={true}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={false}
                                        options={
                                          this.props.selectOptions && this.props.selectOptions.classroom_types
                                            ? this.props.selectOptions.classroom_types
                                            : []
                                        }
                                        placeholder={T.t('gen_select_type')}
                                        value={props.values.classroom_types ? props.values.classroom_types : null}
                                        onChange={(
                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                        ) => {
                                          const list: Array<Types.ISelectOption> = options
                                            ? (options as Array<Types.ISelectOption>)
                                            : [];
                                          props.setFieldValue('classroom_types', list);
                                          props.setFieldValue(
                                            'classroom_type_ids',
                                            list.map((item) => item.value.toString())
                                          );
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="add-custom-tag mb-3">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_invigilator_status')}</label>
                                      <Select
                                        id='select_invigilator_status'
                                        className="react-select"
                                        isMulti={true}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={false}
                                        options={InvigilatorStatuses(T)}
                                        placeholder={T.t('gen_select_invigilator_status')}
                                        value={props.values.invigilator_status ? props.values.invigilator_status : null}
                                        onChange={(
                                          options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                        ) => {
                                          const list: Array<Types.ISelectOption> = options
                                            ? (options as Array<Types.ISelectOption>)
                                            : [];
                                          props.setFieldValue('invigilator_status', list);
                                          props.setFieldValue(
                                            'invigilator_status_ids',
                                            list.map((item) => item.value)
                                          );
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_invigilator_status')}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3 mt-3">
                                  <div className="form-input form-group date-picker">
                                    <input
                                      id="description"
                                      name="description"
                                      value={props.values.description}
                                      onChange={props.handleChange}
                                      type="text"
                                      required
                                    />
                                    <span className="highlight" />
                                    <span className="bar" />
                                    <label htmlFor="description">{T.t('gen_description')}</label>
                                    <i className="material-icons">title</i>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="row mt-3">
                                <div className="col-6">
                                  <button
                                    id='button_arrow_upward'
                                    type="button"
                                    onClick={() => {
                                      this.state.filterIsOpen = false;
                                      this.setState(this.state);
                                    }}
                                    className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                                  >
                                    <i className="material-icons">arrow_upward</i>
                                  </button>
                                  <button
                                    id='button_delete_sweep'
                                    type="reset"
                                    onClick={props.handleReset}
                                    className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                                  >
                                    <i className="material-icons">delete_sweep</i>
                                  </button>
                                </div>
                                <div className="col-6 text-right">
                                  <button
                                    id='button_search'
                                    type="button"
                                    className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                                    onClick={() => props.handleSubmit()}
                                    disabled={props.isSubmitting}
                                  >
                                    <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                                  </button>
                                </div>
                              </div>
                            </form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                    <thead>
                      <tr>
                        <th data-cell="select">
                          <div className="tick-radio position-relative">
                            <input
                              id='selected_ids'
                              type="checkbox"
                              className="form-radio"
                              checked={
                                this.state.selected_ids && this.props.filters && this.state.selected_ids.length == this.props.filters.total
                              }
                              onChange={this.onSelectAll}
                            />
                          </div>
                        </th>
                        <SortedColumn
                          datacell="status"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_status')}
                          sortkey="status"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="course_no"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_course_no')}
                          sortkey="course_no"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="course_code"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_code')}
                          sortkey="course_code"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="conjugate_course_code"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_conjugate_course_code')}
                          sortkey="conjugate_course_code"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="name"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_name')}
                          sortkey="name"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="student_count"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_student_count')}
                          sortkey="student_count"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="total_student_count"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_total_student_count')}
                          sortkey="total_student_count"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_grade')}</span>
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_section')}</span>
                        </th>
                        <SortedColumn
                          datacell="term"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_faculty')}
                          sortkey="faculty_name"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="campus"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_campus')}
                          sortkey="campus"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="exam_duration"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_exam_duration')}
                          sortkey="exam_duration"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="exam_session"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_session_count')}
                          sortkey="exam_session"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="classroom_combine_status"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_classroom_combine_status')}
                          sortkey="classroom_combine_status"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="exam_type"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_exam_type')}
                          sortkey="exam_type"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="exam_environment"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_exam_environment')}
                          sortkey="exam_environment"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="exam_overlap_type"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_exam_overlap_type')}
                          sortkey="exam_overlap_type"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="programs"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_connected_programs')}
                          sortkey="programs"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="lecture_staff"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_lecture_staff')}
                          sortkey="lecture_staff"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <th scope="col" className="text-center">
                          {T.t('gen_assistants')}
                        </th>
                        <th scope="col" className="text-center">
                          <span className="text-center">{T.t('gen_description')}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {courseList && courseList.length ? (
                        courseList.map((item: any) => {
                          let ClassroomCombineStatusObject = ClassroomCombineTypes(T).find(
                            object => object.value == item.classroom_combine_status
                          );

                          let ExamTypesObject = ExamTypes(T).find(
                            (object) => object.value == item.exam_type
                          );

                          let ExamEnvironmentsObject = ExamEnvironments(T).find(
                            (object) => object.value == item.exam_environment
                          );

                          let ExamOverlapTypesObject = ExamOverlapTypes(T).find(
                            (object) => object.value == item.exam_overlap_type
                          );

                          return (
                            <tr key={'course-' + item.course_id} data-title={item.name}>
                              <td data-cell="select">
                                <div className="tick-radio position-relative">
                                  <input
                                    id='select_course'
                                    type="checkbox"
                                    className="form-radio"
                                    checked={
                                      this.state.selected_ids && this.state.selected_ids.indexOf(item.course_id ? item.course_id : -1) > -1
                                    }
                                    data-id={item.course_id}
                                    disabled={item.course_term_status === 2}
                                    onChange={this.onSelectCourse}
                                  />
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_status')} id={'t-' + item.course_id}>
                                <div className="tags ml-1 mr-4">
                                  <button
                                    id="tesat"
                                    className={`small-tag text-uppercase` + ((item.status == 2 || item.status == 0) ? ` tag-red` : ` tag-green`)}
                                  >
                                    {GT.GetCourseTermStatus(item.status)}
                                  </button>
                                  {item.course_term_status == 2 ? (
                                    <UncontrolledPopover trigger="hover focus" placement="bottom" target={'t-' + item.course_id}>
                                      <PopoverBody className="scrollable-popoverbody">
                                        Bu ders, birleştirilmiş bir sınavın içinde olduğu için ayrıca eklenemez.
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  ) : null}
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_course_no')} className="text-center">
                                {item && item.course_id}
                              </td>
                              <td scope="row" data-label={T.t('gen_code')} className="text-center">
                                <div className="table-scrollable-td">{item && item.course_code ? item.course_code.split(';').join(', ') : '-'}</div>
                              </td>
                              <td scope="row" data-label={T.t('gen_name')} className="text-center">
                                <div className="table-scrollable-td">{item && item.conjugate_course_code ? item.conjugate_course_code.split(';').join(', ') : '-'}</div>
                              </td>
                              <td scope="row" data-label={T.t('gen_name')} className="text-center">
                                <div className="table-scrollable-td">{item && item.name ? item.name.split(';').join(', ') : '-'}</div>
                              </td>
                              <td className="text-center">
                                {
                                  item && item.student_counts && item.student_counts.length > 0 ?
                                    <div className="table-scrollable-td">{item.student_counts && item.student_counts.map((i: any, index: any) => (index == item!.student_counts!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  item && item.student_counts && item.student_counts.length > 0 ?
                                    <div className="table-scrollable-td">{item.student_count_total}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  item && item.classes && item.classes.length > 0 ?
                                    <div className="table-scrollable-td">{item.classes && item.classes.map((i: any, index: any) => (index == item!.classes!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  item && item.sections && item.sections.length > 0 ?
                                    <div className="table-scrollable-td">{item.sections && item.sections.map((i: any, index: any) => (index == item!.sections!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  item && item.faculties && item.faculties.length > 0 ?
                                    <div className="table-scrollable-td">{item.faculties && item.faculties.map((i: any, index: any) => (index == item!.faculties!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  item && item.campuses && item.campuses.length > 0 ?
                                    <div className="table-scrollable-td">{item.campuses && item.campuses.map((i: any, index: any) => (index == item!.campuses!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td scope="row" data-label={T.t('gen_exam_duration')} className="text-center">
                                {item && item.exam_duration}
                              </td>
                              <td data-label={T.t('gen_session_count')} className="text-center">
                                {item && item.exam_session}
                              </td>
                              <td data-label={T.t('gen_classroom_combine_status')} className="text-center">
                                {ClassroomCombineStatusObject && ClassroomCombineStatusObject.label}
                              </td>
                              <td data-label={T.t('gen_exam_type')} className="text-center">
                                {ExamTypesObject && ExamTypesObject.label ? ExamTypesObject && ExamTypesObject.label : '-'}
                              </td>
                              <td data-label={T.t('gen_exam_environment')} className="text-center">
                                {ExamEnvironmentsObject && ExamEnvironmentsObject.label ? ExamEnvironmentsObject && ExamEnvironmentsObject.label : '-'}
                              </td>
                              <td data-label={T.t('gen_exam_overlap_type')} className="text-center">
                                {ExamOverlapTypesObject && ExamOverlapTypesObject.label ? ExamOverlapTypesObject && ExamOverlapTypesObject.label : '-'}
                              </td>
                              <td className="text-center">
                                {
                                  item && item.programs && item.programs.length > 0 ?
                                    <div className="table-scrollable-td">{item.programs && item.programs.map((i: any, index: any) => (index == item!.programs!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  item && item.instructors && item.instructors.length > 0 ?
                                    <div className="table-scrollable-td">{item.instructors && item.instructors.map((i: any, index: any) => (index == item!.instructors!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td className="text-center">
                                {
                                  item && item.assistant_staff && item.assistant_staff.length > 0 ?
                                    <div className="table-scrollable-td">{item.assistant_staff && item.assistant_staff.map((i: any, index: any) => (index == item!.assistant_staff!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                }
                              </td>
                              <td data-label={T.t('gen_description')} className="text-center">
                                {item.description && item.description.length ?
                                  <div className="table-scrollable-td">{item.description}</div> : '-'}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={16}>
                            <Announce title={T.t('gen_no_records_found')} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="row-options justify-content-end">
                    <div className="page-sorting d-flex align-items-center justify-content-center" style={{ marginTop: '5px' }}>
                      {this.props.results && this.props.results.length > 0 ? (
                        <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ICoursePageProps): Types.ICoursePageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.ICoursePageProps = Object.assign({}, ownProps, {
    results: store.state.course_page && store.state.course_page.results,
    filters: store.state.course_page && store.state.course_page.filters,
    all_ids: store.state.course_page && store.state.course_page.all_ids,
    selectOptions: store.state.select_options && store.state.select_options.coursePage,
    programs_related_faculty: store.state.select_options && store.state.select_options.programs_related_faculty
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  // if (next.state.course_page) {
  //   return (
  //     !!equal(prev.state.course_page && prev.state.course_page.results, next.state.course_page && next.state.course_page.results) &&
  //     !!equal(prev.state.course_page && prev.state.course_page.all_ids, next.state.course_page && next.state.course_page.all_ids) &&
  //     !!equal(
  //       prev.state.select_options && prev.state.select_options.coursePage,
  //       next.state.select_options && next.state.select_options.coursePage
  //     )
  //   );
  // } else {
  //   return true;
  // }
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CourseAddModal);

export default container;
