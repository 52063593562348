import { put, takeEvery, all, take, select } from 'redux-saga/effects';
import * as actions from '../constants/all';
import { Log } from 'ng2-logger';
import PerculusApiClient from '../../services/perculus-api-client';
import * as Types from '../types';
import * as Actions from '../actions/general';
import { SettingsDashboardStatus } from '../../store/constants/dashboard-const';
import { routes as Routes } from '../../store/constants/routes';
import * as GT from '../../tools/general-tools';
import MockAPIClient from '../../services/mock-api-client';

const L = Log.create('core-saga');

function* setWellKnownUnits(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response = yield MockAPI.GetResponse(MockAPI.GetWellKnownUnit());
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('notification_error_occured_report_manager', 'gen_warning', 'danger'));
  }

  yield put(Actions.ApiResponse(action, response, content));
}

function* setAuthUser(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response = yield MockAPI.GetResponse(MockAPI.GetAuthUser());
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }

  yield put(Actions.ApiResponse(action, response, content));

  if (content && (content.role == 's' || content.role == 'p' || content.role == 'r') && (content.dashboardStatus == SettingsDashboardStatus.ActiveForCourseTerms || content.dashboardStatus == SettingsDashboardStatus.ActiveForAllTerms)) {
    yield put(Actions.Navigation(GT.Route(Routes.DASHBOARD_COURSE_PLANNING), 100));
  } else if (content && (content.role == 's' || content.role == 'p' || content.role == 'r') && content.dashboardStatus == SettingsDashboardStatus.ActiveForExamTerms) {
    yield put(Actions.Navigation(GT.Route(Routes.DASHBOARD_EXAM_PLANNING), 100));
  } else {
    yield put(Actions.Navigation(GT.Route(Routes.EXAM_PERIODS), 100));
  }
}

export function* getWellKnownUnits() {
  yield takeEvery((action: any) => action.target === actions.gen.CORE_WELL_KNOWN_UNITS, setWellKnownUnits);
}

export function* getAuthUser() {
  yield takeEvery((action: any) => action.target === actions.gen.CORE_AUTH_USER, setAuthUser);
}

export default function* coreSagas() {
  yield all([getWellKnownUnits(), getAuthUser()]);
}
