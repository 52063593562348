import { put, takeEvery, all, take, select, call } from 'redux-saga/effects';
import * as Actions from '../actions/general';
import { constants } from '../constants/solution-const';
import * as Types from '../types';
import MockAPIClient from '../../services/mock-api-client';
import { Log } from 'ng2-logger';

function* getSolutionByTerm(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;

  if (action.body && action.body.reset) {
    response = new Response(null);
    content = null;
  } else {
    response = yield MockAPI.GetResponse(MockAPI.GetSolutionByTerm(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    } else if (response && response.status === 404) {
      yield put(Actions.Notification('gen_solution_not_found', 'gen_warning', 'warning'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSolution(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;

  if (action.body && action.body.reset) {
    response = new Response(null);
    content = null;
  } else {
    response = yield MockAPI.GetResponse(MockAPI.GetSolution(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    } else if (response && response.status === 404) {
      yield put(Actions.Notification('gen_solution_not_found', 'gen_warning', 'warning'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getCourseSolution(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;

  if (action.body && action.body.reset) {
    response = new Response(null);
    content = null;
  } else {
    response = yield MockAPI.GetResponse(MockAPI.GetCourseSolution(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    } else if (response && response.status === 404) {
      yield put(Actions.Notification('gen_solution_not_found', 'gen_warning', 'warning'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getInvigilatorCount(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;

  if (action.body && action.body.reset) {
    response = new Response(null);
    content = null;
  } else {
    response = yield MockAPI.GetResponse(MockAPI.GetInvigilatorCount(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getPublicSolution(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;

  if (action.body && action.body.reset) {
    response = new Response(null);
    content = null;
  } else {
    response = yield MockAPI.GetResponse(MockAPI.PublicSolution(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* publishSolution(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.PublishSolution(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
    yield put(Actions.Notification(content == "Çözüm yayınlandı." ? 'notification_solution_published' : 'notification_solution_unpublished', 'gen_success'));
    if (action.callback) {
      action.callback();
    }
  } else {
    yield put(
      Actions.Notification('notification_solution_saved_error', 'gen_warning', 'danger')
    );
  }
}

function* copySolution(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.CopySolution(action.body));
  let content: any = yield MockAPI.GetContent(response);
  if (response && response.status === 200) {
    if (action.callback) {
      action.callback();
    }
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteSolution(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DeleteSolution(action.body));
  let content: any = yield MockAPI.GetContent(response);
  if (response && response.status === 200) {
    if (action.callback) {
      action.callback();
    }
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSolutionActivitySelectOptions(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SolutionGetActivitySelectOptions(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSolutionActivityClassroomSelectOptions(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SolutionGetActivityClassroomSelectOptions(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* solutionEditActivity(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.SolutionEditActivity(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status !== 200) {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* solutionAddActivity(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.SolutionAddActivity(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status !== 200) {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* solutionEditCourse(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.SolutionEditCourse(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status !== 200) {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* solutionAddCourse(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.SolutionAddCourse(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status !== 200) {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* solutionDeleteCourse(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.SolutionDeleteCourse(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_course_deleted', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* solutionDeleteActivity(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.SolutionDeleteActivity(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_course_deleted', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSolutionGetProgramsByFaculties(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SolutionGetProgramsByFaculties(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSolutionCourseGetProgramsByFaculties(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SolutionCourseGetProgramsByFaculties(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSolutionCourseSelectOptions(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SolutionGetCourseSelectOptions(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* downloadInstructorExamList(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.InstructorDownloadExamList(action.body));
  let content: any = yield MockAPI.GetContent(response);
  if (response && response.status === 200) {
  } else if (response && response.status == 404) {
    yield put(Actions.Notification(content, 'gen_warning', 'warning'));
  } else {
    yield put(Actions.Notification('notification_error_preparing_data', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* downloadStudentExamList(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.StudentDownloadExamList(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('notification_error_preparing_data', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* downloadSolutionRawFiles(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.SolutionDownloadRawFiles(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('notification_solution_not_from_distribution', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSolutionActivityPrograms(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SolutionActivityPrograms(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSolutionCoursePrograms(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SolutionCoursePrograms(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSolutionExamDates(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.SolutionGetExamDates(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getSolutionBatchChangeExamDates(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.SolutionBatchChangeExamDates(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_exam_dates_changed', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

export function* watchGetSolutionByTerm() {
  yield takeEvery((action: any) => action.target === constants.SOLUTION_GET_BY_TERM, getSolutionByTerm);
}

export function* watchGetSolution() {
  yield takeEvery((action: any) => action.target === constants.SOLUTION_LIST_SEARCH, getSolution);
}

export function* watchGetCourseSolution() {
  yield takeEvery((action: any) => action.target === constants.SOLUTION_ACTIVITY_LIST_SEARCH, getCourseSolution);
}

export function* watchGetInvigilatorCountSolution() {
  yield takeEvery((action: any) => action.target === constants.SOLUTION_LIST_INVIGILATOR_COUNTS, getInvigilatorCount);
}

export function* watchSaveSolution() {
  yield takeEvery((action: any) => action.target === constants.SOLUTION_PUBLISH_SOLUTION, publishSolution);
}

export function* watchCopySolution() {
  yield takeEvery((action: any) => action.target === constants.SOLUTION_COPY_SOLUTION, copySolution);
}

export function* watchDeleteSolution() {
  yield takeEvery((action: any) => action.target === constants.SOLUTION_DELETE_SOLUTION, deleteSolution);
}

export function* watchPublicSolution() {
  yield takeEvery((action: any) => action.target === constants.COURSE_SOLUTION_PUBLIC, getPublicSolution);
}

export function* watchSolutionActivitySelectOptions() {
  yield takeEvery((action: any) => action.target === constants.SOLUTION_ACTIVITY_SELECT_OPTIONS, getSolutionActivitySelectOptions);
}

export function* watchSolutionActivityClassroomSelectOptions() {
  yield takeEvery((action: any) => action.target === constants.SOLUTION_ACTIVITY_CLASSROOM_SELECT_OPTIONS, getSolutionActivityClassroomSelectOptions);
}

export function* watchSolutionEditActivity() {
  yield takeEvery((action: any) => action.target === constants.SOLUTION_EDIT_ACTIVITY, solutionEditActivity);
}

export function* watchSolutionAddActivity() {
  yield takeEvery((action: any) => action.target === constants.SOLUTION_ADD_ACTIVITY, solutionAddActivity);
}

export function* watchSolutionDeleteActivity() {
  yield takeEvery((action: any) => action.target === constants.SOLUTION_DELETE_ACTIVITY, solutionDeleteActivity);
}

export function* watchSolutionGetProgramsByFaculties() {
  yield takeEvery((action: any) => action.target === constants.SOLUTION_GET_PROGRAMS_BY_FACULTIES, getSolutionGetProgramsByFaculties);
}

export function* watchSolutionCourseGetProgramsByFaculties() {
  yield takeEvery((action: any) => action.target === constants.SOLUTION_COURSE_GET_PROGRAMS_BY_FACULTIES, getSolutionCourseGetProgramsByFaculties);
}

export function* watchSolutionCourseSelectOptions() {
  yield takeEvery((action: any) => action.target === constants.SOLUTION_COURSE_SELECT_OPTIONS, getSolutionCourseSelectOptions);
}

export function* watchSolutionEditCourse() {
  yield takeEvery((action: any) => action.target === constants.SOLUTION_EDIT_COURSE, solutionEditCourse);
}

export function* watchSolutionAddCourse() {
  yield takeEvery((action: any) => action.target === constants.SOLUTION_ADD_COURSE, solutionAddCourse);
}

export function* watchSolutionDeleteCourse() {
  yield takeEvery((action: any) => action.target === constants.SOLUTION_DELETE_COURSE, solutionDeleteCourse);
}

export function* watchDownloadStudentExamList() {
  yield takeEvery((action: any) => action.target === constants.STUDENT_DOWNLOAD_EXAM_LIST, downloadStudentExamList);
}

export function* watchDownloadInstructorExamList() {
  yield takeEvery((action: any) => action.target === constants.INSTRUCTOR_DOWNLOAD_EXAM_LIST, downloadInstructorExamList);
}

export function* watchDownloadSolutionRawFiles() {
  yield takeEvery((action: any) => action.target === constants.SOLUTION_DOWNLOAD_RAW_FILES, downloadSolutionRawFiles);
}

export function* watchSolutionActivityPrograms() {
  yield takeEvery((action: any) => action.target === constants.SOLUTION_GET_ACTIVITY_PROGRAMS, getSolutionActivityPrograms);
}

export function* watchSolutionCoursePrograms() {
  yield takeEvery((action: any) => action.target === constants.SOLUTION_GET_COURSE_PROGRAMS, getSolutionCoursePrograms);
}

export function* watchSolutionGetExamDates() {
  yield takeEvery((action: any) => action.target === constants.SOLUTION_GET_EXAM_DATES, getSolutionExamDates);
}

export function* watchSolutionBatchChangeExamDates() {
  yield takeEvery((action: any) => action.target === constants.SOLUTION_BATCH_CHANGE_EXAM_DATES, getSolutionBatchChangeExamDates);
}