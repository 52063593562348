import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Formik, FormikActions, FormikProps } from 'formik';
import { ValueType } from 'react-select/lib/types';
import { Log } from 'ng2-logger';
import * as Actions from '../../../store/actions/general';
import * as Constants from '../../../store/constants/all';
import * as Types from '../../../store/types';
import { ActivitySearchInitialValues, ActivityTermLectureLocations, LectureLocationsLabel, ActivityTypes, ActivityTypesLabel, CourseTypesLabel, CourseEnvironments } from '../../../store/constants/activity-const';
import { routes as Routes } from '../../../store/constants/routes';
import * as GT from '../../../tools/general-tools';
import ImportModal, { ExcelImportKeys } from '../../../components/excel-imports/import-modal';
import CourseForm from './course-form';
import SyncCourseModal from './course-sync-modal';
import MultipleCheckbox from '../../../components/checkboxes/multiple-checkbox';
import Spinner from '../../../components/templates/spinner';
import MainLayout from '../../layouts/main-layout';
import SimplePage from '../../../components/templates/simple-page';
import APlanHeader from '../../../components/templates/aplan-header';
import SortedColumn from '../../../components/table/sorted-column';
import Paginate from '../../../components/table/paginate';
import DownloadButton from '../../../components/excel-imports/export';
import DownloadButtonSyncResult from '../../../components/excel-imports/export-sync-result';
import { SectionTypes } from '../../../store/constants/enums';
import { Grades, EducationTypeSelectOptions, CourseTypes } from '../../../store/constants/course-const';
import ChangeAllDataModal from './change-all-data-modal';
import Translator from '../../../services/translate-factory';
import excelLogo from '../../../assets_aplan/images/xls.svg';

const T = Translator.create();
const L = Log.create('CourseListPage');

class CourseTable extends Component<Types.IActivityPageProps, Types.IActivityPageState> {
  state: Types.IActivityPageState = {
    filters: ActivitySearchInitialValues,
    filterIsOpen: false,
    viewMenuIsOpen: false,
    selectedView: 'Sınav Planlama',
    integrationModalIsOpen: false,
    activityFormIsOpen: false,
    activityNo: undefined,
    selected_ids: [],
    all_ids: [],
    is_related: false,
    changeAllDataModalIsOpen: false
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.searchCourse();
    this.getCourseSelectOptions();
    this.getProgramsByFaculties([]);
    this.state.is_related = this.props.is_related
    this.setState(this.state);
  }

  searchCourse() {
    let newModel = {
      activity_noes_ids: this.state.filters.activity_noes_ids,
      course_codes_ids: this.state.filters.course_codes_ids,
      activity_type: this.state.filters.activity_type,
      add_list: this.state.filters.add_list,
      assistant_staff: this.state.filters.assistant_staff,
      course_name: this.state.filters.course_name,
      education_type: this.state.filters.education_types && this.state.filters.education_types.map((i: any) => i.value),
      course_type: this.state.filters.course_type,
      grades_ids: this.state.filters.grades_ids,
      instructor_ids: this.state.filters.instructor_ids,
      instructors: this.state.filters.instructors,
      lecture_location_ids: this.state.filters.lecture_location_ids,
      lecture_locations: this.state.filters.lecture_locations,
      lecture_staff: this.state.filters.lecture_staff,
      lesson_counts_ids: this.state.filters.lesson_counts_ids,
      order_by: this.state.filters.order_by,
      page: this.state.filters.page,
      practical_staff: this.state.filters.practical_staff,
      faculty_ids: this.state.filters.faculty_ids,
      faculties: this.state.filters.faculties,
      program_ids: this.state.filters.program_ids,
      programs: this.state.filters.programs,
      query: this.state.filters.query,
      sections: this.state.filters.sections,
      select_all: this.state.filters.select_all,
      size: this.state.filters.size,
      status: this.state.filters.status,
      total: this.state.filters.total,
      course_environment_ids: this.state.filters.course_environment_ids,
      course_language_ids: this.state.filters.course_language_ids,
      classroom_type_ids: this.state.filters.classroom_type_ids,
      week: this.state.filters.week
    }
    this.props.dispatch(
      Actions.ApiRequest(Constants.activity.ACTIVITY_LIST_SEARCH, newModel, 'course-list-spin')
    );
  }

  selectView = (viewType: string) => {
    this.state.selectedView = viewType;
    this.setState(this.state);
  };

  getCourseSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_GET_SELECT_OPTIONS, 'course-list-spin'));
  }

  onCourseImported = () => {
    this.searchCourse();
  };

  toggleViewMenu = () => {
    this.state.viewMenuIsOpen = !this.state.viewMenuIsOpen;
    this.setState(this.state);
  };

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.searchCourse();
  };

  activityFormIsOpen = () => {
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.COURSE, '/create')));

    let fields: Types.IActivityItem = {
      name: '',
      student_count: -1,
      instructor_ids: [],
      assistant_staff: [],
      assistant_staff_ids: [],
      programs: [],
      program_ids: [],
      campus: undefined,
      campus_id: 0,
      exam_duration: undefined,
      class: undefined,
      section: '',
      sections: [],
      exam_session: undefined,
      description: '',
      program_id: 0,
      faculty_id: 0,
    };

    this.setState({
      ...this.state,
      activityFormIsOpen: !this.state.activityFormIsOpen,
      editActivity: fields
    });
  };

  activityFormOnClose = () => {
    if (this.state.activityFormIsOpen) {
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.COURSE)));

      this.setState({
        ...this.state,
        activityFormIsOpen: !this.state.activityFormIsOpen
      });
    }

    this.searchCourse();
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.searchCourse();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, ActivitySearchInitialValues);
    this.setState(this.state);
    this.searchCourse();
  };

  onFilterActivity(model: Types.IFilterActivity, FormActions: FormikActions<Types.IFilterActivity>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.searchCourse();
    FormActions.setSubmitting(false);
  }

  onActivityEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.currentTarget) {
      const id: string = e.currentTarget.dataset.id || '';
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.COURSE, '/' + id)));
    }
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        let newModel = {
          activity_noes_ids: this.state.filters.activity_noes_ids ? this.state.filters.activity_noes_ids : null,
          course_codes_ids: this.state.filters.course_codes_ids ? this.state.filters.course_codes_ids : null,
          activity_type: this.state.filters.activity_type ? this.state.filters.activity_type : null,
          add_list: this.state.filters.add_list ? this.state.filters.add_list : null,
          assistant_staff: this.state.filters.assistant_staff && this.state.filters.assistant_staff != [] ? this.state.filters.assistant_staff : null,
          course_name: this.state.filters.course_name && this.state.filters.course_name != "" ? this.state.filters.course_name : null,
          education_type: this.state.filters.education_types && this.state.filters.education_types.map((i: any) => i.value) ? this.state.filters.education_types && this.state.filters.education_types.map((i: any) => i.value) : null,
          course_type: this.state.filters.course_type ? this.state.filters.course_type : null,
          grades_ids: this.state.filters.grades_ids ? this.state.filters.grades_ids : null,
          instructor_ids: this.state.filters.instructor_ids && this.state.filters.instructor_ids != [] ? this.state.filters.instructor_ids : null,
          instructors: this.state.filters.instructors && this.state.filters.instructors != [] ? this.state.filters.instructors : null,
          lecture_location_ids: this.state.filters.lecture_location_ids && this.state.filters.lecture_location_ids != [] ? this.state.filters.lecture_location_ids : null,
          lecture_locations: this.state.filters.lecture_locations && this.state.filters.lecture_locations != [] ? this.state.filters.lecture_locations : null,
          lecture_staff: this.state.filters.lecture_staff && this.state.filters.lecture_staff != [] ? this.state.filters.lecture_staff : null,
          lesson_counts_ids: this.state.filters.lesson_counts_ids ? this.state.filters.lesson_counts_ids : null,
          order_by: this.state.filters.order_by,
          page: this.state.filters.page,
          practical_staff: this.state.filters.practical_staff && this.state.filters.practical_staff != [] ? this.state.filters.practical_staff : null,
          faculty_ids: this.state.filters.faculty_ids && this.state.filters.faculty_ids != [] ? this.state.filters.faculty_ids : null,
          faculties: this.state.filters.faculties && this.state.filters.faculties != [] ? this.state.filters.faculties : null,
          program_ids: this.state.filters.program_ids && this.state.filters.program_ids != [] ? this.state.filters.program_ids : null,
          programs: this.state.filters.programs && this.state.filters.programs != [] ? this.state.filters.programs : null,
          query: this.state.filters.query ? this.state.filters.query : null,
          sections: this.state.filters.sections && this.state.filters.sections != [] ? this.state.filters.sections : null,
          select_all: true,
          size: this.state.filters.size,
          status: this.state.filters.status && this.state.filters.status != [] ? this.state.filters.status : null,
          term_id: this.state.filters.term_id ? this.state.filters.term_id : null,
          total: this.state.filters.total ? this.state.filters.total : null,
          week: this.state.filters.week ? this.state.filters.week : null,
          course_language_ids: this.state.filters.course_language_ids ? this.state.filters.course_language_ids : null,
          course_environment_ids: this.state.filters.course_environment_ids ? this.state.filters.course_environment_ids : null,
          classroom_type_ids: this.state.filters.classroom_type_ids ? this.state.filters.classroom_type_ids : null,
          description: this.state.filters.description ? this.state.filters.description : null
        };
        this.setState(this.state);
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.activity.ACTIVITY_LIST_SEARCH,
            newModel,
            'activity-list-spin',
            (response: any) =>
              this.setState({
                ...this.state,
                all_ids: response.all_ids,
                selected_ids: response.all_ids
              })
          )
        );
      } else {
        this.setState({
          ...this.state,
          all_ids: [],
          selected_ids: [],
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
    }
  };

  checkAllIdsSelected = (): boolean => {
    const all_ids = this.state.all_ids ? this.state.all_ids : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  onSelectCourse = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
        }
        this.setState({
          ...this.state,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList
      });
    }
  };

  onDeleteCourse = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const deleteList = this.state.selected_ids;
    this.props.dispatch(
      Actions.ApiRequest(Constants.activity.ACTIVITY_IS_RELATED, deleteList, 'activity-list-spin')
    );
    setTimeout(() => {
      if (this.props.is_related) {
        this.props.dispatch(
          Actions.ShowModal({
            title: T.t('gen_has_activity_relations'),
            body: T.t('gen_has_activity_relations_info'),
            name: 'course_delete',
            icon: 'warning',
            iconColor: 'red',
            cancel: T.t('gen_ok'),
            confirm: T.t('gen_go_to_activity_relations'),
            onConfirm: () => {
              this.props.dispatch(Actions.Navigation(GT.Route(Routes.ACTIVITY_RELATIONS)))
            }
          })
        );
      } else if (!this.props.is_related) {
        this.props.dispatch(
          Actions.ShowModal({
            title: T.t('gen_delete_action'),
            body: T.t('gen_delete_course_question'),
            name: 'course_delete',
            icon: 'warning',
            iconColor: 'red',
            confirm: T.t('gen_yes'),
            cancel: T.t('gen_cancel'),
            onConfirm: () => {
              const resultCallback = (status: number) => {
                if (status == 200) {
                  this.state.selected_ids = [];
                  this.state.filters = ActivitySearchInitialValues;
                  this.setState(this.state);
                  this.searchCourse();
                }
              };

              const deleteList = this.state.selected_ids;
              this.props.dispatch(
                Actions.ApiRequest(Constants.activity.ACTIVITY_DELETE, deleteList, 'activity-list-spin', resultCallback)
              );
            }
          })
        );
      }
    }, 1000);
  };

  createBreakCourses = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_break_courses_info_tag'),
          body: T.t('gen_break_courses_info'),
          name: 'break_courses',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (status: number) => {
              if (status == 200) {
                this.state.selected_ids = [];
                this.state.filters = ActivitySearchInitialValues;
                this.setState(this.state);
                this.searchCourse();
              }
            };

            this.props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_CREATE_BREAK_COURSES, resultCallback));
          }
        })
      );
    }
  };

  termWeekDatesRouter = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_has_not_term_week_dates'),
        body: T.t('gen_has_not_term_week_dates_info'),
        name: 'course_import',
        icon: 'warning',
        iconColor: 'red',
        cancel: T.t('gen_ok'),
        confirm: T.t('gen_go_to_term_week_dates'),
        onConfirm: () => {
          this.props.dispatch(Actions.Navigation(GT.Route(Routes.COURSE_PERIODS_PLANNING_TERM_WEEK_DATES)))
        }
      }))
  };

  getProgramsByFaculties = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_GET_PROGRAMS_BY_FACULTIES, facultyIds, 'activity-list-spin'));
  }

  onInputChange = (inputValue: string, action: any) => {
    if (action.action === "input-change") {
      this.state.course_codes_filtered = this.props.selectOptions && this.props.selectOptions.course_codes.filter((item: any) => item.label.toLowerCase().includes(inputValue.toLowerCase()));
    }

    if (action.action === "set-value" && this.state.course_codes_filtered == undefined) {
      this.state.course_codes_filtered = this.props.selectOptions && this.props.selectOptions.course_codes;
    }

    if (action.action === "menu-close" && this.state.course_codes_filtered != undefined) {
      this.state.course_codes_filtered = undefined;
    }
  };

  filterOption = (option: Types.ISelectOption, inputValue: string) => {
    if (option.value === -1) {
      return true;
    } else if (inputValue) {
      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    } else {
      return true;
    }
  };

  onChangeAllData = () => {
    this.setState({
      ...this.state,
      changeAllDataModalIsOpen: !this.state.changeAllDataModalIsOpen
    });
  }

  switchChangeAlldataModalStatus = () => {
    this.setState({
      ...this.state,
      changeAllDataModalIsOpen: !this.state.changeAllDataModalIsOpen
    });
  };

  switchIntegrationModalStatus = () => {
    this.setState({
      ...this.state,
      integrationModalIsOpen: !this.state.integrationModalIsOpen
    });
  };

  static getDerivedStateFromProps(props: Types.IActivityPageProps, state: Types.IActivityPageState) {
    let hasNewState: boolean = false;

    if (props.match && props.match.params.id) {
      hasNewState = true;
      if (props.match.params.id !== 'create') {
        state.activityNo = props.match.params.id;
      } else {
        state.activityNo = undefined;
      }
    } else {
      hasNewState = true;
      state.activityNo = undefined;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    const courseCodeOptions = this.props.selectOptions && this.props.selectOptions.course_codes ? this.props.selectOptions.course_codes : []
    const courseCodeSelectOptions = [{ label: T.t('gen_select_all'), value: -1 }, ...courseCodeOptions];

    let courseList = this.props.results;
    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const allSelectOptions = [...ActivityTermLectureLocations(T), ...campusOptions].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));

    let activityTypes = ActivityTypes(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_activity_types && this.props.selectOptions.additional_activity_types.length > 0) {
      activityTypes = [...ActivityTypes(T), ...this.props.selectOptions.additional_activity_types].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));
    }

    const programOptions = this.props && this.props.programs_related_faculty ? this.props.programs_related_faculty : []
    const programSelectOptions = [{ label: T.t('gen_select_all'), value: -1 }, ...programOptions];

    return (
      <MainLayout header={<APlanHeader />}>
        <SimplePage name="faculty-page">
          <div className="main list-campus">
            <div className="container-fluid">
              <div className="row align-items-center mb-1">
                <div className="col-md-6 col-sm-4 col-12">
                  <h4 className="mb-4 mb-sm-0">{T.t('gen_courses')}</h4>
                </div>
                <div className="col-md-6 col-sm-8 col-12 text-right"></div>
              </div>
              <Spinner name="course-list-spin" />
              <div className="white-container mt-4">
                <div className="row align-items-center mb-1">
                  <div className="col-md-9 col-sm-12 col-12">
                    {this.state.selected_ids && this.state.selected_ids.length ? (
                      <React.Fragment>
                        <button
                          id='button_cancel_selection'
                          className="category-tag-square tag-gray float-left pr-2 pl-2"
                          style={{ margin: '5px' }}
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              selected_ids: []
                            });
                          }}
                        >
                          <i className="material-icons mr-2">close</i>
                          <span>
                            {T.t('gen_cancel_selection')} <b>&nbsp;({this.state.selected_ids.length})</b>
                          </span>
                        </button>
                        <button
                          id='button_delete_outline'
                          className="category-tag-square float-left pr-2 pl-2"
                          style={{ margin: '5px', color: '#fff', backgroundColor: '#dc3545' }}
                          onClick={this.onDeleteCourse}
                        >
                          <i className="material-icons mr-2">delete_outline</i>
                          <span> {T.t('gen_delete_selected')}</span>
                        </button>
                        <button
                          id='button_sync'
                          className="category-tag-square float-left pr-2 pl-2 tag-green"
                          style={{ margin: '5px', color: '#fff' }}
                          onClick={this.onChangeAllData}
                        >
                          <i className="material-icons mr-2">sync</i>
                          <span> {T.t('gen_change_all_data')}</span>
                        </button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <button id='button_add_course' className="category-tag-square tag-green"
                          onClick={this.props.results_term_week_dates && this.props.results_term_week_dates.term_week_schedule && this.props.results_term_week_dates.term_week_schedule.length === 0 ?
                            this.termWeekDatesRouter : this.activityFormIsOpen}>
                          <i className="material-icons mr-2">add</i>{T.t('gen_add_course')}
                        </button>
                        {this.props.user && this.props.user.role === 's' ? (
                          <button
                            id='button_playlist_add_check'
                            className="category-tag-square tag-green ml-1"
                            onClick={this.createBreakCourses}
                          >
                            <i className="material-icons mr-2">playlist_add_check</i>{T.t('gen_create_break_courses')}
                          </button>
                        ) : null}
                        <button
                          id='button_playlist_add'
                          className="category-tag-square tag-green ml-1"
                          onClick={this.switchIntegrationModalStatus}
                        >
                          <i className="material-icons mr-2">playlist_add</i>{T.t('gen_list_sync_jobs')}
                        </button>
                        {this.props.results_term_week_dates && this.props.results_term_week_dates.term_week_schedule && this.props.results_term_week_dates.term_week_schedule.length === 0 ?
                          <button type="button" className="ml-1 category-tag-square tag-green" onClick={this.termWeekDatesRouter}>
                            <img src={excelLogo} height={24} className="mr-md-2 mr-0" />
                            <span>{T.t('gen_add_with_excel')}</span>
                          </button>
                          :
                          <ImportModal
                            title={T.t('gen_add_with_excel')}
                            componentKey={ExcelImportKeys.Courses}
                            dispatch={this.props.dispatch}
                            onImport={this.onCourseImported}
                          />
                        }
                        <DownloadButtonSyncResult title={'SyncCoursesResult'} sectionType={SectionTypes.SyncCoursesResult} />
                        <DownloadButton title={'Dersler'} sectionType={SectionTypes.Courses} />
                      </React.Fragment>
                    )}
                  </div>
                  <div className="col-md-3 col-sm-12 col-12 text-right">
                    <div className="options d-md-inline-flex d-lg-inline-flex align-items-center">
                      <button
                        id='button_filter'
                        className="category-tag-square tag-glass float-right ml-3 mr-3"
                        style={{ margin: '5px' }}
                        onClick={() => {
                          this.state.filterIsOpen = !this.state.filterIsOpen;
                          this.setState(this.state);
                        }}
                      >
                        <i className="material-icons mr-2">filter_list</i>
                        <span>{T.t('gen_filter')}</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                  id="advance-search"
                >
                  <div className="advance-search d-block mt-3">
                    <Formik
                      initialValues={ActivitySearchInitialValues}
                      enableReinitialize={true}
                      onSubmit={(values, actions) => {
                        this.onFilterActivity(values, actions);
                      }}
                      onReset={this.onFormReset}
                    >
                      {(props: FormikProps<Types.IFilterActivity>) => {
                        return (
                          <form onSubmit={props.handleSubmit}>
                            <div className="row">
                              <div className="col-12">
                                <h6>{T.t('gen_filter_parameters')}</h6>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_activity_no')}</label>
                                    <Select
                                      id='select_activty_no'
                                      className="react-select"
                                      isMulti={true}
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.activity_noes
                                          ? this.props.selectOptions.activity_noes
                                          : []
                                      }
                                      placeholder={T.t('gen_select_activity_no')}
                                      value={props.values.activity_noes}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('activity_noes', list);
                                        props.setFieldValue(
                                          'activity_noes_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_activity')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_course_code')}</label>
                                    <Select
                                      id='select_course_code'
                                      className="react-select"
                                      isMulti={true}
                                      closeMenuOnSelect={false}
                                      options={courseCodeSelectOptions}
                                      placeholder={T.t('gen_select_course_code')}
                                      value={props.values.course_codes}
                                      filterOption={this.filterOption}
                                      onInputChange={this.onInputChange}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('course_codes', list.map(item => item.value).includes(-1) ? this.state.course_codes_filtered! : list);
                                        props.setFieldValue(
                                          'course_codes_ids',
                                          list.map(item => item.value).includes(-1) ? this.state.course_codes_filtered!.map((item: any) => item.value) : list.map(item => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_activity')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3 mt-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="course_name"
                                    name="course_name"
                                    value={props.values.course_name}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="name">{T.t('gen_name')}</label>
                                  <i className="material-icons">title</i>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_lesson_location')}</label>
                                    <Select
                                      id='select_location'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={allSelectOptions}
                                      placeholder={T.t('gen_select_location')}
                                      value={props.values.lecture_locations ? props.values.lecture_locations : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('lecture_locations', list);
                                        props.setFieldValue(
                                          'lecture_location_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_activity_type')}</label>
                                    <Select
                                      id='select_activity_type'
                                      className="react-select"
                                      isMulti={false}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={true}
                                      options={activityTypes}
                                      placeholder={T.t('gen_select_activity_type')}
                                      value={
                                        props.values.activity_type != undefined && props.values.activity_type != null
                                          ? activityTypes.find((option) => option.value == props.values.activity_type)
                                          : null
                                      }
                                      onChange={(option: any) => {
                                        props.setFieldValue('activity_type', option && option.value);
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_activity_type')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 col-xs-12">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_faculty')}</label>
                                    <Select
                                      id='select_faculty'
                                      className="react-select"
                                      isMulti={true}
                                      closeMenuOnSelect={true}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.faculties
                                          ? this.props.selectOptions.faculties
                                          : []
                                      }
                                      placeholder={T.t('gen_select_faculty')}
                                      value={props.values.faculties ? props.values.faculties : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('faculties', list);
                                        props.setFieldValue(
                                          'faculty_ids',
                                          list.map((item) => item.value)
                                        );
                                        props.setFieldValue('programs', []);
                                        props.setFieldValue('program_ids', []);
                                        this.getProgramsByFaculties(list.map(item => item.value))
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_program')}</label>
                                    <Select
                                      id='select_program'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label && option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={programSelectOptions}
                                      placeholder={T.t('gen_select_program')}
                                      value={props.values.programs}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('programs',
                                          list.map(item => item.value).includes(-1) ? this.props.programs_related_faculty : list);
                                        props.setFieldValue(
                                          'program_ids',
                                          list.map(item => item.value).includes(-1) ? programOptions.map((item: any) => item.value) : list.map(item => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_course_type')}</label>
                                    <Select
                                      id='select_course_type'
                                      className="react-select"
                                      isMulti={true}
                                      closeMenuOnSelect={false}
                                      options={CourseTypes(T)}
                                      placeholder={T.t('gen_select_course_type')}
                                      value={props.values.course_types}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('course_types', list);
                                        props.setFieldValue(
                                          'course_type',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_lesson_count')}</label>
                                    <Select
                                      id='select_lesson_count'
                                      className="react-select"
                                      isMulti={true}
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.lesson_counts
                                          ? this.props.selectOptions.lesson_counts
                                          : []
                                      }
                                      placeholder={T.t('gen_select_lesson_count')}
                                      value={props.values.lesson_counts}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('lesson_counts', list);
                                        props.setFieldValue(
                                          'lesson_counts_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_lesson_count')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_instructors')}</label>
                                    <Select
                                      id='select_instructor'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.instructors
                                          ? this.props.selectOptions.instructors
                                          : []
                                      }
                                      placeholder={T.t('gen_select_instructor')}
                                      value={props.values.lecture_staff}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('lecture_staff', list);
                                        props.setFieldValue(
                                          'instructor_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_education_type')}</label>
                                    <Select
                                      id='select_education_type'
                                      className="react-select"
                                      isMulti={true}
                                      closeMenuOnSelect={false}
                                      options={EducationTypeSelectOptions(T)}
                                      placeholder={T.t('gen_select_education_type')}
                                      value={props.values.education_types}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('education_types', list);
                                        props.setFieldValue(
                                          'education_type',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_education_type')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_grade')}</label>
                                    <Select
                                      id='select_grade'
                                      className="react-select"
                                      isMulti={true}
                                      closeMenuOnSelect={false}
                                      options={Grades}
                                      placeholder={T.t('gen_select_grade')}
                                      value={props.values.grades}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('grades', list);
                                        props.setFieldValue(
                                          'grades_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_grade')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_section')}</label>
                                    <Select
                                      id='select_section'
                                      className="react-select"
                                      isMulti={true}
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.sections
                                          ? this.props.selectOptions.sections
                                          : []
                                      }
                                      placeholder={T.t('gen_select_section')}
                                      value={props.values.sections_object}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('sections_object', list);
                                        props.setFieldValue(
                                          'sections',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_section')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_course_language')}</label>
                                    <Select
                                      id='select_course_language'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={true}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.course_languages_active
                                          ? this.props.selectOptions.course_languages_active
                                          : []
                                      }
                                      placeholder={T.t('gen_select_course_language')}
                                      value={props.values.course_languages ? props.values.course_languages : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('course_languages', list);
                                        props.setFieldValue(
                                          'course_language_ids',
                                          list.map((item) => item.value.toString())
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_course_language')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_course_environment')}</label>
                                    <Select
                                      id='select_course_environment'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={CourseEnvironments(T)}
                                      placeholder={T.t('gen_select_course_environment')}
                                      value={props.values.course_environments ? props.values.course_environments : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('course_environments', list);
                                        props.setFieldValue(
                                          'course_environment_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_exam_environment')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_classroom_type')}</label>
                                    <Select
                                      id='select_type'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.classroom_types
                                          ? this.props.selectOptions.classroom_types
                                          : []
                                      }
                                      placeholder={T.t('gen_select_type')}
                                      value={props.values.classroom_types ? props.values.classroom_types : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('classroom_types', list);
                                        props.setFieldValue(
                                          'classroom_type_ids',
                                          list.map((item) => item.value.toString())
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2 mt-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="week"
                                    name="week"
                                    value={props.values.week}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="week">{T.t('gen_week')}</label>
                                  <i className="material-icons">filter_1</i>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-md-6">
                                <div className="text-left">
                                  <h6>{T.t('gen_status')}</h6>
                                  <div className="tick-radio position-relative mb-3 d-inline-block">
                                    <MultipleCheckbox name="status" text={T.t('gen_active')} value={1} />
                                  </div>
                                  <div className="tick-radio position-relative mb-3 d-inline-block ml-4">
                                    <MultipleCheckbox name="status" text={T.t('gen_passive')} value={0} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row mt-3">
                              <div className="col-6">
                                <button
                                  id='button_arrow_upward'
                                  type="button"
                                  onClick={() => {
                                    this.state.filterIsOpen = false;
                                    this.setState(this.state);
                                  }}
                                  className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                                >
                                  <i className="material-icons">arrow_upward</i>
                                </button>
                                <button
                                  id='button_delete_sweep'
                                  type="reset"
                                  onClick={props.handleReset}
                                  className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2 ml-2"
                                >
                                  <i className="material-icons">delete_sweep</i>
                                </button>
                              </div>
                              <div className="col-6 text-right">
                                <button
                                  id='button_search'
                                  type="button"
                                  className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                                  onClick={() => props.handleSubmit()}
                                  disabled={props.isSubmitting}
                                >
                                  <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                                </button>
                              </div>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                      <thead>
                        <tr>
                          <th data-cell="select">
                            <div className="tick-radio position-relative">
                              <input
                                id='select_all'
                                type="checkbox"
                                className="form-radio"
                                checked={this.checkAllIdsSelected()}
                                onChange={this.onSelectAll}
                              />
                            </div>
                          </th>
                          <SortedColumn
                            datacell="status"
                            title={T.t('gen_status')}
                            sortkey="status"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="activity_no"
                            title={T.t('gen_activity_no')}
                            sortkey="activity_no"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="course_code"
                            title={T.t('gen_code')}
                            sortkey="course_code"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="course_name"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_name')}
                            sortkey="course_name"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="activity_type"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_activity_type')}
                            sortkey="activity_type"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="lesson_count"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_lesson_count')}
                            sortkey="lesson_count"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <th scope="col" className="text-center">
                            {T.t('gen_lesson_location')}
                          </th>
                          <SortedColumn
                            datacell="education_type"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_education_type')}
                            sortkey="education_type"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="faculty"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_faculty')}
                            sortkey="faculty"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="program"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_connected_programs')}
                            sortkey="program"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="grade"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_grade')}
                            sortkey="grade"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="section"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_section')}
                            sortkey="section"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="instructors"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_lecture_staff')}
                            sortkey="instructor"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="student_count"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_student_count')}
                            sortkey="student_count"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="week"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_week')}
                            sortkey="week"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="course_type"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_course_type')}
                            sortkey="course_type"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <th scope="col" className="text-center">
                            {T.t('gen_description')}
                          </th>
                          <th scope="col" className="text-right">
                            <span className="text-right">{T.t('gen_actions')}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {courseList && courseList.length
                          ? courseList.map((item: any, index: any) => {
                            const courseLocation = allSelectOptions.find(
                              (option) => option.value == item.lecture_location
                            );
                            return (
                              <tr key={index} data-title={item.name}>
                                <td data-cell="select">
                                  <div className="tick-radio position-relative">
                                    <input
                                      id='select_course'
                                      type="checkbox"
                                      className="form-radio"
                                      checked={
                                        this.state.selected_ids &&
                                        this.state.selected_ids.indexOf(item.activity_no ? item.activity_no : -1) > -1
                                      }
                                      data-id={item.activity_no}
                                      onChange={this.onSelectCourse}
                                    />
                                  </div>
                                </td>
                                <td scope="row" data-label={T.t('gen_status')}>
                                  <div className="tags ml-1 mr-4">
                                    <button
                                      id='button_status'
                                      className={
                                        `small-tag text-uppercase` + (item.status == 1 ? ` tag-green` : ` tag-red`)
                                      }
                                    >
                                      {GT.GetActiveStatus(item.status)}
                                    </button>
                                  </div>
                                </td>
                                <td scope="row" data-label={T.t('gen_activity_no')}>{item.activity_no}</td>
                                <td scope="row" data-label={T.t('gen_code')} className="text-center">
                                  <div className="table-scrollable-td">{item.course_code ? item.course_code.split(';').join(', ') : '-'}</div>
                                </td>
                                <td scope="row" data-label={T.t('gen_name')} className="text-center">
                                  <div className="table-scrollable-td">{item.course_name ? item.course_name.split(';').join(', ') : '-'}</div>
                                </td>
                                <td data-label={T.t('gen_activity_type')} className="text-center">{ActivityTypesLabel(item.activity_type, T)}</td>
                                <td scope="row" data-label={T.t('gen_lesson_count')} className="text-center">{item.lesson_count}</td>
                                <td scope="row" data-label={T.t('gen_lesson_location')} className="text-center">{LectureLocationsLabel(item.lecture_location.label, T)}</td>
                                <td scope="row" data-label={T.t('gen_education_type')} className="text-center">
                                  {item.education_type === EducationTypeSelectOptions(T)[0].value ?
                                    EducationTypeSelectOptions(T)[0].label :
                                    item.education_type === EducationTypeSelectOptions(T)[1].value ?
                                      EducationTypeSelectOptions(T)[1].label :
                                      item.education_type === EducationTypeSelectOptions(T)[2].value ?
                                        EducationTypeSelectOptions(T)[2].label : item.education_type
                                  }
                                </td>
                                <td className="text-center">
                                  {
                                    item && item.faculties && item.faculties.length > 0 ?
                                      <div className="table-scrollable-td">{item.faculties && item.faculties.map((i: any, index: any) => (index == item!.faculties!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                  }
                                </td>
                                <td className="text-center">
                                  {
                                    item && item.programs && item.programs.length > 0 ?
                                      <div className="table-scrollable-td">{item.programs && item.programs.map((i: any, index: any) => (index == item!.programs!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                  }
                                </td>
                                <td className="text-center">
                                  {
                                    item && item.classes && item.classes.length > 0 ?
                                      <div className="table-scrollable-td">{item.classes && item.classes.map((i: any, index: any) => (index == item!.classes!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                  }
                                </td>
                                <td className="text-center">
                                  {
                                    item && item.sections && item.sections.length > 0 ?
                                      <div className="table-scrollable-td">{item.sections && item.sections.map((i: any, index: any) => (index == item!.sections!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                  }
                                </td>
                                <td className="text-center">
                                  {
                                    item && item.instructors && item.instructors.length > 0 ?
                                      <div className="table-scrollable-td">{item.instructors && item.instructors.map((i: any, index: any) => (index == item!.instructors!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                  }
                                </td>
                                <td scope="row" data-label={T.t('gen_student_count')} className="text-center">
                                  {item.student_count}
                                </td>
                                <td scope="row" data-label={T.t('gen_week')} className="text-center">
                                  <div className="table-scrollable-td">{item.week ? item.week.split(',').join(', ') : '-'}</div>
                                </td>
                                <td scope="row" data-label={T.t('gen_course_type')} className="text-center">
                                  {CourseTypesLabel(item.course_type, T)}
                                </td>
                                <td data-label={T.t('gen_description')} className="text-center">
                                  {item.description && item.description.length ?
                                    <div className="table-scrollable-td">{item.description}</div> : '-'}
                                </td>
                                <td data-label={T.t('gen_actions')} className="table-buttons">
                                  <div className="table-buttons-wrapper">
                                    <button
                                      id='button_edit'
                                      data-toggle="tooltip"
                                      data-id={item.activity_no}
                                      onClick={() => {
                                        this.setState({
                                          ...this.state,
                                          activityFormIsOpen: !this.state.activityFormIsOpen,
                                          editActivity: item
                                        });
                                      }}
                                      title={T.t('gen_edit')}
                                      className="btn btn-light btn-sm table-button"
                                    >
                                      <span className="d-block" data-toggle="modal" data-target="#addNew">
                                        <i className="material-icons">edit</i>
                                      </span>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                          : null}
                      </tbody>
                    </table>
                    <div className="row-options justify-content-end">
                      <div
                        className="page-sorting d-flex align-items-center justify-content-center"
                        style={{ marginTop: '5px' }}
                      >
                        {this.props.results && this.props.results.length > 0 && (
                          <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SimplePage>
        <ChangeAllDataModal
          modalIsOpen={this.state.changeAllDataModalIsOpen}
          onClose={this.switchChangeAlldataModalStatus}
          term_id={this.state.filters.term_id}
          selected_ids={this.state.selected_ids}
        />
        {
          this.state.activityFormIsOpen ? (
            <CourseForm
              programs_related_faculty={[]}
              activityNo={this.state.activityNo}
              course={this.state.editActivity}
              formIsOpen={this.state.activityFormIsOpen}
              onClose={this.activityFormOnClose}
            />
          ) : null
        }
        {
          this.state.integrationModalIsOpen ? (
            <SyncCourseModal
              modalIsOpen={this.state.integrationModalIsOpen}
              onClose={this.switchIntegrationModalStatus}
              onUpdateList={() => this.searchCourse()}
            />
          ) : null
        }
      </MainLayout >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IActivityPageProps): Types.IActivityPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IActivityPageProps = Object.assign({}, ownProps, {
    is_related: store.state.activity_page && store.state.activity_page.is_related,
    results: store.state.activity_page && store.state.activity_page.results,
    filters: store.state.activity_page && store.state.activity_page.filters,
    selectOptions: store.state.select_options && store.state.select_options.activityPage,
    programs_related_faculty: store.state.select_options && store.state.select_options.programs_related_faculty,
    results_term_week_dates: store.state && store.state.examPeriodModal && store.state.examPeriodModal.resultsTermWeekDates,
    user: store.state.user,
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.activity_page) {
    return (
      !!equal(
        prev.state.activity_page && prev.state.activity_page.is_related,
        next.state.activity_page && next.state.activity_page.is_related
      ) &&
      !!equal(
        prev.state.activity_page && prev.state.activity_page.results,
        next.state.activity_page && next.state.activity_page.results
      ) &&
      !!equal(
        prev.state.activity_page && prev.state.activity_page.filters,
        next.state.activity_page && next.state.activity_page.filters
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.activityPage,
        next.state.select_options && next.state.select_options.activityPage
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.programs_related_faculty,
        next.state.select_options && next.state.select_options.programs_related_faculty
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(CourseTable);

export default container;
