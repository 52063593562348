import { put, takeEvery, all, take, select, call } from 'redux-saga/effects';
import * as Actions from '../actions/general';
import { constants, ProgramSearchInitialValues } from '../constants/program-const';
import * as Types from '../types';
import MockAPIClient from '../../services/mock-api-client';

function* getPrograms(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ProgramSearch(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getProgramData(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ProgramGetById(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateProgram(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ProgramUpdate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_program_updated', 'gen_success'));
  } else if (response && response.status == 409) {
    yield put(Actions.Notification(content, 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createProgram(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ProgramCreate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_program_created', 'gen_success'));
  } else if (response && response.status == 409) {
    yield put(Actions.Notification(content, 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createMultiPrograms(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ProgramMultiCreate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_program_created', 'gen_success'));
  } else if (response && response.status == 409) {
    yield put(Actions.Notification(content, 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteProgram(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ProgramDelete(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_program_deleted', 'gen_success'));
    yield put(Actions.ApiRequest(constants.PROGRAM_LIST_SEARCH, ProgramSearchInitialValues));
    content = yield MockAPI.GetContent(response);
    if (action.callback) {
      action.callback(response.status);
    }
  } else if (response && response.status === 409) {
    yield put(Actions.Notification('gen_relation_occurred_programs', 'gen_warning', 'danger'));
  } else if (response && response.status === 406) {
    yield put(Actions.Notification('notification_programs_exists_in_solutions', 'gen_error', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getProgramSelectOptions(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.ProgramGetSelectOptions());
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createProgramExcelBulk(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.ProgramCreateExcelBulk(action.body));
    if (response && (response.status === 200 || response.status === 409)) {
      content = yield MockAPI.GetContent(response);
    } else if (response && response.status === 406) {
      yield put(Actions.Notification('notification_programs_exists_in_solutions', 'gen_error', 'danger'));
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* changeProgramAllData(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ChangeProgramAllData(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_all_data_for_programs_changed', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

export function* watchProgramSearch() {
  yield takeEvery((action: any) => action.target === constants.PROGRAM_LIST_SEARCH, getPrograms);
}

export function* watchProgramGetById() {
  yield takeEvery((action: any) => action.target === constants.PROGRAM_GET_BY_ID, getProgramData);
}

export function* watchProgramGetSelectOptions() {
  yield takeEvery((action: any) => action.target === constants.PROGRAM_GET_SELECT_OPTIONS, getProgramSelectOptions);
}

export function* watchProgramUpdate() {
  yield takeEvery((action: any) => action.target === constants.PROGRAM_UPDATE, updateProgram);
}

export function* watchProgramCreate() {
  yield takeEvery((action: any) => action.target === constants.PROGRAM_CREATE, createProgram);
}

export function* watchProgramMultiCreate() {
  yield takeEvery((action: any) => action.target === constants.PROGRAM_MULTI_CREATE, createMultiPrograms);
}

export function* watchProgramDelete() {
  yield takeEvery((action: any) => action.target === constants.PROGRAM_DELETE, deleteProgram);
}

export function* watchCreateBuildingExcelBulk() {
  yield takeEvery((action: any) => action.target === constants.PROGRAM_CREATE_EXCEL_BULK, createProgramExcelBulk);
}

export function* watchChangeProgramAllData() {
  yield takeEvery((action: any) => action.target === constants.PROGRAM_CHANGE_ALL_DATA, changeProgramAllData);
}