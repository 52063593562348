import * as Types from '../store/types';
import AppFactory from './app-factory';
import Translator from './translate-factory';

import { Log } from 'ng2-logger';

const Logger = Log.create('MockAPI');
const T = Translator.create();

declare var appSettings: Types.IAppSettings;

let _instance: MockAPIClient;
export default class MockAPIClient {
  _apiURL: string = appSettings.API_URL || '';
  _accessToken: string = '';
  _appFactory: AppFactory | undefined;
  _termId: number = -1;

  constructor(useSingleton: boolean = true) {
    if (_instance && useSingleton) {
      return _instance;
    } else {
      _instance = this;
    }
  }

  RequestAsync(method: string = 'GET', path: string, data: any = null, qs: any = null): Promise<Response> {
    if (qs) {
      let qs_params: Array<string> = [];
      for (let k in qs) {
        if (qs.hasOwnProperty(k)) {
          qs_params.push(encodeURIComponent(k) + '=' + encodeURIComponent(qs[k]));
        }
      }
      if (qs_params.length > 0) {
        path = path + (path.indexOf('?') > -1 ? '&' : '?') + qs_params.join('&');
      }
    }
    return fetch(this._apiURL + path, {
      method: method,
      body: data === null ? null : JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this._accessToken,
        TermId: this._termId.toString(),
        Language: T.getSelectedLanguage()
      }
    });
  }

  RequestIntegration(method: string = 'GET', path: string, data: any = null, qs: any = null): Promise<Response> {
    if (qs) {
      let qs_params: Array<string> = [];
      for (let k in qs) {
        if (qs.hasOwnProperty(k)) {
          qs_params.push(encodeURIComponent(k) + '=' + encodeURIComponent(qs[k]));
        }
      }
      if (qs_params.length > 0) {
        path = path + (path.indexOf('?') > -1 ? '&' : '?') + qs_params.join('&');
      }
    }
    return fetch(path, {
      method: method,
      body: data === null ? null : JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this._accessToken,
        TermId: this._termId.toString(),
        Language: T.getSelectedLanguage()
      }
    });
  }

  async GetResponse(fn: Promise<Response>): Promise<Response> {
    return fn
      .then((response: Response) => {
        if (response.status === 401) {
          // Token not found or expired
          if (this._appFactory) {
            this._appFactory.logOff(true);
          }
        }
        if (response.status === 403) {
          // TODO: permission denied
        } else {
          //
        }
        return response;
      })
      .catch(function (e: any) {
        return e;
      });
  }

  async GetContent(response: Response): Promise<any> {
    return response
      .json()
      .then(function (content: any) {
        return content;
      })
      .catch(function (e: any) {
        return e;
      });
  }
  async GetFileDownload(response: Response): Promise<any> {
    var a = document.createElement('a');
    document.body.appendChild(a);
    const headers = response.headers.get('Content-Disposition');
    //const filename = headers && headers.match(/(?<=")(.*)(?=")/); Safari doesn't support lookbehind regex

    const filename = headers && headers.match(/filename="?([^\s;"]+)/);
    return response
      .blob()
      .then((blob: any) => {
        return URL.createObjectURL(blob);
      })
      .then((url: any) => {
        a.href = url;
        a.download = filename && filename.length > 1 ? filename[1] : "file.xlsx";
        a.click();
        // window.open(url, '_blank');
        URL.revokeObjectURL(url);
      });
  }

  async GetFile(response: Response): Promise<any> {
    var a = document.createElement('a');
    document.body.appendChild(a);
    const headers = response.headers.get('Content-Disposition');
    const filename = headers && headers.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
    return response
      .blob()
      .then((blob: any) => {
        return URL.createObjectURL(blob);
      })
      .then((url: any) => {
        a.href = url;
        a.download = filename && filename.length > 1 ? filename[1].replace(/['"]/g, '') : "file";
        a.click();
        URL.revokeObjectURL(url);
      });
  }

  async GetWellKnownUnit(): Promise<Response> {
    return await this.RequestAsync('GET', '/integration/well-known-unit');
  }

  async GetAuthUser(): Promise<Response> {
    return await this.RequestAsync('GET', '/integration/authuser');
  }

  async GetAuthToken(model: Types.ILoginView): Promise<Response> {
    return await this.RequestAsync('POST', '/integration', model);
  }

  // SETTINGS

  async GetSettings(): Promise<Response> {
    return await this.RequestAsync('GET', '/settings/search');
  }

  async GetGeneralSettings(): Promise<Response> {
    return await this.RequestAsync('POST', '/settings/general-search');
  }

  async UpdateSettings(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/settings', model);
  }

  async GetIntegration(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/integration/api/v1/' + model.page, model);
  }

  async AddMultiWithIntegration(model: any): Promise<Response> {
    return await this.RequestAsync('POST', model.page, model);
  }

  // CAMPUS

  async CampusSearch(model: Types.IFilterCampus): Promise<Response> {
    return await this.RequestAsync('POST', '/campus/search', model);
  }

  async CampusGetById(campusId: string): Promise<Response> {
    return await this.RequestAsync('GET', '/campus/' + campusId);
  }

  async CampusGetDistances(): Promise<Response> {
    return await this.RequestAsync('GET', '/campus/distances');
  }

  async CampusPostDistances(model: Types.ICampusMatrixDataModel): Promise<Response> {
    return await this.RequestAsync('POST', '/campus/distances', model);
  }

  async CampusUpdate(model: Types.ICampusItem): Promise<Response> {
    return await this.RequestAsync('PUT', '/campus/' + model.campus_id, model);
  }

  async CampusGetSelectOptions(): Promise<Response> {
    return await this.RequestAsync('GET', '/campus/select-options');
  }

  async CampusCreate(model: Types.ICampusItem): Promise<Response> {
    return await this.RequestAsync('POST', '/campus', model);
  }

  async CampusDelete(campusIds: Array<number>): Promise<Response> {
    return await this.RequestAsync('DELETE', '/campus', campusIds);
  }

  async CampusCreateExcelBulk(model: Types.IImportPost[]): Promise<Response> {
    return await this.RequestAsync('POST', '/campus/import-campuses', model);
  }

  // BUILDING

  async BuildingSearch(model: Types.IFilterBuilding): Promise<Response> {
    return await this.RequestAsync('POST', '/building/search', model);
  }

  async BuildingGetById(buildingId: string): Promise<Response> {
    return await this.RequestAsync('GET', '/building/' + buildingId);
  }

  async BuildingUpdate(model: Types.IBuildingItem): Promise<Response> {
    return await this.RequestAsync('PUT', '/building/' + model.building_id, model);
  }

  async BuildingGetSelectOptions(): Promise<Response> {
    return await this.RequestAsync('GET', '/building/select-options');
  }

  async BuildingCreate(model: Types.IBuildingItem): Promise<Response> {
    return await this.RequestAsync('POST', '/building', model);
  }

  async BuildingDelete(buildingIds: Array<number>): Promise<Response> {
    return await this.RequestAsync('DELETE', '/building', buildingIds);
  }

  async BuildingCreateExcelBulk(model: Types.IImportPost[]): Promise<Response> {
    return await this.RequestAsync('POST', '/building/import-buildings', model);
  }

  async BuildingGetDistances(): Promise<Response> {
    return await this.RequestAsync('GET', '/building/distances');
  }

  async BuildingPostDistances(model: Types.IBuildingMatrixDataModel): Promise<Response> {
    return await this.RequestAsync('POST', '/building/distances', model);
  }

  async DownloadAllData(model: { termID: number; sectionType: number; sectionID?: number }): Promise<Response> {
    return await this.RequestAsync('POST', '/integration/download', model);
  }

  // FACULTY

  async FacultySearch(model: Types.IFilterFaculty): Promise<Response> {
    return await this.RequestAsync('POST', '/faculty/search', model);
  }

  async FacultyGetById(facultyId: string): Promise<Response> {
    return await this.RequestAsync('GET', '/faculty/' + facultyId);
  }

  async FacultyGetSelectOptions(): Promise<Response> {
    return await this.RequestAsync('GET', '/faculty/select-options');
  }

  async FacultyUpdate(model: Types.IFacultyItem): Promise<Response> {
    return await this.RequestAsync('PUT', '/faculty/' + model.faculty_id, model);
  }

  async FacultyCreate(model: Types.IFacultyItem): Promise<Response> {
    return await this.RequestAsync('POST', '/faculty', model);
  }

  async FacultyDelete(facultyIds: Array<number>): Promise<Response> {
    return await this.RequestAsync('DELETE', '/faculty', facultyIds);
  }

  async FacultyCreateExcelBulk(model: Types.IImportPost[]): Promise<Response> {
    return await this.RequestAsync('POST', '/faculty/import-faculties', model);
  }

  // CLASSROOM

  async ClassroomSearch(model: Types.IFilterClassroom): Promise<Response> {
    return await this.RequestAsync('POST', '/classroom/search', model);
  }

  async ClassroomGetById(classroomId: string): Promise<Response> {
    return await this.RequestAsync('GET', '/classroom/' + classroomId);
  }

  async ClassroomGetSelectOptions(): Promise<Response> {
    return await this.RequestAsync('GET', '/classroom/select-options');
  }

  async ClassroomUpdate(model: Types.IClassroomItem): Promise<Response> {
    return await this.RequestAsync('PUT', '/classroom/' + model.classroom_id, model);
  }

  async ClassroomCreate(model: Types.IClassroomItem): Promise<Response> {
    return await this.RequestAsync('POST', '/classroom', model);
  }

  async ClassroomDelete(classroomIds: Array<number>): Promise<Response> {
    return await this.RequestAsync('DELETE', '/classroom', classroomIds);
  }

  async ClassroomCreateExcelBulk(model: Types.IImportPost[]): Promise<Response> {
    return await this.RequestAsync('POST', '/classroom/import-classrooms', model);
  }

  async ClassroomGetBuildingsByCampuses(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/classroom/buildings-by-campuses', model);
  }

  async ClassroomChangeAllData(model: Types.IFilterChangeAllDataModal): Promise<Response> {
    return await this.RequestAsync('POST', '/classroom/change-all-data', model);
  }

  // PROGRAM

  async ProgramSearch(model: Types.IFilterProgram): Promise<Response> {
    return await this.RequestAsync('POST', '/program/search', model);
  }

  async ProgramGetById(programId: string): Promise<Response> {
    return await this.RequestAsync('GET', '/program/' + programId);
  }

  async ProgramUpdate(model: Types.IProgramItem): Promise<Response> {
    return await this.RequestAsync('PUT', '/program/' + model.program_id, model);
  }

  async ProgramGetSelectOptions(): Promise<Response> {
    return await this.RequestAsync('GET', '/program/select-options');
  }

  async ProgramCreate(model: Types.IProgramItem): Promise<Response> {
    return await this.RequestAsync('POST', '/program', model);
  }

  async ProgramMultiCreate(model: Types.IProgramItem): Promise<Response> {
    return await this.RequestAsync('POST', '/program/import-multiple-programs', model);
  }

  async ProgramDelete(programIds: Array<number>): Promise<Response> {
    return await this.RequestAsync('DELETE', '/program', programIds);
  }

  async ProgramCreateExcelBulk(model: Types.IImportPost[]): Promise<Response> {
    return await this.RequestAsync('POST', '/program/import-programs', model);
  }

  async ChangeProgramAllData(model: Types.IProgramFilterChangeAllDataModal): Promise<Response> {
    return await this.RequestAsync('POST', '/program/change-all-data', model);
  }

  // USER

  async UserSearch(model: Types.IPagedQuery): Promise<Response> {
    return await this.RequestAsync('POST', '/user/search', model);
  }

  async UserSelectOptions(): Promise<Response> {
    return await this.RequestAsync('GET', '/user/select-options');
  }

  async UserGetProgramsByFaculties(model: Types.IFilterFacultyModal): Promise<Response> {
    return await this.RequestAsync('POST', '/user/programs-by-faculties', model);
  }

  async UserGetFilterProgramsByFaculties(model: Types.IFilterFacultyModal): Promise<Response> {
    return await this.RequestAsync('POST', '/user/filter-programs-by-faculties', model);
  }

  async UserCreate(model: Types.IUserPost): Promise<Response> {
    return await this.RequestAsync('POST', '/user/admin/createUser', model);
  }

  async UserUpdate(model: Types.IUserPost): Promise<Response> {
    return await this.RequestAsync('POST', '/user/admin/updateUser/' + model.user_id, model);
  }

  async UserGetById(userId: string, qs: any = null): Promise<Response> {
    return await this.RequestAsync('GET', '/user/' + userId, null, qs);
  }

  async UserDelete(userIds: string[]): Promise<Response> {
    return await this.RequestAsync('DELETE', '/user', userIds);
  }

  async UserBrowse(model: Types.IUserBrowseQuery): Promise<Response> {
    return await this.RequestAsync('POST', '/user/browse', model);
  }

  async UserResetPassword(model: Types.IResetPasswordPost): Promise<Response> {
    return await this.RequestAsync('PUT', '/user/' + model.user_id + '/changePassword', model);
  }

  async UserCreateBulk(model: Types.IUserImportPost[]): Promise<Response> {
    return await this.RequestAsync('POST', '/user/import', model);
  }

  async UserUpdateRole(model: Types.IUserUpdateRole): Promise<Response> {
    return await this.RequestAsync('PUT', '/user/role/' + model.role, model.user_ids);
  }

  async UserCreateExcelBulk(model: Types.IImportPost[]): Promise<Response> {
    return await this.RequestAsync('POST', '/user/import-users', model);
  }

  // COURSE

  async CourseSearch(model: Types.IFilterCourse): Promise<Response> {
    return await this.RequestAsync('POST', '/course/search', model);
  }

  async CourseGetById(course_id: string): Promise<Response> {
    return await this.RequestAsync('GET', '/course/' + course_id);
  }

  async CourseUpdate(model: Types.ICourseItem): Promise<Response> {
    return await this.RequestAsync('PUT', '/course/' + model.course_id, model);
  }

  async CourseGetSelectOptions(): Promise<Response> {
    return await this.RequestAsync('GET', '/course/select-options');
  }

  async CourseGetProgramsByFaculties(model: Types.IFilterFacultyModal): Promise<Response> {
    return await this.RequestAsync('POST', '/course/programs-by-faculties', model);
  }

  async CourseCreate(model: Types.ICourseItem): Promise<Response> {
    return await this.RequestAsync('POST', '/course', model);
  }

  async CourseDelete(programId: string): Promise<Response> {
    return await this.RequestAsync('DELETE', '/course', programId);
  }

  async CourseCreateExcelBulk(model: Types.IImportPost[]): Promise<Response> {
    return await this.RequestAsync('POST', '/course/import-courses', model);
  }

  async CourseChangeAllData(model: Types.IFilterChangeAllDataModal): Promise<Response> {
    return await this.RequestAsync('POST', '/course/change-all-data', model);
  }

  // INSTRUCTOR

  async InstructorSearch(model: Types.IFilterInstructor): Promise<Response> {
    return await this.RequestAsync('POST', '/instructor/search', model);
  }

  async InstructorGetById(programId: string): Promise<Response> {
    return await this.RequestAsync('GET', '/instructor/' + programId);
  }

  async InstructorGetSelectOptions(): Promise<Response> {
    return await this.RequestAsync('GET', '/instructor/select-options');
  }

  async InstructorGetProgramsByFaculties(model: Types.IFilterFacultyModal): Promise<Response> {
    return await this.RequestAsync('POST', '/instructor/programs-by-faculties', model);
  }

  async InstructorGetInvigilatorProgramsByFaculties(model: Types.IFilterFacultyModal): Promise<Response> {
    return await this.RequestAsync('POST', '/instructor/invigilator-programs-by-faculties', model);
  }

  async InstructorUpdate(model: Types.IInstructorItem): Promise<Response> {
    return await this.RequestAsync('PUT', '/instructor/' + model.instructor_id, model);
  }

  async InstructorCreate(model: Types.IInstructorItem): Promise<Response> {
    return await this.RequestAsync('POST', '/instructor', model);
  }

  async InstructorDelete(instructorIds: Array<number>): Promise<Response> {
    return await this.RequestAsync('DELETE', '/instructor', instructorIds);
  }

  async FacultyInstructorExcelBulk(model: Types.IImportPost[]): Promise<Response> {
    return await this.RequestAsync('POST', '/instructor/import-instructors', model);
  }

  async ChangeInstructorAllData(model: Types.IInstructorFilterChangeAllDataModal): Promise<Response> {
    return await this.RequestAsync('POST', '/instructor/change-all-data', model);
  }

  async InstructorDownloadExamList(model: { solutionId: number }): Promise<Response> {
    return await this.RequestAsync('POST', '/integration/download-exam-list/' + model.solutionId);
  }

  async SolutionDownloadRawFiles(model: { solutionId: number }): Promise<Response> {
    return await this.RequestAsync('POST', '/integration/download-solution-raw-files/' + model.solutionId);
  }

  // COURSE PERIOD

  async CoursePeriodSearch(model: Types.IFilterCoursePeriod): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/search', model);
  }

  async CoursePeriodGetById(coursePeriodId: string): Promise<Response> {
    return await this.RequestAsync('GET', '/exam-period/' + coursePeriodId);
  }

  async CoursePeriodUpdate(model: Types.ITermItem): Promise<Response> {
    return await this.RequestAsync('PUT', '/exam-period/' + model.term_id, model);
  }

  async CoursePeriodCreate(model: Types.ITermItem): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period', model);
  }

  async CoursePeriodDelete(coursePeriodId: string): Promise<Response> {
    return await this.RequestAsync('DELETE', '/exam-period', coursePeriodId);
  }

  async CoursePeriodActivityRelationSearch(model: Types.IFilterActivityRelation): Promise<Response> {
    return await this.RequestAsync('POST', '/activity/activity-relations/search', model);
  }

  async UpdateActivityRelation(model: Types.ICourseActivityRelationPostModel): Promise<Response> {
    return await this.RequestAsync('PUT', '/activity/activity-relations', model);
  }

  async CoursePeriodPostActivityRelation(model: Types.ICourseActivityRelationPostModel): Promise<Response> {
    return await this.RequestAsync('POST', '/activity/activity-relations', model);
  }

  async CoursePeriodDeleteActivityRelation(activityRelationId: string): Promise<Response> {
    return await this.RequestAsync('DELETE', '/activity/activity-relations/' + activityRelationId);
  }

  async CoursePeriodActivityDeleteFromActivityRelation(model: Types.ICourseActivityRelationPostModel): Promise<Response> {
    return await this.RequestAsync('DELETE', '/activity/from-activity-relation', model);
  }

  async CourseTermHoursUpdate(model: Types.ICoursePeriod_CourseDataModel): Promise<Response> {
    return await this.RequestAsync('PUT', '/exam-period/course-hours/' + model.term_id, model);
  }

  // ACTIVITY

  async ActivitySearch(model: Types.IFilterActivity): Promise<Response> {
    return await this.RequestAsync('POST', '/activity/search', model);
  }

  async ActivityChangeAllData(model: Types.IFilterChangeAllDataModal): Promise<Response> {
    return await this.RequestAsync('POST', '/activity/change-all-data', model);
  }

  async ActivityGetById(activity_no: string): Promise<Response> {
    return await this.RequestAsync('GET', '/activity/' + activity_no);
  }

  async ActivityIsRelated(activity_numbers: Array<number>): Promise<Response> {
    return await this.RequestAsync('POST', '/activity/activity-relations/is-related', activity_numbers);
  }

  async ActivityUpdate(model: Types.IActivityItem): Promise<Response> {
    return await this.RequestAsync('PUT', '/activity/' + model.activity_no, model);
  }

  async ActivityGetSelectOptions(): Promise<Response> {
    return await this.RequestAsync('GET', '/activity/select-options');
  }

  async ActivityCreate(model: Types.IActivityItem): Promise<Response> {
    return await this.RequestAsync('POST', '/activity', model);
  }

  async ActivityDelete(programId: string): Promise<Response> {
    return await this.RequestAsync('DELETE', '/activity', programId);
  }

  async ActivityCreateExcelBulk(model: Types.IImportPost[]): Promise<Response> {
    return await this.RequestAsync('POST', '/activity/import-activities', model);
  }

  async ActivityCreateBreakCourses(): Promise<Response> {
    return await this.RequestAsync('POST', '/activity/course-breaks');
  }

  async ActivityGetProgramsByFaculties(model: Types.IFilterFacultyModal): Promise<Response> {
    return await this.RequestAsync('POST', '/activity/programs-by-faculties', model);
  }

  async ActivityDublication(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/activity/dublication/' + model.activity_no, model.dublicate_list);
  }

  async ActivityDublicationBySection(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/activity/dublication-by-section/' + model.activity_no, model.dublicate_by_section_list);
  }

  async ActivityCourseSectionsUpdate(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/activity/course-sections-update/' + model.term_id, model.validation);
  }

  // EXAM PERIOD

  async ExamPeriodSearch(model: Types.IFilterExamPeriod): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/search', model);
  }

  async ExamPeriodGetById(examPeriodId: string): Promise<Response> {
    return await this.RequestAsync('GET', '/exam-period/' + examPeriodId);
  }

  async ExamPeriodUpdate(model: Types.ITermItem): Promise<Response> {
    return await this.RequestAsync('PUT', '/exam-period/' + model.term_id, model);
  }

  async ExamPeriodCopy(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/term-clone-request/' + model.term_id, model);
  }

  async ExamPeriodCopyGetRequestStatus(requestId: any): Promise<Response> {
    return await this.RequestAsync('GET', '/exam-period/term-clone-request-status/' + requestId);
  }

  async ExamPeriodCreate(model: Types.ITermItem): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period', model);
  }

  async ExamPeriodDelete(examPeriodId: string): Promise<Response> {
    return await this.RequestAsync('DELETE', '/exam-period', examPeriodId);
  }

  // EXAM PERIOD -> EXAM DATES TAB

  async ExamDatesCreate(model: Types.IExamDatesItem): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/exam-dates', model);
  }

  async ExamDatesGetById(examPeriodId: string): Promise<Response> {
    return await this.RequestAsync('GET', '/exam-period/exam-dates/' + examPeriodId);
  }

  async ExamDatesUpdate(model: Types.IExamDatesItem): Promise<Response> {
    return await this.RequestAsync('PUT', '/exam-period/exam-dates', model);
  }

  async ExamDatesDelete(examPeriodId: string): Promise<Response> {
    return await this.RequestAsync('DELETE', '/exam-period/exam-dates', examPeriodId);
  }

  // EXAM PERIOD -> INSTRUCTOR HOURS TAB

  async InstructorHoursGetById(model: Types.IFilterExamPeriodCalendarModal): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/instructor-hours/' + model.term_id, model);
  }

  async InstructorsAddToPeriod(model: Types.IFilterInstructor): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/instructor-hours/add-instructors/' + model.term_id, model);
  }

  async SaveInstructorInvigilations(model: Types.IExamPeriod_InstructorInvigilations): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/instructor-hours/save-invigilations/' + model.term_id, model);
  }

  async InstructorHoursUpdate(model: Types.IExamPeriod_InstructorDataModel): Promise<Response> {
    return await this.RequestAsync('PUT', '/exam-period/instructor-hours/' + model.term_id, model);
  }

  async InstructorHoursUpdateMultiple(model: Types.IExamPeriod_InstructorDataModel): Promise<Response> {
    return await this.RequestAsync('PUT', '/exam-period/multiple-instructor-hours/' + model.term_id, model);
  }

  async InstructorsDeleteFromPeriod(model: Types.IExamPeriod_InstructorDataModel): Promise<Response> {
    return await this.RequestAsync('DELETE', '/exam-period/instructor-hours/' + model.term_id, model);
  }

  // EXAM PERIOD -> CLASSROOM HOURS TAB

  async ClassroomHoursGetById(model: Types.IFilterExamPeriodCalendarModal): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/classroom-hours/' + model.term_id, model);
  }

  async getTermWeekDates(model: Types.IFilterExamPeriodCalendarModal): Promise<Response> {
    return await this.RequestAsync('GET', '/exam-period/term-week-dates/' + model.term_id);
  }

  async putTermWeekDates(model: Types.IFilterExamPeriodCalendarModal): Promise<Response> {
    return await this.RequestAsync('PUT', '/exam-period/term-week-dates', model);
  }

  async deleteTermWeekExclutionDate(exclution_no: number): Promise<Response> {
    return await this.RequestAsync('DELETE', '/exam-period/term-week-exclution-date/', exclution_no);
  }

  async deleteTermWeekHolidayDate(holiday_no: number): Promise<Response> {
    return await this.RequestAsync('DELETE', '/exam-period/term-week-holiday-date/', holiday_no);
  }

  async ClassroomsAddToPeriod(model: Types.IFilterClassroom): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/classroom-hours/add-classrooms/' + model.term_id, model);
  }

  async ClassroomHoursUpdate(model: Types.IExamPeriod_ClassroomDataModel): Promise<Response> {
    return await this.RequestAsync('PUT', '/exam-period/classroom-hours/' + model.term_id, model);
  }

  async ClassroomMultipleHoursUpdate(model: Types.IExamPeriod_ClassroomDataModel): Promise<Response> {
    return await this.RequestAsync('PUT', '/exam-period/multiple-classroom-hours/' + model.term_id, model);
  }

  async ClassroomsDeleteFromPeriod(model: Types.IExamPeriod_ClassroomDataModel): Promise<Response> {
    return await this.RequestAsync('DELETE', '/exam-period/classroom-hours/' + model.term_id, model);
  }

  // EXAM PERIOD -> COURSE HOURS TAB

  async CourseHoursGetById(model: Types.IFilterExamPeriodCalendarModal): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/course-hours/' + model.term_id, model);
  }

  // For activties
  async CourseTermCourseHoursGetById(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/courseterm-course-hours/' + model.term_id, model);
  }

  async CourseHoursUpdate(model: Types.IExamPeriod_CourseDataModel): Promise<Response> {
    return await this.RequestAsync('PUT', '/exam-period/course-hours/' + model.term_id, model);
  }

  async MultipleCourseHoursUpdate(model: Types.IExamPeriod_CourseDataModel): Promise<Response> {
    return await this.RequestAsync('PUT', '/exam-period/multiple-course-hours/' + model.term_id, model);
  }

  async CourseHoursEdit(model: Types.ICoursePeriod_CourseItem): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/course-hours/edit-course/' + model.term_id, model);
  }

  async CourseTermCourseHoursEdit(model: Types.ICoursePeriod_CourseItem): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/course-hours/edit-activities/' + model.term_id, model);
  }

  async CreateCombinedExams(model: Types.ICombineExamsDataModel): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/combine-exams/' + model.term_id, model);
  }

  async CreateCombinedActivities(model: Types.ICombineExamsDataModel): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/combine-activities/' + model.term_id, model);
  }

  async UpdateCombinedExams(model: Types.ICombineExamsDataModel): Promise<Response> {
    return await this.RequestAsync('PUT', '/exam-period/combine-exams/' + model.term_id, model);
  }

  async CoursesAddToPeriod(model: Types.IFilterCourse): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/course-hours/add-courses/' + model.term_id, model);
  }

  // For Course Term
  async ActivitiesAddToPeriod(model: Types.IFilterActivity): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/course-hours/add-activities/' + model.term_id, model.add_list);
  }

  async CopyCourseToPeriod(model: Types.IFilterCourse): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/course-hours/copy-course/' + model.term_id, model);
  }

  async CourseTermCopyCourseToPeriod(model: Types.IFilterActivity): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/course-hours/copy-activities/' + model.term_id, model);
  }

  async AttachClassroomToCourses(model: Types.IFilterClassroom): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/course-hours/attach-classrooms/' + model.term_id, model);
  }

  async CourseTermAttachClassroomToCourses(model: Types.IFilterClassroom): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/courseterm-course-hours/attach-classrooms/' + model.term_id, model);
  }

  async CourseTermAttachClassroomToPrograms(model: Types.IFilterClassroom): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/courseterm-program-classrooms/attach-classrooms/' + model.term_id, model);
  }

  async GetAttachedClassrooms(model: Types.IFilterClassroom): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/course-hours/get-attached-classrooms/' + model.term_id, model);
  }

  async GetCourseTermAttachedClassrooms(model: Types.IFilterClassroom): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/courseterm-course-hours/get-attached-classrooms/' + model.term_id, model);
  }

  async GetOverlaps(model: Types.IFilterExamPeriodCalendarModal): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/course-hours/overlaps/' + model.term_id, model);
  }

  async CourseTermGetOverlaps(model: Types.IFilterExamPeriodCalendarModal): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/courseterm-course-hours/overlaps/' + model.term_id, model);
  }

  async DeleteAttachedClassrooms(model: Types.IFilterClassroom): Promise<Response> {
    return await this.RequestAsync('DELETE', '/exam-period/course-hours/attach-classrooms/' + model.term_id, model);
  }

  async CourseTermDeleteAttachedClassrooms(model: Types.IFilterClassroom): Promise<Response> {
    return await this.RequestAsync('DELETE', '/exam-period/courseterm-course-hours/attach-classrooms/' + model.term_id, model);
  }

  async CourseTermDeleteClassroomPriorityAttachedClassrooms(model: Types.IFilterClassroom): Promise<Response> {
    return await this.RequestAsync('DELETE', '/exam-period/courseterm-program-classrooms/attach-classrooms/' + model.term_id, model);
  }

  async CoursesDeleteFromPeriod(model: Types.IExamPeriod_CourseDataModel): Promise<Response> {
    return await this.RequestAsync('DELETE', '/exam-period/course-hours/' + model.term_id, model);
  }

  async SearchBulkCombineExams(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/search-bulk-combine-exams/' + model.term_id, model);
  }

  async GetStudents(model: Types.IExamPeriod_CourseDataModel): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/students/' + model.term_id, model);
  }

  async DeleteStudentsFromPeriod(model: Types.IFilterStudent): Promise<Response> {
    return await this.RequestAsync('DELETE', '/exam-period/delete-students/' + model.term_id, model);
  }

  async DeleteAllStudentsFromPeriod(model: Types.IFilterStudent): Promise<Response> {
    return await this.RequestAsync('DELETE', '/exam-period/delete-all-students/' + model.term_id, model);
  }

  async StudentCreateBulk(model: Types.IImportPost[]): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/import-students', model);
  }

  async StudentCheck(model: { termId: number }): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/check-students/' + model.termId);
  }

  async UpdateCoursesViaStudents(model: { term_id: number, validation: boolean }): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/update-courses-via-students/' + model.term_id, model.validation);
  }

  async StudentChangeAllData(model: Types.IFilterChangeAllDataModal): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/students-change-all-data/', model);
  }

  async StudentCourseOverlaps(model: { termId: number; course_ids: number[] }): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/check-course-overlap-for-students/' + model.termId, model.course_ids);
  }

  async StudentGetCourseProgramsByFaculties(model: Types.IFilterFacultyModal): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/course-programs-by-faculties', model);
  }

  async StudentGetEnrolledProgramsByFaculties(model: Types.IFilterFacultyModal): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/enrolled-programs-by-faculties', model);
  }

  async StudentGetSelectOptions(): Promise<Response> {
    return await this.RequestAsync('GET', '/exam-period/select-options');
  }

  async StudentDownloadExamList(model: { solutionId: number; student_id: string }): Promise<Response> {
    return await this.RequestAsync('POST', '/integration/' + model.solutionId + '/download-student-exams/' + model.student_id, model);
  }

  async GetTermList(): Promise<Response> {
    return await this.RequestAsync('GET', '/exam-period/list');
  }

  async GetTermSelectOptions(): Promise<Response> {
    return await this.RequestAsync('GET', '/exam-period/term-select-options');
  }

  async CourseTermClassroomPriorityPrograms(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/exam-period/courseterm-program-classrooms/' + model.term_id, model);
  }

  //SOLUTION
  async GetSolutionByTerm(model: Types.IFilterSolutionModel): Promise<Response> {
    return await this.RequestAsync('GET', '/integration/' + model.term_id + '/solution-by-term/' + model.solution_id);
  }

  async GetSolution(model: Types.IFilterExamSolution): Promise<Response> {
    return await this.RequestAsync('POST', '/integration/search-exam-solution/', model);
  }

  async GetCourseSolution(model: Types.IFilterActivitySolution): Promise<Response> {
    return await this.RequestAsync('POST', '/integration/search-activity-solution/', model);
  }

  async GetInvigilatorCount(solution_id: number): Promise<Response> {
    return await this.RequestAsync('GET', '/integration/search-invigilator-count/' + solution_id);
  }

  async PublishSolution(model: { solutionId: number; term_id: number; publisher: boolean }): Promise<Response> {
    return await this.RequestAsync('POST', '/integration/publishing', model);
  }

  async CopySolution(model: { solution_id: number; term_type: number; term_id: number; }): Promise<Response> {
    return await this.RequestAsync('POST', '/integration/copy-solution', model);
  }

  async DeleteSolution(model: { solution_id: number; term_id: number; }): Promise<Response> {
    return await this.RequestAsync('POST', '/integration/delete-solution', model);
  }

  async PublicSolution(term_type: number): Promise<Response> {
    return await this.RequestAsync('GET', '/integration/published-solution/' + term_type);
  }

  async SolutionGetActivitySelectOptions(term_id: number): Promise<Response> {
    return await this.RequestAsync('GET', '/integration/activity-select-options/' + term_id);
  }

  async SolutionGetActivityClassroomSelectOptions(model: { solutionId: number; term_id: number; campus_id: number }): Promise<Response> {
    return await this.RequestAsync('POST', '/integration/activity-classroom-select-options/', model);
  }

  async SolutionEditActivity(model: any): Promise<Response> {
    return await this.RequestAsync('PUT', '/integration/' + model.term_id + '/coursesolution/' + model.solution_id, model);
  }

  async SolutionAddActivity(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/integration/' + model.term_id + '/coursesolution/' + model.solution_id, model);
  }

  async SolutionDeleteActivity(model: any): Promise<Response> {
    return await this.RequestAsync('DELETE', '/integration/' + model.term_id + '/coursesolution/' + model.solution_id, model);
  }

  async SolutionGetProgramsByFaculties(model: Types.IFilterFacultyModal): Promise<Response> {
    return await this.RequestAsync('POST', '/integration/programs-by-faculties', model);
  }

  async SolutionCourseGetProgramsByFaculties(model: Types.IFilterFacultyModal): Promise<Response> {
    return await this.RequestAsync('POST', '/integration/course-programs-by-faculties', model);
  }

  async SolutionGetCourseSelectOptions(term_id: number): Promise<Response> {
    return await this.RequestAsync('GET', '/integration/course-select-options/' + term_id);
  }

  async SolutionEditCourse(model: any): Promise<Response> {
    return await this.RequestAsync('PUT', '/integration/' + model.term_id + '/solution/' + model.solution_id, model);
  }

  async SolutionAddCourse(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/integration/' + model.term_id + '/solution/' + model.solution_id, model);
  }

  async SolutionDeleteCourse(model: any): Promise<Response> {
    return await this.RequestAsync('DELETE', '/integration/' + model.term_id + '/solution/' + model.solution_id, model);
  }

  async SolutionActivityPrograms(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/integration/' + model.term_id + '/solution-activity-programs/' + model.solution_id, model.activity_no);
  }

  async SolutionCoursePrograms(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/integration/' + model.term_id + '/solution-course-programs/' + model.solution_id, model.course_id);
  }

  async SolutionGetExamDates(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/integration/' + model.term_id + '/solution-dates-info/' + model.solution_id);
  }

  async SolutionBatchChangeExamDates(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/integration/' + model.term_id + '/solution-batch-change-exam-dates/' + model.solution_id, model.exam_dates);
  }

  //SYNCHRONIZATION
  async SyncCreate(model: Types.ISyncItem): Promise<Response> {
    return await this.RequestAsync('POST', '/integration/sync-job', model);
  }

  // async SyncGetSelectOptions(model: any): Promise<Response> {
  //   return await this.RequestAsync('GET', '/integration/select-options/' + model.page);
  // }

  async SyncGetSelectOptions(): Promise<Response> {
    return await this.RequestAsync('GET', '/integration/select-options');
  }

  async SyncBuildingSearch(model: Types.IFilterSyncBuilding): Promise<Response> {
    return await this.RequestAsync('POST', '/sync/search-sync-building', model);
  }

  async SyncProgramSearch(model: Types.IFilterSyncProgram): Promise<Response> {
    return await this.RequestAsync('POST', '/sync/search-sync-program', model);
  }

  async SyncCampusSearch(model: Types.IFilterSyncCampus): Promise<Response> {
    return await this.RequestAsync('POST', '/sync/search-sync-campus', model);
  }

  async SyncClassroomSearch(model: Types.IFilterSyncClassroom): Promise<Response> {
    return await this.RequestAsync('POST', '/sync/search-sync-classroom', model);
  }

  async SyncFacultySearch(model: Types.IFilterSyncFaculty): Promise<Response> {
    return await this.RequestAsync('POST', '/sync/search-sync-faculty', model);
  }

  async SyncInstructorSearch(model: Types.IFilterSyncInstructor): Promise<Response> {
    return await this.RequestAsync('POST', '/sync/search-sync-instructor', model);
  }

  async SyncCourseSearch(model: Types.IFilterSyncCourse): Promise<Response> {
    return await this.RequestAsync('POST', '/sync/search-sync-course', model);
  }

  async SyncStudentSearch(model: Types.IFilterSyncStudent): Promise<Response> {
    return await this.RequestAsync('POST', '/sync/search-sync-student', model);
  }

  // Synch Add To Main Tables

  async SyncBuildingAddToMain(model: Types.ISyncJobStatus): Promise<Response> {
    return await this.RequestAsync('POST', '/sync/buildings/' + model.sync_job_id, model.campus_ids);
  }

  async SyncCampusAddToMain(model: Types.ISyncJobStatus): Promise<Response> {
    return await this.RequestAsync('POST', '/sync/campuses/' + model.sync_job_id, model.campus_ids);
  }

  async SyncClassroomAddToMain(model: Types.ISyncJobStatus): Promise<Response> {
    return await this.RequestAsync('POST', '/sync/classrooms/' + model.sync_job_id, model.building_ids);
  }

  async SyncFacultyAddToMain(model: Types.ISyncJobStatus): Promise<Response> {
    return await this.RequestAsync('POST', '/sync/faculties/' + model.sync_job_id, model.faculty_ids);
  }

  async SyncProgramAddToMain(model: Types.ISyncJobStatus): Promise<Response> {
    return await this.RequestAsync('POST', '/sync/programs/' + model.sync_job_id, model.faculty_ids);
  }

  async SyncInstructorAddToMain(model: Types.ISyncJobStatus): Promise<Response> {
    return await this.RequestAsync('POST', '/sync/instructors/' + model.sync_job_id, model.instructor_ids);
  }

  async SyncCourseAddToMain(model: Types.ISyncJobStatus): Promise<Response> {
    return await this.RequestAsync('POST', '/sync/courses/' + model.sync_job_id, model.program_ids);
  }

  async SyncStudentAddToMain(model: Types.ISyncJobStatus): Promise<Response> {
    return await this.RequestAsync('POST', '/sync/students/' + model.sync_job_id, model.course_ids);
  }

  // Synchronization For Modal List

  async SyncGetModalSelectOptions(): Promise<Response> {
    return await this.RequestAsync('POST', '/sync/select-options');
  }

  async SyncGetCampusOptions(model: Types.IFilterCampusModal): Promise<Response> {
    return await this.RequestAsync('POST', '/sync/campus-select-options', model);
  }

  async SyncGetBuildingOptions(model: Types.IFilterBuildingModal): Promise<Response> {
    return await this.RequestAsync('POST', '/sync/building-select-options', model);
  }

  async SyncGetProgramOptions(model: Types.IFilterProgramModal): Promise<Response> {
    return await this.RequestAsync('POST', '/sync/program-select-options', model);
  }

  async SyncGetFacultyOptions(model: Types.IFilterFacultyModal): Promise<Response> {
    return await this.RequestAsync('POST', '/sync/faculty-select-options', model);
  }

  // Sending Data To "Start Synchronization"

  async SyncSendSyncType(model: Types.ISendModal): Promise<Response> {
    return await this.RequestAsync('POST', '/sync/sync-job', model);
  }

  // Getting Data For "Veri Aktarma Sayfası"

  async SyncJobStatus(model: Types.ISyncJobStatus): Promise<Response> {
    return await this.RequestAsync('GET', '/sync/getJobStatus/' + model.sync_job_id);
  }

  // Displaying Data In Table

  async SyncDisplayCampusData(model: Types.IDisplayCampusData): Promise<Response> {
    return await this.RequestAsync('POST', 'sync/search-sync-campus', model);
  }

  // Senkronizasyon İşlerini Listele

  async SyncListSyncJobs(model: Types.IListSyncJobs): Promise<Response> {
    return await this.RequestAsync('POST', '/sync/getFetchedJobList/' + model.sync_type, model.filters);
  }

  async SyncGetFetchedData(model: Types.IGetFetchedData): Promise<Response> {
    return await this.RequestAsync('GET', '/sync/getFetchedData/' + model.sync_job_id);
  }
  /*async SyncCampusSearch(model: Types.IFilterSyncCampus): Promise<Response> {
    return await this.RequestAsync('POST', '/sync/search-sync-campus', model);
  }*/
  // Synchronization For Modal List END

  // DISTRIBUTION

  async DistributionSearch(model: Types.IFilterDistributor): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/search', model);
  }

  async DistributionSearchStatusHistory(model: Types.IFilterDistributorModal): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/search-status-history', model);
  }

  async DistributionCancel(job_id: number): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/cancel/' + job_id);
  }

  async DistributionSearchInProgressStatus(term_id: number): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/search-status/' + term_id);
  }

  async DistributionCreate(model: Types.IDistributorCreateModel): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/create/' + model.term_id, model);
  }

  async DistributionConfirm(job_id: number): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/confirm/' + job_id);
  }

  async DistributionDownloadHistory(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/integration/download', model);
  }

  async DistributionStopCalculation(job_id: number): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/stop-calculation/' + job_id);
  }

  async ResetMaxNumberOfAttemptsDistribution(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/reset-max-number-of-attempts/' + model.job_id, model.max_number_attempts_updated);
  }

  async DistributionReassignInvigilatorsAuto(job_id: number): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/reassign-invigilators-auto/' + job_id);
  }

  async DistributionSolutionChangeGetSelectOptions(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/solution-change/select-options/' + model.job_id, model.course_id_selected);
  }

  async DistributionGetInfoSolutionChange(model: Types.IFilterDistributorCourseTimeSlot): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/courses-with-time-slots/', model);
  }

  async DistributionSetCourseTimeSlot(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/change-course-timeslot/', model);
  }

  async DistributionSolutionChange(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/solution-change/' + model.job_id, model.accept_inappropriate_solution);
  }

  async DistributionInvigilatorChangeGetSelectOptions(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/invigilator-change/select-options/' + model.job_id);
  }

  async DistributionGetInfoInvigilatorChange(model: Types.IFilterDistributorInvigilator): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/courses-with-invigilators/', model);
  }

  async DistributionSetExamInvigilator(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/change-exam-invigilator/', model);
  }

  async DistributionInvigilatorChange(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/reassign-invigilators-manual/' + model.job_id, model.accept_changes);
  }

  async DistributionSingleExamChangeGetSelectOptions(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/single-exam-change/select-options/' + model.job_id);
  }

  async DistributionGetInfoSingleExamChange(model: Types.IFilterDistributorSingleExam): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/single-exam/', model);
  }

  async DistributionSingleExamChange(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/change-single-exam/' + model.job_id, model.changes);
  }

  async DistributionSingleCourseChangeGetSelectOptions(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/single-course-change/select-options/' + model.job_id);
  }

  async DistributionGetInfoSingleCourseChange(model: Types.IFilterDistributorSingleExam): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/single-course/', model);
  }

  async DistributionSingleCourseChange(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/change-single-course/' + model.job_id, model.changes);
  }

  async DistributionClassroomChangeGetSelectOptions(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/classroom-change/select-options/' + model.job_id);
  }

  async DistributionGetInfoClassroomChange(model: Types.IFilterDistributorCourseTimeSlot): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/courses-with-rooms/', model);
  }

  async DistributionSetCourseClassroom(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/change-course-room/', model);
  }

  async DistributionClassroomChange(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/classroom-change/' + model.job_id, model.accept_changes);
  }

  async DistributionSubmitSolution(model: any): Promise<Response> {
    return await this.RequestAsync('POST', '/distribution/submit-solution/' + model.job_id, model.explanation);
  }

  // NOTIFICATION

  async NotificationSearch(model: Types.IFilterNotification): Promise<Response> {
    return await this.RequestAsync('POST', '/notification/search', model);
  }

  async NotificationGetById(notificationId: string): Promise<Response> {
    return await this.RequestAsync('GET', '/notification/' + notificationId);
  }

  async NotificationGetSignedUrlById(notificationId: string): Promise<Response> {
    return await this.RequestAsync('GET', '/notification/signed-url/' + notificationId);
  }

  async NotificationGetSelectOptions(): Promise<Response> {
    return await this.RequestAsync('GET', '/notification/select-options');
  }

  async NotificationUpdate(model: Types.INotificationItem): Promise<Response> {
    return await this.RequestAsync('PUT', '/notification/' + model.notification_id, model);
  }

  async NotificationCreate(model: Types.INotificationItem): Promise<Response> {
    return await this.RequestAsync('POST', '/notification', model);
  }

  async NotificationDelete(notificationIds: Array<number>): Promise<Response> {
    return await this.RequestAsync('DELETE', '/notification', notificationIds);
  }

  // BACKUP

  async BackupSearch(model: Types.IFilterBackup): Promise<Response> {
    return await this.RequestAsync('POST', '/backup/search', model);
  }

  async BackupGetById(backupId: string): Promise<Response> {
    return await this.RequestAsync('GET', '/backup/' + backupId);
  }

  async BackupGetSelectOptions(): Promise<Response> {
    return await this.RequestAsync('GET', '/backup/select-options');
  }

  async BackupUpdate(model: Types.IBackupItem): Promise<Response> {
    return await this.RequestAsync('PUT', '/backup/' + model.backup_id, model);
  }

  async BackupCreate(model: Types.IBackupItem): Promise<Response> {
    return await this.RequestAsync('POST', '/backup', model);
  }

  async BackupRestore(backupId: string): Promise<Response> {
    return await this.RequestAsync('POST', '/backup/restore/' + backupId);
  }

  async BackupDelete(backupIds: Array<number>): Promise<Response> {
    return await this.RequestAsync('DELETE', '/backup', backupIds);
  }

  // DASHBOARD

  async DashboardGetMetabaseReport(reportType: string): Promise<Response> {
    return await this.RequestAsync('GET', '/dashboard/metabase-report/' + reportType);
  }

}
