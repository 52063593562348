import 'flatpickr/dist/themes/material_green.css';
import moment from 'moment';
import { Log } from 'ng2-logger';
import React from 'react';
import Flatpickr from 'react-flatpickr';
import { Collapse } from 'reactstrap';
import * as Types from '../../store/types';

import * as Constants from '../../store/constants/all';
import Translator from '../../services/translate-factory';
const T = Translator.create();
const L = Log.create('DynamicTimeInput');

function GetInitialInputValues() {
  const InitialInputFieldValues = {
    hour: '',
    duration: ''
  };
  return Object.assign({}, InitialInputFieldValues);
}

export default class DynamicTimeInput extends React.Component<
  Types.IDynamicTimeInputProps,
  Types.IDynamicTimeInputState
> {
  state: Types.IDynamicTimeInputState = {
    timeDuartionInputs: this.props.initialValues ? this.props.initialValues : [],
    isOpen: false
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  static getDerivedStateFromProps(props: Types.IDynamicTimeInputProps, state: Types.IDynamicTimeInputState) {
    let hasNewState: boolean = false;
    if (props.initialValues && props.initialValues.length > 0) {
      state.isOpen = true;
      state.timeDuartionInputs = props.initialValues;
    }
    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  onInputAdd = () => {
    this.state.timeDuartionInputs.push(GetInitialInputValues());
    this.setState(this.state);
  };

  onInputRemove = () => {
    if (this.state.timeDuartionInputs.length === 1) {
      this.state.isOpen = false;
    }
    this.state.timeDuartionInputs.pop();
    this.setState(this.state);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  onCollapseToggle = () => {
    if (this.props.disabled) return;
    if (this.state.timeDuartionInputs.length === 0) {
      this.state.timeDuartionInputs.push(GetInitialInputValues());
    }
    this.state.isOpen = !this.state.isOpen;
    this.setState(this.state);
  };

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  render() {
    return (
      <React.Fragment>
        <div className="col-12 col-md-12 col-sm-12">
          <div className="form-group mb-0">
            <div className="add-custom-tag">
              <div className="add-tags mb-3">
                <div className="tag tag-full mr-2">
                  <span onClick={this.onCollapseToggle}>{T.t('gen_gaps')}</span>
                  {this.state.isOpen && !this.props.disabled && (
                    <React.Fragment>
                      <i className="material-icons ml-3" onClick={this.onInputRemove}>
                        remove
                      </i>
                      <i className="material-icons ml-3" onClick={this.onInputAdd}>
                        add
                      </i>
                    </React.Fragment>
                  )}
                </div>
                <Collapse isOpen={this.state.isOpen}>
                  {this.state.timeDuartionInputs.map((tdi, index) => {
                    return (
                      <React.Fragment key={'key-' + index}>
                        <div className="tag tag-full mt-0 mr-2">
                          <div className="col-md-6 form-input form-group with-icon">
                            <Flatpickr
                              disabled={this.props.disabled}
                              value={tdi.hour}
                              placeholder={T.t('gen_select_time')}
                              options={{
                                enableTime: true,
                                dateFormat: 'H:i',
                                noCalendar: true,
                                time_24hr: true,
                                defaultHour: 12
                              }}
                              onClose={(value) => {
                                tdi.hour = moment(value[0]).format('HH:mm');
                                if (this.props.onSelect) {
                                  this.props.onSelect(this.state.timeDuartionInputs);
                                }
                              }}
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="end-time-datepicker">
                              {index + 1}.{T.t('gen_gap_start_time')}
                            </label>
                          </div>
                          <div className="col-md-6 form-input form-group with-icon">
                            <Flatpickr
                              value={tdi.duration}
                              disabled={this.props.disabled}
                              placeholder={T.t('gen_select_duration')}
                              options={{
                                enableTime: true,
                                dateFormat: 'H:i',
                                noCalendar: true,
                                time_24hr: true,
                                minTime: '00:05',
                                maxTime: '03:00',
                                defaultHour: 0,
                                defaultMinute: 30
                              }}
                              onClose={(value) => {
                                tdi.duration = moment(value[0]).format('HH:mm');
                                if (this.props.onSelect) {
                                  this.props.onSelect(this.state.timeDuartionInputs);
                                }
                              }}
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="start-time-datepicker">
                              {index + 1}.{T.t('gen_gap_length')} {T.t('gen_minute_and_hour')}
                            </label>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
