import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import { EducationTypeSelectOptions } from '../../../store/constants/course-const';
import { CourseTypesLabel, ActivityTypesLabel } from '../../../store/constants/activity-const';
import SolutionEditModal from './solution-edit-modal';
import * as Actions from '../../../store/actions/general';
import { connect } from 'react-redux';
import * as Types from '../../../store/types';
import moment from 'moment';
import * as Constants from '../../../store/constants/all';
import Translator from '../../../services/translate-factory';
import { Days } from '../../../store/constants/course-period-const';

const T = Translator.create();
const Logger = Log.create('DisplayEventModal');

class DisplayEventModal extends Component<any, any> {
  state: any = {
    term_id: -1,
    combined_courses: [],
    formValues: {
      student_count: -1,
      practical_staff: [],
      practical_staff_ids: undefined,
      lecture_staff: [],
      lecture_staff_ids: undefined
    },
    solutionEditModalIsOpen: false
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.getGeneralSettings();

    /*let regexObject = window.location.pathname.match(/([^\/]+$)/);
    let id = regexObject ? parseInt(regexObject[0], 10) : -1;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.term_id = id;
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  getGeneralSettings() {
    this.props.dispatch(Actions.ApiRequest(Constants.setting.GET_GENERAL_SETTINGS, 'general-settings-spin'));
  }

  getSolutionActivitySelectOptions(event: any) {
    let term_id = this.props.termId === undefined ? this.props.term_id : this.props.termId;
    this.props.dispatch(Actions.ApiRequest(Constants.solution.SOLUTION_ACTIVITY_SELECT_OPTIONS, term_id, 'solution-activity-select-options-spin'));

    let times_range: Array<number> = [];

    const course_start_hour = moment(event.startHour, 'HH:mm').format('H');
    const course_start_mins = moment(event.startHour, 'HH:mm').format('m');

    const duration_course_start_hour = parseInt(course_start_hour, 10);
    const duration_course_start_minute = parseInt(course_start_mins, 10);
    let duration_course_start_in_minutes = (duration_course_start_hour * 60) + duration_course_start_minute;
    times_range.push(duration_course_start_in_minutes);

    const duration_hour = moment(this.props.examDates.slot_duration, 'HH:mm').format('H');
    const duration_mins = moment(this.props.examDates.slot_duration, 'HH:mm').format('m');
    const slot_duration_in_minutes = parseInt(duration_hour, 10) * 60 + parseInt(duration_mins, 10);

    for (let index = 1; index < event.lessonCount; index++) {
      duration_course_start_in_minutes = duration_course_start_in_minutes + slot_duration_in_minutes;
      times_range.push(duration_course_start_in_minutes);
    }

    let model = {
      term_id: event.termId,
      activity_no: event.id,
      solution_id: Number(event.solutionId),
      hour: Number(event.startHour.split(':')[0]),
      minute: Number(event.startHour.split(':')[1]),
      lesson_count: event.lessonCount,
      campus_code: event.campusCode,
      day_of_week: event.dayOfWeek,
      day: Days[event.dayOfWeek - 1].value,
      times_range: times_range
    }
    this.props.dispatch(Actions.ApiRequest(Constants.solution.SOLUTION_ACTIVITY_CLASSROOM_SELECT_OPTIONS, model, 'solution-activity-select-options-spin'));
  };

  onSolutionEdit = (event: any) => {
    if (event.solutionPublishmentInfo.isPublished) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_edit'),
          body: T.t("gen_solution_process_info"),
          name: 'solution_edit',
          icon: 'error_outline',
          iconColor: 'red',
          cancel: T.t('gen_close')
        })
      );
    } else {
      this.getSolutionActivitySelectOptions(event);

      this.setState({
        ...this.state,
        solutionEditModalIsOpen: !this.state.solutionEditModalIsOpen
      });
    }
  }

  switchSolutionEditModalStatus = () => {
    this.setState({
      ...this.state,
      solutionEditModalIsOpen: !this.state.solutionEditModalIsOpen
    });

    if (this.state.solutionEditModalIsOpen === false) {
      this.setCloseModal();
    }
  };

  onDeleteSolutionActivity = (event: any) => {
    if (event.solutionPublishmentInfo.isPublished) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_delete'),
          body: T.t("gen_solution_process_info"),
          name: 'solution_delete',
          icon: 'error_outline',
          iconColor: 'red',
          cancel: T.t('gen_close')
        })
      );
    } else {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_delete_action'),
          content: (
            <div>
              {event.name} ({event.code}) - {event.id}
              <p> {T.t('gen_delete_lecture_question')} </p>
            </div>
          ),
          name: 'solution_activity_delete',
          icon: 'warning',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (result: any, status: number) => {
              if (status === 200) {
                this.setCloseModal();
              }
            };

            let model = {
              term_id: event.termId,
              solution_id: event.solutionId,
              activity_no: event.id,
              user_id: this.props.user.userId,
              username: this.props.user.username
            }

            this.props.dispatch(
              Actions.ApiRequest(Constants.solution.SOLUTION_DELETE_ACTIVITY, model, 'solution-activity-list-spin', resultCallback)
            );
          }
        })
      );
    }
  };

  render() {
    const event = JSON.parse(this.props.event.desc);
    let calendar_classroom_display_type: number = this.props.settings != undefined ? this.props.settings.calendar_classroom_display_type : this.state.settings.calendar_classroom_display_type;
    let settings_solution_show_course_opened_sections: boolean = this.props.settings != undefined ? this.props.settings.solution_show_course_opened_sections : this.state.settings.solution_show_course_opened_sections;

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '90%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <div className="container">
              <div className="row">
                <div className="col-md-9 col-xs-12">
                  <span className="lesson-code">{event.code}</span>
                  <span className="lesson-code">{event.id}</span>
                  <span className="lesson-name">
                    {event.name + ' (' + ActivityTypesLabel(event.activityType, T) + ' / ' + CourseTypesLabel(event.courseType, T) + ')'}
                  </span>
                </div>
                <div className="col-md-1 col-xs-12">
                  {this.props.user && this.props.user.role === 's' ? (
                    <button
                      className="category-tag-square float-rigth"
                      style={{ color: '#fff', backgroundColor: '#dc3545' }}
                      onClick={() => this.onDeleteSolutionActivity(event)}
                    >
                      <i className="material-icons mr-2">delete_outline</i>
                      <span> {T.t('gen_delete')}</span>
                    </button>
                  ) : null}
                </div>
                <div className="col-md-1 col-xs-12">
                  {this.props.user && this.props.user.role === 's' ? (
                    <button
                      className="category-tag-square tag-orange"
                      onClick={() => this.onSolutionEdit(event)}
                    >
                      <i className="material-icons mr-2">edit</i>
                      <span>{T.t('gen_edit')}</span>
                    </button>
                  ) : null}
                </div>
                <div className="col-md-1 col-xs-12">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.setCloseModal}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-body  text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-3 col-xs-12">
                  <div className="border b-c-orange">
                    <span className="tits">{T.t('gen_date') + ' ' + T.t('gen_time')}</span>
                    <span className="text">{event.date}</span>
                  </div>
                </div>
                <div className="col-md-3 col-xs-12">
                  <div className="border b-c-green">
                    <span className="tits">{T.t('gen_course_duration')}</span>
                    <span className="text">{event.duration} {T.t('gen_minute')}</span>
                  </div>
                </div>
                <div className="col-md-3 col-xs-12">
                  <div className="border b-c-red">
                    <span className="tits">{T.t('gen_campus')}</span>
                    <span className="text">{event.campus}</span>
                  </div>
                </div>
                <div className="col-md-3 col-xs-12">
                  <div className="border b-c-blue">
                    <span className="tits">{T.t('gen_building')}</span>
                    <span className="text">{event.building ? event.building : '-'}</span>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
              <div className="row b-bottom-gray">
                <div className="col-md-3 col-xs-12 p-15">
                  <span className="tits">{T.t('gen_instructor')}</span>
                  <span className="text p-0-15">{event.instructor}</span>
                </div>
                <div className="col-md-3 col-xs-12">
                  <span className="tits p-0-15">{T.t('gen_classroom')}</span>
                  <span className="text p-0-15">
                    {(calendar_classroom_display_type == 0 ? event.classroom + ' ' + (event.classroomCode != '-' && event.classroomCode != "" ? ' (' + event.classroomCode + ')' : '-') :
                      (calendar_classroom_display_type == 1 ? (event.classroomCode != '-' && event.classroomCode != "" ? event.classroomCode : '-') : event.classroom))
                    }</span>
                </div>
                <div className="col-md-3 col-xs-12">
                  <span className="tits p-0-15">{T.t('gen_program')}</span>
                  <span className="text p-0-15">{event.program}</span>
                </div>
                <div className="col-md-3 col-xs-12">
                  <span className="tits p-0-15">{T.t('gen_student_count')}</span>
                  <span className="text p-0-15">
                    {
                      event.studentCount > event.lectureCapacity ?
                        ['s', 'a', 'b', 'e', 'd', 'p', 'r'].includes(this.props.user.role) && event.lectureCapacity != 1000 && event.lectureCapacity != 0 ?
                          (<span className="text p-0-15 c-burgundy"> {event.studentCount} </span>)
                          : event.studentCount
                        : event.studentCount
                    }
                  </span>
                </div>
                <div className="col-md-3 col-xs-12">
                  <span className="tits p-0-15">{T.t('gen_education_type')}</span>
                  <span className="text p-0-15">
                    {event.educationType === EducationTypeSelectOptions(T)[0].value ?
                      EducationTypeSelectOptions(T)[0].label :
                      event.educationType === EducationTypeSelectOptions(T)[1].value ?
                        EducationTypeSelectOptions(T)[1].label :
                        event.educationType === EducationTypeSelectOptions(T)[2].value ?
                          EducationTypeSelectOptions(T)[2].label : event.educationType}
                  </span>
                </div>
                <div className="col-md-3 col-xs-12">
                  <span className="tits p-0-15">{T.t('gen_classroom_capacity')}</span>
                  <span className="text p-0-15">
                    {
                      event.lectureCapacity != 1000 && event.lectureCapacity != 0 ?
                        event.studentCount > event.lectureCapacity ?
                          ['s', 'a', 'b', 'e', 'd', 'p', 'r'].includes(this.props.user.role) ?
                            (<span className="text p-0-15 c-burgundy"> {event.lectureCapacity} </span>)
                            : event.lectureCapacity
                          : event.lectureCapacity
                        : '-'
                    }
                  </span>
                </div>
                <div className="col-md-3 col-xs-12">
                  <span className="tits p-0-15">{T.t('gen_grade')}</span>
                  <span className="text p-0-15">{event.grade}</span>
                </div>
                <div className="col-md-3 col-xs-12">
                  <span className="tits p-0-15">{T.t('gen_section')}</span>
                  <span className="text p-0-15">{(settings_solution_show_course_opened_sections && event.courseOpenedSection != undefined) ? event.courseOpenedSection : event.branch}</span>
                </div>
                <div className="clearfix" />
              </div>
            </div>
          </div>
        </div>
        <SolutionEditModal
          activityNo={event.id}
          solutionId={event.solutionId}
          solutionEvent={event}
          solutionActivityPage={this.props.solutionActivityPage}
          modalIsOpen={this.state.solutionEditModalIsOpen}
          onClose={this.switchSolutionEditModalStatus}
          term_id={event.term_id}
          selected_ids={this.state.selected_ids}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ICoursePageProps): Types.ICoursePageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.ICoursePageProps = Object.assign({}, ownProps, {
    user: store.state.user,
    term_id: store.state.term_id,
    settings: store.state.general_settings,
    solutionActivityPage: store.state.select_options && store.state.select_options.solutionActivityPage,
    examDates: store.state.examPeriodModal && store.state.examPeriodModal.exam_dates
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.course_page) {
    return (
      !!equal(
        prev.state.user,
        next.state.user
      ) &&
      !!equal(
        prev.state.term_id,
        next.state.term_id
      ) &&
      !!equal(
        prev.state.general_settings,
        next.state.general_settings
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.solutionActivityPage,
        next.state.select_options && next.state.select_options.solutionActivityPage
      ) &&
      !!equal(
        prev.state.examPeriodModal && prev.state.examPeriodModal.exam_dates,
        next.state.examPeriodModal && next.state.examPeriodModal.exam_dates
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(DisplayEventModal);

export default container;
