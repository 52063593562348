import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Formik, FormikActions, FormikProps } from 'formik';
import { ValueType } from 'react-select/lib/types';
import { Log } from 'ng2-logger';

import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import {
  ClassroomSearchInitialValues,
  ClassroomFormInitialValues,
  ClassroomFeatureOptions
} from '../../store/constants/classroom-const';
import { routes as Routes } from '../../store/constants/routes';
import * as GT from '../../tools/general-tools';
import ImportModal, { ExcelImportKeys } from '../../components/excel-imports/import-modal';
import Spinner from '../../components/templates/spinner';
import MainLayout from '../layouts/main-layout';
import SimplePage from '../../components/templates/simple-page';
import APlanHeader from '../../components/templates/aplan-header';
import SortedColumn from '../../components/table/sorted-column';
import Paginate from '../../components/table/paginate';
import MultipleCheckbox from '../../components/checkboxes/multiple-checkbox';
import ClassroomImportForm from '../../components/excel-imports/classroom-import';
import DownloadButton from '../../components/excel-imports/export';
import DownloadButtonSyncResult from '../../components/excel-imports/export-sync-result';
import { SectionTypes } from '../../store/constants/enums';
import Translator from '../../services/translate-factory';

const T = Translator.create();
const L = Log.create('ClassroomProgramListPage');

class ClassroomTable extends Component<Types.IClassroomPageProps, Types.IClassroomPageState> {
  state: Types.IClassroomPageState = {
    filters: Object.assign({}, ClassroomSearchInitialValues),
    filterIsOpen: false,
    classroomFormIsOpen: false,
    integrationModalIsOpen: false,
    classroomId: undefined,
    all_ids: [],
    selected_ids: [],
    selected_classrooms: [],
    changeAllDataModalIsOpen: false
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    window.scrollTo(0, 0);
    this.getClassroomSelectOptions();
    this.getBuildingsByCampusesAtClassrooms([]);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  getClassroomSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.classroom.CLASSROOM_GET_SELECT_OPTIONS, 'classroom-list-spin'));
  }

  getBuildingsByCampusesAtClassrooms = (campusIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.classroom.CLASSROOM_GET_BUILDINGS_BY_CAMPUSES, campusIds, 'classroom-list-spin'));
  }

  getProgramClassrooms() {
    this.state.filters.term_id = this.props.term_id;
    this.state.filters.program_ids = [];

    this.props.dispatch(
      Actions.ApiRequest(Constants.exam_period.COURSE_PERIOD_GET_CLASSROOM_PRIORITY, this.state.filters, 'exam-period-modal-tab-spin')
    );
  }

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
  };

  onClassroomEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.currentTarget) {
      const id: string = e.currentTarget.dataset.id || '';
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.CLASSROOM, '/' + id)));
    }
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);

        const program_id = this.props.programInfo && this.props.programInfo.program_id;
        const all_ids: any = this.props.programClassroomList && this.props.programClassroomList.filter((item: any) => item.program_id == program_id).map((element: any) => element.classroom_id);

        this.setState({
          ...this.state,
          all_ids: all_ids,
          selected_ids: all_ids,
          selected_classrooms: [],
          selected_total_exam_capacity: 0,
          selected_total_lecture_capacity: 0,
          selected_id: undefined
        })
      } else {
        this.setState({
          ...this.state,
          all_ids: [],
          selected_ids: [],
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
    }
  };

  checkAllIdsSelected = (): boolean => {
    const all_ids = this.state.all_ids ? this.state.all_ids : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  onDeleteClassroom = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_classroom_delete_program'),
          body: T.t('gen_classroom_delete_program_question'),
          name: 'courseperiod_classroom_priority_delete_attached_classrooms',
          icon: 'warning',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
              if (status == 200) {
                this.state.selected_ids = [];
                this.setState(this.state);
                this.getProgramClassrooms();
              }
            };

            this.state.filters.term_id = this.props.term_id;
            this.state.filters.delete_list = this.state.selected_ids;
            let programId: any = this.props.programInfo && this.props.programInfo.program_id;
            this.state.filters.program_ids = [programId]

            this.props.dispatch(
              Actions.ApiRequest(
                Constants.exam_period.COURSE_TERM_DELETE_CLASSROOM_PRIORITY_ATTACHED_CLASSROOM,
                this.state.filters,
                'classroom-priority-tab-attached-classrooms',
                resultCallback
              )
            );
          }
        })
      );
    }
  };

  onSelectClassroom = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
        }
        this.setState({
          ...this.state,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList,
        selected_id: id
      });
    }
  };

  switchIntegrationModalStatus = () => {
    this.setState({
      ...this.state,
      integrationModalIsOpen: !this.state.integrationModalIsOpen
    });
  };

  onChangeAllData = () => {
    this.setState({
      ...this.state,
      changeAllDataModalIsOpen: !this.state.changeAllDataModalIsOpen
    });
  }

  switchChangeAlldataModalStatus = () => {
    this.setState({
      ...this.state,
      changeAllDataModalIsOpen: !this.state.changeAllDataModalIsOpen
    });
  };


  static getDerivedStateFromProps(props: Types.IClassroomPageProps, state: Types.IClassroomPageState) {
    let hasNewState: boolean = false;
    if (props.match && props.match.params.id) {
      hasNewState = true;
      state.classroomFormIsOpen = true;
      if (props.match.params.id !== 'create') {
        state.classroomId = props.match.params.id;
      } else {
        state.classroomId = undefined;
      }
    } else {
      hasNewState = true;
      state.classroomFormIsOpen = false;
      state.classroomId = undefined;
    }

    if (state.selected_id) {
      let programClassroomList: any = props.programClassroomList && props.programClassroomList.map((item) => item.classroomInfo);

      state.selected_classroom = programClassroomList!.filter((classroom: any) => classroom.classroom_id == state.selected_id);
      let isAlreadyOnSelectedClassroom = state.selected_classrooms!.filter((classroom: any) => classroom.classroom_id == state.selected_id);
      if (isAlreadyOnSelectedClassroom.length != 0) {
        let index = state.selected_classrooms!.findIndex((item: any) => isAlreadyOnSelectedClassroom.map((item: any) => item.classroom_id).includes(Number(item.classroom_id)));
        state.selected_classrooms!.splice(index, 1);
      } else {
        state.selected_classrooms = state.selected_classroom != undefined && state.selected_classroom != null ? state.selected_classrooms!.concat(state.selected_classroom) : state.selected_classrooms;
      }
      state.selected_total_exam_capacity = state.selected_classrooms!.map((classroom: any) => classroom.exam_capacity).reduce((a, b) => a + b, 0);
      state.selected_total_lecture_capacity = state.selected_classrooms!.map((classroom: any) => classroom.lecture_capacity).reduce((a, b) => a + b, 0);
      state.selected_id = undefined;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let classroomList: Array<Types.IClassroomItem> = [];
    let programClassroomList: any = this.props.programClassroomList && this.props.programClassroomList.map((item) => item.classroomInfo);
    if (programClassroomList) {
      classroomList = programClassroomList;
    }

    let classroomFeatureOptions = ClassroomFeatureOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_features && this.props.selectOptions.additional_classroom_features.length > 0) {
      classroomFeatureOptions = [...ClassroomFeatureOptions(T), ...this.props.selectOptions.additional_classroom_features].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));
    }

    return (
      <div>
        {/* TABLE STARTS HERE  */}
        <div className="ml-2">
          <h5 className="mb-sm-0">{T.t('gen_classrooms')}</h5>
          <b>({this.props && this.props.programInfo ? this.props.programInfo.name : null})</b>
        </div>
        <div className="row align-items-center mb-1">
          {this.props.user && this.props.user.role === 's' ? (
            this.state.selected_ids && this.state.selected_ids.length ? (
              <React.Fragment>
                <button
                  id='button_cancel_selection'
                  className="category-tag-square tag-gray float-left pr-2 pl-2"
                  style={{ margin: '5px' }}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      selected_ids: [],
                      selected_classrooms: [],
                      selected_total_exam_capacity: 0,
                      selected_total_lecture_capacity: 0,
                      selected_id: undefined,
                      filters: {
                        ...this.state.filters,
                        select_all: false
                      }
                    });
                  }}
                >
                  <i className="material-icons mr-2">close</i>
                  <span>
                    {T.t('gen_cancel_selection')} <b>&nbsp;({this.state.selected_ids.length})</b>
                  </span>
                </button>
                <button
                  id='button_delete_classroom'
                  className="category-tag-square float-left pr-2 pl-2"
                  style={{ margin: '5px', color: '#fff', backgroundColor: '#dc3545' }}
                  onClick={this.onDeleteClassroom}
                >
                  <i className="material-icons mr-2">delete_outline</i>
                  <span> {T.t('gen_delete_selected')}</span>
                </button>
                {!this.state.filters.select_all ? <>
                  <span className="category-tag-square tag-info float-left pr-2 pl-2"
                    style={{ margin: '5px' }}>
                    {T.t('gen_total_exam_capacity')} <b>&nbsp;({this.state.selected_total_exam_capacity})</b>
                  </span>
                  <span className="category-tag-square tag-info float-left pr-2 pl-2"
                    style={{ margin: '5px' }}>
                    {T.t('gen_total_lecture_capacity')} <b>&nbsp;({this.state.selected_total_lecture_capacity})</b>
                  </span>
                </> : null}
              </React.Fragment>
            ) : null
          ) : null}
        </div>
        <div className="row">
          <div className="col-12">
            <div style={{ overflowX: "auto", overflowY: "auto" }}>
              <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                <thead>
                  <tr>
                    {this.props.user && this.props.user.role === 's' ? (
                      <th data-cell="select">
                        <div className="tick-radio position-relative">
                          <input
                            id='select_all'
                            type="checkbox"
                            className="form-radio"
                            checked={this.checkAllIdsSelected()}
                            onChange={this.onSelectAll}
                          />
                        </div>
                      </th>
                    ) : null}
                    <SortedColumn
                      datacell="status"
                      title={T.t('gen_status')}
                      sortkey="status"
                      sortedcolumn={this.state.filters.order_by}
                      sort={this.sort}
                    />
                    <SortedColumn
                      datacell="classroom_code"
                      title={T.t('gen_code')}
                      sortkey="classroom_code"
                      sortedcolumn={this.state.filters.order_by}
                      sort={this.sort}
                    />
                    <SortedColumn
                      datacell="name"
                      className="d-none d-lg-table-cell d-xl-table-cell text-center"
                      title={T.t('gen_name')}
                      sortkey="name"
                      sortedcolumn={this.state.filters.order_by}
                      sort={this.sort}
                    />
                    <SortedColumn
                      datacell="building_name"
                      className="d-none d-lg-table-cell d-xl-table-cell text-center"
                      title={T.t('gen_campus_and_building')}
                      sortkey="building_name"
                      sortedcolumn={this.state.filters.order_by}
                      sort={this.sort}
                    />
                    <SortedColumn
                      datacell="building_floor"
                      className="d-none d-lg-table-cell d-xl-table-cell text-center"
                      title={T.t('gen_floor')}
                      sortkey="building_floor"
                      sortedcolumn={this.state.filters.order_by}
                      sort={this.sort}
                    />
                    <SortedColumn
                      datacell="door_order"
                      className="d-none d-lg-table-cell d-xl-table-cell text-center"
                      title={T.t('gen_door_number')}
                      sortkey="door_order"
                      sortedcolumn={this.state.filters.order_by}
                      sort={this.sort}
                    />
                    {this.props.term_type === 1 ? (
                      <SortedColumn
                        datacell="exam_capacity"
                        className="d-none d-lg-table-cell d-xl-table-cell text-center"
                        title={T.t('gen_exam_capacity')}
                        sortkey="exam_capacity"
                        sortedcolumn={this.state.filters.order_by}
                        sort={this.sort}
                      />
                    ) : null}
                    <SortedColumn
                      datacell="lecture_capacity"
                      className="d-none d-lg-table-cell d-xl-table-cell text-center"
                      title={T.t('gen_lecture_capacity')}
                      sortkey="lecture_capacity"
                      sortedcolumn={this.state.filters.order_by}
                      sort={this.sort}
                    />
                    {this.props.term_type === 1 ? (
                      <SortedColumn
                        datacell="invigilator_count"
                        className="d-none d-lg-table-cell d-xl-table-cell text-center"
                        title={T.t('gen_invigilator_count')}
                        sortkey="invigilator_count"
                        sortedcolumn={this.state.filters.order_by}
                        sort={this.sort}
                      />
                    ) : null}
                    <SortedColumn
                      datacell="classroom_type"
                      className="d-none d-lg-table-cell d-xl-table-cell text-center"
                      title={T.t('gen_type')}
                      sortkey="classroom_type"
                      sortedcolumn={this.state.filters.order_by}
                      sort={this.sort}
                    />
                    <th scope="col" className="text-center">
                      {T.t('gen_features')}
                    </th>
                    <th scope="col" className="text-center">
                      {T.t('gen_description')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {classroomList && classroomList.length
                    ? classroomList.map((item: Types.IClassroomItem) => {

                      let classroomFeatures: any = [];
                      item.feature_codes &&
                        item.feature_codes.length &&
                        item.feature_codes.map((featureCode: string) => {
                          let feature = classroomFeatureOptions.find(
                            (item: Types.ISelectOption) => item.value == featureCode
                          );
                          if (!feature) {
                            feature = GT.GetUnknownSelectOption(featureCode);
                          }
                          classroomFeatures.push(feature);
                        });

                      return (
                        <tr key={'classroom-' + item.classroom_id} data-title={item.name}>
                          {this.props.user && this.props.user.role === 's' ? (
                            <td data-cell="select">
                              <div className="tick-radio position-relative">
                                <input
                                  id='select_classroom'
                                  type="checkbox"
                                  className="form-radio"
                                  checked={
                                    this.state.selected_ids &&
                                    this.state.selected_ids.indexOf(item.classroom_id ? item.classroom_id : -1) >
                                    -1
                                  }
                                  data-id={item.classroom_id}
                                  onChange={this.onSelectClassroom}
                                />
                              </div>
                            </td>
                          ) : null}
                          <td scope="row" data-label={T.t('gen_status')}>
                            <div className="tags ml-1 mr-4">
                              <button
                                id='button_status'
                                className={
                                  `small-tag text-uppercase` + (item.status == 1 ? ` tag-green` : ` tag-red`)
                                }
                              >
                                {GT.GetActiveStatus(item.status)}
                              </button>
                            </div>
                          </td>
                          <td scope="row" data-label={T.t('gen_code')}>
                            {item.classroom_code}
                          </td>
                          <td data-label={T.t('gen_name')} className="text-center">
                            {item.name}
                          </td>
                          <td data-label={T.t('gen_campus_and_building')} className="text-center">
                            {item.building_name}
                          </td>
                          <td data-label={T.t('gen_floor')} className="text-center">
                            {item.building_floor}
                          </td>
                          <td data-label={T.t('gen_door_number')} className="text-center">
                            {item.door_order}
                          </td>
                          {this.props.term_type === 1 ? (
                            <td data-label={T.t('gen_exam_capacity')} className="text-center">
                              {item.exam_capacity}
                            </td>
                          ) : null}
                          <td data-label={T.t('gen_lecture_capacity')} className="text-center">
                            {item.lecture_capacity}
                          </td>
                          {this.props.term_type === 1 ? (
                            <td data-label={T.t('gen_invigilator_count')} className="text-center">
                              {item.invigilator_count}
                            </td>
                          ) : null}
                          <td data-label={T.t('gen_type')} className="text-center">
                            {item.classroom_type}
                          </td>
                          <td className="text-center">
                            {classroomFeatures && classroomFeatures.length > 0 ?
                              <div className="table-scrollable-td">{classroomFeatures && classroomFeatures.map((i: any, index: any) => (index == classroomFeatures.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                            }
                          </td>
                          <td data-label={T.t('gen_description')} className="text-center">
                            {item.description && item.description.length ?
                              <div className="table-scrollable-td">{item.description}</div> : '-'}
                          </td>
                        </tr>
                      );
                    })
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.IClassroomPageProps
): Types.IClassroomPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IClassroomPageProps = Object.assign({}, ownProps, {
    user: store.state.user,
    results: store.state.classroom_page && store.state.classroom_page.results,
    filters: store.state.classroom_page && store.state.classroom_page.filters,
    selectOptions: store.state.select_options && store.state.select_options.classroomPage,
    term_type: store.state.term_type,
    term_id: store.state.term_id,
    buildings_related_campuses: store.state.select_options && store.state.select_options.buildings_related_campuses
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.classroom_page) {
    return (
      !!equal(
        prev.state.user,
        next.state.user
      ) &&
      !!equal(
        prev.state.classroom_page && prev.state.classroom_page.results,
        next.state.classroom_page && next.state.classroom_page.results
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.buildings_related_campuses,
        next.state.select_options && next.state.select_options.buildings_related_campuses
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.classroomPage,
        next.state.select_options && next.state.select_options.classroomPage
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ClassroomTable);

export default container;
