import { Formik, FormikActions, FormikProps, ErrorMessage } from 'formik';
import { Log, Logger } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Paginate from '../../components/table/paginate';
import Announce from '../../components/templates/announce';
import { Modal } from 'reactstrap';
import Spinner from '../../components/templates/spinner';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';
import { Collapse, CardBody, Card, CardHeader } from 'reactstrap';
import { CourseClassroomSearchInitialValues } from '../../store/constants/distributor-const';
import { ValueType } from 'react-select/lib/types';


const T = Translator.create();
const L = Log.create('ClassroomEditModal');

const initialValues: any = {
  availabilityCalendarModalIsOpen: false,
  filters: CourseClassroomSearchInitialValues,
  filterIsOpen: false,
  deletedListIsOpen: false,
  deletedList: [],
  collapse: [],
  cards: [],
  accept_inappropriate_solution: true,
  term_id: -1,
  page: 1,
  total: -1,
  size: 10,
};
function getInitialState(): any {
  return Object.assign({}, initialValues);
}

class ChangeClassroomModal extends Component<any, any> {
  state: {
    availabilityCalendarModalIsOpen?: boolean;
    filters: Types.IFilterDistributorCourseClassroom;
    filterIsOpen: boolean;
    deletedListIsOpen: boolean;
    collapse: Array<Number>;
    cards: Array<Types.IDistributorCourseClassroomEditModel>;
    deleted_cards: Types.IDistributorCourseClassroomEditModel;
    term_id: number;
    size: number;
    page: number;
    total: number;
  } = getInitialState();
  componentRef: any;

  constructor(props: any) {
    super(props);
    this.componentRef = React.createRef();
    this.toggle = this.toggle.bind(this);
  }

  toggle(e: any) {
    let event = e.target.dataset.event;
    this.state.collapse.includes(Number(event)) ? this.state.collapse = this.state.collapse.filter((item: any) => item != Number(event)) : this.state.collapse.push(Number(event));
    this.setState(this.state);
  }

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.state.filters.page = 1;
    this.state.page = 1;
    this.setState(this.state);
    this.getInfoClassroomChangeDistributions();
    this.getClassroomChangeSelectOptions();
    this.setState(this.state);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  getClassroomChangeSelectOptions() {
    let model = {
      job_id: this.props.distribution_in_progress_status.job_id > 0 ? this.props.distribution_in_progress_status.job_id : this.props.jobId
    }
    this.props.dispatch(Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_CLASSROOM_CHANGE_GET_SELECT_OPTIONS, model, 'distribution-classroom-change-spin'));
  }

  getInfoClassroomChangeDistributions() {
    const resultCallback = (result: any, status: number) => {
      if (status === 200) {
        this.state.total = result && result.count_rooms;
        if (result && result.deleted_courses && result.deleted_courses.courses && result.deleted_courses.courses.length) this.state.deletedListIsOpen = false;
        else this.state.deletedListIsOpen = false;
        this.setState(this.state);
      }
    };

    this.state.filters.job_id = this.props.distribution_in_progress_status.job_id > 0 ? this.props.distribution_in_progress_status.job_id : this.props.jobId;
    this.props.dispatch(
      Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_GET_INFO_CLASSROOM_CHANGE, this.state.filters, 'distribution-classroom-change-spin', resultCallback),
    );
  }

  setCourseClassroom(course_id: number, room_id: number, assigned_room_id: number) {
    const resultCallback = (result: any, status: number) => {
      if (status === 200) {
        if (assigned_room_id == -3) {
          this.props.dispatch(
            Actions.ShowModal({
              title: T.t('gen_success'),
              body: T.t('notification_classroom_of_course_assigned') + result,
              name: 'download_sync_results',
              icon: 'info',
              iconColor: 'blue',
              cancel: T.t('gen_ok'),
            })
          );
        }
        this.getInfoClassroomChangeDistributions();
      }
    };
    const model = {
      job_id: this.props.distribution_in_progress_status.job_id > 0 ? this.props.distribution_in_progress_status.job_id : this.props.jobId,
      course_id: course_id,
      room_id: room_id,
      assigned_room_id: assigned_room_id,
    };
    this.props.dispatch(Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_CLASSROOM_CHANGE_SET_COURSE_CLASSROOM, model, '', resultCallback));
  }

  onAssignCourseTimeSlotRandomly = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, course_id: number, room_id: number) => {
    if (e && e.currentTarget) {
      const onConfirmAssignment = () => {
        this.setCourseClassroom(course_id, room_id, -3);
      };
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_distribution_assign_course_classroom_randomly_title'),
          body: T.t('gen_distribution_assign_course_classroom_randomly_body'),
          name: 'distribution_assign_course_classroom_randomly',
          icon: 'error_outline',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_no'),
          onConfirm: onConfirmAssignment
        })
      );
    }
  };

  onClassroomChange(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, accept_changes: boolean) {
    if (e && e.currentTarget) {
      const resultCallback = (result: any, status: number) => {
        if (status === 200) {
          this.setCloseModal();
        }
      };
      const onConfirm = () => {
        const model = {
          job_id: this.props.distribution_in_progress_status.job_id > 0 ? this.props.distribution_in_progress_status.job_id : this.props.jobId,
          accept_changes: accept_changes
        };
        this.props.dispatch(
          Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_CLASSROOM_CHANGE, model, 'distribution-classroom-change-spin', resultCallback)
        );
      };
      if (accept_changes) {
        this.props.dispatch(
          Actions.ShowModal({
            title: T.t('gen_distribution_assign_classroom_accept_title'),
            body: T.t('gen_distribution_assign_classroom_accept_body'),
            name: 'distribution_assign_classroom_accept',
            icon: 'info',
            iconColor: 'blue',
            confirm: T.t('gen_yes'),
            cancel: T.t('gen_no'),
            onConfirm: onConfirm
          })
        );
      } else {
        this.props.dispatch(
          Actions.ShowModal({
            title: T.t('gen_distribution_assign_classroom_cancel_title'),
            body: T.t('gen_distribution_assign_classroom_cancel_body'),
            name: 'distribution_assign_classroom_cancel',
            icon: 'error_outline',
            iconColor: 'red',
            confirm: T.t('gen_yes'),
            cancel: T.t('gen_no'),
            onConfirm: onConfirm
          })
        );
      }
    }
  }

  showInfoClassroomCapacity(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, room_capacity: number, student_count: number) {
    if (e && e.currentTarget) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_classroom_capacity_info'),
          body: room_capacity < student_count ? T.t('gen_classroom_capacity_info_insufficient_body').replace('{0}', student_count - room_capacity) :
            (room_capacity > student_count ? T.t('gen_classroom_capacity_info_greater_body').replace('{0}', room_capacity - student_count) :
              T.t('gen_classroom_capacity_info_equal_body')),
          name: 'distribution_assign_classroom_accept',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_ok'),
        })
      );
    }
  }

  onFilterCourseClassroom(model: Types.IFilterDistributorCourseClassroom, FormActions: FormikActions<Types.IFilterDistributorCourseClassroom>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.page = 1;
    this.state.filters.course_id_for_overlaps = 0;
    this.state.filterIsOpen = true;
    this.state.collapse = [];
    this.setState(this.state);
    this.getInfoClassroomChangeDistributions();
    FormActions.setSubmitting(false);
  }

  onSearchOverlappingCourses(course_id: number) {
    const filter: Types.IFilterDistributorCourseClassroom = {
      course_id_for_overlaps: course_id,
      page: 1,
      total: -1,
      size: 100,
    };
    this.state.filters = filter;
    this.state.filterIsOpen = true;
    this.state.deletedListIsOpen = false;
    this.state.collapse = [];
    this.setState(this.state);
    this.getInfoClassroomChangeDistributions();
  }

  onFormReset = () => {
    this.state.filters = Object.assign({}, CourseClassroomSearchInitialValues);
    this.setState(this.state);
    this.getInfoClassroomChangeDistributions();
  };

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  static getDerivedStateFromProps(props: any, state: any) {
    let hasNewState: boolean = false;

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  onPageChange = (page: number) => {
    this.state.page = page;
    this.state.filters.page = page;
    this.setState(this.state);
    this.getInfoClassroomChangeDistributions();
  };

  switchAvailabilityCalendarModalStatus = () => {
    if (this.state.availabilityCalendarModalIsOpen) {
      this.getClassroomChangeSelectOptions();
    }
    this.setState({
      ...this.state,
      availabilityCalendarModalIsOpen: !this.state.availabilityCalendarModalIsOpen
    });
  };

  render() {
    let termType = this.props && this.props.term_type > -1 ? this.props.term_type : -1;

    let cards = this.props.result && this.props.result.rooms_with_courses;
    let deletedCourses = this.props.result && this.props.result.deleted_courses && this.props.result.deleted_courses.courses ? this.props.result.deleted_courses.courses : [];

    const scrollToTop = () => {
      this.componentRef.current.scrollIntoView({ block: 'nearest' });
    }

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div ref={this.componentRef}></div>
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_course_and_classroom_distribution')}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <Spinner name="distribution-classroom-change-spin" />
            <div className="container-fluid p-0">
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                }}
              >
                {(props: FormikProps<Types.IDistributorCourseClassroomEditModel>) => {
                  return (
                    <form onSubmit={props.handleSubmit}>
                      <div className="row">
                        <div className="col-md-6 col-sm-4 col-12">
                          <button
                            id='button_deleted_list'
                            className="category-tag-square tag-red float-left pr-2 pl-2"
                            style={{ margin: '5px' }}
                            onClick={() => {
                              this.state.deletedListIsOpen = !this.state.deletedListIsOpen;
                              this.setState(this.state);
                            }}
                          >
                            <i className="material-icons mr-2">delete_outline</i>
                            <span>{T.t('gen_courses_without_assigned_classroom')}</span>
                          </button>
                        </div>
                        <div className="col-6 mb-3 text-right">
                          <div className="options d-md-inline-flex d-lg-inline-flex align-items-center">
                            <button
                              id='button_filter'
                              className="category-tag-square tag-blue float-right ml-3 mr-3"
                              style={{ margin: '5px' }}
                              onClick={() => {
                                this.state.filterIsOpen = !this.state.filterIsOpen;
                                this.setState(this.state);
                              }}
                            >
                              <i className="material-icons mr-2">filter_list</i>
                              <span>{T.t('gen_filter')}</span>
                            </button>
                          </div>
                        </div>
                        {/* FILTER STARTS HERE  */}
                        <div
                          className={`white-container collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                          id="advance-search"
                        >
                          <div className="advance-search d-block ml-4 mr-4" style={{}}>
                            <Formik
                              initialValues={CourseClassroomSearchInitialValues}
                              enableReinitialize={true}
                              onSubmit={(values, Actions) => {
                                this.onFilterCourseClassroom(values, Actions);
                                scrollToTop();
                              }}
                              onReset={this.onFormReset}
                            >
                              {(props: FormikProps<Types.IFilterDistributorCourseClassroom>) => {
                                return (
                                  <form onSubmit={props.handleSubmit}>
                                    <div className="row">
                                      <div className="col-12" style={{ backgroundColor: 'lightblue' }}>
                                        <h6 className="mt-1" style={{ color: 'black' }}>{T.t('gen_filter_parameters')}</h6>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_classroom')}</label>
                                            <Select
                                              id='select_classroom'
                                              className="react-select"
                                              isMulti={true}
                                              closeMenuOnSelect={false}
                                              options={
                                                this.props.selectOptions && this.props.selectOptions.rooms
                                                  ? this.props.selectOptions.rooms
                                                  : []
                                              }
                                              placeholder={T.t('gen_select_classroom')}
                                              value={props.values.rooms_select}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('rooms_select', list);
                                                props.setFieldValue(
                                                  'room_ids',
                                                  list.map((item) => item.value)
                                                );
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_classroom')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{termType == 1 ? T.t('gen_course_no') : T.t('gen_activity_no')}</label>
                                            <Select
                                              id='select_course_no'
                                              className="react-select"
                                              isMulti={true}
                                              closeMenuOnSelect={false}
                                              options={
                                                this.props.selectOptions && this.props.selectOptions.course_ids_select
                                                  ? this.props.selectOptions.course_ids_select
                                                  : []
                                              }
                                              placeholder={termType == 1 ? T.t('gen_select_course_no') : T.t('gen_select_activity_no')}
                                              value={props.values.course_ids_select}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('course_ids_select', list);
                                                props.setFieldValue(
                                                  'course_ids',
                                                  list.map((item) => item.value)
                                                );
                                              }}
                                              noOptionsMessage={(): string => (termType == 1 ? T.t('gen_select_no_course_no') : T.t('gen_select_no_activity_no'))}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_course_code')}</label>
                                            <Select
                                              id='select_course_code'
                                              className="react-select"
                                              isMulti={true}
                                              closeMenuOnSelect={false}
                                              options={
                                                this.props.selectOptions && this.props.selectOptions.course_codes_select
                                                  ? this.props.selectOptions.course_codes_select
                                                  : []
                                              }
                                              placeholder={T.t('gen_select_course_code')}
                                              value={props.values.course_codes_select}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('course_codes_select', list);
                                                props.setFieldValue(
                                                  'course_codes_ids',
                                                  list.map((item) => item.value)
                                                );
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_course_code')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="add-custom-tag mb-4">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_connected_programs')}</label>
                                            <Select
                                              id='select_program'
                                              className="react-select"
                                              isMulti={true}
                                              filterOption={(option: any, query: any) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                              }
                                              closeMenuOnSelect={false}
                                              options={
                                                this.props.selectOptions && this.props.selectOptions.programs
                                                  ? this.props.selectOptions.programs
                                                  : []
                                              }
                                              placeholder={T.t('gen_select_program')}
                                              value={props.values.programs}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('programs', list);
                                                props.setFieldValue(
                                                  'program_ids',
                                                  list.map((item) => item.value)
                                                );
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_grade')}</label>
                                            <Select
                                              id='select_grade'
                                              className="react-select"
                                              isMulti={true}
                                              closeMenuOnSelect={false}
                                              options={
                                                this.props.selectOptions && this.props.selectOptions.class
                                                  ? this.props.selectOptions.class
                                                  : []
                                              }
                                              placeholder={T.t('gen_select_grade')}
                                              value={props.values.class ? props.values.class : null}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('class', list);
                                                props.setFieldValue(
                                                  'class_ids',
                                                  list.map((item) => item.value)
                                                );
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_grade')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_instructors')}</label>
                                            <Select
                                              id='select_instructor'
                                              className="react-select"
                                              isMulti={true}
                                              filterOption={(option: any, query: any) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                              }
                                              closeMenuOnSelect={false}
                                              options={
                                                this.props.selectOptions && this.props.selectOptions.instructors
                                                  ? this.props.selectOptions.instructors
                                                  : []
                                              }
                                              placeholder={T.t('gen_select_instructor')}
                                              value={props.values.instructors}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('instructors', list);
                                                props.setFieldValue(
                                                  'instructor_ids',
                                                  list.map((item) => item.value)
                                                );
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row mb-3">
                                      <div className="col-6">
                                        <button
                                          id='button_arrow_upward'
                                          type="button"
                                          onClick={() => {
                                            this.state.filterIsOpen = false;
                                            this.setState(this.state);
                                          }}
                                          className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                                        >
                                          <i className="material-icons">arrow_upward</i>
                                        </button>
                                        <button
                                          id='button_delete_sweep'
                                          type="reset"
                                          onClick={() => {
                                            this.state.filters.page = 1;
                                            this.state.page = 1;
                                            this.setState(this.state);
                                            props.handleReset();
                                            scrollToTop();
                                          }}
                                          className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                                        >
                                          <i className="material-icons">delete_sweep</i>
                                        </button>
                                      </div>
                                      <div className="col-6 text-right">
                                        <button
                                          id='button_search'
                                          type="button"
                                          className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                                          onClick={() => props.handleSubmit()}
                                          disabled={props.isSubmitting}
                                        >
                                          <i className="material-icons mr-2">search</i>{T.t('gen_search')}
                                        </button>
                                      </div>
                                    </div>
                                    <hr />
                                    <hr />
                                  </form>
                                );
                              }}
                            </Formik>
                          </div>
                        </div>
                        {/* DELETED LIST STARTS HERE  */}
                        <div
                          className={`white-container collapse ` + (this.state.deletedListIsOpen ? `show` : ``)}
                          id="deleted-list"
                        >
                          <div className="row ml-2 mr-2">
                            <div className="col-12" style={{ backgroundColor: '#f5a4a6' }}>
                              <h6 className="mt-2" style={{ color: 'black' }}>{T.t('gen_courses_without_assigned_classroom')}</h6>
                            </div>
                            <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover filter-table">
                              <thead>
                                <tr>
                                  <th className="text-center" scope="col">
                                    {T.t('gen_activity_no')}
                                  </th>
                                  <th className="text-center" scope="col">
                                    {T.t('gen_code')}
                                  </th>
                                  <th className="text-center" scope="col">
                                    {T.t('gen_name')}
                                  </th>
                                  <th className="text-center" scope="col">
                                    {T.t('gen_student_count')}
                                  </th>
                                  <th className="text-center" scope="col">
                                    {T.t('gen_activity_type')}
                                  </th>
                                  <th className="text-center" scope="col">
                                    {T.t('gen_lesson_count')}
                                  </th>
                                  <th className="text-center" scope="col">
                                    {T.t('gen_week')}
                                  </th>
                                  <th className="text-center" scope="col">
                                    {T.t('gen_faculty')}
                                  </th>
                                  <th className="text-center" scope="col">
                                    {T.t('gen_programs')}
                                  </th>
                                  <th className="text-center" scope="col">
                                    {T.t('gen_grade')}
                                  </th>
                                  <th className="text-center" scope="col">
                                    {T.t('gen_section')}
                                  </th>
                                  <th className="text-center" scope="col">
                                    {T.t('gen_instructors')}
                                  </th>
                                  <th className="text-center" scope="col" style={{ width: '20%' }}>
                                    {T.t('gen_available_classroom')}
                                  </th>
                                  <th className="text-center" scope="col">
                                    {T.t('gen_assign_randomly')}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {deletedCourses.map((course: any, index_course: any) => {
                                  return (
                                    <tr key={'status-history-detail-' + index_course}>
                                      <td scope="row" data-label={T.t('gen_course_no')} className="text-center">
                                        {course && course.activity_detail && course.activity_detail.activity_no}
                                      </td>
                                      <td scope="row" data-label={T.t('gen_code')} className="text-center">
                                        <div className="table-scrollable-td">{course && course.activity_detail && course.activity_detail.course_code ? course.activity_detail.course_code.split(';').join(', ') : '-'}{course && course.activity_detail && course.activity_detail.conjugate_course_code ? ' (' + course.activity_detail.conjugate_course_code.split(';').join(', ') + ')' : ''}</div>
                                      </td>
                                      <td scope="row" data-label={T.t('gen_name')} className="text-center">
                                        <div className="table-scrollable-td">{course && course.activity_detail && course.activity_detail.course_name ? course.activity_detail.course_name.split(';').join(', ') : '-'}</div>
                                      </td>
                                      <td scope="row" data-label={T.t('gen_student_count')} className="text-center">
                                        {course && course.activity_detail && course.activity_detail.student_count}
                                      </td>
                                      <td scope="row" data-label={T.t('gen_activity_type')} className="text-center">
                                        {course && course.activity_detail && course.activity_detail.activity_type}
                                      </td>
                                      <td data-label={T.t('gen_lesson_count')} className="text-center">
                                        {course && course.activity_detail && course.activity_detail.lesson_count}
                                      </td>
                                      <td data-label={T.t('gen_week')} className="text-center">
                                        {course && course.activity_detail && course.activity_detail.week}
                                      </td>
                                      <td className="text-center">
                                        {
                                          course && course.activity_detail && course.activity_detail.faculties && course.activity_detail.faculties.length > 0 ?
                                            <div className="table-scrollable-td">{course.activity_detail.faculties && course.activity_detail.faculties.map((i: any, index: any) => (index == course!.activity_detail!.faculties!.length - 1) ? <>{i ? i.name : '-'}<br /></> : <>{i ? i.name : '-'},<br /></>)}</div> : '-'
                                        }
                                      </td>
                                      <td className="text-center">
                                        {
                                          course && course.activity_detail && course.activity_detail.programs && course.activity_detail.programs.length > 0 ?
                                            <div className="table-scrollable-td">{course.activity_detail.programs && course.activity_detail.programs.map((i: any, index: any) => (index == course!.activity_detail!.programs!.length - 1) ? <>{i ? i.name : '-'}<br /></> : <>{i ? i.name : '-'},<br /></>)}</div> : '-'
                                        }
                                      </td>
                                      <td data-label={T.t('gen_grade')} className="text-center">
                                        {course && course.activity_detail && course.activity_detail.class}
                                      </td>
                                      <td className="text-center">
                                        {
                                          course && course.activity_detail && course.activity_detail.sections && course.activity_detail.sections.length > 0 ?
                                            <div className="table-scrollable-td">{course.activity_detail.sections && course.activity_detail.sections.map((i: any, index: any) => (index == course!.activity_detail!.sections!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                        }
                                      </td>
                                      <td className="text-center">
                                        {
                                          course && course.activity_detail && course.activity_detail.instructors && course.activity_detail.instructors.length > 0 ?
                                            <div className="table-scrollable-td">{course.activity_detail.instructors && course.activity_detail.instructors.map((i: any, index: any) => (index == course!.activity_detail!.instructors!.length - 1) ? <>{i ? i.title + " " + i.name : '-'}<br /></> : <>{i ? i.title + " " + i.name : '-'},<br /></>)}</div> : '-'
                                        }
                                      </td>
                                      <td style={{ width: '20%' }} className="text-center" data-label={T.t('gen_available_classroom')} key={'select_available_classroom-' + index_course}>
                                        <div className="add-custom-tag mt-3">
                                          <div className="react-select-container">
                                            <Select
                                              id={'select_available_classroom-' + index_course}
                                              name={'select_available_classroom-' + index_course}
                                              className="react-select"
                                              isMulti={false}
                                              filterOption={(option: any, query: any) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                              }
                                              closeMenuOnSelect={true}
                                              options={course.available_rooms}
                                              placeholder={T.t('gen_select_available_classroom')}
                                              value={course.selected_room}
                                              onChange={(option: any) => {
                                                this.setCourseClassroom(course.activity_detail.activity_no, -2, option.value)
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                            />
                                          </div>
                                        </div>
                                      </td>
                                      <td className="text-center" data-label={T.t('button_deleted_course_restore')} key={'button_deleted_course_restore-' + index_course}>
                                        <button
                                          id={"button_deleted_course_restore" + index_course}
                                          className="ms-table-delete-button"
                                          data-toggle="tooltip"
                                          title={T.t('gen_restore_course')}
                                          style={{ backgroundColor: "#0b8902" }}
                                          disabled={course.available_rooms.length == 0}
                                          onClick={(e) => this.onAssignCourseTimeSlotRandomly(e, course.activity_detail.activity_no, -2)}
                                        >
                                          <i className="material-icons pr-1 pl-1">casino</i>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          <hr />
                        </div>
                        <div className="col-12">
                          {cards && cards.length ? (
                            cards.map((item: Types.IDistributorCourseClassroomEditModel, index: any) => {
                              return (
                                <Card style={{ marginBottom: '1rem' }} key={index} >
                                  <CardHeader style={{ backgroundColor: 'lightgray' }} onClick={this.toggle} data-event={index}>
                                    {
                                      this.state.collapse.includes(index) ?
                                        <i className="material-icons pr-1 pl-1 mb-1" onSelect={this.toggle} data-event={index}>expand_more</i> :
                                        <i className="material-icons pr-1 pl-1 mb-1" onSelect={this.toggle} data-event={index}>navigate_next</i>
                                    }
                                    <b onSelect={this.toggle} data-event={index}>{T.t('gen_classroom') + ": " + item.room}</b>
                                    <b className='float-right' onSelect={this.toggle} data-event={index}>{T.t('gen_capacity') + ": " + item.room_capacity}</b>
                                  </CardHeader>
                                  <Collapse isOpen={this.state.collapse.includes(index) ? true : false}>
                                    <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover filter-table">
                                      <thead>
                                        <tr>
                                          <th className="text-center" scope="col">
                                            {T.t('gen_activity_no')}
                                          </th>
                                          <th className="text-center" scope="col">
                                            {T.t('gen_code')}
                                          </th>
                                          <th className="text-center" scope="col">
                                            {T.t('gen_name')}
                                          </th>
                                          <th className="text-center" scope="col">
                                            {T.t('gen_student_count')}
                                          </th>
                                          <th className="text-center" scope="col">
                                            {T.t('gen_activity_type')}
                                          </th>
                                          <th className="text-center" scope="col">
                                            {T.t('gen_lesson_count')}
                                          </th>
                                          <th className="text-center" scope="col">
                                            {T.t('gen_week')}
                                          </th>
                                          <th className="text-center" scope="col">
                                            {T.t('gen_faculty')}
                                          </th>
                                          <th className="text-center" scope="col">
                                            {T.t('gen_programs')}
                                          </th>
                                          <th className="text-center" scope="col">
                                            {T.t('gen_grade')}
                                          </th>
                                          <th className="text-center" scope="col">
                                            {T.t('gen_section')}
                                          </th>
                                          <th className="text-center" scope="col">
                                            {T.t('gen_instructors')}
                                          </th>
                                          <th className="text-center" scope="col" style={{ width: '20%' }}>
                                            {T.t('gen_available_classroom')}
                                          </th>
                                          <th className="text-center" scope="col">
                                          </th>
                                          <th className="text-center" scope="col">
                                            {T.t('gen_actions')}
                                          </th>
                                          <th className="text-center" scope="col">
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {item.courses.map((course: any, index_course: any) => {
                                          return (
                                            <tr key={'status-history-detail-' + index_course}>
                                              <td scope="row" data-label={T.t('gen_course_no')} className="text-center">
                                                {course && course.activity_detail && course.activity_detail.activity_no}
                                              </td>
                                              <td scope="row" data-label={T.t('gen_code')} className="text-center">
                                                <div className="table-scrollable-td">{course && course.activity_detail && course.activity_detail.course_code ? course.activity_detail.course_code.split(';').join(', ') : '-'}{course && course.activity_detail && course.activity_detail.conjugate_course_code ? ' (' + course.activity_detail.conjugate_course_code.split(';').join(', ') + ')' : ''}</div>
                                              </td>
                                              <td scope="row" data-label={T.t('gen_name')} className="text-center">
                                                <div className="table-scrollable-td">{course && course.activity_detail && course.activity_detail.course_name ? course.activity_detail.course_name.split(';').join(', ') : '-'}</div>
                                              </td>
                                              <td scope="row" data-label={T.t('gen_student_count')} className="text-center">
                                                {course && course.activity_detail && course.activity_detail.student_count}
                                              </td>
                                              <td scope="row" data-label={T.t('gen_activity_type')} className="text-center">
                                                {course && course.activity_detail && course.activity_detail.activity_type}
                                              </td>
                                              <td data-label={T.t('gen_lesson_count')} className="text-center">
                                                {course && course.activity_detail && course.activity_detail.lesson_count}
                                              </td>
                                              <td data-label={T.t('gen_week')} className="text-center">
                                                {course && course.activity_detail && course.activity_detail.week}
                                              </td>
                                              <td className="text-center">
                                                {
                                                  course && course.activity_detail && course.activity_detail.faculties && course.activity_detail.faculties.length > 0 ?
                                                    <div className="table-scrollable-td">{course.activity_detail.faculties && course.activity_detail.faculties.map((i: any, index: any) => (index == course!.activity_detail!.faculties!.length - 1) ? <>{i ? i.name : '-'}<br /></> : <>{i ? i.name : '-'},<br /></>)}</div> : '-'
                                                }
                                              </td>
                                              <td className="text-center">
                                                {
                                                  course && course.activity_detail && course.activity_detail.programs && course.activity_detail.programs.length > 0 ?
                                                    <div className="table-scrollable-td">{course.activity_detail.programs && course.activity_detail.programs.map((i: any, index: any) => (index == course!.activity_detail!.programs!.length - 1) ? <>{i ? i.name : '-'}<br /></> : <>{i ? i.name : '-'},<br /></>)}</div> : '-'
                                                }
                                              </td>
                                              <td data-label={T.t('gen_grade')} className="text-center">
                                                {course && course.activity_detail && course.activity_detail.class}
                                              </td>
                                              <td className="text-center">
                                                {
                                                  course && course.activity_detail && course.activity_detail.sections && course.activity_detail.sections.length > 0 ?
                                                    <div className="table-scrollable-td">{course.activity_detail.sections && course.activity_detail.sections.map((i: any, index: any) => (index == course!.activity_detail!.sections!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                                }
                                              </td>
                                              <td className="text-center">
                                                {
                                                  course && course.activity_detail && course.activity_detail.instructors && course.activity_detail.instructors.length > 0 ?
                                                    <div className="table-scrollable-td">{course.activity_detail.instructors && course.activity_detail.instructors.map((i: any, index: any) => (index == course!.activity_detail!.instructors!.length - 1) ? <>{i ? i.title + " " + i.name : '-'}<br /></> : <>{i ? i.title + " " + i.name : '-'},<br /></>)}</div> : '-'
                                                }
                                              </td>
                                              <td style={{ width: '20%' }} className="text-center" data-label={T.t('gen_available_classroom')} key={'select_available_classroom-' + index_course}>
                                                <div className="add-custom-tag mt-3">
                                                  <div className="react-select-container">
                                                    <Select
                                                      id={'select_available_classroom-' + index_course}
                                                      name={'select_available_classroom-' + index_course}
                                                      className="react-select"
                                                      isMulti={false}
                                                      filterOption={(option: any, query: any) =>
                                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                      }
                                                      closeMenuOnSelect={true}
                                                      options={course.available_rooms}
                                                      placeholder={T.t('gen_select_available_classroom')}
                                                      value={course.selected_room}
                                                      onChange={(option: any) => {
                                                        this.setCourseClassroom(course.activity_detail.activity_no, item.dll_room_id, option.value);
                                                      }}
                                                      noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                                    />
                                                  </div>
                                                </div>
                                              </td>
                                              <td className="text-center" data-label={T.t('gen_classroom_capacity_info')} key={'classroom_capacity_info-' + index_course}>
                                                <button
                                                  id='button_classroom_capacity_info'
                                                  className="ms-table-delete-button"
                                                  data-toggle="tooltip"
                                                  title={item.room_capacity < course.activity_detail.student_count ? T.t('gen_over_capacity') : T.t('gen_suitable_capacity')}
                                                  style={item.room_capacity < course.activity_detail.student_count ? { backgroundColor: "#FA8B0E" } :
                                                    (item.room_capacity > course.activity_detail.student_count ? { backgroundColor: "#c3d042" } :
                                                      { backgroundColor: "#25be2a" })}
                                                  onClick={(e) => this.showInfoClassroomCapacity(e, item.room_capacity, course.activity_detail.student_count)}
                                                >
                                                  <i className="material-icons pr-1 pl-1">info</i>
                                                </button>
                                              </td>
                                              <td className="text-center" data-label={T.t('button_search_overlapping_courses')} key={'button_search_overlapping_courses-' + index_course}>
                                                <button
                                                  id={"button_search_overlapping_courses" + index_course}
                                                  className="ms-table-delete-button"
                                                  data-toggle="tooltip"
                                                  title={T.t('gen_list_interrelated_courses')}
                                                  style={{ backgroundColor: "#2596be" }}
                                                  onClick={() => {
                                                    this.onSearchOverlappingCourses(course.activity_detail.activity_no);
                                                    scrollToTop();
                                                  }}
                                                >
                                                  <i className="material-icons pr-1 pl-1">search</i>
                                                </button>
                                              </td>
                                              <td className="text-center" data-label={T.t('gen_remove_assigned_classroom')} key={'remove_assigned_classroom-' + index_course}>
                                                <button
                                                  id='button_delete_course'
                                                  className="ms-table-delete-button"
                                                  data-toggle="tooltip"
                                                  title={T.t('gen_delete')}
                                                  onClick={() => this.setCourseClassroom(course.activity_detail.activity_no, item.dll_room_id, -2)}
                                                >
                                                  <i className="material-icons pr-1 pl-1">delete_outline</i>
                                                </button>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </Collapse>
                                </Card>
                              )
                            })) : (
                            <div className="row">
                              <div className="col-md-12 col-xs-12 text-center">
                                <Announce title={T.t('gen_no_records_found')} />
                              </div>
                            </div>)
                          }
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div className="row-options justify-content-end">
              <div className="page-sorting d-flex align-items-center justify-content-center" style={{ marginTop: '5px' }}>
                {cards && cards.length > 0 && this.state.filters.course_id_for_overlaps == 0 ? (
                  <Paginate filters={this.state}
                    onPageChange={(e: any) => {
                      this.onPageChange(e);
                      this.state.collapse = [];
                      this.setState(this.state);
                      scrollToTop();
                    }} />
                ) : null}
              </div>
            </div>
            <div className="row-options justify-content-end">
              <div className="page-sorting d-flex align-items-end justify-content-end" style={{ marginRight: '5px' }}>
                {cards && cards.length > 0 ? (
                  <div className="row">
                    <button
                      id='button_cancel'
                      type="button"
                      className="btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2 mr-3"
                      onClick={(e) => {
                        this.onClassroomChange(e, false);
                      }}
                    >
                      {T.t('gen_cancel')}
                    </button>
                    <button
                      id='button_save'
                      type="button"
                      className="btn btn-green mt-md-0 mt-2 mb-md-0 mb-2 mr-3"
                      onClick={(e) => {
                        this.onClassroomChange(e, true);
                      }}
                    >
                      {T.t('gen_save')}
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Modal >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IDistributorPageProps): Types.IDistributorPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IDistributorPageProps = Object.assign({}, ownProps, {
    result: store.state.distributor_classroom_change_page && store.state.distributor_classroom_change_page.results,
    term_type: store.state.term_type,
    term_id: store.state.term_id,
    distribution_in_progress_status: store.state.distribution_in_progress_status,
    selectOptions: store.state.select_options && store.state.select_options.distributorClassroomChangePage,
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.examPeriodModal) {
    return (
      !!equal(
        prev.state.distributor_classroom_change_page && prev.state.distributor_classroom_change_page.results,
        next.state.distributor_classroom_change_page && next.state.distributor_classroom_change_page.results
      ) &&
      !!equal(
        prev.state && prev.state.term_type,
        next.state && next.state.term_type
      ) &&
      !!equal(
        prev.state && prev.state.term_id,
        next.state && next.state.term_id
      ) &&
      !!equal(
        prev.state && prev.state.distribution_in_progress_status,
        next.state && next.state.distribution_in_progress_status
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.distributorClassroomChangePage,
        next.state.select_options && next.state.select_options.distributorClassroomChangePage
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ChangeClassroomModal);

export default container;