import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Formik, FormikActions, FormikProps } from 'formik';
import { ValueType } from 'react-select/lib/types';
import { Log } from 'ng2-logger';

import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import {
  ClassroomSearchInitialValues,
  ClassroomFormInitialValues,
  ClassroomFeatureOptions
} from '../../store/constants/classroom-const';
import { routes as Routes } from '../../store/constants/routes';
import * as GT from '../../tools/general-tools';
import ImportModal, { ExcelImportKeys } from '../../components/excel-imports/import-modal';
import ClassroomForm from './classroom-form';
import IntegrationModal from './classroom-integration-modal';
import SyncClassroomModal from './classroom-sync-modal';
import Spinner from '../../components/templates/spinner';
import MainLayout from '../layouts/main-layout';
import SimplePage from '../../components/templates/simple-page';
import APlanHeader from '../../components/templates/aplan-header';
import SortedColumn from '../../components/table/sorted-column';
import Paginate from '../../components/table/paginate';
import MultipleCheckbox from '../../components/checkboxes/multiple-checkbox';
import ClassroomImportForm from '../../components/excel-imports/classroom-import';
import DownloadButton from '../../components/excel-imports/export';
import DownloadButtonSyncResult from '../../components/excel-imports/export-sync-result';
import { SectionTypes } from '../../store/constants/enums';
import ChangeAllDataModal from './change-all-data-modal';
import Translator from '../../services/translate-factory';

const T = Translator.create();
const L = Log.create('FacultyListPage');

class ClassroomTable extends Component<Types.IClassroomPageProps, Types.IClassroomPageState> {
  state: Types.IClassroomPageState = {
    filters: Object.assign({}, ClassroomSearchInitialValues),
    filterIsOpen: false,
    classroomFormIsOpen: false,
    integrationModalIsOpen: false,
    classroomId: undefined,
    all_ids: [],
    selected_ids: [],
    selected_classrooms: [],
    changeAllDataModalIsOpen: false
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    window.scrollTo(0, 0);
    this.searchClassrooms();
    this.getClassroomSelectOptions();
    this.getBuildingsByCampusesAtClassrooms([]);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  searchClassrooms() {
    this.props.dispatch(
      Actions.ApiRequest(Constants.classroom.CLASSROOM_LIST_SEARCH, this.state.filters, 'classroom-list-spin')
    );
  }

  getClassroomSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.classroom.CLASSROOM_GET_SELECT_OPTIONS, 'classroom-list-spin'));
  }

  getBuildingsByCampusesAtClassrooms = (campusIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.classroom.CLASSROOM_GET_BUILDINGS_BY_CAMPUSES, campusIds, 'classroom-list-spin'));
  }

  onClassroomImported = () => {
    this.searchClassrooms();
  };

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.searchClassrooms();
  };

  classroomFormIsOpen = () => {
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.CLASSROOM, '/create')));
  };

  classroomFormOnClose = (refresh: boolean) => {
    if (this.state.classroomFormIsOpen) {
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.CLASSROOM)));
    }
    if (refresh) {
      this.searchClassrooms();
    }
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.searchClassrooms();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, ClassroomSearchInitialValues);
    this.setState(this.state);
    this.searchClassrooms();
  };

  onFilterClassroom(model: Types.IFilterClassroom, FormActions: FormikActions<Types.IFilterClassroom>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.searchClassrooms();
    FormActions.setSubmitting(false);
  }

  onClassroomEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.currentTarget) {
      const id: string = e.currentTarget.dataset.id || '';
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.CLASSROOM, '/' + id)));
    }
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.classroom.CLASSROOM_LIST_SEARCH,
            this.state.filters,
            'classroom-list-spin',
            (response: any) =>
              this.setState({
                ...this.state,
                all_ids: response.all_ids,
                selected_ids: response.all_ids,
                selected_classrooms: [],
                selected_total_exam_capacity: 0,
                selected_total_lecture_capacity: 0,
                selected_id: undefined
              })
          )
        );
      } else {
        this.setState({
          ...this.state,
          all_ids: [],
          selected_ids: [],
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
    }
  };

  checkAllIdsSelected = (): boolean => {
    const all_ids = this.state.all_ids ? this.state.all_ids : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  onDeleteClassroom = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_delete_action'),
          body: T.t('gen_delete_classroom_question'),
          name: 'classroom_delete',
          icon: 'warning',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (status: number) => {
              if (status == 200) {
                this.state.selected_ids = [];
                this.state.filters = ClassroomSearchInitialValues;
                this.setState(this.state);
                this.searchClassrooms();
              }
            };

            const deleteList = this.state.selected_ids;
            this.props.dispatch(
              Actions.ApiRequest(
                Constants.classroom.CLASSROOM_DELETE,
                deleteList,
                'classroom-list-spin',
                resultCallback
              )
            );
          }
        })
      );
    }
  };

  onSelectClassroom = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
        }
        this.setState({
          ...this.state,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList,
        selected_id: id
      });
    }
  };

  switchIntegrationModalStatus = () => {
    this.setState({
      ...this.state,
      integrationModalIsOpen: !this.state.integrationModalIsOpen
    });
  };

  onChangeAllData = () => {
    this.setState({
      ...this.state,
      changeAllDataModalIsOpen: !this.state.changeAllDataModalIsOpen
    });
  }

  switchChangeAlldataModalStatus = () => {
    this.setState({
      ...this.state,
      changeAllDataModalIsOpen: !this.state.changeAllDataModalIsOpen
    });
  };


  static getDerivedStateFromProps(props: Types.IClassroomPageProps, state: Types.IClassroomPageState) {
    let hasNewState: boolean = false;
    if (props.match && props.match.params.id) {
      hasNewState = true;
      state.classroomFormIsOpen = true;
      if (props.match.params.id !== 'create') {
        state.classroomId = props.match.params.id;
      } else {
        state.classroomId = undefined;
      }
    } else {
      hasNewState = true;
      state.classroomFormIsOpen = false;
      state.classroomId = undefined;
    }

    if (state.selected_id) {
      state.selected_classroom = props.results!.filter((classroom: any) => classroom.classroom_id == state.selected_id);
      let isAlreadyOnSelectedClassroom = state.selected_classrooms!.filter((classroom: any) => classroom.classroom_id == state.selected_id);
      if (isAlreadyOnSelectedClassroom.length != 0) {
        let index = state.selected_classrooms!.findIndex((item: any) => isAlreadyOnSelectedClassroom.map((item: any) => item.classroom_id).includes(Number(item.classroom_id)));
        state.selected_classrooms!.splice(index, 1);
      } else {
        state.selected_classrooms = state.selected_classroom != undefined && state.selected_classroom != null ? state.selected_classrooms!.concat(state.selected_classroom) : state.selected_classrooms;
      }
      state.selected_total_exam_capacity = state.selected_classrooms!.map((classroom: any) => classroom.exam_capacity).reduce((a, b) => a + b, 0);
      state.selected_total_lecture_capacity = state.selected_classrooms!.map((classroom: any) => classroom.lecture_capacity).reduce((a, b) => a + b, 0);
      state.selected_id = undefined;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let classroomList = this.props.results;

    let classroomFeatureOptions = ClassroomFeatureOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_features && this.props.selectOptions.additional_classroom_features.length > 0) {
      classroomFeatureOptions = [...ClassroomFeatureOptions(T), ...this.props.selectOptions.additional_classroom_features].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));
    }

    return (
      <MainLayout header={<APlanHeader />}>
        <SimplePage name="faculty-page">
          <div className="main list-campus">
            <div className="container-fluid">
              <h4 className="mb-4 mb-sm-0">{T.t('gen_classrooms')}</h4>
              <Spinner name="classroom-list-spin" />
              <div className="white-container mt-4">
                {/* TABLE STARTS HERE  */}
                <div className="row align-items-center mb-1">
                  <div className="col-md-10 col-sm-4 col-12">
                    {this.props.user && this.props.user.role === 's' ? (
                      this.state.selected_ids && this.state.selected_ids.length ? (
                        <React.Fragment>
                          <button
                            id='button_cancel_selection'
                            className="category-tag-square tag-gray float-left pr-2 pl-2"
                            style={{ margin: '5px' }}
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                selected_ids: [],
                                selected_classrooms: [],
                                selected_total_exam_capacity: 0,
                                selected_total_lecture_capacity: 0,
                                selected_id: undefined,
                                filters: {
                                  ...this.state.filters,
                                  select_all: false
                                }
                              });
                            }}
                          >
                            <i className="material-icons mr-2">close</i>
                            <span>
                              {T.t('gen_cancel_selection')} <b>&nbsp;({this.state.selected_ids.length})</b>
                            </span>
                          </button>
                          <button
                            id='button_delete_classroom'
                            className="category-tag-square float-left pr-2 pl-2"
                            style={{ margin: '5px', color: '#fff', backgroundColor: '#dc3545' }}
                            onClick={this.onDeleteClassroom}
                          >
                            <i className="material-icons mr-2">delete_outline</i>
                            <span> {T.t('gen_delete_selected')}</span>
                          </button>
                          {!this.state.filters.select_all ? <>
                            <span className="category-tag-square tag-info float-left pr-2 pl-2"
                              style={{ margin: '5px' }}>
                              {T.t('gen_total_exam_capacity')} <b>&nbsp;({this.state.selected_total_exam_capacity})</b>
                            </span>
                            <span className="category-tag-square tag-info float-left pr-2 pl-2"
                              style={{ margin: '5px' }}>
                              {T.t('gen_total_lecture_capacity')} <b>&nbsp;({this.state.selected_total_lecture_capacity})</b>
                            </span>
                          </> : null}
                          <button
                            id='button_sync'
                            className="category-tag-square float-left pr-2 pl-2 tag-green"
                            style={{ margin: '5px', color: '#fff' }}
                            onClick={this.onChangeAllData}
                          >
                            <i className="material-icons mr-2">sync</i>
                            <span> {T.t('gen_change_all_data')}</span>
                          </button>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <button id='button_add' className="category-tag-square tag-green" onClick={this.classroomFormIsOpen}>
                            <i className="material-icons mr-2">add</i>
                            {T.t('gen_add_classroom')}
                          </button>
                          <button
                            id='button_list_sync'
                            className="category-tag-square tag-green ml-1"
                            onClick={this.switchIntegrationModalStatus}
                          >
                            <i className="material-icons mr-2">playlist_add</i>
                            {T.t('gen_list_sync_jobs')}
                          </button>
                          <ImportModal
                            title={T.t('gen_add_with_excel')}
                            componentKey={ExcelImportKeys.Classrooms}
                            dispatch={this.props.dispatch}
                            onImport={this.onClassroomImported}
                          />
                          <DownloadButtonSyncResult title={'SyncClassroomsResult'} sectionType={SectionTypes.SyncClassroomsResult} />
                          <DownloadButton title={T.t('gen_classrooms')} sectionType={SectionTypes.Classrooms} />
                        </React.Fragment>
                      )
                    ) : null}
                  </div>
                  <div className="col-md-2 col-sm-8 col-12 text-right">
                    <div className="options d-md-inline-flex d-lg-inline-flex align-items-center">
                      <button
                        id='button_filter'
                        className="category-tag-square tag-glass float-right ml-3 mr-3"
                        style={{ margin: '5px' }}
                        onClick={() => {
                          this.state.filterIsOpen = !this.state.filterIsOpen;
                          this.setState(this.state);
                        }}
                      >
                        <i className="material-icons mr-2">filter_list</i>
                        <span>{T.t('gen_filter')}</span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* FILTER STARTS HERE  */}
                <div
                  className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                  id="advance-search"
                >
                  <div className="advance-search d-block mt-3">
                    <Formik
                      initialValues={ClassroomSearchInitialValues}
                      enableReinitialize={true}
                      onSubmit={(values, actions) => {
                        this.onFilterClassroom(values, actions);
                      }}
                      onReset={this.onFormReset}
                    >
                      {(props: FormikProps<Types.IFilterClassroom>) => {
                        return (
                          <form onSubmit={props.handleSubmit}>
                            <div className="row">
                              <div className="col-12">
                                <h6>{T.t('gen_filter_parameters')}</h6>
                              </div>
                              <div className="col-md-3 mt-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="classroom_code"
                                    name="classroom_code"
                                    value={props.values.classroom_code}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="classroom_code">{T.t('gen_classroom_code')}</label>
                                  <i className="material-icons">filter_1</i>
                                </div>
                              </div>
                              <div className="col-md-3 mt-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="name"
                                    name="name"
                                    value={props.values.name}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="name">{T.t('gen_classroom_name')}</label>
                                  <i className="material-icons">title</i>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_campus')}</label>
                                    <Select
                                      id='select_campus'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.campuses
                                          ? this.props.selectOptions.campuses
                                          : []
                                      }
                                      placeholder={T.t('gen_select_campus')}
                                      value={props.values.campuses ? props.values.campuses : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('campuses', list);
                                        props.setFieldValue('campus_ids', list.map((item) => item.value));
                                        this.getBuildingsByCampusesAtClassrooms(list.map((item) => item.value));
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_campus')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_building')}</label>
                                    <Select
                                      id='select_building'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={this.props.buildings_related_campuses ? this.props.buildings_related_campuses : []}
                                      placeholder={T.t('gen_select_building')}
                                      value={props.values.buildings ? props.values.buildings : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('buildings', list);
                                        props.setFieldValue('building_ids', list.map((item) => item.value));
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_building')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_classroom_features')}</label>
                                    <Select
                                      id='select_feature'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={classroomFeatureOptions}
                                      placeholder={T.t('gen_select_feature')}
                                      value={props.values.classroom_features}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('classroom_features', list);
                                        props.setFieldValue(
                                          'classroom_feature_codes',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_feature')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_classroom_type')}</label>
                                    <Select
                                      id='select_type'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.classroom_types
                                          ? this.props.selectOptions.classroom_types
                                          : []
                                      }
                                      placeholder={T.t('gen_select_type')}
                                      value={props.values.classroom_types ? props.values.classroom_types : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('classroom_types', list);
                                        props.setFieldValue(
                                          'classroom_type_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2 mt-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="building_floor"
                                    name="building_floor"
                                    value={props.values && props.values.building_floor}
                                    onChange={props.handleChange}
                                    type="number"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="building_floor">{T.t('gen_floor')}</label>
                                </div>
                              </div>
                              <div className="col-md-2 mt-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="door_order"
                                    name="door_order"
                                    value={props.values.door_order}
                                    onChange={props.handleChange}
                                    type="number"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="door_order">{T.t('gen_door_number')}</label>
                                </div>
                              </div>
                              {this.props.term_type === 1 ? (
                                <div className="col-md-2 mt-3">
                                  <div className="form-input form-group date-picker">
                                    <input
                                      id="exam_capacity"
                                      name="exam_capacity"
                                      value={props.values.exam_capacity}
                                      onChange={props.handleChange}
                                      type="number"
                                      required
                                    />
                                    <span className="highlight" />
                                    <span className="bar" />
                                    <label htmlFor="exam_capacity">{T.t('gen_exam_capacity')}</label>
                                  </div>
                                </div>
                              ) : null}
                              <div className="col-md-2 mt-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="lecture_capacity"
                                    name="lecture_capacity"
                                    value={props.values.lecture_capacity}
                                    onChange={props.handleChange}
                                    type="number"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="lecture_capacity">{T.t('gen_lecture_capacity')}</label>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-md-6">
                                <div className="text-left">
                                  <h6>{T.t('gen_status')}</h6>
                                  <div className="tick-radio position-relative mb-3 d-inline-block">
                                    <MultipleCheckbox name="status" text={T.t('gen_active')} value={1} />
                                  </div>
                                  <div className="tick-radio position-relative mb-3 d-inline-block ml-4">
                                    <MultipleCheckbox name="status" text={T.t('gen_passive')} value={0} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row mt-3">
                              <div className="col-6">
                                <button
                                  id='button_arrow_upward'
                                  type="button"
                                  onClick={() => {
                                    this.state.filterIsOpen = false;
                                    this.setState(this.state);
                                  }}
                                  className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                                >
                                  <i className="material-icons">arrow_upward</i>
                                </button>
                                <button
                                  id='button_delete_sweep'
                                  type="reset"
                                  onClick={props.handleReset}
                                  className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                                >
                                  <i className="material-icons">delete_sweep</i>
                                </button>
                              </div>
                              <div className="col-6 text-right">
                                <button
                                  id='button_search'
                                  type="button"
                                  className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                                  onClick={() => props.handleSubmit()}
                                  disabled={props.isSubmitting}
                                >
                                  <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                                </button>
                              </div>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                      <thead>
                        <tr>
                          {this.props.user && this.props.user.role === 's' ? (
                            <th data-cell="select">
                              <div className="tick-radio position-relative">
                                <input
                                  id='select_all'
                                  type="checkbox"
                                  className="form-radio"
                                  checked={this.checkAllIdsSelected()}
                                  onChange={this.onSelectAll}
                                />
                              </div>
                            </th>
                          ) : null}
                          <SortedColumn
                            datacell="status"
                            title={T.t('gen_status')}
                            sortkey="status"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="classroom_code"
                            title={T.t('gen_code')}
                            sortkey="classroom_code"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="name"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_name')}
                            sortkey="name"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="building_name"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_campus_and_building')}
                            sortkey="building_name"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="building_floor"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_floor')}
                            sortkey="building_floor"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="door_order"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_door_number')}
                            sortkey="door_order"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          {this.props.term_type === 1 ? (
                            <SortedColumn
                              datacell="exam_capacity"
                              className="d-none d-lg-table-cell d-xl-table-cell text-center"
                              title={T.t('gen_exam_capacity')}
                              sortkey="exam_capacity"
                              sortedcolumn={this.state.filters.order_by}
                              sort={this.sort}
                            />
                          ) : null}
                          <SortedColumn
                            datacell="lecture_capacity"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_lecture_capacity')}
                            sortkey="lecture_capacity"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          {this.props.term_type === 1 ? (
                            <SortedColumn
                              datacell="invigilator_count"
                              className="d-none d-lg-table-cell d-xl-table-cell text-center"
                              title={T.t('gen_invigilator_count')}
                              sortkey="invigilator_count"
                              sortedcolumn={this.state.filters.order_by}
                              sort={this.sort}
                            />
                          ) : null}
                          <SortedColumn
                            datacell="classroom_type"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_type')}
                            sortkey="classroom_type"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <th scope="col" className="text-center">
                            {T.t('gen_features')}
                          </th>
                          <th scope="col" className="text-center">
                            {T.t('gen_description')}
                          </th>
                          {this.props.user && this.props.user.role === 's' ? (
                            <th scope="col" className="text-right">
                              <span className="text-right">{T.t('gen_actions')}</span>
                            </th>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        {classroomList && classroomList.length
                          ? classroomList.map((item: Types.IClassroomItem) => {

                            let classroomFeatures: any = [];
                            item.feature_codes &&
                              item.feature_codes.length &&
                              item.feature_codes.map((featureCode: string) => {
                                let feature = classroomFeatureOptions.find(
                                  (item: Types.ISelectOption) => item.value == featureCode
                                );
                                if (!feature) {
                                  feature = GT.GetUnknownSelectOption(featureCode);
                                }
                                classroomFeatures.push(feature);
                              });

                            return (
                              <tr key={'classroom-' + item.classroom_id} data-title={item.name}>
                                {this.props.user && this.props.user.role === 's' ? (
                                  <td data-cell="select">
                                    <div className="tick-radio position-relative">
                                      <input
                                        id='select_classroom'
                                        type="checkbox"
                                        className="form-radio"
                                        checked={
                                          this.state.selected_ids &&
                                          this.state.selected_ids.indexOf(item.classroom_id ? item.classroom_id : -1) >
                                          -1
                                        }
                                        data-id={item.classroom_id}
                                        onChange={this.onSelectClassroom}
                                      />
                                    </div>
                                  </td>
                                ) : null}
                                <td scope="row" data-label={T.t('gen_status')}>
                                  <div className="tags ml-1 mr-4">
                                    <button
                                      id='button_status'
                                      className={
                                        `small-tag text-uppercase` + (item.status == 1 ? ` tag-green` : ` tag-red`)
                                      }
                                    >
                                      {GT.GetActiveStatus(item.status)}
                                    </button>
                                  </div>
                                </td>
                                <td scope="row" data-label={T.t('gen_code')}>
                                  {item.classroom_code}
                                </td>
                                <td data-label={T.t('gen_name')} className="text-center">
                                  {item.name}
                                </td>
                                <td data-label={T.t('gen_campus_and_building')} className="text-center">
                                  {item.building_name}
                                </td>
                                <td data-label={T.t('gen_floor')} className="text-center">
                                  {item.building_floor}
                                </td>
                                <td data-label={T.t('gen_door_number')} className="text-center">
                                  {item.door_order}
                                </td>
                                {this.props.term_type === 1 ? (
                                  <td data-label={T.t('gen_exam_capacity')} className="text-center">
                                    {item.exam_capacity}
                                  </td>
                                ) : null}
                                <td data-label={T.t('gen_lecture_capacity')} className="text-center">
                                  {item.lecture_capacity}
                                </td>
                                {this.props.term_type === 1 ? (
                                  <td data-label={T.t('gen_invigilator_count')} className="text-center">
                                    {item.invigilator_count}
                                  </td>
                                ) : null}
                                <td data-label={T.t('gen_type')} className="text-center">
                                  {item.classroom_type}
                                </td>
                                <td className="text-center">
                                  {classroomFeatures && classroomFeatures.length > 0 ?
                                    <div className="table-scrollable-td">{classroomFeatures && classroomFeatures.map((i: any, index: any) => (index == classroomFeatures.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                  }
                                </td>
                                <td data-label={T.t('gen_description')} className="text-center">
                                  {item.description && item.description.length ?
                                    <div className="table-scrollable-td">{item.description}</div> : '-'}
                                </td>
                                {this.props.user && this.props.user.role === 's' ? (
                                  <td data-label={T.t('gen_actions')} className="table-buttons">
                                    <div className="table-buttons-wrapper">
                                      <button
                                        id='button_edit'
                                        data-toggle="tooltip"
                                        data-id={item.classroom_id}
                                        onClick={this.onClassroomEdit}
                                        title={T.t('gen_edit')}
                                        className="btn btn-light btn-sm table-button"
                                      >
                                        <span className="d-block" data-toggle="modal" data-target="#addNew">
                                          <i className="material-icons">edit</i>
                                        </span>
                                      </button>
                                    </div>
                                  </td>
                                ) : null}
                              </tr>
                            );
                          })
                          : null}
                      </tbody>
                    </table>
                    <div className="row-options justify-content-end">
                      <div
                        className="page-sorting d-flex align-items-center justify-content-center"
                        style={{ marginTop: '5px' }}
                      >
                        {this.props.results && this.props.results.length > 0 && (
                          <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SimplePage>
        <ClassroomForm
          classroomId={this.state.classroomId}
          formIsOpen={this.state.classroomFormIsOpen}
          onClose={this.classroomFormOnClose}
        />
        {
          this.state.integrationModalIsOpen ? (
            <SyncClassroomModal
              modalIsOpen={this.state.integrationModalIsOpen}
              onClose={this.switchIntegrationModalStatus}
              onUpdateList={() => this.searchClassrooms()}
            />
          ) : null
        }
        <ChangeAllDataModal
          modalIsOpen={this.state.changeAllDataModalIsOpen}
          onClose={this.switchChangeAlldataModalStatus}
          term_id={this.state.filters.term_id}
          selected_ids={this.state.selected_ids}
        />
      </MainLayout >
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.IClassroomPageProps
): Types.IClassroomPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IClassroomPageProps = Object.assign({}, ownProps, {
    user: store.state.user,
    results: store.state.classroom_page && store.state.classroom_page.results,
    filters: store.state.classroom_page && store.state.classroom_page.filters,
    selectOptions: store.state.select_options && store.state.select_options.classroomPage,
    term_type: store.state.term_type,
    buildings_related_campuses: store.state.select_options && store.state.select_options.buildings_related_campuses
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.classroom_page) {
    return (
      !!equal(
        prev.state.user,
        next.state.user
      ) &&
      !!equal(
        prev.state.classroom_page && prev.state.classroom_page.results,
        next.state.classroom_page && next.state.classroom_page.results
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.buildings_related_campuses,
        next.state.select_options && next.state.select_options.buildings_related_campuses
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.classroomPage,
        next.state.select_options && next.state.select_options.classroomPage
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ClassroomTable);

export default container;
