import React, { Component } from 'react';
import { Formik, ErrorMessage, FormikProps, FormikActions } from 'formik';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import { Log } from 'ng2-logger';

import * as Types from '../../store/types';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { CampusFormValidation } from './validations/campus-form-val';
import { CampusFormInitialValues } from '../../store/constants/campus-const';

import Checkbox from '../../components/checkboxes/single-checkbox';
import Spinner from '../../components/templates/spinner';

import Translator from '../../services/translate-factory';
const T = Translator.create();

const equal = require('deep-equal');
const Logger = Log.create('CampusForm');

function getInitialState(): Types.ICampusFormState {
  const initialValues: Types.ICampusFormState = {
    model: Object.assign({}, CampusFormInitialValues)
  };
  return Object.assign({}, initialValues);
}

class CampusForm extends Component<Types.ICampusFormProps, Types.ICampusFormState> {
  state: Types.ICampusFormState = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    document.onkeyup = (e: KeyboardEvent) => {
      if (this.props.formIsOpen && e.key === 'Escape') {
        this.setClose();
      }
    };
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseForm = () => {
    this.setClose();
  };

  onFormSave = (model: Types.ICampusItem, FormActions: FormikActions<Types.ICampusItem>) => {

    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (result && result.code) {
        let errors: any = {};
        if (result.details) {
          const validations: Array<Types.IValidationResponse> = result.details;
          validations.forEach((m: Types.IValidationResponse) => {
            errors[m.field] = m.message[0];
          });
        }

        FormActions.setErrors(errors);
      }
      if (status === 200 || status === 201) {
        this.setClose(true);
      }
    };

    if (this.props.campusId) {
      this.props.dispatch(
        Actions.ApiRequest(Constants.campus.CAMPUS_UPDATE, model, 'campus-form-spin', resultCallback)
      );
    } else {
      this.props.dispatch(
        Actions.ApiRequest(Constants.campus.CAMPUS_CREATE, model, 'campus-form-spin', resultCallback)
      );
    }
    FormActions.setSubmitting(false);
  };

  static getDerivedStateFromProps(props: Types.ICampusFormProps, state: Types.ICampusFormState) {
    let hasNewState: boolean = false;
    if (props.campusId && props.campusId != state.model.campus_id) {
      state.model.campus_id = props.campusId;
      props.dispatch(Actions.ApiRequest(Constants.campus.CAMPUS_GET_BY_ID, props.campusId, 'campus-form-spin'));
      hasNewState = true;
    }
    if (props.form && props.campusId && props.campusId == state.model.campus_id) {
      state.model = props.form;
      if (props.form.buildings) {
        state.model.building_ids = props.form.buildings.map((f) => f.value);
      }
      hasNewState = true;
    }
    if (hasNewState) {
      return state;
    } else if (!props.campusId && state.model.campus_id) {
      return getInitialState();
    } else return null;
  }

  render() {
    return (
      <Modal modalClassName="modal-from-right" className="pt-0" isOpen={this.props.formIsOpen}>
        <Spinner name="campus-form-spin" />
        <Formik
          enableReinitialize={true}
          initialValues={this.state.model}
          onSubmit={(values, actions) => {
            this.onFormSave(values, actions);
          }}
          validationSchema={CampusFormValidation(T)}
        >
          {(props: FormikProps<Types.ICampusItem>) => {
            const { values, handleChange, errors, handleBlur, handleSubmit } = props;
            return (
              <form onSubmit={handleSubmit}>
                <div className="" id="addNew">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">
                          <span>
                            {this.props.campusId ? <><i className="material-icons mr-2">edit</i>{T.t('gen_update_campus')}</> : <><i className="material-icons mr-2">add_circle_outline</i> {T.t('gen_add_campus')}</>}
                          </span>
                        </h5>
                        <button
                          id='button_close'
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={this.setCloseForm}
                        >
                          <i className="material-icons">close</i>
                          <span>ESC</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="campus_code"
                              name="campus_code"
                              value={values.campus_code}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="campus_code">{T.t('gen_code')}</label>
                            {errors && errors.campus_code && props.submitCount > 0 && (
                              <div className="error">{errors && errors.campus_code}</div>
                            )}
                          </div>
                          <div className="col-md-6 form-input form-group with-icon">
                            <input
                              id="name"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="name">{T.t('gen_name')}</label>
                            {errors && errors.name && props.submitCount > 0 && (
                              <div className="error">{errors && errors.name}</div>
                            )}
                          </div>
                          <div className="col-md-12 form-input form-group with-icon">
                            <input
                              id="address"
                              name="address"
                              value={values.address}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="text"
                              required
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="address">{T.t('gen_address')}</label>
                          </div>
                          <div className="col-md-12 form-input form-group with-icon">
                            <textarea
                              name="description"
                              className="form-input"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              rows={2}
                              id="description"
                              value={values.description || ''}
                              placeholder={T.t('gen_description')}
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="description" />
                            {errors && errors.description && props.submitCount > 0 && (
                              <div className="error">{errors && errors.description}</div>
                            )}
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <div className="text-left">
                              <h6>{T.t('gen_status')}</h6>
                              <div className="tick-radio position-relative d-inline-block">
                                <Checkbox name="status" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer d-block">
                        <div className="row">
                          <div className="col-md">
                            <button
                              id='button_cancel'
                              type="button"
                              data-dismiss="modal"
                              className="btn btn-gray min-auto"
                              aria-label="Close"
                              onClick={this.setCloseForm}
                            >
                              {T.t('gen_cancel')}
                            </button>
                          </div>
                          <div className="col-md text-md-right">
                            <button
                              id='button_save'
                              type="button"
                              data-dismiss="modal"
                              aria-label="alert-success"
                              onClick={() => props.handleSubmit()}
                              className="btn btn-green"
                            >
                              <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ICampusFormProps): Types.ICampusFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.ICampusFormProps = Object.assign({}, ownProps, {
    form: store.state.campus_page && store.state.campus_page.form ? store.state.campus_page.form : getInitialState(),
    selectOptions: store.state.select_options && store.state.select_options.campusPage
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.campus_page) {
    return (
      !!equal(
        prev.state.campus_page && prev.state.campus_page.form,
        next.state.campus_page && next.state.campus_page.form
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.campusPage,
        next.state.select_options && next.state.select_options.campusPage
      )
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(CampusForm);

export default container;
