import { put, takeEvery, all, take, select } from 'redux-saga/effects';
import * as actions from '../constants/all';
import { Log } from 'ng2-logger';
import PerculusApiClient from '../../services/perculus-api-client';
import MockAPIClient from '../../services/mock-api-client';
import * as Types from '../types';
import * as Actions from '../actions/general';
const L = Log.create('dashboard-saga');

function* getSessions(action: Types.IApiRequestAction) {
  const papi: PerculusApiClient = new PerculusApiClient();
  const response: Response = yield papi.GetResponse(papi.DashboardGetSessions());
  let content: any = null;

  if (response && response.status === 200) {
    content = yield papi.GetContent(response);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getMetabaseReport(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.DashboardGetMetabaseReport(action.body));
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

export function* watchDashboardGetSessions() {
  yield takeEvery((action: any) => action.target === actions.dasboard.DASHBOARD_GET_SESSIONS, getSessions);
}

export function* watchDashboardGetMetabaseReport() {
  yield takeEvery((action: any) => action.target === actions.dasboard.DASHBOARD_GET_METABASE_REPORT, getMetabaseReport);
}
