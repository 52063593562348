import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';

export const constants = {
  CLASSROOM_LIST_SEARCH: 'CLASSROOM:LIST_SEARCH',
  CLASSROOM_CREATE: 'CLASSROOM:CREATE',
  CLASSROOM_UPDATE: 'CLASSROOM:UPDATE',
  CLASSROOM_DELETE: 'CLASSROOM:DELETE',
  CLASSROOM_GET_BY_ID: 'CLASSROOM:GET_BY_ID',
  CLASSROOM_GET_SELECT_OPTIONS: 'CLASSROOM:GET_SELECT_OPTIONS',
  CLASSROOM_GET_BUILDINGS_BY_CAMPUSES: 'CLASSROOM:GET_BUILDINGS_BY_CAMPUSES',
  CLASSROOM_CREATE_EXCEL_BULK: 'CLASSROOM:CREATE_EXCEL_BULK',
  CLASSROOM_CHANGE_ALL_DATA: 'CLASSROOM:CHANGE_ALL_DATA',
};

export const ClassroomSearchInitialValues: Types.IFilterClassroom = {
  classroom_code: '',
  name: '',
  status: [],
  buildings: [],
  classroom_features: [],
  classroom_types: [],
  classroom_ids: [],
  course_ids: [],
  term_id: undefined,
  select_all: false,
  add_list: [],
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10
};

export const ClassroomHoursSearchInitialValues: Types.IFilterClassroom = {
  classroom_code: '',
  name: '',
  status: [1],
  buildings: [],
  classroom_types: [],
  classroom_ids: [],
  course_ids: [],
  term_id: undefined,
  add_list: [],
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10,
  select_all: false
};

export const ClassroomFormInitialValues: Types.IClassroomItem = {
  classroom_id: undefined,
  classroom_code: '',
  status: 1,
  name: '',
  description: '',
  building_name: '',
  campus_name: '',
  building_floor: '',
  door_order: undefined,
  exam_capacity: undefined,
  lecture_capacity: undefined,
  invigilator_count: undefined,
  classroom_type: '',
  features: []
};

export const ChangeAlldataModalInitialValues: Types.IFilterChangeAllDataModal = {
  status: 0
};

export const ClassroomFeatureOptions = (T: Translator) => [
  { label: T.t('gen_smartboard'), value: 'AT' },
  { label: T.t('gen_computer'), value: 'Bil' },
  { label: T.t('gen_dvd'), value: 'DVD' },
  { label: T.t('gen_internet_access'), value: 'Int' },
  { label: T.t('gen_air_conditioner'), value: 'Klima' },
  { label: T.t('gen_projector'), value: 'Proj' },
  { label: T.t('gen_fixed_curtain'), value: 'SbtPer' },
  { label: T.t('gen_sound_system'), value: 'SesSist' },
  { label: T.t('gen_video'), value: 'Vid' }
];

export const ImportingKeySelectOptions = (T: Translator) => [
  { label: T.t('gen_building_code_and_classroom_code'), value: 'BUILDINGCODE_CLASSROOMCODE' },
  { label: T.t('gen_do_not_check'), value: 'NONE' }
];