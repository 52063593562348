import { constants } from '../constants/course-period-const';
import * as Types from '../types';
import * as GT from '../../tools/general-tools';

const coursePeriodReducer = (state: Types.IAppState = GT.GetInitialState(), action: Types.IAction): Types.IAppState => {
  switch (action.type) {
    case constants.COURSE_PERIOD_LIST_SEARCH: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          course_period_page: {
            ...state.course_period_page,
            results: result.content ? result.content.data : [],
            filters: result.content.filters
          }
        };
      }
    }
    case constants.ACTIVITY_RELATION_SEARCH: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      let data = [];
      if (result && result.content) {
        data = result.content.data;
      }
      return {
        ...state,
        activity_relation_page: {
          ...state.activity_relation_page,
          activity_relations: data,
          filters: result.content.filters
        }
      };
    }
    case constants.COURSE_PERIOD_GET_BY_ID: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          course_period_page: {
            ...state.course_period_page,
            form: result.content.data
          }
        });
      }
      return state;
    }
    case constants.COURSE_TERM_HOURS_UPDATE: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          course_period_page: {
            ...state.course_period_page,
            courseTermHoursUpdateResult: result.content ? result.content.data : [],
          }
        };
      }
    }

     // Derslere aktarılan derslikleri apiden çekip redux store'a ekleme
     case constants.COURSE_TERM_ATTACHED_CLASSROOM: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        if (state.examPeriodModal && state.examPeriodModal.course_hours) {
          state = Object.assign({}, state, {
            course_period_page: {
              ...state.course_period_page,
              attached_classrooms: result.content.data,
              attached_classrooms_filters: result.content.filters
            }
          });
        }
      }
      return state;
    }

    default: {
      return state;
    }
  }
};

export default coursePeriodReducer;
