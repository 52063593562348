import { constants } from '../constants/program-const';
import * as Types from '../types';
import * as GT from '../../tools/general-tools';

const classroomReducer = (state: Types.IAppState = GT.GetInitialState(), action: Types.IAction): Types.IAppState => {
  switch (action.type) {
    case constants.PROGRAM_LIST_SEARCH: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        return {
          ...state,
          program_page: {
            ...state.program_page,
            results: result.content ? result.content.data : [],
            filters: result.content.filters
          }
        };
      }
    }
    case constants.PROGRAM_GET_BY_ID: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          program_page: {
            ...state.program_page,
            form: result.content.data
          }
        });
      }
      return state;
    }
    case constants.PROGRAM_GET_SELECT_OPTIONS: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          select_options: {
            ...state.select_options,
            programPage: result.content
          }
        });
      }
      return state;
    }
    case constants.PROGRAM_CREATE_EXCEL_BULK: {
      const result: Types.IApiResponseAction = action as Types.IApiResponseAction;
      if (result && result.content) {
        state = Object.assign({}, state, {
          program_import_result: result.content
        });
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

export default classroomReducer;
