import React from 'react';
import { Log } from 'ng2-logger';
import { IPageProps } from '../../store/types';
import { routes as Routes } from '../../store/constants/routes';
import * as GT from '../../tools/general-tools';
import imgLogo from '../../assets_aplan/images/main-logo.svg';
import APlanLogo from '../../assets_aplan/images/plan-365-logo.png';
import iconMenu from '../../assets/images/hamburger.svg';
import iconUser from '../../assets/images/sample-user-image.png';
import * as Actions from '../../store/actions/general';

import * as Constants from '../../store/constants/all';
import { SettingsDashboardStatus } from '../../store/constants/dashboard-const';
import SyncJobModal from './sync-job-modal';
import * as Types from '../../store/types';

import { MdLanguage, MdPersonOutline, MdMenu, MdWarning } from 'react-icons/md';
import Translator from '../../services/translate-factory';
import { Link } from 'react-router-dom';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
  UncontrolledCollapse
} from 'reactstrap';
import AppFactory from '../../services/app-factory';
import { connect } from 'react-redux';
import { ShowModal } from '../../store/actions/general';
import TermSelector from '../term/term-selector';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { UncontrolledTooltip, UncontrolledAlert } from 'reactstrap';
import { NotificationSearchInitialValues, NotificationStatuses, NotificationTypes } from '../../store/constants/notification-const';
import { Modal, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';

const T = Translator.create();
const AF = AppFactory.create();
const L = Log.create('App');

class Header extends React.Component<IPageProps, Types.IHeaderState> {
  state: Types.IHeaderState = {
    syncModalIsOpen: false,
    eventSource: null,
    notificationInventory: {
      notifications: []
    }
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    if (this.props.term_id) {
      this.CreateSSE();
    }
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.CloseSSE();
  }


  private CreateSSE() {
    let user_id = !!this.props.user ? this.props.user.userId : 0;
    var path = window.location.origin == "http://localhost:3000" ? "http://localhost:2900" : window.location.origin + "/api";
    const eventSourceLocal = new EventSourcePolyfill(path + "/notification/" + user_id + "/" + this.props.term_id, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("access_token"),
        'Content-Type': 'text/event-stream',
        'Cache-Control': 'no-cache',
      }
    }) as EventSource;

    this.setState({
      ...this.state,
      eventSource: eventSourceLocal
    });

    eventSourceLocal.addEventListener('notification_inventory', (e: any) => {
      if (e.data) {
        const notificationInventory: Types.ISSENotificationInventory = JSON.parse(e.data);
        if (notificationInventory) {
          let notficationInventory = {
            notifications: notificationInventory.notifications ? notificationInventory.notifications : [],
          }
          if (notficationInventory == null || notficationInventory == undefined) {
            this.CloseSSE();
          }
          this.setState({
            ...this.state,
            notificationInventory: notficationInventory
          });
        }
      }
    }, false);
  }

  private CloseSSE() {
    const eventSourceLocal = (this.state.eventSource as EventSource);
    if (!!this.state.eventSource) {
      eventSourceLocal.close();
    }
  }

  onLogOut = () => {
    AF.logOff();
  };

  onGoToPersonalInformations = () => {
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.USER_SETTINGS)))
  }

  switchSyncModalStatus = () => {
    this.setState({
      ...this.state,
      syncModalIsOpen: !this.state.syncModalIsOpen
    });
  };

  navigateToHelpPage = () => {
    window.location.href = 'https://yardim.kampus365.com/aym/plan365';
  }

  navigateToReportIssuePage = () => {
    window.location.href = 'https://destek.kampus365.com/servicedesk';
  }

  showDownloadFileModal = (e: React.MouseEvent<HTMLButtonElement>, notification_id: number) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_download_file_title'),
          body: T.t('gen_download_file_notification_body'),
          name: 'download_file',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            this.props.dispatch(
              Actions.ApiRequest(Constants.notification.NOTIFICATION_GET_SIGNED_URL_BY_ID, notification_id, '',
                (result: string, status: number) => {
                  if (status === 200) {
                    this.openLinkInNewTab(result);
                  }
                })
            );
          }
        })
      );
    }
  };

  openLinkInNewTab(url: string) {
    var win = window.open(url, '_blank');
    win && win.focus();
  }

  render() {
    const languages: Array<Types.ISelectOption> = GT.Languages().map((m) => {
      return { label: T.t(m.label), value: m.value };
    });

    let definitionMenu = (
      <li>
        <a
          className="nav-link dropdown-toggle"
          href="#"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {T.t('gen_definitions')}
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
          {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a') ? (
            <Link to={GT.Route(Routes.CAMPUS)} className="dropdown-item">
              <i className="material-icons mr-2">account_balance</i>
              {T.t('gen_campuses')}
            </Link>
          ) : null}
          {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a') ? (
            <Link to={GT.Route(Routes.BUILDING)} className="dropdown-item">
              <i className="material-icons mr-2">domain</i>{T.t('gen_buildings')}
            </Link>
          ) : null}
          {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a') ? (
            <Link to={GT.Route(Routes.CLASSROOM)} className="dropdown-item">
              <i className="material-icons mr-2">class</i>
              {T.t('gen_classrooms')}
            </Link>
          ) : null}
          {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a') ? (
            <Link to={GT.Route(Routes.FACULTY)} className="dropdown-item">
              <i className="material-icons mr-2">location_city</i>
              {T.t('gen_faculties')}
            </Link>
          ) : null}
          {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a') ? (
            <Link to={GT.Route(Routes.PROGRAM)} className="dropdown-item">
              <i className="material-icons mr-2">assignment</i>
              {T.t('gen_programs')}
            </Link>
          ) : null}
          <Link to={GT.Route(Routes.INSTRUCTOR)} className="dropdown-item">
            <i className="material-icons mr-2">person</i>
            {T.t('gen_instructors')}
          </Link>
          <Link to={GT.Route(Routes.COURSE)} className="dropdown-item">
            <i className="material-icons mr-2">school</i>{T.t('gen_courses')}
          </Link>
          {this.props.term_type === 0 ? (
            <Link to={GT.Route(Routes.ACTIVITY_RELATIONS)} className="dropdown-item">
              <i className="material-icons mr-2">account_tree</i>{T.t('gen_activity_relations')}
            </Link>
          ) : null}
        </div>
      </li>
    );

    let helpMenu = (
      <li>
        <a
          className="nav-link dropdown-toggle"
          href="#"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {T.t('gen_help')}
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
          <a onClick={this.navigateToHelpPage} className="dropdown-item">
            <i className="material-icons mr-2">help</i>
            {T.t('gen_help_guide')}
          </a>
          <a onClick={this.navigateToReportIssuePage} className="dropdown-item">
            <i className="material-icons mr-2">report_problem</i>
            {T.t('gen_report_issue')}
          </a>
        </div>
      </li>
    );

    return (
      <header>
        <div className="container-fluid" >
          <div className="row align-items-center">
            <div className="col-lg-1 col-md-3">
              <Link to={GT.Route(Routes.DASHBOARD)}>
                <img src={APlanLogo} alt="APlan" style={{ width: '100px', height: 'auto' }} />
              </Link>
              <button
                className="btn menu-button d-block d-md-none"
                type="button"
                data-toggle="collapse"
                data-target="#mNav"
              >
                Menu <img src={iconMenu} alt="Menu" className="ml-2" />
              </button>
            </div>
            <div className="col-lg-11 col-md-9">
              <div
                className="d-md-flex justify-content-md-between align-items-md-center mobile-menu collapse"
                id="mNav"
              >
                <button
                  type="button"
                  className="d-md-none close"
                  data-toggle="collapse"
                  data-target="#mNav"
                  aria-label="Close"
                >
                  <i className="material-icons">close</i>
                  <span>CLOSE</span>
                </button>
                <ul className="menu">
                  {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'p' || this.props.user.role === 'r') &&
                    this.props.general_settings && this.props.general_settings.dashboard_status > SettingsDashboardStatus.Passive ? (
                    <li>
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {T.t('gen_dashboard')}
                      </a>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        {this.props.general_settings && this.props.general_settings.dashboard_status != SettingsDashboardStatus.ActiveForExamTerms ? (
                          <Link to={GT.Route(Routes.DASHBOARD_COURSE_PLANNING)} className="dropdown-item">
                            <i className="material-icons mr-2">event_note</i>
                            {T.t('gen_course_planning')}
                          </Link>
                        ) : null}
                        {this.props.general_settings && this.props.general_settings.dashboard_status != SettingsDashboardStatus.ActiveForCourseTerms ? (
                          {/* // todo after 2926: no reports for exam planning!
                        <Link to={GT.Route(Routes.DASHBOARD_EXAM_PLANNING)} className="dropdown-item">
                          <i className="material-icons mr-2">event_note</i>
                          {T.t('gen_exam_planning')}
                        </Link> */}
                        ) : null}
                      </div>
                    </li>
                  ) : null}
                  {this.props.user && this.props.user.role === 's' ? (
                    <li>
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {T.t('gen_synchronization')}
                      </a>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a onClick={this.switchSyncModalStatus} className="dropdown-item">
                          <i className="material-icons mr-2">sync</i>
                          {T.t('gen_new_sync')}
                        </a>
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {T.t('gen_existing_sync_jobs')}
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                          <Link to={GT.Route(Routes.SYNC_CAMPUS)} className="dropdown-item">
                            <i className="material-icons mr-2">sync</i>{T.t('gen_campuses')}
                          </Link>
                          <Link to={GT.Route(Routes.SYNC_BUILDING)} className="dropdown-item">
                            <i className="material-icons mr-2">sync</i>{T.t('gen_buildings')}
                          </Link>
                          <Link to={GT.Route(Routes.SYNC_CLASSROOM)} className="dropdown-item">
                            <i className="material-icons mr-2">sync</i>{T.t('gen_classrooms')}
                          </Link>
                          <Link to={GT.Route(Routes.SYNC_FACULTY)} className="dropdown-item">
                            <i className="material-icons mr-2">sync</i>{T.t('gen_faculties')}
                          </Link>
                          <Link to={GT.Route(Routes.SYNC_PROGRAM)} className="dropdown-item">
                            <i className="material-icons mr-2">sync</i>{T.t('gen_programs')}
                          </Link>
                          <Link to={GT.Route(Routes.SYNC_INSTRUCTOR)} className="dropdown-item">
                            <i className="material-icons mr-2">sync</i>{T.t('gen_instructors')}
                          </Link>
                          <Link to={GT.Route(Routes.SYNC_COURSE)} className="dropdown-item">
                            <i className="material-icons mr-2">sync</i>{T.t('gen_courses')}
                          </Link>
                          {
                            this.props.term_type === 1 ?
                              <Link to={GT.Route(Routes.SYNC_STUDENT)} className="dropdown-item">
                                <i className="material-icons mr-2">sync</i>{T.t('gen_students')}
                              </Link>
                              : null
                          }
                        </div>
                      </div>
                    </li>
                  ) : null}
                  <li>
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {T.t('gen_terms')}
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <Link to={GT.Route(Routes.COURSE_PERIODS)} className="dropdown-item">
                        <i className="material-icons mr-2">event_note</i>
                        {T.t('gen_course_terms')}
                      </Link>
                      <Link to={GT.Route(Routes.EXAM_PERIODS)} className="dropdown-item">
                        <i className="material-icons mr-2">event_note</i>
                        {T.t('gen_exam_terms')}
                      </Link>
                    </div>
                  </li>
                  {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a') && (window.innerWidth > 1170 || window.innerWidth < 765) ? (
                    definitionMenu
                  ) : null}
                  {(window.innerWidth > 1300 || window.innerWidth < 765) ? (
                    <li>
                      <Link
                        to={GT.Route(
                          this.props.term_type == 0 ? Routes.COURSE_PERIODS : Routes.EXAM_PERIODS,
                          '/calendar/' + this.props.term_id
                        )}
                      >
                        {T.t('gen_planning')}
                      </Link>
                    </li>
                  ) : null}
                  {this.props.user && this.props.user.role === 's' && (window.innerWidth > 1395 || window.innerWidth < 765) ? (
                    <li>
                      <Link to={GT.Route(Routes.USERS)}>{T.t('gen_users')}</Link>
                    </li>
                  ) : null}
                  {(window.innerWidth > 1490 || window.innerWidth < 765) ? (
                    <li>
                      <Link to={GT.Route(Routes.SOLUTION, '/solution/' + this.props.term_id)}>{T.t('gen_solution')}</Link>
                    </li>
                  ) : null}
                  {(window.innerWidth > 1595 || window.innerWidth < 765) ? (
                    helpMenu
                  ) : null}
                  {this.props.user && this.props.user.role === 's' && (window.innerWidth > 1660 || window.innerWidth < 765) ? (
                    <li>
                      <Link to={GT.Route(Routes.SETTINGS)}>{T.t('gen_settings')}</Link>
                    </li>
                  ) : null}
                  {this.props.user && this.props.user.role === 's' && (window.innerWidth > 1765 || window.innerWidth < 765) ? (
                    <li>
                      <Link to={GT.Route(Routes.BACKUPS)}>{T.t('gen_backup')}</Link>
                    </li>
                  ) : null}
                  {(window.innerWidth <= 1765 && window.innerWidth >= 765) ? (
                    <li>
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                      </a>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a') && (window.innerWidth <= 1170) ? (
                          definitionMenu
                        ) : null}
                        {(window.innerWidth <= 1300) ? (
                          <li>
                            <Link
                              to={GT.Route(
                                this.props.term_type == 0 ? Routes.COURSE_PERIODS : Routes.EXAM_PERIODS,
                                '/calendar/' + this.props.term_id
                              )}
                            >
                              {T.t('gen_planning')}
                            </Link>
                          </li>
                        ) : null}
                        {this.props.user && this.props.user.role === 's' && (window.innerWidth <= 1395) ? (
                          <li>
                            <Link to={GT.Route(Routes.USERS)}>{T.t('gen_users')}</Link>
                          </li>
                        ) : null}
                        {(window.innerWidth <= 1490) ? (
                          <li>
                            <Link to={GT.Route(Routes.SOLUTION, '/solution/' + this.props.term_id)}>{T.t('gen_solution')}</Link>
                          </li>
                        ) : null}
                        {(window.innerWidth <= 1595) ? (
                          helpMenu
                        ) : null}
                        {this.props.user && this.props.user.role === 's' && (window.innerWidth <= 1660) ? (
                          <li>
                            <Link to={GT.Route(Routes.SETTINGS)}>{T.t('gen_settings')}</Link>
                          </li>
                        ) : null}
                        {this.props.user && this.props.user.role === 's' && (window.innerWidth <= 1765) ? (
                          <li>
                            <Link to={GT.Route(Routes.BACKUPS)}>{T.t('gen_backup')}</Link>
                          </li>
                        ) : null}
                      </div>
                    </li>
                  ) : null}
                </ul>
                <TermSelector onLogOut={this.onLogOut} />
                <div className="header-item menu-lang"></div>
                <div className="header-options">
                  {
                    this.state.notificationInventory && this.state.notificationInventory.notifications && this.state.notificationInventory.notifications.length > 0 && this.state.notificationInventory.notifications[0].is_read == false ?
                      <Link to={GT.Route(Routes.NOTIFICATIONS)} id='notification_tooltip'>
                        {<i className="material-icons" style={{ fontSize: 'xx-large' }}>
                          circle_notifications
                        </i>}
                        {(window.innerWidth > 760) ?
                          <div
                            id='notification_span'
                            className="mss-notification-span"
                            style={{ margin: '25px 20px 0px 0px' }}
                          >
                            <div style={{ marginTop: '-2px' }}>
                              {this.state.notificationInventory.notifications!.length}
                            </div>
                          </div> :
                          <div
                            id='notification_span'
                            className="mss-notification-span"
                          >
                            <div style={{ marginTop: '-2px' }}>
                              {this.state.notificationInventory.notifications!.length}
                            </div>
                          </div>
                        }
                        <UncontrolledPopover trigger="hover focus" placement="bottom" target="notification_tooltip">
                          <PopoverBody className="scrollable-popoverbody">
                            {this.state.notificationInventory.notifications.map((item: Types.INotificationItem) => {
                              return (
                                <tr key={'notification-' + item.notification_id} data-title={item.description}>
                                  <td style={{ width: '15px' }} scope="row" data-label={T.t('gen_status')} className="text-center mr-1">
                                    <div className="tags">
                                      <button
                                        id='button_status'
                                        className={
                                          `small-tag text-uppercase` +
                                          (item.status == 1 ? ` tag-green` : (item.status == 0 ? ` tag-orange` : ` tag-red`))
                                        }
                                      >
                                        {NotificationStatuses(T).find((x) => x.value === item.status)!.label}
                                      </button>
                                    </div>
                                  </td>
                                  <td style={{ minWidth: '95px' }} data-label={T.t('gen_notification_type')} className="text-center mr-1 ml-1">
                                    {NotificationTypes(T).find((x) => x.value === item.type)!.label}
                                  </td>
                                  <td style={{ minWidth: '50px' }} data-label={T.t('gen_description')} className="text-center mr-1 ml-1">
                                    {item.description && item.description.length ?
                                      <div className="table-scrollable-td">{item.description}</div> : '-'}
                                  </td>
                                  <td data-label={T.t('gen_download')} className="text-center">
                                    {item && item.status == 1 && item.type != 2 ? (
                                      <button
                                        id='button_download'
                                        className="category-tag-square tag-blue text-center ml-2 mt-2"
                                        disabled={((item.status == 1) ? false : true)}
                                        onClick={(e) => this.showDownloadFileModal(e, item.notification_id)}>
                                        <i className="material-icons">download</i>
                                      </button>
                                    ) : ("-")}
                                  </td>
                                </tr>
                              );
                            })}
                          </PopoverBody>
                        </UncontrolledPopover>
                      </Link>
                      : (
                        <Link to={GT.Route(Routes.NOTIFICATIONS)}>
                          {<i className="material-icons" style={{ fontSize: 'xx-large' }}>
                            circle_notifications
                          </i>}
                        </Link>
                      )
                  }
                  <UncontrolledButtonDropdown title={T.t('gen_header_change_lang')}>
                    <DropdownToggle className="" color="transparent" tag="div">
                      <i className="material-icons" style={{ fontSize: 'xx-large' }}>
                        language
                      </i>
                    </DropdownToggle>
                    <DropdownMenu>
                      {languages.map((m, i) => {
                        return (
                          <DropdownItem key={'lang-' + i} onClick={() => T.changeLanguage(m.value, true)}>
                            {m.label}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                  <UncontrolledButtonDropdown>
                    <DropdownToggle className="" color="transparent" tag="div">
                      <i className="material-icons" style={{ fontSize: 'xx-large' }}>
                        account_circle
                      </i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={this.onGoToPersonalInformations}>
                        {T.t('gen_user_settings')}
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem onClick={this.onLogOut}>{T.t('gen_sign_out')}</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.syncModalIsOpen ? (
          <SyncJobModal
            modalIsOpen={this.state.syncModalIsOpen}
            onClose={this.switchSyncModalStatus}
          />
        ) : null}
      </header>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IPageProps): Types.IPageProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IPageProps = Object.assign({}, ownProps, {
    user: store.state.user,
    term_id: store.state.term_id,
    term_type: store.state.term_type,
    general_settings: store.state.general_settings
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps)(Header);

export default container;
