import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Types from '../../../store/types';
import Translator from '../../../services/translate-factory';
import Select from 'react-select';
import { ErrorMessage, Formik, FormikActions, FormikProps } from 'formik';
import {
  ExamPeriodCourseTermLectureLocations
} from '../../../store/constants/course-const';

const T = Translator.create();

function getInitialState(): any {
  const initialValues: any = {
    classroom_selected: {},
    classroom_id_selected: 0,
    invigilator_selected: {},
    invigilator_id_selected: 0,
    student_counts: [],
    student_count: 0,
    combined_values: [],
    combined_values_temp: [],
    combined_value_query: "",
    isAdded: false,
    loadedProps: false,
    courseDetail: undefined,
    isValidationPassed: true,
  };
  return Object.assign({}, initialValues);
}

class MultiSelectionTableClassroom extends Component<any, any> {
  state: {
    classroom_selected: Types.ISelectOption;
    classroom_id_selected: number;
    invigilator_selected: Types.ISelectOption;
    invigilator_id_selected: number;
    student_counts?: Array<any>;
    student_count: number;
    all_ids?: Array<number>;
    selected_ids?: Array<number>;
    combined_values: Array<any>;
    combined_values_temp: Array<any>;
    combined_value_query: string;
    isAdded: boolean;
    alreadyInTheList: boolean;
    loadedProps: boolean;
    courseDetail: any;
    isValidationPassed: boolean;
  } = getInitialState();

  setInitialState() {
    if (this.props.initialCVS) {
      const newState = {
        ...this.state,
        combined_values: [...this.props.initialCVS],
      }
      this.setState(newState)
    }
  }

  componentDidMount() {
    this.setInitialState()
  }

  putToTable = (values: any, plusButtonClicked: boolean) => {
    let addModal = {
      classroom_id: values.classroom_id_selected,
      classroom: values.classroom_selected,
      invigilator_id: values.invigilator_id_selected,
      invigilator: values.invigilator_selected,
      student_count: values.student_count,
      index: this.state.combined_values.length + 1,
    }

    if (values.classroom_id_selected > 0 && values.student_count > 0) {
      this.state.combined_values.push(addModal)
      this.state.isAdded = true;

      this.setState({
        ...this.state,
        combined_values: this.state.combined_values,
        total_student_count: this.state.combined_values.map((item: any) => item.student_count).reduce((a: number, b: number) => a + b, 0),
        all_ids: [],
        selected_ids: [],
        isAdded: true,
        isValidationPassed: true
      });
    } else {
      this.setState({
        ...this.state,
        isValidationPassed: false,
      });
    }

    plusButtonClicked = !plusButtonClicked;
  };

  onDeleteRow = (selected_ids: any) => {
    if (this.state.selected_ids) {
      let isTempCombinedValues: boolean = this.state.combined_value_query != undefined && this.state.combined_value_query != '' && this.state.combined_values_temp && this.state.combined_values_temp.length != 0;
      if (isTempCombinedValues) {
        selected_ids && selected_ids.forEach((id: any, index: number) => {
          this.state.combined_values_temp = this.state.combined_values_temp.filter((item: any) => item.index != id)
        });
      } else {
        selected_ids && selected_ids.forEach((id: any, index: number) => {
          this.state.combined_values = this.state.combined_values.filter((item: any) => item.index != id)
        });
      }
      this.setState({
        ...this.state,
        combined_values: isTempCombinedValues ? this.state.combined_values_temp : this.state.combined_values,
        total_student_count: this.state.combined_values.map((item: any) => item.student_count).reduce((a: number, b: number) => a + b, 0),
        all_ids: [],
        selected_ids: [],
      });
    }
  };

  isIncludeCurrentForm = (values: any) => {
    let table_values = values.combined_values && values.combined_values.map((element: any, index: number) => (
      {
        values:
          element.classroom_id + ',' +
          element.invigilator_id
      }));

    if (table_values !== undefined) {
      let current_form_values = { values: values.classroom_id_selected + ',' + values.invigilator_id_selected }
      if (table_values!.some((e: { values: string; }) => e.values == current_form_values.values)) {
        this.state.alreadyInTheList = true;
        this.state.isAdded = true;
      } else if (this.state.isAdded) {
        this.state.alreadyInTheList = false;
        this.state.isAdded = false;
      } else {
        this.state.alreadyInTheList = false;
        this.state.isAdded = false;
      }
    }
  };

  onSelectCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
        }
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList
      });
    }
  };

  checkAllIdsSelected = (): boolean => {
    const all_ids = this.state.combined_values ? this.state.combined_values.map((item: any) => item.index) : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.setState({
          ...this.state,
          all_ids: this.state.combined_values ? this.state.combined_values.map((item: any) => item.index) : [],
          selected_ids: this.state.combined_values ? this.state.combined_values.map((item: any) => item.index) : []
        })
      } else {
        this.setState({
          ...this.state,
          all_ids: [],
          selected_ids: []
        });
      }
    }
  };

  static getDerivedStateFromProps(props: any, state: any) {
    let hasNewState: boolean = false;

    if (props && props.courseDetail) {
      hasNewState = true;
      state.courseDetail = props.courseDetail;
    } else {
      state.courseDetail = undefined;
    }

    if (hasNewState) {
      return state;
    } else return null;
  }

  render() {
    this.props.multiSelectionObjectFunction(
      this.state.combined_values,
    );
    const classroomOptions =
      this.props.solutionCourseAddSelectOptions && this.props.solutionCourseAddSelectOptions.classrooms ? this.props.solutionCourseAddSelectOptions.classrooms : [];
    const ClassroomSelectOptions = [...ExamPeriodCourseTermLectureLocations(T), ...classroomOptions];

    return (
      <Formik
        initialValues={this.state}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          this.putToTable(this.state, true);
        }}
      >
        {(props: FormikProps<any>) => {
          const { values, handleChange, errors, handleBlur, handleSubmit } = props;

          this.isIncludeCurrentForm(this.state);

          if (this.state.combined_value_query != undefined && this.state.combined_value_query != '') {
            if (this.state.combined_values_temp.length == 0) {
              this.state.combined_values_temp = this.state.combined_values;
            }
            this.state.combined_values = this.state.combined_values.filter((element: any) =>
              element.classroom.label.toLowerCase().includes(this.state.combined_value_query.toLowerCase()) ||
              element.invigilator.label.toLowerCase().includes(this.state.combined_value_query.toLowerCase()) ||
              element.student_count.toString().includes(this.state.combined_value_query)
            );
          } else if (this.state.combined_values_temp && this.state.combined_values_temp.length != 0) {
            this.state.combined_values = this.state.combined_values_temp;
            this.state.combined_values_temp = [];
          }

          return (
            <div>
              <div className="d-flex align-items-center justify-content-center">
                <div className="col-md-4">
                  <div className="mb-3 add-custom-tag">
                    <div className="react-select-container">
                      <label>{T.t('gen_classroom')}</label>
                      <Select
                        id='select_classroom'
                        className="react-select"
                        isMulti={false}
                        filterOption={(option: any, query: any) =>
                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                        }
                        closeMenuOnSelect={true}
                        options={ClassroomSelectOptions}
                        placeholder={T.t('gen_select_classroom')}
                        onChange={(option: any) => {
                          this.setState({
                            ...this.state,
                            classroom_id_selected: option && option.value,
                            classroom_selected: option
                          });
                        }}
                        noOptionsMessage={(): string => T.t('gen_select_no_classroom')}
                      />
                    </div>
                    {this.state.classroom_id_selected < 1 && !this.state.isValidationPassed && (
                      <div className="error" style={{ marginTop: '-20px' }}>{T.t('gen_cannot_leave_empty')}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3 add-custom-tag">
                    <div className="react-select-container">
                      <label>{T.t('gen_invigilator')}</label>
                      <Select
                        id='select_invigilator'
                        className="react-select"
                        isMulti={false}
                        isDisabled={this.state.classroom_selected == undefined || this.state.classroom_selected == null ? true : false}
                        filterOption={(option: any, query: any) =>
                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                        }
                        closeMenuOnSelect={true}
                        options={
                          this.props.solutionCourseAddSelectOptions && this.props.solutionCourseAddSelectOptions.invigilators
                            ? this.props.solutionCourseAddSelectOptions.invigilators
                            : []
                        }
                        placeholder={T.t('gen_select_invigilator')}
                        onChange={(option: any) => {
                          this.setState({
                            ...this.state,
                            invigilator_id_selected: option && option.value,
                            invigilator_selected: option
                          });
                        }}
                        noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-3 col-md-4 form-input form-group with-icon">
                  <input
                    id="student_count"
                    name="student_count"
                    value={this.state.student_count}
                    disabled={this.state.invigilator_selected == undefined || this.state.invigilator_selected == null ? true : false}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      this.setState({
                        ...this.state,
                        student_count: Number(e.target.value),
                      });
                    }}
                    onBlur={handleBlur}
                    type="number"
                  />
                  <span className="highlight" />
                  <span className="bar" />
                  <label htmlFor="student_count">{T.t('gen_student_count')}</label>
                  {this.state.student_count <= 0 && !this.state.isValidationPassed && (
                    <div className="error">{T.t('gen_must_be_greater_than_zero')}</div>
                  )}
                </div>
              </div>

              <div className="mb-3 col-md-12 input-group autocomplete">
                <input
                  type="text"
                  name="valufilter-combined-valueses"
                  className="form-control searchWrapper input-group autocomplete"
                  value={this.state.combined_value_query}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    this.setState({
                      ...this.state,
                      combined_value_query: event.target.value
                    })
                  }
                  id="filter-combined-values"
                  placeholder={T.t('gen_search')}
                  data-class="fup"
                />
                <div className="ml-1 mr-2 input-group-prepend collapsed" aria-expanded="false">
                  <span
                    className="pl-0 pr-2 category-tag-square tag-glass"
                    onClick={() => {
                      this.setState({
                        ...this.state,
                        combined_value_query: ''
                      });
                    }}
                  >
                    <i data-toggle="tooltip" className="material-icons" data-original-title="Listeden Çıkar">
                      close
                    </i>
                    {T.t('gen_clear')}
                  </span>
                </div>
                <button
                  id='button_delete_circle_outline'
                  className="mr-3 mss-form-button"
                  style={{
                    color: "#fff",
                    backgroundColor: ((this.state.selected_ids && this.state.selected_ids.length > 0 ? false : true) ? '#d1a3a8' : '#dc3545')
                  }}
                  disabled={
                    (this.state.selected_ids && this.state.selected_ids.length > 0 ? false : true)
                  }
                  onClick={() => this.onDeleteRow(this.state.selected_ids)}
                >
                  <i className="pl-1 pr-1 material-icons">delete_sweep</i>
                </button>
                <button
                  style={{
                    color: "#fff",
                    backgroundColor: ((this.props.selected_course == 0 ?
                      (this.state.alreadyInTheList ? true : false)
                      : ((this.state.alreadyInTheList ? true : false)
                        || (this.props.selected_course && this.props.selected_course != null) ? false : true)) ? '#628061' : '#059700')

                  }}
                  id='button_add_circle_outline'
                  className="mss-form-button"
                  disabled={
                    this.props.selected_course == 0 ?
                      (this.state.alreadyInTheList ? true : false)
                      : ((this.state.alreadyInTheList ? true : false)
                        || (this.props.selected_course && this.props.selected_course != null) ? false : true)
                  }
                  data-dismiss="modal"
                  onClick={() => {
                    props.handleSubmit()
                  }}
                >
                  <i className="material-icons align-items-center justify-content-center">add_circle_outline</i>
                </button>
              </div>
              <div className="mb-3 add-custom-tag">
                <table className="table aplan-table aplan-table-responsive table-borderless table-striped table-hover sortable filter-table courseformtable">
                  <thead>
                    <tr className="courseformtable-thead-tr">
                      <th data-cell="select" scope="col" style={{ 'width': '7%' }}>
                        <div className="mt-2 tick-radio d-flex align-items-center justify-content-center">
                          <input
                            id='select_all'
                            type="checkbox"
                            className="form-radio"
                            checked={this.checkAllIdsSelected()}
                            onChange={this.onSelectAll}
                          />
                        </div>
                      </th>
                      <th scope="col" style={{ 'width': '35%' }}>
                        <span>{T.t('gen_classroom')}</span>
                      </th>
                      <th scope="col" style={{ 'width': '38%' }}>
                        <span>{T.t('gen_invigilator')}</span>
                      </th>
                      <th scope="col" style={{ 'width': '20%' }}>
                        <span>{T.t('gen_student_count')}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.combined_values && this.state.combined_values.length
                      ? this.state.combined_values.map((item: any, index: any) => {
                        return (
                          <tr key={index} data-title={item.faculty_id}>
                            <tr className="courseformtable-tbody-tr">
                              <td scope="row" className="courseformtable-td" key={index} style={{ 'width': '45px' }}>
                                <div className="tick-radio d-flex align-items-center justify-content-center">
                                  <input
                                    id='select_course'
                                    type="checkbox"
                                    className="form-radio"
                                    checked={
                                      this.state.selected_ids &&
                                      this.state.selected_ids.indexOf(item.index ? item.index : -1) > -1
                                    }
                                    data-id={item.index}
                                    onChange={this.onSelectCheckBox}
                                  />
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_classroom')} className="courseformtable-td" style={{ 'width': '35%' }}>
                                {item.classroom.label}
                              </td>
                              <td scope="row" data-label={T.t('gen_invigilator')} className="courseformtable-td" style={{ 'width': '38%' }}>
                                {item.invigilator.label}
                              </td>
                              <td scope="row" data-label={T.t('gen_student_count')} className="text-center courseformtable-td" style={{ 'width': '20%' }}>
                                {item.student_count}
                              </td>
                            </tr>
                          </tr>
                        );
                      })
                      : null}
                  </tbody>
                </table>
              </div>
            </div >
          );
        }
        }
      </Formik >
    )
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
    solutionCourseAddSelectOptions: store.state.select_options && store.state.select_options.solutionCoursePage,
    courseDetail: store.state.course_page && store.state.course_page.form,
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(MultiSelectionTableClassroom);

export default container;