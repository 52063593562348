import { put, takeEvery, all, take, select, call } from 'redux-saga/effects';
import * as Actions from '../actions/general';
import { constants, BackupSearchInitialValues } from '../constants/backup-const';
import * as Types from '../types';
import MockAPIClient from '../../services/mock-api-client';
import Translator from '../../services/translate-factory';

const T = Translator.create();

function* getBackup(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.BackupSearch(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getBackupData(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.BackupGetById(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getBackupSelectOptions(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let content: any = null;
  let response: Response;
  if (action.body && action.body.reset) {
    response = new Response(null);
    content = { results: [] };
  } else {
    response = yield MockAPI.GetResponse(MockAPI.BackupGetSelectOptions());
    if (response && response.status === 200) {
      content = yield MockAPI.GetContent(response);
    }
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* updateBackup(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.BackupUpdate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_backup_updated', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createBackup(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.BackupCreate(action.body));
  let content: any = yield MockAPI.GetContent(response);
  if (response && response.status == 200) {
    yield put(Actions.Notification('notification_backup_created', 'gen_success'));
  } else if (response && response.status === 409) {
    yield put(
      Actions.ShowModal({
        body: content,
        title: T.t('gen_pending'),
        name: 'gen_pending',
        icon: 'warning',
        iconColor: 'red',
        cancel: T.t('gen_close')
      })
    );
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* restoreBackup(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.BackupRestore(action.body));
  let content: any = yield MockAPI.GetContent(response);
  if (response && response.status == 200) {
    yield put(Actions.Notification('notification_backup_restored', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* deleteBackup(action: Types.IApiRequestAction) {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.BackupDelete(action.body));
  let content: any = null;
  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_backup_deleted', 'gen_success'));
    yield put(Actions.ApiRequest(constants.BACKUP_LIST_SEARCH, BackupSearchInitialValues));
    content = yield MockAPI.GetContent(response);
    if (action.callback) {
      action.callback(response.status);
    } else {
      yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
    }
    yield put(Actions.ApiResponse(action, response, content));
  }
}

export function* watchBackupSearch() {
  yield takeEvery((action: any) => action.target === constants.BACKUP_LIST_SEARCH, getBackup);
}

export function* watchBackupGetById() {
  yield takeEvery((action: any) => action.target === constants.BACKUP_GET_BY_ID, getBackupData);
}

export function* watchBackupGetSelectOptions() {
  yield takeEvery((action: any) => action.target === constants.BACKUP_GET_SELECT_OPTIONS, getBackupSelectOptions);
}

export function* watchBackupUpdate() {
  yield takeEvery((action: any) => action.target === constants.BACKUP_UPDATE, updateBackup);
}

export function* watchBackupCreate() {
  yield takeEvery((action: any) => action.target === constants.BACKUP_CREATE, createBackup);
}

export function* watchBackupRestore() {
  yield takeEvery((action: any) => action.target === constants.BACKUP_RESTORE, restoreBackup);
}

export function* watchBackupDelete() {
  yield takeEvery((action: any) => action.target === constants.BACKUP_DELETE, deleteBackup);
}