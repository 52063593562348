import { ErrorMessage, Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Modal } from 'reactstrap';
import Spinner from '../../components/templates/spinner';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { SectionTypes } from '../../store/constants/enums';
import * as Types from '../../store/types';

import Translator from '../../services/translate-factory';
const Logger = Log.create('BuildingForm');

const T = Translator.create();
function getInitialState(): any {
  const initialValues: any = {
    termID: -1,
    sectionType: -1
  };
  return Object.assign({}, initialValues);
}

class BuildingForm extends Component<any, any> {
  state: any = getInitialState();

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }
  showInfoModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_download_all_info'),
          body: T.t('gen_download_all_question'),
          name: 'examPeriod_courses_delete',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const model: { termId: number; sectionType: number; sectionID?: number } = {
              termId: this.props.termId,
              sectionType: this.props.sectionType,
              sectionID: this.props.sectionID ? this.props.sectionID : undefined
            };

            this.props.dispatch(Actions.ApiRequest(Constants.building.DOWNLOAD_ALL_DATA, model, ''));
          }
        })
      );
    }
  };

  render() {
    return (
      <button className="category-tag-square tag-blue ml-1 pl-2" onClick={this.showInfoModal}>
        <i className="material-icons mr-2" style={{ color: 'white' }}>cloud_download</i>
        <span>{T.t('gen_download')}</span>
      </button>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
    termId: store.state.term_id
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });
const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.building_page) {
    return !!equal(prev.state.term_id, next.state.term_id);
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(BuildingForm);

export default container;
