import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Paginate from '../../components/table/paginate';
import SortedColumn from '../../components/table/sorted-column';
import Announce from '../../components/templates/announce';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { DistributorSearchInitialValues, DistributorJobStatus, StatusSelectOptions, GetDistributionStatusInfo, GetDistributionStatusColor } from '../../store/constants/distributor-const';
import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';
import DistributorModal from "./distributor-modal";
import DistributorStatusHistoryModal from "./distributor-status-history-modal";
import { ValueType } from 'react-select/lib/types';
import Select from 'react-select';

const T = Translator.create();
const L = Log.create('DistributorListPage');

class DistributorListPage extends Component<Types.IDistributorPageProps, Types.IDistributorPageState> {
  state: Types.IDistributorPageState = {
    filters: DistributorSearchInitialValues,
    statusTableIsOpen: false,
    filterIsOpen: false,
    distributorList: [],
    max_number_attempts: 10000,
    distributorModalIsOpen: false,
    distributorStatusHistoryModalIsOpen: false
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.filters.term_ids = [id];
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  getDistributions() {
    this.props.dispatch(
      Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_GET_DISTRIBUTION_LIST, this.state.filters, 'distribution-list-spin')
    );
  }

  onFilterDistributionList(model: Types.IFilterDistributor, FormActions: FormikActions<Types.IFilterDistributor>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.getDistributions();
    FormActions.setSubmitting(false);
  }

  onDistributionStarter(max_number_of_attempts: Number) {
    if (max_number_of_attempts != 0) {
      const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
        if (result && status === 200) {
          this.setState({
            ...this.state,
            job_id: Number(result)
          });

          this.switchdistributorModalStatus();
        }
      };

      let model = {
        max_number_of_attempts: max_number_of_attempts,
        term_id: this.state.filters.term_ids![0]
      }

      this.props.dispatch(
        Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_CREATE_DISTRIBUTION, model, 'classroom-form-spin', resultCallback)
      );
    }
  }

  switchdistributorModalStatus = () => {
    this.setState({
      ...this.state,
      distributorModalIsOpen: !this.state.distributorModalIsOpen
    });

    if (this.state.distributorModalIsOpen === false) {
      this.getDistributions();
    }
  };

  switchdistributorStatusHistoryModalStatus = (distributionNumber: number) => {
    this.setState({
      ...this.state,
      job_id: distributionNumber,
      distributorStatusHistoryModalIsOpen: !this.state.distributorStatusHistoryModalIsOpen
    });
  };

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.getDistributions();
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.getDistributions();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, DistributorSearchInitialValues);
    this.setState(this.state);
    this.getDistributions();
  };

  static getDerivedStateFromProps(props: Types.IDistributorPageProps, state: Types.IDistributorPageState) {
    let hasNewState: boolean = false;

    if (props.results) {
      hasNewState = true;
      state.distributorList = props.results;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let termType = this.props.term_type != undefined ? this.props.term_type : -1;

    let distributorList = this.state.distributorList ? this.state.distributorList : [];

    let DistributionStopStatus: Array<number> = [
      DistributorJobStatus.Stopped,
      DistributorJobStatus.UnexpectedError,
      DistributorJobStatus.DataPreparation_Error,
      DistributorJobStatus.ImpossibilityTest_Error,
      DistributorJobStatus.Calculations_Error,
      DistributorJobStatus.ClassroomAssignment_Error,
      DistributorJobStatus.InvigilatorAssignment_Error
    ];

    let DistributionReadyOrPendingStatus: Array<number> = [];
    if (termType == 1) {
      DistributionReadyOrPendingStatus = [
        DistributorJobStatus.Calculations_Pending,
        DistributorJobStatus.Calculations_Ready,
        DistributorJobStatus.DataPreparation_Pending,
        DistributorJobStatus.DataPreparation_Ready,
        DistributorJobStatus.ImpossibilityTest_Pending,
        DistributorJobStatus.ImpossibilityTest_Ready,
        DistributorJobStatus.ClassroomAssignment_Pending,
        DistributorJobStatus.ClassroomAssignment_Ready,
        DistributorJobStatus.InvigilatorAssignment_Pending,
      ];
    } else if (termType == 0) {
      DistributionReadyOrPendingStatus = [
        DistributorJobStatus.Calculations_Pending,
        DistributorJobStatus.Calculations_Ready,
        DistributorJobStatus.DataPreparation_Pending,
        DistributorJobStatus.DataPreparation_Ready,
        DistributorJobStatus.ImpossibilityTest_Pending,
        DistributorJobStatus.ImpossibilityTest_Ready,
        DistributorJobStatus.ClassroomAssignment_Pending,
      ];
    }

    let DistributionCompletedStatus: Array<number> = [];
    if (termType == 1) {
      DistributionCompletedStatus = [
        DistributorJobStatus.InvigilatorAssignment_Ready,
        DistributorJobStatus.Completed_Successfully,
      ];
    } else if (termType == 0) {
      DistributionCompletedStatus = [
        DistributorJobStatus.ClassroomAssignment_Ready,
        DistributorJobStatus.Completed_Successfully,
      ];
    }

    let Table = (
      <div className="row">
        <div className="col-12">
          <div className="quick-actions">
            <div className="row">
              <div className="col-3 form-input form-group with-icon">
                <input
                  id="max_number_of_attempts"
                  name="max_number_of_attempts"
                  type="number"
                  value={this.state.max_number_attempts}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const newValue = Number(e.target.value);
                    this.setState({
                      ...this.state,
                      max_number_attempts: newValue
                    });
                  }}
                />
                <span className="highlight" />
                <span className="bar" />
                <label htmlFor="max_number_of_attempts">{T.t('gen_max_number_of_attempts')}</label>
              </div>
              <div className="col-4 mt-3">
                {termType === 1 ? (
                  <button id='playlist_add' className="category-tag-square tag-green ml-3 text-fit-3" onClick={() => this.onDistributionStarter(this.state.max_number_attempts)}>
                    <i className="material-icons mr-2">playlist_add</i>
                    {T.t('gen_distribute_exams')}
                  </button>
                ) : <button id='playlist_add' className="category-tag-square tag-green ml-3 text-fit-3" onClick={() => this.onDistributionStarter(this.state.max_number_attempts)}>
                  <i className="material-icons mr-2">playlist_add</i>
                  {T.t('gen_distribute_activities')}
                </button>}
              </div>
              <div className="col-5 mt-3">
                <button
                  id='button_filter'
                  className="category-tag-square tag-glass float-right ml-3 mr-3"
                  style={{ margin: '5px' }}
                  onClick={() => {
                    this.state.filterIsOpen = !this.state.filterIsOpen;
                    this.setState(this.state);
                  }}
                >
                  <i className="material-icons mr-2">filter_list</i>
                  <span>{T.t('gen_filter')}</span>
                </button>
              </div>
            </div>
          </div>
        </div >
        <div className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)} id="advance-search">
          <div className="advance-search d-block mt-3">
            <Formik
              initialValues={DistributorSearchInitialValues}
              enableReinitialize={true}
              onSubmit={(values, actions) => {
                values.term_ids = this.state.filters.term_ids;
                this.onFilterDistributionList(values, actions);
              }}
              onReset={this.onFormReset}
            >
              {(props: FormikProps<Types.IFilterDistributor>) => {
                return (
                  <form onSubmit={props.handleSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <h6>{T.t('gen_filter_parameters')}</h6>
                      </div>
                    </div>
                    <hr />
                    <div className="col-md-2">
                      <div className="add-custom-tag mb-3">
                        <div className="react-select-container">
                          <label>{T.t('gen_status')}</label>
                          <Select
                            id='select_status'
                            className="react-select"
                            isMulti={true}
                            filterOption={(option: any, query: any) =>
                              option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                            }
                            closeMenuOnSelect={false}
                            options={StatusSelectOptions(T)}
                            placeholder={T.t('gen_select_status')}
                            //value={props.values.campuses ? props.values.campuses : null}
                            onChange={(
                              options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                            ) => {
                              const list: Array<Types.ISelectOption> = options
                                ? (options as Array<Types.ISelectOption>)
                                : [];
                              props.setFieldValue('status', list.map((item) => item.value));
                            }}
                            noOptionsMessage={(): string => T.t('gen_select_no_status')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-6">
                        <button
                          id='button_arrow_upward'
                          type="button"
                          onClick={() => {
                            this.state.filterIsOpen = false;
                            this.setState(this.state);
                          }}
                          className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                        >
                          <i className="material-icons">arrow_upward</i>
                        </button>
                        <button
                          id='button_delete_sweep'
                          type="reset"
                          onClick={props.handleReset}
                          className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2"
                        >
                          <i className="material-icons">delete_sweep</i>
                        </button>
                      </div>
                      <div className="col-6 text-right">
                        <button
                          id='button_search'
                          type="button"
                          className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                          onClick={() => props.handleSubmit()}
                          disabled={props.isSubmitting}
                        >
                          <i className="material-icons mr-2">search</i>{T.t('gen_search')}
                        </button>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
        <div className="col-12">
          <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
            <thead>
              <tr>
                <SortedColumn
                  datacell="distribution_number"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center"
                  title={T.t('gen_distribution_number')}
                  sortkey="job_id"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <SortedColumn
                  datacell="status"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center"
                  title={T.t('gen_status')}
                  sortkey="status"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <SortedColumn
                  datacell="compatibility_rate"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center"
                  title={T.t('gen_compatibility_rate')}
                  sortkey="compatibility_rate"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <SortedColumn
                  datacell="start_date"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center"
                  title={T.t('gen_start_date')}
                  sortkey="start_date"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <SortedColumn
                  datacell="end_date"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center"
                  title={T.t('gen_end_date')}
                  sortkey="end_date"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <SortedColumn
                  datacell="completion_time"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center"
                  title={T.t('gen_completion_time')}
                  sortkey="completion_time"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <SortedColumn
                  datacell="updated_date"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center"
                  title={T.t('gen_updated_date')}
                  sortkey="updated_date"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <SortedColumn
                  datacell="user"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center"
                  title={T.t('gen_user')}
                  sortkey="user"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <th scope="col" className="text-center">
                  {T.t('gen_actions')}
                </th>
              </tr>
            </thead>
            <tbody>
              {distributorList && distributorList.length ? (
                distributorList.map((item: Types.IDistributorItem) => {
                  let distributionNum = item.distribution_number ? item.distribution_number : -1;
                  let isErrorFatal = item.is_error_fatal != undefined ? item.is_error_fatal : true;
                  return (
                    <tr key={'distribution-' + distributionNum} data-title={item.distribution_number}>
                      <td data-label={T.t('gen_distribution_number')} className="text-center">
                        {item.distribution_number}
                      </td>
                      <td scope="row" data-label={T.t('gen_status')} className="text-center">
                        <div className="tags ml-1 mr-4">
                          <button
                            id='button_status'
                            className={`small-tag text-uppercase tag-` + GetDistributionStatusColor(T, termType, item.status, isErrorFatal)}
                            style={{ cursor: 'default' }}
                          >
                            {GetDistributionStatusInfo(T, termType, item.status, isErrorFatal)}

                          </button>
                        </div>
                      </td>
                      <td data-label={T.t('gen_compatibility_rate')} className="text-center">
                        {item.compatibility_rate}
                      </td>
                      <td data-label={T.t('gen_start_date')} className="text-center">
                        {item.start_date && item.start_date.startsWith('0001') ? '-' : item.start_date}
                      </td>
                      <td data-label={T.t('gen_end_date')} className="text-center">
                        {item.end_date && item.end_date.startsWith('0001') ? '-' : item.end_date}
                      </td>
                      <td data-label={T.t('gen_completion_time')} className="text-center">
                        {item.completion_time && item.completion_time.startsWith('-') ? '-' : item.completion_time}
                      </td>
                      <td data-label={T.t('gen_updated_date')} className="text-center">
                        {item.updated_date && item.updated_date.startsWith('0001') ? '-' : item.updated_date}
                      </td>
                      <td data-label={T.t('gen_user')} className="text-center">
                        {item.user}
                      </td>
                      <td data-label={T.t('gen_actions')} className="table-buttons">
                        <div className="table-buttons-wrapper">
                          <button
                            id='button_edit'
                            data-toggle="tooltip"
                            data-id={distributionNum}
                            onClick={() => this.switchdistributorStatusHistoryModalStatus(distributionNum)}
                            title={T.t('gen_edit')}
                            className="btn btn-light btn-sm table-button"
                          >
                            <span className="d-block" data-toggle="modal" data-target="#addNew">
                              <i className="material-icons">edit</i>
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={24}>
                    <Announce title={T.t('gen_no_records_found')} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="row-options justify-content-end">
            <div className="page-sorting d-flex align-items-center justify-content-center" style={{ marginTop: '5px' }}>
              {this.state.distributorList && this.state.distributorList.length > 0 ? (
                <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
              ) : null}
            </div>
          </div>
        </div>
      </div >
    );

    return (
      <div className="white-container mt-4">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 courses">
            {Table}
          </div>
        </div>
        {
          this.state.distributorModalIsOpen && (
            <DistributorModal
              jobId={this.state.job_id}
              modalIsOpen={this.state.distributorModalIsOpen}
              onClose={this.switchdistributorModalStatus}
            />
          )
        }
        {
          this.state.distributorStatusHistoryModalIsOpen && (
            <DistributorStatusHistoryModal
              jobId={this.state.job_id}
              modalIsOpen={this.state.distributorStatusHistoryModalIsOpen}
              onClose={this.switchdistributorStatusHistoryModalStatus}
            />
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IDistributorPageProps): Types.IDistributorPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IDistributorPageProps = Object.assign({}, ownProps, {
    results: store.state.distributor_page && store.state.distributor_page.results,
    filters: store.state.distributor_page && store.state.distributor_page.filters,
    term_type: store.state.term_type,
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.examPeriodModal) {
    return (
      !!equal(
        prev.state.distributor_page && prev.state.distributor_page.results,
        next.state.distributor_page && next.state.distributor_page.results
      ) &&
      !!equal(
        prev.state.distributor_page && prev.state.distributor_page.filters,
        next.state.distributor_page && next.state.distributor_page.filters
      ) &&
      !!equal(
        prev.state && prev.state.term_type,
        next.state && next.state.term_type
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(DistributorListPage);

export default container;
