import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import { UncontrolledTooltip } from 'reactstrap';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';
import moment from 'moment';

const T = Translator.create();
const L = Log.create('DistributionInvigilatorCountsModal');

const initialValues: any = {
  invigilators: [],
  job_id: 0,
  term_id: 0,
  filter_invigilator_name: ''
};
function getInitialState(): any {
  return Object.assign({}, initialValues);
}

class DistributorInvigilatorCountsModal extends Component<any, any> {
  state: {
    invigilators: any,
    job_id: number,
    term_id: number,
    filter_invigilator_name: string
  } = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    moment.locale('tr');
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (JSON.stringify(prevProps.invigilators) !== JSON.stringify(this.props.invigilators)) {
      this.state.invigilators = this.props.invigilators.sort((a: any, b: any) => {
        return a.top_all - b.top_all;
      });
      this.setState(this.state);
      this.forceUpdate();
    }
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.state = getInitialState();
    this.setState(this.state);
    this.setClose();
  };

  handleChangeFilter(e: React.ChangeEvent<HTMLInputElement>) {
    if (e && e.target && e.target.value && e.target.value.length) {
      this.state.filter_invigilator_name = e.target.value;
    } else {
      this.state.filter_invigilator_name = '';
    }
    this.setState(this.state);
  }

  static getDerivedStateFromProps(props: any, state: any) {
    let hasNewState: boolean = false;

    if (props.invigilators && props.invigilators.length) {
      hasNewState = true;
      state.invigilators = props.invigilators;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }


  render() {
    let invigilators = this.state.invigilators && this.state.invigilators.length ? this.state.invigilators : [];
    invigilators.sort((a: any, b: any) => {
      return b.top_all - a.top_all;
    });
    if (this.state.filter_invigilator_name && this.state.filter_invigilator_name.length) {
      invigilators = invigilators.filter((invigilator: any) => (invigilator.instructor_name.toLowerCase().includes(this.state.filter_invigilator_name.toLowerCase())))
    }

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '85%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center ml-4" id="exampleModalLabel">
              {T.t('gen_invigilator_counts')}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body distributor-invigilatorcounts-table">
            <div className={'col-xl-12 col-lg-12 col-md-12 settings-dropdown-90'}>
              <div className="row">
                <div className="col-md-4 pt-3">
                  <div className="form-input form-group date-picker">
                    <input
                      id="filter_invigilator_name"
                      name="filter_invigilator_name"
                      value={this.state.filter_invigilator_name}
                      onChange={(e) => this.handleChangeFilter(e)}
                      type="text"
                      required
                    />
                    <span className="highlight" />
                    <span className="bar" />
                    <label htmlFor="filter_invigilator_name">{T.t('gen_invigilator') + " " + T.t('gen_name_surname')}</label>
                    <i className="material-icons">title</i>
                  </div>
                </div>
                <div className="col-12">
                  <table className="aplan-table table aplan-table-responsive table-borderless table-striped table-hover sortable filter-table">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">
                          {T.t('gen_invigilator')}
                        </th>
                        <><th id="own_exams_formal_education_code" scope="col" className="text-center">{T.t('gen_own_exams_formal_education_code')}</th>
                          <UncontrolledTooltip placement="left" target="own_exams_formal_education_code">
                            {T.t('gen_own_exams_formal_education')}
                          </UncontrolledTooltip></>
                        <><th id="gen_own_exams_secondary_education_code" scope="col" className="text-center">{T.t('gen_own_exams_secondary_education_code')}</th>
                          <UncontrolledTooltip placement="left" target="gen_own_exams_secondary_education_code">
                            {T.t('gen_own_exams_secondary_education')}
                          </UncontrolledTooltip></>
                        <><th id="gen_own_exams_weekend_code" scope="col" className="text-center">{T.t('gen_own_exams_weekend_code')} </th>
                          <UncontrolledTooltip placement="left" target="gen_own_exams_weekend_code">
                            {T.t('gen_own_exams_weekend')}
                          </UncontrolledTooltip></>
                        <><th id="gen_own_exams_sum_code" scope="col" className="text-center">{T.t('gen_own_exams_sum_code')}</th>
                          <UncontrolledTooltip placement="left" target="gen_own_exams_sum_code">
                            {T.t('gen_own_exams_sum')}
                          </UncontrolledTooltip></>
                        <><th id="gen_invigilations_formal_education_code" scope="col" className="text-center"> {T.t('gen_invigilations_formal_education_code')} </th>
                          <UncontrolledTooltip placement="left" target="gen_invigilations_formal_education_code">
                            {T.t('gen_invigilations_formal_education')}
                          </UncontrolledTooltip></>
                        <><th id="gen_invigilations_secondary_education_code" scope="col" className="text-center">{T.t('gen_invigilations_secondary_education_code')}</th>
                          <UncontrolledTooltip placement="left" target="gen_invigilations_secondary_education_code">
                            {T.t('gen_invigilations_secondary_education')}
                          </UncontrolledTooltip></>
                        <><th id="gen_invigilations_weekend_code" scope="col" className="text-center"> {T.t('gen_invigilations_weekend_code')} </th>
                          <UncontrolledTooltip placement="left" target="gen_invigilations_weekend_code">
                            {T.t('gen_invigilations_weekend')}
                          </UncontrolledTooltip></>
                        <><th id="gen_invigilations_sum_code" scope="col" className="text-center">  {T.t('gen_invigilations_sum_code')} </th>
                          <UncontrolledTooltip placement="left" target="gen_invigilations_sum_code">
                            {T.t('gen_invigilations_sum')}
                          </UncontrolledTooltip></>
                        <><th id="gen_all_exams_formal_education_code" scope="col" className="text-center"> {T.t('gen_all_exams_formal_education_code')}</th>
                          <UncontrolledTooltip placement="left" target="gen_all_exams_formal_education_code">
                            {T.t('gen_all_exams_formal_education')}
                          </UncontrolledTooltip></>
                        <><th id="gen_all_exams_secondary_education_code" scope="col" className="text-center">  {T.t('gen_all_exams_secondary_education_code')}</th>
                          <UncontrolledTooltip placement="left" target="gen_all_exams_secondary_education_code">
                            {T.t('gen_all_exams_secondary_education')}
                          </UncontrolledTooltip></>
                        <><th id="gen_all_exams_weekend_code" scope="col" className="text-center">  {T.t('gen_all_exams_weekend_code')} </th>
                          <UncontrolledTooltip placement="left" target="gen_all_exams_weekend_code">
                            {T.t('gen_all_exams_weekend')}
                          </UncontrolledTooltip></>
                        <><th id="gen_all_exams_sum_code" scope="col" className="text-center"> {T.t('gen_all_exams_sum_code')} </th>
                          <UncontrolledTooltip placement="left" target="gen_all_exams_sum_code">
                            {T.t('gen_all_exams_sum')}
                          </UncontrolledTooltip></>
                      </tr>
                    </thead>
                    <tbody>
                      {invigilators && invigilators.length
                        ? invigilators.map((item: any) => (
                          <tr key={'invigilator-' + item.dll_instructor_id} data-title={item.dll_instructor_id}>
                            <td scope="row" data-label={T.t('gen_invigilator')} className="text-center">
                              {item.instructor_name}
                            </td>
                            <td data-label={'S_NO'} className="text-center">
                              {item.s_no}
                            </td>
                            <td data-label={'S_IO'} className="text-center">
                              {item.s_io}
                            </td>
                            <td data-label={'S_HS'} className="text-center">
                              {item.s_hs}
                            </td>
                            <td data-label={'S_TOP'} className="text-center">
                              {item.s_top}
                            </td>
                            <td data-label={'G_NO'} className="text-center">
                              {item.g_no}
                            </td>
                            <td data-label={'G_IO'} className="text-center">
                              {item.g_io}
                            </td>
                            <td data-label={'G_HS'} className="text-center">
                              {item.g_hs}
                            </td>
                            <td data-label={'G_TOP'} className="text-center">
                              {item.g_top}
                            </td>
                            <td data-label={'TOP_NO'} className="text-center">
                              {item.top_no}
                            </td>
                            <td data-label={'TOP_IO'} className="text-center">
                              {item.top_io}
                            </td>
                            <td data-label={'TOP_HS'} className="text-center">
                              {item.top_hs}
                            </td>
                            <td data-label={'TOP_ALL'} className="text-center">
                              {item.top_all}
                            </td>
                          </tr>
                        ))
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IExamPeriod_CourseProps): Types.IExamPeriod_CourseProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IExamPeriod_CourseProps = Object.assign({}, ownProps, {
    selectOptions: store.state.select_options && store.state.select_options.distributorSolutionChangePage,
    term_id: store.state.term_id
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(DistributorInvigilatorCountsModal);

export default container;