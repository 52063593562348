import React from 'react';
import { Log } from 'ng2-logger';
import * as Icons from 'react-icons/md';
import { IColumnProps } from '../../store/types';
const L = Log.create('sorted-column');

export default class SortedColumn extends React.Component<IColumnProps, any> {
  state = {
    order_by: '',
    sortkey: this.props.sortkey
  };

  onClick = () => {
    if (this.state.order_by === '') {
      this.state.order_by = 'asc';
    } else if (this.state.order_by === 'asc') {
      this.state.order_by = 'desc';
    } else if (this.state.order_by === 'desc') {
      this.state.order_by = 'asc';
    }
    this.setState(this.state);
    if (this.props.sort) {
      this.props.sort(this.props.sortkey, this.state.order_by);
    }
  };

  static getDerivedStateFromProps(props: any, current_state: any) {
    if (!(props.sortedcolumn.indexOf(current_state.sortkey + '_') === 0)) {
      current_state.order_by = '';
      return current_state;
    } else {
      return null;
    }
  }

  render() {
    let icon = <div></div>;
    if (this.state.order_by === '') {
      icon = <Icons.MdSort />;
    } else if (this.state.order_by === 'asc') {
      icon = <Icons.MdKeyboardArrowDown />;
    } else if (this.state.order_by === 'desc') {
      icon = <Icons.MdKeyboardArrowUp />;
    }

    return (
      <th className={this.props.className} data-cell={this.props.datacell} onClick={this.onClick}>
        {this.props.title}&nbsp;{icon}
      </th>
    );
  }
}
