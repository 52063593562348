import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import Paginate from '../../../components/table/paginate';
import * as Actions from '../../../store/actions/general';
import Announce from '../../../components/templates/announce';
import * as Constants from '../../../store/constants/all';
import { EducationTypeSelectOptions } from '../../../store/constants/course-const';
import * as Types from '../../../store/types';
import * as GT from '../../../tools/general-tools';
import Translator from '../../../services/translate-factory';
import { ProgramSearchInitialValues } from '../../../store/constants/program-const';
import SortedColumn from '../../../components/table/sorted-column';
import ClassroomAddModal from '../classroom-hours-add-modal';

const T = Translator.create();
const L = Log.create('ProgramListPage');

class ProgramListPage extends Component<Types.IProgramPageProps, Types.IProgramPageState> {
  state: Types.IProgramPageState = {
    filters: Object.assign({}, ProgramSearchInitialValues),
    filterIsOpen: false,
    pageIsChanged: false,
    programFormIsOpen: false,
    selectedProgramId: 0,
    classroomAddModalIsOpen: false,
  };

  componentDidMount() {
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
    let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.filters.term_id = id;
    this.setState(this.state);
    this.getProgramSelectOptions();
  }

  componentDidUpdate(prevProps: Types.IProgramPageProps, prevState: Types.IProgramPageState) {
    if (prevProps.programList !== this.props.programList) {
      this.setState({
        pageIsChanged: false
      })
    }
    if (prevProps.programList !== this.props.programList && !this.state.pageIsChanged) {
      if (this.props.filters && !this.props.filters.select_all) {
        this.setState({
          selected_program_ids: [],
          selected_ids: [],
          selected_programs: []
        })
      }
    }
  }

  getProgramClassrooms() {
    this.state.filters.term_id = this.props.term_id;

    this.props.dispatch(
      Actions.ApiRequest(Constants.exam_period.COURSE_PERIOD_GET_CLASSROOM_PRIORITY, this.state.filters, 'exam-period-modal-tab-spin')
    );
  }

  getProgramSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.program.PROGRAM_GET_SELECT_OPTIONS, 'program-list-spin'));
  }

  switchClassroomModalStatus = () => {
    this.setState({
      ...this.state,
      classroomAddModalIsOpen: !this.state.classroomAddModalIsOpen
    });
  };

  showClassroomProgram = (e?: any, id?: number) => {
    if (this.props.onSelect) {
      this.props.onSelect(e, id);
      this.state.selectedProgramId = id;
      this.setState(this.state);
    }
  };

  onShowClassroomProgram = (e?: any) => {
    let program_id: number = -1;
    const str_program_id: string = e.currentTarget.dataset.program_id || '';

    program_id = parseInt(str_program_id, 10);
    this.showClassroomProgram(e, program_id);
  };

  checkAllSelected = (): boolean => {
    const all_ids = this.state.all_ids ? this.state.all_ids : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.state.filters.term_id = this.props.term_id;
        this.setState(this.state);
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.program.PROGRAM_LIST_SEARCH,
            this.state.filters,
            'program-list-spin',
            (response: any) =>
              this.setState({
                ...this.state,
                all_ids: response.all_ids,
                selected_ids: response.all_ids,
                selected_program_ids: response.all_ids
              })
          )
        );
      } else {
        this.setState({
          ...this.state,
          selected_ids: [],
          selected_program_ids: [],
          all_ids: [],
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
    }
  };

  onSelectProgramCheckbox = async (e: any) => {
    if (e && e.currentTarget) {
      let checkedIDList: Array<number> = Object.assign([], this.state.selected_ids);
      let selected_program_ids: Array<number> = Object.assign([], this.state.selected_program_ids);

      let checkedPrograms = Object.assign([], this.state.selected_programs);
      let stringID: any = e.currentTarget.dataset.id || -1;
      let string_programItem: string = e.currentTarget.dataset.item || '';
      let programItem: Types.ICoursePeriod_ProgramItem = JSON.parse(string_programItem);
      let programID = parseInt(stringID, 10);

      if (selected_program_ids.findIndex((_id: number) => _id === programItem.program_id) === -1) {
        selected_program_ids.push(programItem.program_id ? programItem.program_id : -1)
      } else {
        selected_program_ids = selected_program_ids.filter((_id: number) => _id != programItem.program_id)
      }

      if (checkedIDList.findIndex((_id: number) => _id === programID) === -1) {
        checkedIDList.push(programID);
        checkedPrograms.push({ id: programID, item: programItem });
      } else {

        let index = checkedIDList.indexOf(programID);
        let programItem: any = {};

        programItem = checkedPrograms.find(
          (item: { id: number; item: Types.ICoursePeriod_ProgramItem }) => item.id === programID
        );

        let programItemIndex = programItem ? programItem.id : -1;

        if (index !== -1) {
          checkedIDList.splice(index, 1);
        }

        if (programItemIndex !== -1) {
          checkedPrograms = checkedPrograms.filter((item: any) => item.id != programID);
        }

        this.setState({
          selected_ids: checkedIDList,
          selected_program: programItem,
          selected_programs: checkedPrograms,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });

      }

      await this.setState({
        selected_ids: checkedIDList,
        selected_program_ids: selected_program_ids,
        selected_program: programItem,
        selected_programs: checkedPrograms
      });
    }
  };

  checkAllIdsSelected = (): boolean => {
    const all_ids = this.state.all_ids ? this.state.all_ids : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.term_id = this.props.term_id;
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.getProgramClassrooms();
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.state.pageIsChanged = true;
    this.setState(this.state);
    this.getProgramClassrooms();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, ProgramSearchInitialValues);
    GT.GetTermIdFromURL(window, this.state.filters.term_id);
    this.setState(this.state);
    this.getProgramClassrooms();
  };

  onFilterProgram(model: Types.IFilterProgram, FormActions: FormikActions<Types.IFilterProgram>) {
    GT.GetTermIdFromURL(window, this.state.filters.term_id);
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.state.pageIsChanged = true;
    this.setState(this.state);
    this.getProgramClassrooms();
    FormActions.setSubmitting(false);
  }

  translateEducationTypeCode = (programCode: string) => {
    let educationTypeCodes = ["NO", "IO", "Uzaktan Eğitim"];

    if (educationTypeCodes.some(s => programCode.endsWith(s))) {
      if (programCode.endsWith("Uzaktan Eğitim")) {
        let code = "Uzaktan Eğitim"
        let parts = programCode.split(code);
        return parts.join("") + " " + T.t(code);
      } else {
        let parts = programCode.split(" ");
        let code = parts.pop()
        return parts.join(" ") + " " + T.t(code);
      }
    }
    else {
      return programCode;
    }
  }

  static getDerivedStateFromProps(props: Types.IProgramPageProps, state: Types.IProgramPageState) {
    let hasNewState: boolean = false;
    if (props.programList) {
      hasNewState = true;
      state.program_list = props.programList;
    }
    if (props.compactTable && props.selectedProgramId && !!!state.selectedProgramId) {
      hasNewState = true;
      state.selectedProgramId = props.selectedProgramId;
    }
    if (props.all_ids && props.all_ids.length) {
      hasNewState = true;
      state.all_ids = props.all_ids;
    }
    if (
      props.compactTable &&
      props.checkedProgramIds &&
      props.checkedProgramIds.length > 0 &&
      state.selected_ids &&
      state.selected_ids.length == 0
    ) {
      hasNewState = true;
    }
    if (
      props.compactTable &&
      props.checkedProgramIds &&
      props.checkedProgramIds.length == 0 &&
      state.selected_ids &&
      state.selected_ids.length > 0
    ) {
      hasNewState = true;
    }
    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let programList = this.state.program_list && this.state.program_list.programInfo;

    let Table = (
      <div className="row">
        <div className="col-12">
          <div className="quick-actions">
            <span
              id='span_filter'
              className="category-tag-square tag-glass float-right"
              onClick={() => {
                this.state.filterIsOpen = !this.state.filterIsOpen;
                this.setState(this.state);
              }}
            >
              <i data-toggle="tooltip" className="material-icons" data-original-title="Listeye Ekle">
                filter_list
              </i>
              {T.t('gen_filter')}
            </span>
            {(this.state.selected_program_ids && this.state.selected_program_ids.length) ? (
              <React.Fragment>
                {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a' || this.props.user.role === 'e' || this.props.user.role === 'p') ? (
                  this.state.selected_ids && this.state.selected_ids.length !== 0 ? (
                    <button
                      id='button_check_item'
                      className="category-tag-square tag-gray float-left pr-2 pl-2"
                      style={{ margin: '2px' }}
                      onClick={() => {
                        this.setState({
                          ...this.state,
                          selected_ids: [],
                          selected_programs: [],
                          selected_program_ids: [],
                          all_ids: []
                        });
                      }}
                    >
                      <i className="material-icons mr-2">close</i>
                      <span id='span_cancel'>{this.props.compactTable ? T.t('gen_cancel') : T.t('gen_cancel_selection')}</span>
                      <b>
                        &nbsp;(
                        {this.state.selected_ids && this.state.selected_ids.length}
                        )
                      </b>
                    </button>
                  ) : null
                ) : null}
                {this.props.user && (this.props.user.role == 's' || this.props.user.role == 'a' || this.props.user.role == 'e' || this.props.user.role === 'p') ? (
                  this.state.selected_ids && this.state.selected_ids.length !== 0 ? (
                    <button
                      id='button_assign_classroom'
                      className="category-tag-square tag-green float-left pr-2 pl-2"
                      style={{ margin: '2px' }}
                      onClick={this.switchClassroomModalStatus}
                    >
                      <i className="material-icons mr-2">input</i>
                      <span>{T.t('gen_assign_classroom')}</span>
                    </button>
                  ) : null
                ) : null}
              </React.Fragment>
            ) : (null)}
          </div>
        </div>
        <div className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)} id="advance-search">
          <div className="advance-search d-block mt-3">
            <Formik
              initialValues={ProgramSearchInitialValues}
              enableReinitialize={true}
              onSubmit={(values, actions) => {
                this.onFilterProgram(values, actions);
              }}
              onReset={this.onFormReset}
            >
              {(props: FormikProps<Types.IFilterProgram>) => {
                return (
                  <form onSubmit={props.handleSubmit}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        props.handleSubmit();
                      }
                    }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <h6>{T.t('gen_filter_parameters')}</h6>
                      </div>
                      <div className="col-md-3 mt-3">
                        <div className="form-input form-group date-picker">
                          <input
                            id="program_code"
                            name="program_code"
                            value={props.values.program_code}
                            onChange={props.handleChange}
                            type="text"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="program_code">{T.t('gen_code')}</label>
                          <i className="material-icons">filter_1</i>
                        </div>
                      </div>
                      <div className="col-md-3 mt-3">
                        <div className="form-input form-group date-picker">
                          <input
                            id="name"
                            name="name"
                            value={props.values.name}
                            onChange={props.handleChange}
                            type="text"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="name">{T.t('gen_name')}</label>
                          <i className="material-icons">title</i>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_faculty_college')}</label>
                            <Select
                              id='select_faculty'
                              className="react-select"
                              isMulti={true}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                              }
                              closeMenuOnSelect={false}
                              options={
                                this.props.selectOptions && this.props.selectOptions.faculties
                                  ? this.props.selectOptions.faculties
                                  : []
                              }
                              placeholder={T.t('gen_select_faculty')}
                              value={props.values.faculties ? props.values.faculties : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('faculties', list);
                                props.setFieldValue(
                                  'faculty_ids',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="add-custom-tag mb-3">
                          <div className="react-select-container">
                            <label>{T.t('gen_education_type')}</label>
                            <Select
                              id='select_education_type'
                              className="react-select"
                              isMulti={true}
                              closeMenuOnSelect={false}
                              options={EducationTypeSelectOptions(T)}
                              placeholder={T.t('gen_select_education_type')}
                              value={props.values.education_types_select}
                              onChange={(
                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                              ) => {
                                const list: Array<Types.ISelectOption> = options
                                  ? (options as Array<Types.ISelectOption>)
                                  : [];
                                props.setFieldValue('education_types_select', list);
                                props.setFieldValue(
                                  'education_types',
                                  list.map((item) => item.value)
                                );
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_education_type')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row mt-3">
                      <div className="col-6">
                        <button
                          id='button_arrow_upwoard'
                          type="button"
                          onClick={() => {
                            this.state.filterIsOpen = false;
                            this.setState(this.state);
                          }}
                          className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                        >
                          <i className="material-icons">arrow_upward</i>
                        </button>
                        <button
                          id='button_delete_sweep'
                          type="reset"
                          onClick={props.handleReset}
                          className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2 ml-2"
                        >
                          <i className="material-icons">delete_sweep</i>
                        </button>
                      </div>
                      <div className="col-6 text-right">
                        <button
                          id='button_search'
                          type="button"
                          className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                          onClick={() => props.handleSubmit()}
                          disabled={props.isSubmitting}
                        >
                          <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                        </button>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
        <div className="col-12">
          <table
            className={this.props.compactTable ? "aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table table-course-hours-classrooms" : "aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table"}
            style={this.props.compactTable ? { cursor: 'pointer' } : {}}
          >
            <thead>
              <tr>
                {this.props.user && this.props.user.role === 's' ? (
                  <th data-cell="select">
                    <div className="tick-radio position-relative">
                      <input
                        id='select_all'
                        type="checkbox"
                        className="form-radio"
                        checked={this.checkAllIdsSelected()}
                        onChange={this.onSelectAll}
                      />
                    </div>
                  </th>
                ) : null}
                <SortedColumn
                  className="d-none d-lg-table-cell d-xl-table-cell examp-compt-header-5"
                  datacell="prioritization"
                  title={T.t('gen_prioritization')}
                  sortkey="prioritization"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <SortedColumn
                  className="d-none d-lg-table-cell d-xl-table-cell examp-compt-header"
                  datacell="program_code"
                  title={T.t('gen_code')}
                  sortkey="program_code"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <SortedColumn
                  datacell="name"
                  className="d-none d-lg-table-cell d-xl-table-cell examp-compt-header-1"
                  title={T.t('gen_name')}
                  sortkey="name"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <th scope="col"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center examp-compt-header-1">
                  {T.t('gen_faculty_college')}
                </th>
                <th scope="col"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center examp-compt-header">
                  {T.t('gen_education_type')}
                </th>
                <th scope="col"
                  className="text-center examp-compt-header-5">
                  {T.t('gen_grade')}
                </th>
              </tr>
            </thead>
            <tbody>
              {programList && programList.length ? (
                programList.map((item: any) => {

                  let tableContent = null;
                  let keyId = Math.random() * 1000
                  item.status_classroom_assignment = this.state.program_list && this.state.program_list.programClassrooms && this.state.program_list.programClassrooms.filter((programClassroom: any) => programClassroom.program_id == item.program_id ? programClassroom.status_classroom_assignment : 0);

                  if (item) {
                    tableContent = (
                      <tr className="table-course-hours-classrooms-tr" key={keyId}>
                        <td data-cell="select">
                          {this.props.user && (this.props.user.role === 's' || this.props.user.role === 'a' || this.props.user.role === 'e' || this.props.user.role === 'p') ? (
                            <div className="tick-radio position-relative">
                              <input
                                id='check_course'
                                type="checkbox"
                                className="form-radio"
                                onChange={this.onSelectProgramCheckbox}
                                checked={this.state.selected_ids && this.state.selected_ids.indexOf(item.program_id ? item.program_id : -1) > -1}
                                data-id={item.program_id}
                                data-name={
                                  item.program_code + ' - ' + item.name
                                }
                                data-item={JSON.stringify(item)}
                              />
                            </div>
                          ) : null}
                        </td>
                        <td
                          key={'course-' + item.program_id}
                          data-title={item.name}
                          data-id={item.program_id}
                          data-program_id={item.program_id}
                          data-name={item.program_code + ' - ' + item.name}
                          data-item={JSON.stringify(item)}
                          onClick={this.onShowClassroomProgram}
                          className="table-program-classrooms-div"
                          style={
                            this.state.selectedProgramId == item.program_id
                              ? {
                                backgroundColor: 'rgba(218, 225, 232, 0.96)',
                                border: '1px solid darkgrey'
                              }
                              : {}
                          }>
                          <table>
                            <tbody>
                              <tr>
                                <td className="" style={{ 'width': '5%' }}>
                                  {item.status_classroom_assignment && item.status_classroom_assignment.length > 0 ? (
                                    <>
                                      <i className="material-icons" data-toggle="tooltip" title={T.t('gen_badge_assigned')} style={{ color: '#33c900c4' }}>check_circle</i>
                                    </>
                                  ) : (
                                    <>
                                      <i className="material-icons" data-toggle="tooltip" title={T.t('gen_badge_unassigned')} style={{ color: '#ff0000c4' }}>cancel</i>
                                    </>
                                  )}
                                </td>
                                <td className="" style={{ 'width': '110px' }} scope="row" data-label={T.t('gen_code')}>
                                  {this.translateEducationTypeCode(item.program_code)}
                                </td>
                                <td className="text-center" style={{ 'width': '280px' }} data-label={T.t('gen_name')}>
                                  {item.name}
                                </td>
                                <td className="text-center " style={{ 'minWidth': '220px' }} data-label={T.t('gen_faculty_college')}>
                                  {item.faculty_name}
                                </td>
                                <td className="text-center" style={{ 'minWidth': '153px' }} scope="row" data-label={T.t('gen_education_type')}>
                                  {item.education_type === EducationTypeSelectOptions(T)[0].value ?
                                    EducationTypeSelectOptions(T)[0].label :
                                    item.education_type === EducationTypeSelectOptions(T)[1].value ?
                                      EducationTypeSelectOptions(T)[1].label :
                                      item.education_type === EducationTypeSelectOptions(T)[2].value ?
                                        EducationTypeSelectOptions(T)[2].label : item.education_type
                                  }
                                </td>
                                <td className="text-center " style={{ 'minWidth': '30px' }} data-label={T.t('gen_grade')}>
                                  {item.grade}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    );
                  }
                  return tableContent;
                })
              ) : (
                <tr>
                  <td colSpan={24}>
                    <Announce title={T.t('gen_no_records_found')} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="row-options justify-content-end">
            <div className="page-sorting d-flex align-items-center justify-content-center" style={{ marginTop: '5px' }}>
              {this.state.program_list && this.state.program_list.programInfo && this.state.program_list.programInfo.length > 0 ? (
                <Paginate filters={this.props.filters} onPageChange={this.onPageChange} />
              ) : null}
            </div>
          </div>
        </div >
        {
          this.state.classroomAddModalIsOpen && (
            <ClassroomAddModal
              modalIsOpen={this.state.classroomAddModalIsOpen}
              onClose={this.switchClassroomModalStatus}
              onUpdateList={() => this.getProgramClassrooms()}
              calledFromClassroomPriorityTab={true}
              selectedProgramIDs={this.state.selected_program_ids}
            />
          )
        }

      </div >
    );

    return <div className="container-fluid p-0">{Table}</div>;
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IProgramPageProps): Types.IProgramPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IProgramPageProps = Object.assign({}, ownProps, {
    user: store.state.user,
    programList:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.courseterm_program_classrooms &&
      store.state.examPeriodModal.courseterm_program_classrooms.program_list,
    results: store.state.program_page && store.state.program_page.results,
    filters: store.state.examPeriodModal && store.state.examPeriodModal.filters,
    selectOptions: store.state.select_options && store.state.select_options.programPage,
    all_ids:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.courseterm_course_hours &&
      store.state.examPeriodModal.courseterm_course_hours.all_ids,
    term_id: store.state.term_id,
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ProgramListPage);

export default container;
