import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal } from 'reactstrap';
import * as Actions from '../../../store/actions/general';
import * as Constants from '../../../store/constants/all';
import * as Types from '../../../store/types';
import {
  ChangeAllDataModalStudentInitialValues,
} from '../../../store/constants/student-const';
import { Grades } from '../../../store/constants/course-const';
import Translator from '../../../services/translate-factory';
import { UncontrolledTooltip } from 'reactstrap';

const T = Translator.create();
const L = Log.create('ChangeAllDataModal');

function getInitialState(): Types.ChangeAllDataModalState {
  const initialValues: Types.ChangeAllDataModalState = {
    all_ids: [],
    selected_ids: []
  };
  return Object.assign({}, initialValues);
}

class ChangeAllDataModal extends Component<any, Types.ChangeAllDataModalState> {
  state = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  getCourseProgramsByFacultiesAtStudents = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_STUDENTS_GET_COURSE_PROGRAMS_BY_FACULTIES, facultyIds, 'student-list-spin'));
  }

  getEnrolledProgramsByFacultiesAtStudents = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_STUDENTS_GET_ENROLLED_PROGRAMS_BY_FACULTIES, facultyIds, 'student-list-spin'));
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  static getDerivedStateFromProps(props: Types.IClassroomPageProps, state: Types.ChangeAllDataModalState) {
    let hasNewState: boolean = false;
    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  onChangeAllData(values: any, FormActions: FormikActions<Types.IStudentFilterChangeAllDataModal>) {
    const resultCallback = (result: any, status: number) => {
      if (status === 200) {
        this.setClose(true);
      }
    };

    let model;
    switch (values.selected_type_ids) {
      case 0:
        model = {
          selected_type: 0,
          term_id: this.props.term_id,
          grade: values.grade,
          student_ids: this.props.selected_ids
        }
        break;
      case 1:
        model = {
          selected_type: 1,
          term_id: this.props.term_id,
          section: values.section,
          student_ids: this.props.selected_ids
        }
        break;
      case 2:
        model = {
          selected_type: 2,
          term_id: this.props.term_id,
          course_faculty_id: values.course_faculty_selected.value,
          course_program_id: values.course_program_selected.value,
          student_ids: this.props.selected_ids
        }
        break;
      case 3:
        model = {
          selected_type: 3,
          term_id: this.props.term_id,
          enrolled_faculty_id: values.enrolled_faculty_selected.value,
          enrolled_program_id: values.enrolled_program_selected.value,
          student_ids: this.props.selected_ids
        }
        break;

      default:
        break;
    }

    this.props.dispatch(
      Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_STUDENTS_CHANGE_ALL_DATA, model, 'student-list-spin', resultCallback)
    );
  }

  render() {

    let listOptions = [
      { label: T.t('gen_grade'), value: 0 },
      { label: T.t('gen_section'), value: 1 },
      { label: T.t('gen_course_faculty_and_program'), value: 2 },
      { label: T.t('gen_enrolled_faculty_and_program'), value: 3 }
    ];

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '700px', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_changing_all_data')}
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <Formik
                initialValues={ChangeAllDataModalStudentInitialValues}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                  this.onChangeAllData(values, actions);
                }}
              >
                {(props: FormikProps<Types.IStudentFilterChangeAllDataModal>) => {
                  const { values, handleChange, errors, handleBlur, handleSubmit } = props;
                  return (
                    <form onSubmit={props.handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="col-md-12 pb-3">
                            {T.t('gen_change_all_data_description')}
                          </div>
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_change_all_data')}</label>
                                <Select
                                  className="react-select"
                                  isMulti={false}
                                  closeMenuOnSelect={true}
                                  options={listOptions}
                                  placeholder={T.t('gen_select_type')}
                                  value={props.values.selected_types ? props.values.selected_types : null}
                                  onChange={(
                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                  ) => {
                                    const list: any = options
                                      ? (options as Array<Types.ISelectOption>)
                                      : [];
                                    props.setFieldValue('selected_types', list);
                                    props.setFieldValue(
                                      'selected_type_ids',
                                      list.value
                                    );
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                />
                              </div>
                            </div>
                          </div>
                          {
                            props.values.selected_type_ids === 0 ?
                              <div className="col-md-4">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_grade')}</label>
                                    <Select
                                      id='select_grade'
                                      className="react-select"
                                      isMulti={false}
                                      closeMenuOnSelect={true}
                                      options={Grades}
                                      placeholder={T.t('gen_select_grade')}
                                      value={
                                        values.grade != undefined && values.grade != null
                                          ? Grades.find((option) => option.value == values.grade)
                                          : null
                                      }
                                      onChange={(option: any) => {
                                        props.setFieldValue('grade', option.value);
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_grade')}
                                    />
                                  </div>
                                </div>
                              </div> :
                              props.values.selected_type_ids === 1 ?
                                <div className="col-md-4 form-input form-group with-icon">
                                  <input
                                    id="section"
                                    name="section"
                                    value={props.values.section}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="section">{T.t('gen_section')}</label>
                                </div> :
                                props.values.selected_type_ids === 2 ?
                                  <div>
                                    <div className="col-md-8">
                                      <div className="add-custom-tag mb-3">
                                        <div className="react-select-container">
                                          <label>{T.t('gen_course_faculty')}</label>
                                          <Select
                                            className="react-select"
                                            isMulti={false}
                                            filterOption={(option: any, query: any) =>
                                              option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                            }
                                            closeMenuOnSelect={true}
                                            options={
                                              this.props.selectOptions && this.props.selectOptions.faculties
                                                ? this.props.selectOptions.faculties
                                                : []
                                            }
                                            placeholder={T.t('gen_select_faculty')}
                                            value={props.values.course_faculty_selected}
                                            onChange={(option: any) => {
                                              props.setFieldValue('course_faculty_id', option.value);
                                              props.setFieldValue('course_faculty_selected', option);
                                              props.setFieldValue('course_program_id', 0);
                                              props.setFieldValue('course_program_selected', null);
                                              this.getCourseProgramsByFacultiesAtStudents([option.value]);
                                            }}
                                            noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-8">
                                      <div className="add-custom-tag mb-3">
                                        <div className="react-select-container">
                                          {props.values.course_faculty_selected == undefined || props.values.course_faculty_selected == null ? (
                                            <><label id="select_faculty_filter_program_batch">{T.t('gen_course_program')} <i className="material-icons">info_outlined</i></label>
                                              <UncontrolledTooltip placement="bottom" target="select_faculty_filter_program_batch">
                                                {T.t('gen_select_faculty_filter_program')}
                                              </UncontrolledTooltip></>
                                          ) : (<><label>{T.t('gen_course_program')}</label></>)}
                                          <Select
                                            className="react-select"
                                            isMulti={false}
                                            filterOption={(option: any, query: any) =>
                                              option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                            }
                                            closeMenuOnSelect={true}
                                            isDisabled={props.values.course_faculty_selected == undefined || props.values.course_faculty_selected == null ? true : false}
                                            options={
                                              this.props.course_programs_related_faculty
                                                ? this.props.course_programs_related_faculty
                                                : []
                                            }
                                            placeholder={T.t('gen_select_program')}
                                            value={props.values.course_program_selected}
                                            onChange={(option: any) => {
                                              props.setFieldValue('course_program_id', option.value);
                                              props.setFieldValue('course_program_selected', option);
                                            }}
                                            noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div> :
                                  props.values.selected_type_ids === 3 ?
                                    <div>
                                      <div className="col-md-8">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_enrolled_faculty')}</label>
                                            <Select
                                              className="react-select"
                                              isMulti={false}
                                              filterOption={(option: any, query: any) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                              }
                                              closeMenuOnSelect={true}
                                              options={
                                                this.props.selectOptions && this.props.selectOptions.faculties
                                                  ? this.props.selectOptions.faculties
                                                  : []
                                              }
                                              placeholder={T.t('gen_select_faculty')}
                                              value={props.values.enrolled_faculty_selected}
                                              onChange={(option: any) => {
                                                props.setFieldValue('enrolled_faculty_id', option.value);
                                                props.setFieldValue('enrolled_faculty_selected', option);
                                                props.setFieldValue('enrolled_program_id', 0);
                                                props.setFieldValue('enrolled_program_selected', null);
                                                this.getEnrolledProgramsByFacultiesAtStudents([option.value]);
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-8">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            {props.values.enrolled_faculty_selected == undefined || props.values.enrolled_faculty_selected == null ? (
                                              <><label id="select_faculty_filter_program_batch2">{T.t('gen_enrolled_program')} <i className="material-icons">info_outlined</i></label>
                                                <UncontrolledTooltip placement="bottom" target="select_faculty_filter_program_batch2">
                                                  {T.t('gen_select_faculty_filter_program')}
                                                </UncontrolledTooltip></>
                                            ) : (<><label>{T.t('gen_enrolled_program')}</label></>)}
                                            <Select
                                              className="react-select"
                                              isMulti={false}
                                              filterOption={(option: any, query: any) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                              }
                                              closeMenuOnSelect={true}

                                              isDisabled={props.values.enrolled_faculty_selected == undefined || props.values.enrolled_faculty_selected == null ? true : false}
                                              options={
                                                this.props.enrolled_programs_related_faculty
                                                  ? this.props.enrolled_programs_related_faculty
                                                  : []
                                              }
                                              placeholder={T.t('gen_select_program')}
                                              value={props.values.enrolled_program_selected}
                                              onChange={(option: any) => {
                                                props.setFieldValue('enrolled_program_id', option.value);
                                                props.setFieldValue('enrolled_program_selected', option);
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div> : null
                          }
                          {
                            props.values.selected_type_ids !== undefined ?
                              <div className="col-6 pt-3 pb-3">
                                <button
                                  type="button"
                                  className="btn btn-green"
                                  disabled={(props.values.selected_type_ids == 2 && props.values.course_program_id == 0) ||
                                    ((props.values.selected_type_ids == 3 && props.values.enrolled_program_id == 0))}
                                  onClick={() => props.handleSubmit()}
                                >
                                  {T.t('gen_change')}
                                </button>
                              </div> : null
                          }
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Modal >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IStudentPageProps): Types.IStudentPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IStudentPageProps = Object.assign({}, ownProps, {
    selectOptions: store.state.select_options && store.state.select_options.studentPage,
    term_type: store.state.term_type,
    term_id: store.state.term_id,
    course_programs_related_faculty: store.state.select_options && store.state.select_options.studentPage && store.state.select_options.studentPage.course_programs_related_faculty,
    enrolled_programs_related_faculty: store.state.select_options && store.state.select_options.studentPage && store.state.select_options.studentPage.enrolled_programs_related_faculty,
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ChangeAllDataModal);

export default container;