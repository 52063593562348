import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';
import * as Actions from '../../store/actions/general';
import Spinner from '../../components/templates/spinner';
import * as GT from '../../tools/general-tools';
import Announcement from '../../assets_aplan/images/announcement.svg';
import {
  CourseHoursInitialValues
} from '../../store/constants/exam-period-const';
import moment from 'moment';

const T = Translator.create();
const L = Log.create('DistributionAvailabilityCalendarModal');

const initialValues: any = {
  model: Object.assign({}, CourseHoursInitialValues),
  course_id: 0,
  term_id: 0
};
function getInitialState(): any {
  return Object.assign({}, initialValues);
}

class AvailabilityCalendarModal extends Component<any, any> {
  state: {
    model: any,
    course_id: number,
    term_id: number
  } = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    moment.locale('tr');
    let termType = this.props && this.props.term_type > -1 ? this.props.term_type : -1;
    if (termType > -1) {
      this.getCourseHours(termType);
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if ((prevProps.selectOptions != undefined && prevProps.selectOptions.course_id_selected != undefined && prevProps.selectOptions.course_id_selected !== this.props.selectOptions.course_id_selected)
      || (prevProps.selectOptions == undefined && this.props.selectOptions != undefined && this.props.selectOptions.course_id_selected > 0)) {
      this.state.course_id = this.props.selectOptions.course_id_selected;
      this.setState(this.state);
      let termType = this.props && this.props.term_type > -1 ? this.props.term_type : -1;
      if (termType > -1) {
        this.getCourseHours(termType);
      }
    }
  }

  createModifiedSchedule(termType: number) {
    let scheduleClone: any = Object.assign({}, this.state.model.calendar.schedule);
    let daysClone: any = Object.assign([], this.state.model.calendar.days);
    let test_schedule: any = {};

    if (termType == 1) {
      daysClone.map((day: any) => {
        let newArray: any = [];
        scheduleClone[day].map((item: any) => {
          let obj = {
            hour: item.hour,
            status: item.isActive == true ? 'empty' : 'disabled',
            course: [],
            instructor: {
              own_exams: [],
              invigilators: []
            },
            classroom: []
          };
          newArray.push(obj);
        });
        test_schedule[day] = newArray;
      });

      this.state.model.calendar.schedule = test_schedule;
      this.state.model.initialSchedule = test_schedule;
      this.state.model.is_calendar_created = true;

      let overlaps: any = this.state.model.overlaps && this.state.model.overlaps;
      if (overlaps !== undefined) {
        let classroom_hours = overlaps['classroom'];;
        let instructor_hours = overlaps['instructor'];
        let course_hours = overlaps['course'];

        let instructor_has_any_time_slots =
          instructor_hours &&
          instructor_hours.length > 0 &&
          instructor_hours.some(
            (item: any) =>
              (item.time_slots && Object.keys(item.time_slots).length) ||
              (item.invigilation_time_slots && Object.keys(item.invigilation_time_slots).length)
          );

        let classroom_has_any_time_slots =
          classroom_hours &&
          classroom_hours.length > 0 &&
          classroom_hours.some((item: any) => item.time_slots && Object.keys(item.time_slots).length);

        let course_has_any_time_slots =
          course_hours &&
          course_hours.length > 0 &&
          course_hours.some((item: any) => item.time_slots && Object.keys(item.time_slots).length);

        let dateObject_timeSlots: any = {};
        let result_timeSlots: any = {};
        let scheduleClone: any = this.state.model.calendar.schedule;

        if (instructor_has_any_time_slots) {
          instructor_hours.map((instructor: Types.IExamPeriod_InstructorItem) => {
            if (instructor.time_slots && Object.keys(instructor.time_slots).length) {
              let examTimeSlots: any = instructor.time_slots;
              let examDays = Object.keys(examTimeSlots);

              examDays.map((day: string) => {
                dateObject_timeSlots = scheduleClone[moment(day).format('YYYY-MM-DD')];
                examTimeSlots[day].map((dataHour: string) => {
                  dateObject_timeSlots = dateObject_timeSlots.map((item: any) => {
                    if (item.hour == dataHour) {
                      let arr = item && item.instructor && item.instructor.own_exams;
                      arr.push(instructor.instructorInfo && instructor.instructorInfo.name);
                      return Object.assign(item, {
                        status: 'overlap',
                        instructor: Object.assign(item.instructor, {
                          own_exams: arr
                        })
                      });
                    } else return item;
                  });
                  result_timeSlots = Object.assign(scheduleClone, result_timeSlots, {
                    [day]: dateObject_timeSlots
                  });
                });
              });
            }

            if (instructor.invigilator_time_slots && Object.keys(instructor.invigilator_time_slots).length) {
              let invigilatorTimeSlots: any = instructor.invigilator_time_slots;
              let invigilatorDays = Object.keys(invigilatorTimeSlots);

              invigilatorDays.map((day: string) => {
                dateObject_timeSlots = scheduleClone[moment(day).format('YYYY-MM-DD')];
                invigilatorTimeSlots[day].map((dataHour: string) => {
                  dateObject_timeSlots = dateObject_timeSlots.map((item: any) => {
                    if (item.hour == dataHour) {
                      let arr = item && item.instructor && item.instructor.invigilators;
                      arr.push(instructor.instructorInfo && instructor.instructorInfo.name);
                      return Object.assign(item, {
                        status: 'overlap',
                        instructor: Object.assign(item.instructor, {
                          invigilators: arr
                        })
                      });
                    } else return item;
                  });
                  result_timeSlots = Object.assign(result_timeSlots, {
                    [day]: dateObject_timeSlots
                  });
                });
              });
            }
          });
        }

        if (classroom_has_any_time_slots) {
          classroom_hours.map((classroom: Types.IExamPeriod_ClassroomItem) => {
            let classroomTimeSlots: any = classroom.time_slots;
            let classroomDays = Object.keys(classroomTimeSlots);

            classroomDays.map((day: string) => {
              dateObject_timeSlots = scheduleClone[moment(day).format('YYYY-MM-DD')];
              classroomTimeSlots[day].map((dataHour: string) => {
                dateObject_timeSlots = dateObject_timeSlots.map((item: any) => {
                  if (item.hour == dataHour) {
                    let arr = item && item.classroom ? item.classroom : [];
                    arr.push(classroom.classroomInfo && classroom.classroomInfo.name);
                    return Object.assign(item, {
                      status: 'overlap',
                      classroom: arr
                    });
                  } else return item;
                });
                result_timeSlots = Object.assign(scheduleClone, result_timeSlots, {
                  [day]: dateObject_timeSlots
                });
              });
            });
          });
        }

        if (course_has_any_time_slots) {
          course_hours.map((course: Types.IExamPeriod_CourseItem) => {
            let courseTimeSlots: any = course.time_slots;
            let courseDays = Object.keys(courseTimeSlots);

            courseDays.map((day: string) => {
              dateObject_timeSlots = scheduleClone[moment(day).format('YYYY-MM-DD')];
              courseTimeSlots[day].map((dataHour: string) => {
                dateObject_timeSlots = dateObject_timeSlots.map((item: any) => {
                  if (item.hour == dataHour) {
                    let arr = item && item.course ? item.course : [];
                    arr.push(course.courseInfo && course.courseInfo.name);
                    return Object.assign(item, {
                      status: 'overlap',
                      course: arr
                    });
                  } else return item;
                });
                result_timeSlots = Object.assign(scheduleClone, result_timeSlots, {
                  [day]: dateObject_timeSlots
                });
              });
            });
          });
        }
      }
    }

    else if (termType == 0) {
      daysClone.map((day: any) => {
        let newArray: any = [];
        scheduleClone[day].map((item: any) => {
          let obj = {
            hour: item.hour,
            status: item.isActive == true ? 'empty' : 'disabled',
            course: [],
            instructor: {
              time_slots: [],
              invigilators: [],
            },
            classroom: [],
          };
          newArray.push(obj);
        });
        test_schedule[day] = newArray;
      });

      this.state.model.calendar.schedule = test_schedule;
      this.state.model.initialSchedule = test_schedule;
      this.state.model.is_calendar_created = true;

      let overlaps: any = this.state.model.overlaps && this.state.model.overlaps;

      if (overlaps !== undefined) {
        let classroom_hours = overlaps['classroom'];
        let instructor_hours = overlaps['instructor'];
        let course_hours = overlaps['course'];
        let instructor_has_any_time_slots =
          instructor_hours &&
          instructor_hours.length > 0 &&
          instructor_hours.some(
            (item: any) =>
              (item.time_slots && Object.keys(item.time_slots).length) ||
              (item.invigilation_time_slots && Object.keys(item.invigilation_time_slots).length)
          );

        let classroom_has_any_time_slots =
          classroom_hours &&
          classroom_hours.length > 0 &&
          classroom_hours.some((item: any) => item.time_slots && Object.keys(item.time_slots).length);

        let course_has_any_time_slots =
          course_hours && course_hours.length > 0 && course_hours.some((item: any) => item.time_slots && Object.keys(item.time_slots).length);

        let dateObject_timeSlots: any = {};
        let result_timeSlots: any = {};
        let scheduleClone: any = this.state.model.calendar.schedule;

        if (instructor_has_any_time_slots) {
          instructor_hours.map((instructor: Types.IExamPeriod_InstructorItem) => {
            if (instructor.time_slots && Object.keys(instructor.time_slots).length) {
              let examTimeSlots: any = instructor.time_slots;
              let examDays = Object.keys(examTimeSlots);

              examDays.map((day: string) => {
                dateObject_timeSlots = scheduleClone[day];
                examTimeSlots[day].map((dataHour: string) => {
                  dateObject_timeSlots = dateObject_timeSlots.map((item: any) => {
                    if (item.hour == dataHour) {
                      let arr = item && item.instructor && item.instructor.time_slots;
                      arr.push(instructor.instructorInfo && instructor.instructorInfo.name);
                      return Object.assign(item, {
                        status: 'overlap',
                        instructor: Object.assign(item.instructor, {
                          time_slots: arr,
                        }),
                      });
                    } else return item;
                  });
                  result_timeSlots = Object.assign(scheduleClone, result_timeSlots, {
                    [day]: dateObject_timeSlots,
                  });
                });
              });
            }

            if (instructor.invigilator_time_slots && Object.keys(instructor.invigilator_time_slots).length) {
              let invigilatorTimeSlots: any = instructor.invigilator_time_slots;
              let invigilatorDays = Object.keys(invigilatorTimeSlots);

              invigilatorDays.map((day: string) => {
                dateObject_timeSlots = scheduleClone[day];
                invigilatorTimeSlots[day].map((dataHour: string) => {
                  dateObject_timeSlots = dateObject_timeSlots.map((item: any) => {
                    if (item.hour == dataHour) {
                      let arr = item && item.instructor && item.instructor.invigilators;
                      arr.push(instructor.instructorInfo && instructor.instructorInfo.name);
                      return Object.assign(item, {
                        status: 'overlap',
                        instructor: Object.assign(item.instructor, {
                          invigilators: arr,
                        }),
                      });
                    } else return item;
                  });
                  result_timeSlots = Object.assign(result_timeSlots, {
                    [day]: dateObject_timeSlots,
                  });
                });
              });
            }
          });
        }

        if (classroom_has_any_time_slots) {
          classroom_hours.map((classroom: Types.IExamPeriod_ClassroomItem) => {
            let classroomTimeSlots: any = classroom.time_slots;
            let classroomDays = Object.keys(classroomTimeSlots);

            classroomDays.map((day: string) => {
              dateObject_timeSlots = scheduleClone[day];
              classroomTimeSlots[day].map((dataHour: string) => {
                dateObject_timeSlots = dateObject_timeSlots.map((item: any) => {
                  if (item.hour == dataHour) {
                    let arr = item && item.classroom ? item.classroom : [];
                    arr.push(classroom.classroomInfo && classroom.classroomInfo.name);
                    return Object.assign(item, {
                      status: 'overlap',
                      classroom: arr,
                    });
                  } else return item;
                });
                result_timeSlots = Object.assign(scheduleClone, result_timeSlots, {
                  [day]: dateObject_timeSlots,
                });
              });
            });
          });
        }

        if (course_has_any_time_slots) {
          course_hours.map((course: Types.IExamPeriod_ActivityItem) => {
            let courseTimeSlots: any = course.time_slots;
            let courseDays = Object.keys(courseTimeSlots);

            courseDays.map((day: string) => {
              dateObject_timeSlots = scheduleClone[day];
              courseTimeSlots[day].map((dataHour: string) => {
                dateObject_timeSlots = dateObject_timeSlots.map((item: any) => {
                  if (item.hour == dataHour) {
                    let arr = item && item.course ? item.course : [];
                    arr.push(course.activityInfo && course.activityInfo.course_name);
                    return Object.assign(item, {
                      status: 'overlap',
                      course: arr,
                    });
                  } else return item;
                });
                result_timeSlots = Object.assign(scheduleClone, result_timeSlots, {
                  [day]: dateObject_timeSlots,
                });
              });
            });
          });
        }
      }
    }

    this.setState(this.state);
    this.forceUpdate();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.state = getInitialState();
    this.setState(this.state);
    this.setClose();
  };

  getCourseHours(termType: number) {
    const resultCallback = (result: any, status: number) => {
      if (status === 200) {
        this.state.model.overlaps = result.data;
        this.createModifiedSchedule(termType);
      }
    };
    let overlapFilter: Types.IFilterClassroom = {
      term_id: undefined,
      page: 1,
      total: -1,
      course_ids: [],
      size: 10
    };
    overlapFilter.term_id = this.state.term_id;
    overlapFilter.course_id = this.state.course_id;
    if (termType == 1) {
      this.props.dispatch(
        Actions.ApiRequest(
          Constants.exam_period.EXAM_PERIOD_GET_OVERLAPS,
          overlapFilter,
          'distribution-solution-change-availability-calendar-spin',
          resultCallback
        )
      );
    } else if (termType == 0)
      this.props.dispatch(
        Actions.ApiRequest(
          Constants.exam_period.EXAM_PERIOD_COURSETERM_GET_OVERLAPS,
          overlapFilter,
          'distribution-solution-change-availability-calendar-spin',
          resultCallback
        )
      );
  }

  static getDerivedStateFromProps(props: any, state: any) {
    let hasNewState: boolean = false;

    if (
      props.selectOptions &&
      props.selectOptions.times &&
      props.selectOptions.days
    ) {
      hasNewState = true;

      state.model.calendar.times = props.selectOptions.times;
      state.model.calendar.days = props.selectOptions.days;

      let schedule: any = {};
      props.selectOptions.days.map((day: any) => {
        let newArray: any = [];
        props.selectOptions.times.map((time: any) => {
          let obj = { hour: time, isActive: true };
          newArray.push(obj);
        });
        schedule[day] = newArray;
      });

      if (schedule) {
        state.model.calendar.schedule = schedule;
        state.model.is_calendar_created = true;
      }

    }

    if (props.term_id > 0 && props.selectOptions != undefined && props.selectOptions.course_id_selected > 0) {
      hasNewState = true;
      state.term_id = props.term_id;
      state.course_id = props.selectOptions.course_id_selected;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    const appLang = localStorage.getItem('langid');
    const lang = appLang ? appLang.split('-')[0] : 'tr';
    moment.locale(lang);

    let termType = this.props && this.props.term_type > -1 ? this.props.term_type : -1;

    let { times, days } = this.state.model.calendar;
    let tableColumnHeads = null;
    let timeTableRows = null;

    if (termType == 1) {
      let localDays = days.map((day: string) => moment(day).format('D MMM ddd'));
      tableColumnHeads = localDays.map((day: string) => (
        <th key={day}>
          <div style={{ cursor: 'default' }} data-day={day}>{day}</div>
        </th>
      ));
      timeTableRows = null;

      if (this.state.course_id > 0 && this.state.term_id == this.props.term_id && Object.keys(this.state.model.initialSchedule).length !== 0)
        timeTableRows = times.map((time: string) => {
          let boxes = Array(days.length)
            .fill(undefined)
            .map((val, index) => {
              let result_jsx: JSX.Element = <div></div>;
              let day: string = days[index];
              let scheduleClone: any = this.state.model && this.state.model.initialSchedule;
              if (scheduleClone[day] !== undefined) {
                let overlapObject: {
                  hour: string;
                  status: string;
                  course: [];
                  classroom: [];
                  instructor: { invigilators: []; own_exams: [] };
                } = scheduleClone[day].find((item: any) => item.hour == time);

                if (overlapObject.status == 'overlap') {
                  if (
                    overlapObject.course.length &&
                    overlapObject.classroom.length &&
                    overlapObject.instructor.own_exams.length
                  ) {
                    result_jsx = (
                      <React.Fragment>
                        <span
                          style={{
                            backgroundColor: 'red',
                            transform: 'skew(0, -28deg)',
                            left: '49%',
                            borderLeftWidth: '1px',
                            zIndex: 3
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          data-original-title={overlapObject.course.join()}
                          title={overlapObject.course.join()}
                        />

                        <span
                          style={{
                            backgroundColor: 'blue',
                            transform: 'skew(0, 28deg)',
                            right: '49%',
                            borderRightWidth: '1px',
                            zIndex: 4
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          data-original-title={overlapObject.classroom.join()}
                          title={overlapObject.classroom.join()}
                        />

                        <span
                          style={{
                            backgroundColor: '#ffb716',
                            zIndex: 1,
                            top: '-9%',
                            width: '200%',
                            left: '-50%',
                            height: '150%'
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          data-original-title={overlapObject.instructor.own_exams.join()}
                          title={overlapObject.instructor.own_exams.join()}
                        />
                      </React.Fragment>
                    );
                  } else if (
                    overlapObject.course.length &&
                    overlapObject.classroom.length &&
                    !overlapObject.instructor.own_exams.length
                  ) {
                    result_jsx = (
                      <React.Fragment>
                        <span
                          style={{
                            backgroundColor: 'red',
                            transform: 'skew(0, 0deg)',
                            left: '49%',
                            borderLeftWidth: '1px',
                            top: '0%'
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={overlapObject.course.join()}
                        />

                        <span
                          style={{
                            backgroundColor: 'blue',
                            transform: 'skew(0, 0deg)',
                            right: '49%',
                            borderRightWidth: '1px',
                            top: '0%'
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={overlapObject.classroom.join()}
                        />
                      </React.Fragment>
                    );
                  } else if (
                    !overlapObject.course.length &&
                    overlapObject.classroom.length &&
                    overlapObject.instructor.own_exams.length
                  ) {
                    result_jsx = (
                      <React.Fragment>
                        <span
                          style={{
                            backgroundColor: 'blue',
                            transform: 'skew(0, 0deg)',
                            right: '49%',
                            borderRightWidth: '1px',
                            top: '0%'
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={overlapObject.classroom.join()}
                        />

                        <span
                          style={{
                            backgroundColor: '#ffb716',
                            transform: 'skew(0, 0deg)',
                            left: '49%',
                            borderLeftWidth: '1px',
                            top: '0%'
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={overlapObject.instructor.own_exams.join()}
                        />
                      </React.Fragment>
                    );
                  } else if (
                    overlapObject.course.length &&
                    !overlapObject.classroom.length &&
                    overlapObject.instructor.own_exams.length
                  ) {
                    result_jsx = (
                      <React.Fragment>
                        <span
                          style={{
                            backgroundColor: 'red',
                            transform: 'skew(0, 0deg)',
                            left: '49%',
                            borderLeftWidth: '1px',
                            top: '0%'
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={overlapObject.course.join()}
                        />

                        <span
                          style={{
                            backgroundColor: '#ffb716',
                            transform: 'skew(0, 0deg)',
                            right: '49%',
                            borderRightWidth: '1px',
                            top: '0%'
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={overlapObject.instructor.own_exams.join()}
                        />
                      </React.Fragment>
                    );
                  } else if (
                    overlapObject.course.length &&
                    !overlapObject.classroom.length &&
                    !overlapObject.instructor.own_exams.length
                  ) {
                    result_jsx = (
                      <React.Fragment>
                        <span
                          style={{
                            backgroundColor: 'red',
                            transform: 'skew(0, 0deg)',
                            borderRightWidth: '1px',
                            top: '0%',
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={overlapObject.course.join()}
                        />
                      </React.Fragment>
                    );
                  } else if (
                    !overlapObject.course.length &&
                    overlapObject.classroom.length &&
                    !overlapObject.instructor.own_exams.length
                  ) {
                    result_jsx = (
                      <React.Fragment>
                        <span
                          style={{
                            backgroundColor: 'blue',
                            transform: 'skew(0, 0deg)',
                            borderRightWidth: '1px',
                            top: '0%',
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={overlapObject.classroom.join()}
                        />
                      </React.Fragment>
                    );
                  } else if (
                    !overlapObject.course.length &&
                    !overlapObject.classroom.length &&
                    overlapObject.instructor.own_exams.length) {
                    result_jsx = (
                      <React.Fragment>
                        <span
                          style={{
                            backgroundColor: '#ffb716',
                            transform: 'skew(0, 0deg)',
                            borderRightWidth: '1px',
                            top: '0%',
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={overlapObject.instructor.own_exams.join()}
                        />
                      </React.Fragment>
                    );
                  }
                }

                return (
                  <td key={index}>
                    <div className="overlapping d-inline-flex align-items-center align-self-center" style={{ cursor: 'help' }}>
                      <div className="cover">
                        <div className="cover-circle">{result_jsx}</div>
                      </div>
                    </div>
                  </td>
                );
              }
            });

          return (
            <tr>
              <td className="aplan-tm-sticky-col">
                <div style={{ cursor: 'default' }} data-time={time}>{time}</div>
              </td>
              {boxes}
            </tr>
          );
        });
    } else if (termType == 0) {
      tableColumnHeads = days.map((day: any) => (
        <th key={day}>
          <div style={{ cursor: 'default' }} data-day={day}>{day}</div>
        </th>
      ));
      timeTableRows = null;

      if (this.state.course_id > 0 && this.state.term_id == this.props.term_id && Object.keys(this.state.model.initialSchedule).length !== 0)
        timeTableRows = times.map((time: string) => {
          let boxes = Array(days.length)
            .fill(undefined)
            .map((val, index) => {
              let result_jsx: JSX.Element = <div></div>;
              let day: string = days[index];
              let scheduleClone: any = this.state && this.state.model && this.state.model.initialSchedule;
              if (scheduleClone[day] !== undefined) {
                let overlapObject: {
                  hour: string;
                  status: string;
                  course: [];
                  classroom: [];
                  instructor: { invigilators: []; time_slots: [] };
                } = scheduleClone[day].find((item: any) => (item && item.hour) == time);

                if (overlapObject.status == 'overlap') {
                  if (
                    overlapObject.course.length &&
                    overlapObject.classroom.length &&
                    overlapObject.instructor.time_slots.length
                  ) {
                    result_jsx = (
                      <React.Fragment>
                        <span
                          style={{
                            backgroundColor: 'red',
                            transform: 'skew(0, -28deg)',
                            left: '49%',
                            borderLeftWidth: '1px',
                            zIndex: 3,
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          data-original-title={overlapObject.course.join()}
                          title={overlapObject.course.join()}
                        />

                        <span
                          style={{
                            backgroundColor: 'blue',
                            transform: 'skew(0, 28deg)',
                            right: '49%',
                            borderRightWidth: '1px',
                            zIndex: 4,
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          data-original-title={overlapObject.classroom.join()}
                          title={overlapObject.classroom.join()}
                        />

                        <span
                          style={{
                            backgroundColor: '#ffb716',
                            zIndex: 1,
                            top: '-9%',
                            width: '200%',
                            left: '-50%',
                            height: '150%',
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          data-original-title={overlapObject.instructor.time_slots.join()}
                          title={overlapObject.instructor.time_slots.join()}
                        />
                      </React.Fragment>
                    );
                  } else if (
                    overlapObject.course.length &&
                    overlapObject.classroom.length &&
                    !overlapObject.instructor.time_slots.length
                  ) {
                    result_jsx = (
                      <React.Fragment>
                        <span
                          style={{
                            backgroundColor: 'red',
                            transform: 'skew(0, 0deg)',
                            left: '49%',
                            borderLeftWidth: '1px',
                            top: '0%',
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={overlapObject.course.join()}
                        />

                        <span
                          style={{
                            backgroundColor: 'blue',
                            transform: 'skew(0, 0deg)',
                            right: '49%',
                            borderRightWidth: '1px',
                            top: '0%',
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={overlapObject.classroom.join()}
                        />
                      </React.Fragment>
                    );
                  } else if (
                    !overlapObject.course.length &&
                    overlapObject.classroom.length &&
                    overlapObject.instructor.time_slots.length
                  ) {
                    result_jsx = (
                      <React.Fragment>
                        <span
                          style={{
                            backgroundColor: 'blue',
                            transform: 'skew(0, 0deg)',
                            right: '49%',
                            borderRightWidth: '1px',
                            top: '0%',
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={overlapObject.classroom.join()}
                        />

                        <span
                          style={{
                            backgroundColor: '#ffb716',
                            transform: 'skew(0, 0deg)',
                            left: '49%',
                            borderLeftWidth: '1px',
                            top: '0%',
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={overlapObject.instructor.time_slots.join()}
                        />
                      </React.Fragment>
                    );
                  } else if (
                    overlapObject.course.length &&
                    !overlapObject.classroom.length &&
                    overlapObject.instructor.time_slots.length
                  ) {
                    result_jsx = (
                      <React.Fragment>
                        <span
                          style={{
                            backgroundColor: 'red',
                            transform: 'skew(0, 0deg)',
                            left: '49%',
                            borderLeftWidth: '1px',
                            top: '0%',
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={overlapObject.course.join()}
                        />

                        <span
                          style={{
                            backgroundColor: '#ffb716',
                            transform: 'skew(0, 0deg)',
                            right: '49%',
                            borderRightWidth: '1px',
                            top: '0%',
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={overlapObject.instructor.time_slots.join()}
                        />
                      </React.Fragment>
                    );
                  } else if (
                    overlapObject.course.length &&
                    !overlapObject.classroom.length &&
                    !overlapObject.instructor.time_slots.length
                  ) {
                    result_jsx = (
                      <React.Fragment>
                        <span
                          style={{
                            backgroundColor: 'red',
                            transform: 'skew(0, 0deg)',
                            borderRightWidth: '1px',
                            top: '0%',
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={overlapObject.course.join()}
                        />
                      </React.Fragment>
                    );
                  } else if (
                    !overlapObject.course.length &&
                    overlapObject.classroom.length &&
                    !overlapObject.instructor.time_slots.length
                  ) {
                    result_jsx = (
                      <React.Fragment>
                        <span
                          style={{
                            backgroundColor: 'blue',
                            transform: 'skew(0, 0deg)',
                            borderRightWidth: '1px',
                            top: '0%',
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={overlapObject.classroom.join()}
                        />
                      </React.Fragment>
                    );
                  } else if (
                    !overlapObject.course.length &&
                    !overlapObject.classroom.length &&
                    overlapObject.instructor.time_slots.length
                  ) {
                    result_jsx = (
                      <React.Fragment>
                        <span
                          style={{
                            backgroundColor: '#ffb716',
                            transform: 'skew(0, 0deg)',
                            borderRightWidth: '1px',
                            top: '0%',
                          }}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={overlapObject.instructor.time_slots.join()}
                        />
                      </React.Fragment>
                    );
                  }
                }

                return (
                  <td key={index}>
                    <div className="overlapping d-inline-flex align-items-center align-self-center" style={{ cursor: 'help' }}>
                      <div className="cover">
                        <div className="cover-circle">{result_jsx}</div>
                      </div>
                    </div>
                  </td>
                );
              }
            });
          let keyId = Math.random() * 1000

          return (
            <tr key={keyId}>
              <td className="aplan-tm-sticky-col">
                <div style={{ cursor: 'default' }} data-time={time}>{time}</div>
              </td>
              {boxes}
            </tr>
          );
        });
    }


    let OverlapsColorInfo = (
      <div className="d-inline-flex w-100 align-items-center">
        <div className="mr-auto">
          <label className="tick-radio position-relative d-inline-block pl-2 mr-2">
            <input
              type="checkbox"
              name="invigilator"
              id="invigilator"
              className="form-radio"
              style={{
                backgroundColor: 'red',
                color: 'red',
                borderColor: 'red',
                cursor: 'default'
              }}
              defaultChecked
              disabled={true}
            />
            <span className="ml-2" style={{ cursor: 'default' }}>{T.t('gen_course')}</span>
          </label>
          <label className="tick-radio position-relative d-inline-block pl-2 mr-2">
            <input
              type="checkbox"
              name="invigilator"
              id="invigilator"
              className="form-radio"
              style={{
                backgroundColor: 'blue',
                color: 'blue',
                borderColor: 'blue',
                cursor: 'default'
              }}
              defaultChecked
              disabled={true}
            />
            <span className="ml-2" style={{ cursor: 'default' }}>{T.t('gen_classrooms')}</span>
          </label>
          <label className="tick-radio position-relative d-inline-block pl-2 mr-2">
            <input
              type="checkbox"
              name="invigilator"
              id="invigilator"
              className="form-radio"
              style={{
                backgroundColor: '#ffb716',
                color: '#ffb716',
                borderColor: '#ffb716',
                cursor: 'default'
              }}
              defaultChecked
              disabled={true}
            />
            <span className="ml-2" style={{ cursor: 'default' }}>{T.t('gen_instructors')}</span>
          </label>
        </div>
      </div>
    );

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '65%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center ml-4" id="exampleModalLabel">
              {T.t('gen_availability_calendar_in_planning')}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className={'col-xl-12 col-lg-12 col-md-12 settings-dropdown-90'}>
              <Spinner name="distribution-solution-change-availability-calendar-spin" />
              <React.Fragment>{OverlapsColorInfo}</React.Fragment>
              <div className="generic-wrapper">
                {this.state.course_id > 0 &&
                  this.state.term_id == this.props.term_id &&
                  this.state.model.calendar.times.length &&
                  this.state.model.calendar.days.length &&
                  Object.keys(this.state.model.initialSchedule).length !== 0 ? (
                  <React.Fragment>
                    <div className="tab-content" id="nav-tabContent2">
                      <div
                        id="course-hours"
                        role="tabpanel"
                        aria-labelledby="course-hours-tab"
                      >
                        <div className="row mb-4">
                          <div className="col-12">
                            <div className="aplan-table-matrix-wrapper">
                              <div
                                className="aplan-table-matrix-scroller"
                                style={this.state.model.isExpanded ? { height: '100%' } : { height: '400px' }}
                              >
                                <table className="table table-borderless table-striped table-hover aplan-table-matrix mb-0">
                                  <thead>
                                    <tr>
                                      <th className="aplan-tm-sticky-col">
                                        <div style={{ cursor: 'default' }}>#</div>
                                      </th>
                                      {tableColumnHeads}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {timeTableRows}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <button
                              id='button_expand'
                              type="button"
                              className="btn btn-gray mt-2 mb-2 float-none"
                              onClick={() => {
                                this.state.model.isExpanded = !this.state.model.isExpanded;
                                this.setState(this.state);
                              }}
                            >
                              <i className="material-icons mr-2 text-left">
                                {this.state.model.isExpanded ? 'remove' : 'add'}
                              </i>
                              <span>{this.state.model.isExpanded ? T.t('gen_collapse') : T.t('gen_expand')}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <div>
                    <img
                      id='course_hours_area_info_text'
                      style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '40%'
                      }}
                      src={Announcement}
                    />
                    <br />
                    <p style={{ textAlign: 'center' }}>
                      {T.t('gen_course_hours_area_info_text')} <br /> {T.t('gen_remember_to_save_hours')}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IExamPeriod_CourseProps): Types.IExamPeriod_CourseProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IExamPeriod_CourseProps = Object.assign({}, ownProps, {
    selectOptions: store.state.select_options && store.state.select_options.distributorSolutionChangePage,
    term_id: store.state.term_id,
    term_type: store.state.term_type
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(AvailabilityCalendarModal);

export default container;