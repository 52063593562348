import * as Types from '../types';

import Translator from '../../services/translate-factory';

export const actions = {
  SETTING_RESET_PASSWORD: 'SETTING:RESET_PASSWORD',
  GET_SETTINGS: 'SETTING:GET_SETTINGS',
  GET_GENERAL_SETTINGS: 'SETTING:GET_GENERAL_SETTINGS',
  UPDATE_SETTINGS: 'SETTING:SET_SETTINGS'
};

export const EmailServiceSelectOptions = (T: Translator) => [
  { label: 'Gmail', value: 'Gmail' },
  { label: 'Mandrill', value: 'Mandrill' },
  { label: 'Outlook', value: 'Outlook' },
  { label: 'Yandex', value: 'Yandex' },
  { label: T.t('gen_other'), value: 'other' }
];

export const ClassroomInvigilatorCountSettings = (T: Translator) => [
  { label: T.t('settings_classroom_invigilator_count_settings_opt1'), value: 0 },
  { label: T.t('settings_classroom_invigilator_count_settings_opt2'), value: 1 },
  { label: T.t('settings_classroom_invigilator_count_settings_opt3'), value: 2 }
];

export const InvigilatorAssignTypes = (T: Translator) => [
  { label: T.t('settings_invigilator_assign_type_opt1'), value: 0 },
  { label: T.t('settings_invigilator_assign_type_opt2'), value: 1 }
];

export const TermExamTypes = (T: Translator) => [
  { label: T.t('gen_quiz'), value: 1 },
  { label: T.t('gen_midterm_exam'), value: 2 },
  { label: T.t('gen_midterm_makeup_exam'), value: 3 },
  { label: T.t('gen_final_exam'), value: 4 },
  { label: T.t('gen_final_makeup_exam'), value: 5 },
  { label: T.t('gen_practice_or_presentation'), value: 6 },
  { label: T.t('gen_makeup_exam'), value: 7 },
  { label: T.t('gen_single_course_exam'), value: 8 }
];

export const TermExamTypesLabel = (termExamTypeValue: number, T: Translator): string => {
  const termExamTypeObject = TermExamTypes(T).find(item => item.value === termExamTypeValue);

  return termExamTypeObject ? termExamTypeObject.label : '-';
}

export const InstructorTitles = (T: Translator) => [
  { label: T.t('gen_academic_exp'), value: 1 },
  { label: T.t('gen_coach'), value: 2 },
  { label: T.t('gen_ra'), value: 3 },
  { label: T.t('gen_ra_phd'), value: 4 },
  { label: T.t('gen_researcher'), value: 5 },
  { label: T.t('gen_marine_instructor'), value: 6 },
  { label: T.t('gen_language_instructor'), value: 7 },
  { label: T.t('gen_director'), value: 8 },
  { label: T.t('gen_assoc'), value: 9 },
  { label: T.t('gen_assoc_dr'), value: 10 },
  { label: T.t('gen_dr'), value: 11 },
  { label: T.t('gen_dr_instr'), value: 12 },
  { label: T.t('gen_education_consultant'), value: 13 },
  { label: T.t('gen_med_phys_spc'), value: 14 },
  { label: T.t('gen_lectr'), value: 15 },
  { label: T.t('gen_instr'), value: 16 },
  { label: T.t('gen_instr_dr'), value: 17 },
  { label: T.t('gen_instr_lecturer'), value: 18 },
  { label: T.t('gen_staff'), value: 19 },
  { label: T.t('gen_prof'), value: 20 },
  { label: T.t('gen_prof_dr'), value: 21 },
  { label: T.t('gen_project_adv'), value: 22 },
  { label: T.t('gen_responsible'), value: 23 },
  { label: T.t('gen_technical_exp'), value: 24 },
  { label: T.t('gen_thesis_adv'), value: 25 },
  { label: T.t('gen_exp'), value: 26 },
  { label: T.t('gen_exp_dr'), value: 27 },
  { label: T.t('gen_exp_psyc'), value: 28 }
];

export const InstructorTitlesLabel = (titleValue: number, T: Translator): string => {
  const titleObject = InstructorTitles(T).find(title => title.value === titleValue);

  return titleObject ? titleObject.label : "-";
}

export const ActivityTypes = (T: Translator) => [
  { label: T.t('gen_theoric'), value: 1 },
  { label: T.t('gen_application'), value: 2 },
  { label: T.t('gen_laboratory'), value: 3 },
  { label: T.t('gen_problem_solving'), value: 4 },
  { label: T.t('gen_internship'), value: 5 },
  { label: T.t('gen_practice'), value: 6 },
  { label: T.t('gen_break'), value: 7 },
  { label: T.t('gen_thesis'), value: 8 },
  { label: T.t('gen_seminar'), value: 9 },
  { label: T.t('gen_preparatory_class'), value: 10 },
  { label: T.t('gen_scientific_preparation'), value: 11 },
  { label: T.t('gen_thesis_research'), value: 12 },
  { label: T.t('gen_phd_qualification'), value: 13 },
  { label: T.t('gen_project'), value: 14 }
].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));;

export const ActivityTypesLabel = (activityTypeValue: number, T: Translator): string => {
  const activityTypeObject = ActivityTypes(T).find(activityType => activityType.value === activityTypeValue);

  return activityTypeObject ? activityTypeObject.label : '-';
}

export const ClassroomFeatures = (T: Translator) => [
  { label: T.t('gen_projector'), value: 1 },
  { label: T.t('gen_smartboard'), value: 2 },
  { label: T.t('gen_dvd'), value: 3 },
  { label: T.t('gen_sound_system'), value: 4 },
  { label: T.t('gen_computer'), value: 5 },
  { label: T.t('gen_internet_access'), value: 6 },
  { label: T.t('gen_fixed_curtain'), value: 7 },
  { label: T.t('gen_video'), value: 8 },
  { label: T.t('gen_air_conditioner'), value: 9 }
].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));;

export const ClassroomFeaturesLabel = (classroomFeatureValue: number, T: Translator): string => {
  const classroomFeatureObject = ClassroomFeatures(T).find(classroomFeature => classroomFeature.value === classroomFeatureValue);

  return classroomFeatureObject ? classroomFeatureObject.label : '-';
}

export const ResetPasswordFormInitialValues: Types.IResetPassword = {
  currentPassword: '',
  newPassword: '',
  newPasswordConfirm: ''
};

export const SettingsPageRoutes = {
  ChangePassword: '/password'
};
