import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Log } from 'ng2-logger';
import { Formik, FormikActions, FormikProps } from 'formik';
import * as Actions from '../../../store/actions/general';
import * as Constants from '../../../store/constants/all';
import * as Types from '../../../store/types';
import MainLayout from '../../layouts/main-layout';
import APlanHeader from '../../../components/templates/aplan-header';
import Spinner from '../../../components/templates/spinner';
import { ActivityRelationSearchInitialValues, ActivityRelations, ActivityRelationsTypeIds } from '../../../store/constants/course-period-const';
import { ActivityTermLectureLocations, ActivityTypesLabel } from '../../../store/constants/activity-const';
import { TitlesLabel } from '../../../store/constants/instructor-const';
import ActivityAddModal from './activity-add-modal';
import ActivityDeleteModal from './activity-delete-modal';
import Translator from '../../../services/translate-factory';
import Paginate from '../../../components/table/paginate';
import { EducationTypeSelectOptions } from '../../../store/constants/course-const';

const T = Translator.create();
const Logger = Log.create('SolutionPage');

function getInitialState(): any {
  const initialValues: any = {
    filters: ActivityRelationSearchInitialValues,
    filterIsOpen: false,
    integrationModalIsOpen: false,
    activityFormIsOpen: false,
    activityNo: undefined,
    selected_ids: [],
    all_ids: [],
    activeTabIndex: 0,
    activeTab: ActivityRelations.Synchronous,
    selectedRelationId: -1,
    selectedActivityRelation: [],
    tabManager: [
      { key: ActivityRelations.Synchronous, value: true },
      { key: ActivityRelations.Asynchronous, value: false },
      { key: ActivityRelations.Conflictable, value: false },
      { key: ActivityRelations.Consecutive, value: false },
      { key: ActivityRelations.NonConsecutive, value: false },
      { key: ActivityRelations.SameDay, value: false },
      { key: ActivityRelations.DifferentDay, value: false },
      { key: ActivityRelations.DifferentDaySameTime, value: false }
    ]
  };
  return Object.assign({}, initialValues);
}

class ActivityRelationsPage extends Component<any, any> {
  state: any = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.searchActivityRelation(0);
    moment.locale('tr');
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  searchActivityRelation(activeTabIndex: number) {
    this.state.filters.activity_relation_type = activeTabIndex;
    this.props.dispatch(
      Actions.ApiRequest(Constants.course_period.ACTIVITY_RELATION_SEARCH, this.state.filters, 'activity-relations-spinner')
    );
  }

  getProgramsByFaculties = (facultyIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.activity.ACTIVITY_GET_PROGRAMS_BY_FACULTIES, facultyIds, 'activity-relations-spinner'));
  }

  selectActiveTab = (tabName: string) => {
    let tabs =
      this.state.tabManager &&
      this.state.tabManager.map((tab: any) => {
        return tab.key == tabName ? { key: tab.key, value: true } : { key: tab.key, value: false };
      });

    let activeTab = tabs && tabs.find((item: any) => item.value == true);
    let relationType = ActivityRelationsTypeIds.find((item: any) => item.label == activeTab.key);
    let activeTabIndex = relationType && relationType.value;
    let tabConstant = activeTab && activeTab.key;

    this.searchActivityRelation(activeTabIndex);

    if (this.state.selectedRelationId != -1) {
      this.setState({
        selectedRelationId: -1,
      });
    }

    this.setState({
      tab_reloaded: true,
      tabManager: tabs,
      activeTab: tabConstant,
      activeTabIndex
    });
  };

  isActiveTab = (tabName: string) => {
    let activeTab = this.state.tabManager && this.state.tabManager.find((item: any) => item.key == tabName);
    if (activeTab) {
      return activeTab.value;
    } else return false;
  };

  displayLectureStaff = (lectureStaffObject: any): string => {
    const staffNames = lectureStaffObject.map((item: any) => TitlesLabel(item.title, T) + ' ' + item.name);
    const namesString = staffNames.join(', ');

    return namesString;
  };

  deleteRelation = () => {
    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_activity_relations'),
        body: T.t('gen_activity_relation_delete_question'),
        name: 'activity_relations',
        icon: 'warning',
        iconColor: 'red',
        confirm: T.t('gen_yes'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
            if (status == 200) {
              this.searchActivityRelation(this.state.activeTabIndex);
              this.setState({
                ...this.state,
                selectedRelationId: -1
              });
            }
          };
          this.props.dispatch(
            Actions.ApiRequest(
              Constants.course_period.DELETE_ACTIVITY_RELATION,
              this.state.selectedRelationId,
              'activity-relations-spinner',
              resultCallback
            )
          );
        }
      })
    );
  };

  onInputChange = (inputValue: string, action: any) => {
    if (action.action === "input-change") {
      this.state.course_codes_filtered = this.props.selectOptions && this.props.selectOptions.course_codes.filter((item: any) => item.label.toLowerCase().includes(inputValue.toLowerCase()));
    }

    if (action.action === "set-value" && this.state.course_codes_filtered == undefined) {
      this.state.course_codes_filtered = this.props.selectOptions && this.props.selectOptions.course_codes;
    }

    if (action.action === "menu-close" && this.state.course_codes_filtered != undefined) {
      this.state.course_codes_filtered = undefined;
    }
  };

  filterOption = (option: Types.ISelectOption, inputValue: string) => {
    if (option.value === -1) {
      return true;
    } else if (inputValue) {
      return option.label.toLowerCase().includes(inputValue.toLowerCase());
    } else {
      return true;
    }
  };

  static getDerivedStateFromProps(props: any, state: any) {
    let hasNewState: boolean = false;

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.searchActivityRelation(this.state.activeTabIndex);
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, ActivityRelationSearchInitialValues);
    this.setState(this.state);
    this.searchActivityRelation(this.state.activeTabIndex);
  };

  onFilterActivity(model: Types.IFilterActivityRelation, FormActions: FormikActions<Types.IFilterActivityRelation>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.searchActivityRelation(this.state.activeTabIndex);
    FormActions.setSubmitting(false);
  }

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.searchActivityRelation(this.state.activeTabIndex);
  };

  render() {
    const courseCodeOptions = this.props.selectOptions && this.props.selectOptions.course_codes ? this.props.selectOptions.course_codes : []
    const courseCodeSelectOptions = [{ label: T.t('gen_select_all'), value: -1 }, ...courseCodeOptions];

    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const allSelectOptions = [...ActivityTermLectureLocations(T), ...campusOptions].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));
    return (
      <MainLayout header={<APlanHeader />}>
        <div className="main list-campus">
          <Spinner name="activity-relations-spinner" />
          <div className="main editor-screen-main" style={{ display: 'block' }}>
            <div
              className="white-container mt-4 editor-screen collapse editor-screen"
              style={{ display: 'block', minHeight: '100vh' }}
            >
              <div className="row">
                <div className="col-12">
                  {this.props.user && this.props.user.role !== 'e' && this.props.user.role !== 'p' && (
                    <nav className="inner-page-nav">
                      <div className="nav nav-tabs nav-fill mb-4" id="nav-tab" role="tablist">
                        <a
                          className="nav-item nav-link active"
                          onClick={() => this.selectActiveTab(ActivityRelations.Synchronous)}
                          id="dates-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                        >
                          {T.t('gen_activity_sync')}
                        </a>
                        <a
                          className="nav-item nav-link"
                          onClick={() => this.selectActiveTab(ActivityRelations.Asynchronous)}
                          id="classrooms-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                        >
                          {T.t('gen_activity_async')}
                        </a>
                        <a
                          className="nav-item nav-link"
                          onClick={() => this.selectActiveTab(ActivityRelations.Conflictable)}
                          id="classrooms-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                        >
                          {T.t('gen_activity_conflictable')}
                        </a>
                        <a
                          className="nav-item nav-link"
                          onClick={() => this.selectActiveTab(ActivityRelations.Consecutive)}
                          id="course-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                        >
                          {T.t('gen_activity_consecutive')}
                        </a>
                        <a
                          className="nav-item nav-link"
                          onClick={() => this.selectActiveTab(ActivityRelations.NonConsecutive)}
                          id="personnals-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                        >
                          {T.t('gen_activity_unconsecutive')}
                        </a>
                        <a
                          className="nav-item nav-link"
                          onClick={() => this.selectActiveTab(ActivityRelations.SameDay)}
                          id="exam-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                        >
                          {T.t('gen_activity_same_day')}
                        </a>
                        <a
                          className="nav-item nav-link"
                          onClick={() => this.selectActiveTab(ActivityRelations.DifferentDay)}
                          id="exam-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                        >
                          {T.t('gen_activity_diff_day')}
                        </a>
                        <a
                          className="nav-item nav-link"
                          onClick={() => this.selectActiveTab(ActivityRelations.DifferentDaySameTime)}
                          id="exam-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                        >
                          {T.t('gen_activity_diff_day_same_time')}
                        </a>
                      </div>
                    </nav>
                  )}
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="singleTables"
                      role="tabpanel"
                      aria-labelledby="singleTables-tab"
                    >
                      <React.Fragment>
                        {
                          <div className="row align-items-center mb-sm-4 mb-2">
                            <div className="col-md-8 col-sm-8 col-12">
                              {this.state.selectedRelationId == -1 ? (
                                <React.Fragment>
                                  <button
                                    className="category-tag-square tag-green"
                                    onClick={() => this.setState({ addModalIsOpen: !this.state.addModalIsOpen })}
                                  >
                                    <i className="material-icons mr-2">playlist_add</i>
                                    <span>{T.t('gen_add_activity_relation')}</span>
                                  </button>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <button
                                    className="category-tag-square tag-blue"
                                    onClick={() => this.setState({ addModalIsOpen: !this.state.addModalIsOpen })}
                                  >
                                    <i className="material-icons mr-2">call_merge</i>
                                    <span>{T.t('gen_new_activity_add')}</span>
                                  </button>
                                  <button
                                    className="category-tag-square tag-red ml-3"
                                    onClick={() => this.setState({ deleteModalIsOpen: !this.state.deleteModalIsOpen })}
                                  >
                                    <i className="material-icons mr-2">delete_outline</i>
                                    <span>{T.t('gen_activity_remove_from_relation')}</span>
                                  </button>
                                  <button
                                    className="category-tag-square tag-red ml-3"
                                    onClick={() => this.deleteRelation()}
                                  >
                                    <i className="material-icons mr-2">delete</i>
                                    <span>{T.t('gen_activity_delete_relation')}</span>
                                  </button>
                                </React.Fragment>
                              )}
                            </div>
                            <div className="col-md-4 col-sm-4 col-12 text-right">
                              <div className="options d-md-inline-flex d-lg-inline-flex align-items-center">
                                <button
                                  className="category-tag-square tag-glass float-right ml-3 mr-3"
                                  style={{ margin: '5px' }}
                                  onClick={() => {
                                    this.state.filterIsOpen = !this.state.filterIsOpen;
                                    this.setState(this.state);
                                  }}
                                >
                                  <i className="material-icons mr-2">filter_list</i>
                                  <span>{T.t('gen_filter')}</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        }
                        <div
                          className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                          id="advance-search"
                        >
                          <div className="advance-search d-block mt-3">
                            <Formik
                              initialValues={ActivityRelationSearchInitialValues}
                              enableReinitialize={true}
                              onSubmit={(values, actions) => {
                                this.onFilterActivity(values, actions);
                              }}
                              onReset={this.onFormReset}
                            >
                              {(props: FormikProps<Types.IFilterActivityRelation>) => {
                                return (
                                  <form onSubmit={props.handleSubmit}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h6>{T.t('gen_filter_parameters')}</h6>
                                      </div>
                                      <div className="col-md-2">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_activity_no')}</label>
                                            <Select
                                              id='select_activty_no'
                                              className="react-select"
                                              isMulti={true}
                                              closeMenuOnSelect={false}
                                              options={
                                                this.props.selectOptions && this.props.selectOptions.activity_noes
                                                  ? this.props.selectOptions.activity_noes
                                                  : []
                                              }
                                              placeholder={T.t('gen_select_activity_no')}
                                              value={props.values.activity_noes}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('activity_noes', list);
                                                props.setFieldValue(
                                                  'activity_noes_ids',
                                                  list.map((item) => item.value)
                                                );
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_activity')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_course_code')}</label>
                                            <Select
                                              id='select_course_code'
                                              className="react-select"
                                              isMulti={true}
                                              closeMenuOnSelect={false}
                                              options={courseCodeSelectOptions}
                                              placeholder={T.t('gen_select_course_code')}
                                              value={props.values.course_codes}
                                              filterOption={this.filterOption}
                                              onInputChange={this.onInputChange}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('course_codes', list.map(item => item.value).includes(-1) ? this.state.course_codes_filtered! : list);
                                                props.setFieldValue(
                                                  'course_codes_ids',
                                                  list.map(item => item.value).includes(-1) ? this.state.course_codes_filtered!.map((item: any) => item.value) : list.map(item => item.value)
                                                );
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_activity')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="form-input form-group date-picker">
                                          <input
                                            id="course_name"
                                            name="course_name"
                                            value={props.values.course_name}
                                            onChange={props.handleChange}
                                            type="text"
                                            required
                                          />
                                          <span className="highlight" />
                                          <span className="bar" />
                                          <label htmlFor="name">{T.t('gen_name')}</label>
                                          <i className="material-icons">title</i>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_instructors')}</label>
                                            <Select
                                              className="react-select"
                                              isMulti={true}
                                              filterOption={(option: any, query: any) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                              }
                                              closeMenuOnSelect={false}
                                              options={
                                                this.props.selectOptions && this.props.selectOptions.instructors
                                                  ? this.props.selectOptions.instructors
                                                  : []
                                              }
                                              placeholder={T.t('gen_select_instructor')}
                                              value={props.values.instructors}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('instructors', list);
                                                props.setFieldValue(
                                                  'instructor_ids',
                                                  list.map((item) => item.value)
                                                );
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-xs-12">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_faculty')}</label>
                                            <Select
                                              id='select_faculty'
                                              className="react-select"
                                              isMulti={true}
                                              closeMenuOnSelect={true}
                                              options={
                                                this.props.selectOptions && this.props.selectOptions.faculties
                                                  ? this.props.selectOptions.faculties
                                                  : []
                                              }
                                              placeholder={T.t('gen_select_faculty')}
                                              value={props.values.faculties ? props.values.faculties : null}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('faculties', list);
                                                props.setFieldValue(
                                                  'faculty_ids',
                                                  list.map((item) => item.value)
                                                );
                                                props.setFieldValue('programs', []);
                                                props.setFieldValue('program_ids', []);
                                                this.getProgramsByFaculties(list.map(item => item.value))
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_faculty')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-5">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_program')}</label>
                                            <Select
                                              className="react-select"
                                              isMulti={true}
                                              filterOption={(option: any, query: any) =>
                                                option.label && option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                              }
                                              closeMenuOnSelect={false}
                                              options={this.props.programs_related_faculty && this.props.programs_related_faculty.length > 0 ? this.props.programs_related_faculty :
                                                this.props.selectOptions && this.props.selectOptions.programs
                                                  ? this.props.selectOptions.programs
                                                  : []}
                                              placeholder={T.t('gen_select_program')}
                                              value={props.values.programs ? props.values.programs : null} d
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('programs', list);
                                                props.setFieldValue(
                                                  'program_ids',
                                                  list.map((item) => item.value)
                                                );
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_program')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_lesson_location')}</label>
                                            <Select
                                              className="react-select"
                                              isMulti={true}
                                              filterOption={(option: any, query: any) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                              }
                                              closeMenuOnSelect={false}
                                              options={allSelectOptions}
                                              placeholder={T.t('gen_select_location')}
                                              value={props.values.lecture_location ? props.values.lecture_location : null}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('lecture_location', list);
                                                props.setFieldValue(
                                                  'lecture_location_ids',
                                                  list.map((item) => item.value)
                                                );
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <hr />
                                    <div className="row mt-3">
                                      <div className="col-6">
                                        <button
                                          type="button"
                                          onClick={() => {
                                            this.state.filterIsOpen = false;
                                            this.setState(this.state);
                                          }}
                                          className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 btn-gray btn"
                                        >
                                          <i className="material-icons">arrow_upward</i>
                                        </button>
                                        <button
                                          type="reset"
                                          onClick={props.handleReset}
                                          className="mw-none btn btn-danger mt-md-0 mt-2 mb-md-0 mb-2 ml-2"
                                        >
                                          <i className="material-icons">delete_sweep</i>
                                        </button>
                                      </div>
                                      <div className="col-6 text-right">
                                        <button
                                          type="button"
                                          className="btn btn-blue mt-md-0 mt-2 mb-md-0 mb-2"
                                          onClick={() => props.handleSubmit()}
                                          disabled={props.isSubmitting}
                                        >
                                          <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                );
                              }}
                            </Formik>
                          </div>
                        </div>
                        <div className="generic-wrapper">
                          <div className="small-wrapper">
                            <div className="shortened-list">
                              {
                                this.props.activity_relations && this.props.activity_relations.map((item: any, index: any) => (
                                  <div key={index} className="d-inline-flex w-100 mb-2"
                                    style={{
                                      backgroundColor:
                                        this.state.selectedRelationId == item.activity_relation_id
                                          ? '#ffb4b47a'
                                          : '#d3d3d380'
                                    }}
                                  >
                                    <div
                                      className="sl-item align-items-center align-self-center w-100 pt-2 pb-2 pl-2 pr-2"
                                      data-id={item.activityRelationId}
                                      onClick={() => {
                                        const id =
                                          this.state.selectedRelationId == item.activity_relation_id
                                            ? -1
                                            : item.activity_relation_id;
                                        this.setState({
                                          ...this.state,
                                          selectedRelationId: id,
                                          selectedActivityRelation: item
                                        });
                                      }}
                                      key={item.activity_relation_id}
                                    >
                                      <React.Fragment>
                                        <div className="row">
                                          <div className="col-12">
                                            <table className="aplan-table aplan-table-responsive table table-borderless table-striped filter-table">
                                              <thead>
                                                <tr>
                                                  <td scope="row" className="activityrel-td col-1">
                                                    {T.t('gen_package_name')}:
                                                    <button className="multiple-info-tag small-tag group-ellipsis">
                                                      {item.package_name ? item.package_name : "-"}
                                                    </button>
                                                  </td>
                                                  <td
                                                    className="col-2"
                                                    data-cell="descriptions"
                                                    id={'description-popover'}
                                                  >
                                                    {T.t('gen_description')}:
                                                    <button className="multiple-info-tag small-tag group-ellipsis">
                                                      {item && item.description ? item.description : '-'}
                                                    </button>
                                                  </td>
                                                  <td className="col-9"></td>
                                                </tr>
                                              </thead>
                                            </table>
                                            <table className="aplan-table aplan-table-responsive table table-borderless table-striped filter-table">
                                              <thead>
                                                <tr>
                                                  <th scope="col" className="activityrel-th text-center">
                                                    {T.t('gen_activity_no')}
                                                  </th>
                                                  <th scope="col" className="activityrel-th text-center">
                                                    {T.t('gen_course_code')}
                                                  </th>
                                                  <th scope="col" className="activityrel-th text-center">
                                                    {T.t('gen_course_name')}
                                                  </th>
                                                  <th scope="col" className="activityrel-th text-center">
                                                    {T.t('gen_activity_type')}
                                                  </th>
                                                  <th scope="col" className="activityrel-th text-center">
                                                    {T.t('gen_lesson_count')}
                                                  </th>
                                                  <th scope="col" className="activityrel-th text-center">
                                                    {T.t('gen_lesson_location')}
                                                  </th>
                                                  <th scope="col" className="activityrel-th text-center">
                                                    {T.t('gen_education_type')}
                                                  </th>
                                                  <th scope="col" className="activityrel-th text-center">
                                                    {T.t('gen_faculty')}
                                                  </th>
                                                  <th scope="col" className="activityrel-th text-center">
                                                    {T.t('gen_program')}
                                                  </th>
                                                  <th scope="col" className="activityrel-th text-center">
                                                    {T.t('gen_grade')}
                                                  </th>
                                                  <th scope="col" className="activityrel-th text-center">
                                                    {T.t('gen_section')}
                                                  </th>
                                                  <th scope="col" className="activityrel-th text-center">
                                                    {T.t('gen_instructors')}
                                                  </th>
                                                  <th scope="col" className="activityrel-th text-center">
                                                    {T.t('gen_student_count')}
                                                  </th>
                                                  <th scope="col" className="activityrel-th text-center">
                                                    {T.t('gen_week')}
                                                  </th>
                                                  <th scope="col" className="activityrel-th text-center">
                                                    {T.t('gen_course_type')}
                                                  </th>
                                                  <th scope="col" className="activityrel-th text-center">
                                                    {T.t('gen_description')}
                                                  </th>
                                                </tr>
                                              </thead>
                                              {item.related_activities_view && item.related_activities_view.map((activity: any, index: any) => (
                                                <tbody key={index}>
                                                  <tr>
                                                    <td scope="row" className="activityrel-td text-center">
                                                      {activity.activity_no}
                                                    </td>
                                                    <td scope="row" data-label={T.t('gen_code')} className="text-center">
                                                      <div className="table-scrollable-td">{activity.course_code ? activity.course_code.split(';').join(', ') : '-'}</div>
                                                    </td>
                                                    <td scope="row" data-label={T.t('gen_name')} className="text-center">
                                                      <div className="table-scrollable-td">{activity.course_name ? activity.course_name.split(';').join(', ') : '-'}</div>
                                                    </td>
                                                    <td scope="row" className="activityrel-td text-center">
                                                      {ActivityTypesLabel(activity.activity_type, T)}
                                                    </td>
                                                    <td scope="row" className="activityrel-td text-center">
                                                      {activity.lesson_count}
                                                    </td>
                                                    <td scope="row" className="activityrel-td text-center">
                                                      {activity.lecture_location && activity.lecture_location.label}
                                                    </td>
                                                    <td scope="row" className="activityrel-td text-center">
                                                      {activity.education_type === EducationTypeSelectOptions(T)[0].value ?
                                                        EducationTypeSelectOptions(T)[0].label :
                                                        activity.education_type === EducationTypeSelectOptions(T)[1].value ?
                                                          EducationTypeSelectOptions(T)[1].label :
                                                          activity.education_type === EducationTypeSelectOptions(T)[2].value ?
                                                            EducationTypeSelectOptions(T)[2].label : activity.education_type
                                                      }
                                                    </td>
                                                    <td className="text-center">
                                                      {
                                                        activity && activity.faculties && activity.faculties.length > 0 ?
                                                          <div className="table-scrollable-td">{activity.faculties && activity.faculties.map((i: any, index: any) => (index == activity!.faculties!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                                      }
                                                    </td>
                                                    <td className="text-center">
                                                      {
                                                        activity && activity.programs && activity.programs.length > 0 ?
                                                          <div className="table-scrollable-td">{activity.programs && activity.programs.map((i: any, index: any) => (index == activity!.programs!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                                      }
                                                    </td>
                                                    <td className="text-center">
                                                      {
                                                        activity && activity.classes && activity.classes.length > 0 ?
                                                          <div className="table-scrollable-td">{activity.classes && activity.classes.map((i: any, index: any) => (index == activity!.classes!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                                      }
                                                    </td>
                                                    <td className="text-center">
                                                      {
                                                        activity && activity.sections && activity.sections.length > 0 ?
                                                          <div className="table-scrollable-td">{activity.sections && activity.sections.map((i: any, index: any) => (index == activity!.sections!.length - 1) ? <>{i ? i : '-'}<br /></> : <>{i ? i : '-'},<br /></>)}</div> : '-'
                                                      }
                                                    </td>
                                                    <td className="text-center">
                                                      {
                                                        activity && activity.instructors && activity.instructors.length > 0 ?
                                                          <div className="table-scrollable-td">{activity.instructors && activity.instructors.map((i: any, index: any) => (index == activity!.instructors!.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                                      }
                                                    </td>
                                                    <td scope="row" className="activityrel-td text-center">
                                                      {activity.student_count}
                                                    </td>
                                                    <td scope="row" className="activityrel-td text-center">
                                                      {activity.week}
                                                    </td>
                                                    <td scope="row" className="activityrel-td text-center">
                                                      {activity.course_type}
                                                    </td>
                                                    <td data-label={T.t('gen_description')} className="text-center">
                                                      {activity && activity.description && activity.description.length ?
                                                        <div className="table-scrollable-td">{activity.description}</div> : '-'}
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              ))}
                                            </table>
                                          </div>
                                        </div>
                                      </React.Fragment>
                                      <div className="align-items-center align-self-center ml-auto sidebar-controls"></div>
                                    </div>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                          <div className="row-options justify-content-end">
                            <div
                              className="page-sorting d-flex align-items-center justify-content-center"
                              style={{ marginTop: '5px' }}
                            >
                              {
                                this.props.activity_relations && this.props.activity_relations.length > 0 && (
                                  <Paginate filters={this.props.filtersPagination} onPageChange={this.onPageChange} />
                                )}
                            </div>
                          </div>
                        </div>
                        <ActivityAddModal
                          selectedActivityNo={this.state.selectedRelationId}
                          selectedActivityRelation={this.state.selectedActivityRelation}
                          modalIsOpen={this.state.addModalIsOpen}
                          onClose={() => this.setState({ addModalIsOpen: !this.state.addModalIsOpen })}
                          onUpdateList={() => this.searchActivityRelation(this.state.activeTabIndex)}
                          activityRelationType={this.state.activeTabIndex}
                        />
                        <ActivityDeleteModal
                          selectedActivityNo={this.state.selectedRelationId}
                          selectedActivityRelation={this.state.selectedActivityRelation}
                          modalIsOpen={this.state.deleteModalIsOpen}
                          onClose={() => this.setState({ deleteModalIsOpen: !this.state.deleteModalIsOpen })}
                          onUpdateList={() => this.searchActivityRelation(this.state.activeTabIndex)}
                          activityRelationType={this.state.activeTabIndex}
                        />
                      </React.Fragment>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store) {
    return ownProps;
  }

  const newProps: any = Object.assign({}, ownProps, {
    model: store.state.solution_page && store.state.solution_page.solution,
    filters: store.state.solution_page && store.state.solution_page.filters,
    user: store.state.user,
    activity_relations: store.state.activity_relation_page && store.state.activity_relation_page.activity_relations,
    filtersPagination: store.state.activity_relation_page && store.state.activity_relation_page.filters,
    programs_related_faculty: store.state.select_options && store.state.select_options.programs_related_faculty,
    selectOptions: store.state.select_options && store.state.select_options.activityPage
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.course_period_page) {
    return !!equal(
      prev.state.activity_relation_page && prev.state.activity_relation_page.activity_relations,
      next.state.activity_relation_page && next.state.activity_relation_page.activity_relations
    ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.programs_related_faculty,
        next.state.select_options && next.state.select_options.programs_related_faculty
      );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ActivityRelationsPage);

export default container;
