import { Log } from 'ng2-logger';
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import * as Actions from '../../../store/actions/general';
import { routes as Routes } from '../../../store/constants/routes';
import * as Types from '../../../store/types';
import * as GT from '../../../tools/general-tools';
import Translator from '../../../services/translate-factory';
import * as Constants from '../../../store/constants/all';
const T = Translator.create();
const L = Log.create('TermSelectorModal');

class TermSelectorModal extends React.Component<Types.ILoginPageProps, Types.ITermSelectorModalState> {
  state: Types.ITermSelectorModalState = {
    termSelectOptions: []
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.state.selectedTerm = this.state.termSelectOptions.find((t) => t.value == this.props.publicSolution);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  static getDerivedStateFromProps(props: any, state: Types.ITermSelectorModalState) {
    let hasNewState: boolean = false;
    if (props.publicSolution && props.publicSolution.length > 0) {
      hasNewState = true;
      state.termSelectOptions = props.publicSolution.map(
        (term: any): Types.ISelectOption => {
          return {
            label: `${term.year} ${GT.GetAcademicTermText(term.academic_term)} ${GT.GetTermTypeText(
              term.term_type
            )} / ${term.name} (${term.termId})`,
            value: term.solutionId
          };
        }
      );
    }
    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  onTermChange = (item: any) => {
    const term = this.props.publicSolution && this.props.publicSolution.find((term: any) => term.solutionId === item.value);
    const term_type = term ? term.term_type : -1;
    const solution_id = term ? term.solutionId : -1;
    const term_id = term ? term.termId : -1;
    const institutionName = term ? term.institutionName : '';

    this.state.selectedTerm = item;
    this.setState(this.state);
    if (this.props.onTermChange) {
      this.props.onTermChange(term_id, term_type, solution_id, institutionName);
    }
  };

  onAddCoursePeriod = () => {
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.COURSE_PERIODS) + '/create'));
  };

  onAddExamPeriod = () => {
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.EXAM_PERIODS) + '/create'));
  };

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 col-md-12 col-sm-12">
            {T.t('gen_help_academic_plans')}
            <div className="react-select-container mt-2">
              <Select
                className="react-select"
                placeholder={T.t('gen_select_term')}
                noOptionsMessage={(): string => T.t('gen_select_no_term')}
                filterOption={(option: any, query: any) =>
                  option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                }
                options={this.state.termSelectOptions}
                value={this.state.selectedTerm}
                onChange={this.onTermChange}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IPageProps): Types.IPageProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
    publicSolution: store.state.solution_page && store.state.solution_page.publicSolution,
  });
  return newProps;
};

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(TermSelectorModal);

export default container;
