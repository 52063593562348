import moment from 'moment';
import 'moment/locale/tr';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../components/templates/spinner';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import {
  CalendarModalSearchInitialValues,
  CourseHoursDataModelValues,
  CourseHoursInitialValues
} from '../../store/constants/exam-period-const';
import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';
import * as GT from '../../tools/general-tools';

const T = Translator.create();
const equal = require('deep-equal');
const Logger = Log.create('ExamDates');

function getInitialState(): Types.IExamPeriod_Course_TabState {
  const initialValues: Types.IExamPeriod_Course_TabState = {
    courseIsSelected: false,
    model: Object.assign({}, CourseHoursInitialValues),
    dataModel: Object.assign({}, CourseHoursDataModelValues),
    filters: Object.assign({}, CalendarModalSearchInitialValues),
    instructor_ids: []
  };
  return Object.assign({}, initialValues);
}

class Overlaps extends Component<Types.IExamPeriod_CourseProps, Types.IExamPeriod_Course_TabState> {
  state: Types.IExamPeriod_Course_TabState = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e);
      }
    }, 1000);
  };
  componentDidMount() {
    moment.locale('tr');
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
    let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.model.term_id = id;
    this.state.filters.term_id = id;
    this.setState(this.state);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.courseIsSelected && prevState.courseIsSelected) {
      this.state.model.is_calendar_created = !this.state.courseIsSelected;
    }

    if (
      !this.state.model.is_calendar_created &&
      this.state.model.calendar.days.length &&
      this.state.model.calendar.times.length &&
      Object.keys(this.state.model.calendar.schedule).length
    ) {
      this.state.courseIsSelected = false;
      this.createModifiedSchedule();
    }
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.props.dispatch(
      Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_OVERLAPS, {
        reset: true
      })
    );
  }

  getCourseHours() {
    this.props.dispatch(
      Actions.ApiRequest(Constants.exam_period.EXAM_PERIOD_GET_EXAM_HOURS, this.state.filters, 'ep-course-list')
    );
  }

  createModifiedSchedule() {
    let scheduleClone: any = Object.assign({}, this.state.model.calendar.schedule);
    let daysClone: any = Object.assign([], this.state.model.calendar.days);
    let test_schedule: any = {};

    daysClone.map((day: any) => {
      let newArray: any = [];
      scheduleClone[day].map((item: any) => {
        let obj = {
          hour: item.hour,
          status: item.isActive == true ? 'empty' : 'disabled',
          course: [],
          instructor: {
            own_exams: [],
            invigilators: []
          },
          classroom: []
        };
        newArray.push(obj);
      });
      test_schedule[day] = newArray;
    });

    this.state.model.calendar.schedule = test_schedule;
    this.state.model.initialSchedule = test_schedule;
    this.state.model.is_calendar_created = true;
    this.setState(this.state);
    this.convertDataToSchedule();
  }

  convertDataToSchedule() {
    let overlaps: any = this.state.model.overlaps && this.state.model.overlaps;
    let classroom_hours = overlaps['classroom'];
    let instructor_hours = overlaps['instructor'];
    let course_hours = overlaps['course'];

    let instructor_has_any_time_slots =
      instructor_hours &&
      instructor_hours.length > 0 &&
      instructor_hours.some(
        (item: any) =>
          (item.time_slots && Object.keys(item.time_slots).length) ||
          (item.invigilation_time_slots && Object.keys(item.invigilation_time_slots).length)
      );

    let classroom_has_any_time_slots =
      classroom_hours &&
      classroom_hours.length > 0 &&
      classroom_hours.some((item: any) => item.time_slots && Object.keys(item.time_slots).length);

    let course_has_any_time_slots =
      course_hours &&
      course_hours.length > 0 &&
      course_hours.some((item: any) => item.time_slots && Object.keys(item.time_slots).length);

    let dateObject_timeSlots: any = {};
    let result_timeSlots: any = {};
    let scheduleClone: any = this.state.model.calendar.schedule;

    if (instructor_has_any_time_slots) {
      instructor_hours.map((instructor: Types.IExamPeriod_InstructorItem) => {
        if (instructor.time_slots && Object.keys(instructor.time_slots).length) {
          let examTimeSlots: any = instructor.time_slots;
          let examDays = Object.keys(examTimeSlots);

          examDays.map((day: string) => {
            dateObject_timeSlots = scheduleClone[moment(day).format('YYYY-MM-DD')];
            examTimeSlots[day].map((dataHour: string) => {
              dateObject_timeSlots = dateObject_timeSlots.map((item: any) => {
                if (item.hour == dataHour) {
                  let arr = item && item.instructor && item.instructor.own_exams;
                  arr.push(instructor.instructorInfo && instructor.instructorInfo.name);
                  return Object.assign(item, {
                    status: 'overlap',
                    instructor: Object.assign(item.instructor, {
                      own_exams: arr
                    })
                  });
                } else return item;
              });
              result_timeSlots = Object.assign(scheduleClone, result_timeSlots, {
                [day]: dateObject_timeSlots
              });
            });
          });
        }

        if (instructor.invigilator_time_slots && Object.keys(instructor.invigilator_time_slots).length) {
          let invigilatorTimeSlots: any = instructor.invigilator_time_slots;
          let invigilatorDays = Object.keys(invigilatorTimeSlots);

          invigilatorDays.map((day: string) => {
            dateObject_timeSlots = scheduleClone[moment(day).format('YYYY-MM-DD')];
            invigilatorTimeSlots[day].map((dataHour: string) => {
              dateObject_timeSlots = dateObject_timeSlots.map((item: any) => {
                if (item.hour == dataHour) {
                  let arr = item && item.instructor && item.instructor.invigilators;
                  arr.push(instructor.instructorInfo && instructor.instructorInfo.name);
                  return Object.assign(item, {
                    status: 'overlap',
                    instructor: Object.assign(item.instructor, {
                      invigilators: arr
                    })
                  });
                } else return item;
              });
              result_timeSlots = Object.assign(result_timeSlots, {
                [day]: dateObject_timeSlots
              });
            });
          });
        }
      });
    }

    if (classroom_has_any_time_slots) {
      classroom_hours.map((classroom: Types.IExamPeriod_ClassroomItem) => {
        let classroomTimeSlots: any = classroom.time_slots;
        let classroomDays = Object.keys(classroomTimeSlots);

        classroomDays.map((day: string) => {
          dateObject_timeSlots = scheduleClone[moment(day).format('YYYY-MM-DD')];
          classroomTimeSlots[day].map((dataHour: string) => {
            dateObject_timeSlots = dateObject_timeSlots.map((item: any) => {
              if (item.hour == dataHour) {
                let arr = item && item.classroom ? item.classroom : [];
                arr.push(classroom.classroomInfo && classroom.classroomInfo.name);
                return Object.assign(item, {
                  status: 'overlap',
                  classroom: arr
                });
              } else return item;
            });
            result_timeSlots = Object.assign(scheduleClone, result_timeSlots, {
              [day]: dateObject_timeSlots
            });
          });
        });
      });
    }

    if (course_has_any_time_slots) {
      course_hours.map((course: Types.IExamPeriod_CourseItem) => {
        let courseTimeSlots: any = course.time_slots;
        let courseDays = Object.keys(courseTimeSlots);

        courseDays.map((day: string) => {
          dateObject_timeSlots = scheduleClone[moment(day).format('YYYY-MM-DD')];
          courseTimeSlots[day].map((dataHour: string) => {
            dateObject_timeSlots = dateObject_timeSlots.map((item: any) => {
              if (item.hour == dataHour) {
                let arr = item && item.course ? item.course : [];
                arr.push(course.courseInfo && course.courseInfo.name);
                return Object.assign(item, {
                  status: 'overlap',
                  course: arr
                });
              } else return item;
            });
            result_timeSlots = Object.assign(scheduleClone, result_timeSlots, {
              [day]: dateObject_timeSlots
            });
          });
        });
      });
    }
    this.forceUpdate();
  }

  static getDerivedStateFromProps(props: Types.IExamPeriod_CourseProps, state: Types.IExamPeriod_Course_TabState) {
    let hasNewState: boolean = false;

    if (props.selectedCourseInfo && props.selectedCourseInfo.instructor_ids) {
      state.instructor_ids = props.selectedCourseInfo && props.selectedCourseInfo.instructor_ids.filter((v, i, a) => a.indexOf(v) === i);
    }

    if (props.selectedCourseInfo && props.selectedCourseInfo.faculty_ids && props.selectedCourseInfo.faculty_ids.length > 1
      && !GT.isAllEqual(state.instructor_ids)) {
      state.model.isMultiple = true;
    } else {
      state.model.isMultiple = false;
    }

    if (props.overlaps && Object.keys(props.overlaps).length) {
      state.model.overlaps = props.overlaps;
      state.model.course_id = props.overlaps.course && props.overlaps.course[0] && props.overlaps.course[0].course_id;
      if (
        props.storedCalendar &&
        props.storedCalendar.times &&
        props.storedCalendar.days &&
        Object.keys(props.storedCalendar.schedule).length &&
        !state.model.is_calendar_created
      ) {
        hasNewState = true;
        state.model.calendar = JSON.parse(JSON.stringify(props.storedCalendar));
      }
    }

    if (props.courseIsSelected) {
      state.courseIsSelected = props.courseIsSelected;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let { times, days } = this.state.model.calendar;

    let localDays = days.map((day) => moment(day).format('D MMM ddd'));
    let tableColumnHeads = localDays.map((day) => (
      <th key={day}>
        <div style={{ cursor: 'default' }} data-day={day}>{day}</div>
      </th>
    ));
    let timeTableRows = null;
    timeTableRows = times.map((time: string) => {
      let boxes = Array(days.length)
        .fill(undefined)
        .map((val, index) => {
          let result_jsx: JSX.Element = <div></div>;
          let day: string = days[index];
          let scheduleClone: any = this.state && this.state.model.calendar && this.state.model.calendar.schedule;
          let overlapObject: {
            hour: string;
            status: string;
            course: [];
            classroom: [];
            instructor: { invigilators: []; own_exams: [] };
          } = scheduleClone[day].find((item: any) => item.hour == time);
          if (overlapObject.status == 'overlap') {
            if (
              overlapObject.course.length &&
              overlapObject.classroom.length &&
              (this.state.model.isMultiple && overlapObject.instructor.own_exams.length === this.state.instructor_ids.length ?
                (overlapObject.instructor.own_exams.length) :
                this.state.model.isMultiple == false ?
                  (overlapObject.instructor.own_exams.length)
                  : null)
            ) {
              result_jsx = (
                <React.Fragment>
                  <span
                    style={{
                      backgroundColor: 'red',
                      transform: 'skew(0, -28deg)',
                      left: '49%',
                      borderLeftWidth: '1px',
                      zIndex: 3
                    }}
                    data-toggle="tooltip"
                    data-placement="top"
                    data-original-title={overlapObject.course.join()}
                    title={overlapObject.course.join()}
                  />

                  <span
                    style={{
                      backgroundColor: 'blue',
                      transform: 'skew(0, 28deg)',
                      right: '49%',
                      borderRightWidth: '1px',
                      zIndex: 4
                    }}
                    data-toggle="tooltip"
                    data-placement="top"
                    data-original-title={overlapObject.classroom.join()}
                    title={overlapObject.classroom.join()}
                  />

                  <span
                    style={{
                      backgroundColor: '#ffb716',
                      zIndex: 1,
                      top: '-9%',
                      width: '200%',
                      left: '-50%',
                      height: '150%'
                    }}
                    data-toggle="tooltip"
                    data-placement="top"
                    data-original-title={overlapObject.instructor.own_exams.join()}
                    title={overlapObject.instructor.own_exams.join()}
                  />
                </React.Fragment>
              );
            } else if (
              overlapObject.course.length &&
              overlapObject.classroom.length &&
              !(this.state.model.isMultiple && overlapObject.instructor.own_exams.length === this.state.instructor_ids.length ?
                (overlapObject.instructor.own_exams.length) :
                this.state.model.isMultiple == false ?
                  (overlapObject.instructor.own_exams.length)
                  : null)
            ) {
              result_jsx = (
                <React.Fragment>
                  <span
                    style={{
                      backgroundColor: 'red',
                      transform: 'skew(0, 0deg)',
                      left: '49%',
                      borderLeftWidth: '1px',
                      top: '0%'
                    }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={overlapObject.course.join()}
                  />

                  <span
                    style={{
                      backgroundColor: 'blue',
                      transform: 'skew(0, 0deg)',
                      right: '49%',
                      borderRightWidth: '1px',
                      top: '0%'
                    }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={overlapObject.classroom.join()}
                  />
                </React.Fragment>
              );
            } else if (
              !overlapObject.course.length &&
              overlapObject.classroom.length &&
              (this.state.model.isMultiple && overlapObject.instructor.own_exams.length === this.state.instructor_ids.length ?
                (overlapObject.instructor.own_exams.length) :
                this.state.model.isMultiple == false ?
                  (overlapObject.instructor.own_exams.length)
                  : null)
            ) {
              result_jsx = (
                <React.Fragment>
                  <span
                    style={{
                      backgroundColor: 'blue',
                      transform: 'skew(0, 0deg)',
                      right: '49%',
                      borderRightWidth: '1px',
                      top: '0%'
                    }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={overlapObject.classroom.join()}
                  />

                  <span
                    style={{
                      backgroundColor: '#ffb716',
                      transform: 'skew(0, 0deg)',
                      left: '49%',
                      borderLeftWidth: '1px',
                      top: '0%'
                    }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={overlapObject.instructor.own_exams.join()}
                  />
                </React.Fragment>
              );
            } else if (
              overlapObject.course.length &&
              !overlapObject.classroom.length &&
              (this.state.model.isMultiple && overlapObject.instructor.own_exams.length === this.state.instructor_ids.length ?
                (overlapObject.instructor.own_exams.length) :
                this.state.model.isMultiple == false ?
                  (overlapObject.instructor.own_exams.length)
                  : null)
            ) {
              result_jsx = (
                <React.Fragment>
                  <span
                    style={{
                      backgroundColor: 'red',
                      transform: 'skew(0, 0deg)',
                      left: '49%',
                      borderLeftWidth: '1px',
                      top: '0%'
                    }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={overlapObject.course.join()}
                  />

                  <span
                    style={{
                      backgroundColor: '#ffb716',
                      transform: 'skew(0, 0deg)',
                      right: '49%',
                      borderRightWidth: '1px',
                      top: '0%'
                    }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={overlapObject.instructor.own_exams.join()}
                  />
                </React.Fragment>
              );
            } else if (
              overlapObject.course.length &&
              !overlapObject.classroom.length &&
              !(this.state.model.isMultiple && overlapObject.instructor.own_exams.length === this.state.instructor_ids.length ?
                (overlapObject.instructor.own_exams.length) :
                this.state.model.isMultiple == false ?
                  (overlapObject.instructor.own_exams.length)
                  : null)
            ) {
              result_jsx = (
                <React.Fragment>
                  <span
                    style={{
                      backgroundColor: 'red',
                      transform: 'skew(0, 0deg)',
                      borderRightWidth: '1px',
                      top: '0%',
                    }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={overlapObject.course.join()}
                  />
                </React.Fragment>
              );
            } else if (
              !overlapObject.course.length &&
              overlapObject.classroom.length &&
              !(this.state.model.isMultiple && overlapObject.instructor.own_exams.length === this.state.instructor_ids.length ?
                (overlapObject.instructor.own_exams.length) :
                this.state.model.isMultiple == false ?
                  (overlapObject.instructor.own_exams.length)
                  : null)
            ) {
              result_jsx = (
                <React.Fragment>
                  <span
                    style={{
                      backgroundColor: 'blue',
                      transform: 'skew(0, 0deg)',
                      borderRightWidth: '1px',
                      top: '0%',
                    }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={overlapObject.classroom.join()}
                  />
                </React.Fragment>
              );
            } else if (
              !overlapObject.course.length &&
              !overlapObject.classroom.length &&
              (this.state.model.isMultiple && overlapObject.instructor.own_exams.length === this.state.instructor_ids.length ?
                (overlapObject.instructor.own_exams.length) :
                this.state.model.isMultiple == false ?
                  (overlapObject.instructor.own_exams.length)
                  : null)) {
              result_jsx = (
                <React.Fragment>
                  <span
                    style={{
                      backgroundColor: '#ffb716',
                      transform: 'skew(0, 0deg)',
                      borderRightWidth: '1px',
                      top: '0%',
                    }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={overlapObject.instructor.own_exams.join()}
                  />
                </React.Fragment>
              );
            }
          }

          return (
            <td key={index}>
              <div className="overlapping d-inline-flex align-items-center align-self-center" style={{ cursor: 'help' }}>
                <div className="cover">
                  <div className="cover-circle">{result_jsx}</div>
                </div>
              </div>
            </td>
          );
        });

      return (
        <tr>
          <td className="aplan-tm-sticky-col">
            <div style={{ cursor: 'default' }} data-time={time}>{time}</div>
          </td>
          {boxes}
        </tr>
      );
    });

    let OverlapsSchedule = (
      <div className="row">
        <div className="col-12">
          <div className="d-inline-flex w-100 align-items-center">
            <div className="mr-auto">
              <label className="tick-radio position-relative d-inline-block pl-2 mr-2">
                <input
                  type="checkbox"
                  name="invigilator"
                  id="invigilator"
                  className="form-radio"
                  style={{
                    backgroundColor: 'red',
                    color: 'red',
                    borderColor: 'red'
                  }}
                  defaultChecked
                  disabled={true}
                />
                <span className="ml-2">{T.t('gen_course')}</span>
              </label>
              <label className="tick-radio position-relative d-inline-block pl-2 mr-2">
                <input
                  type="checkbox"
                  name="invigilator"
                  id="invigilator"
                  className="form-radio"
                  style={{
                    backgroundColor: 'blue',
                    color: 'blue',
                    borderColor: 'blue'
                  }}
                  defaultChecked
                  disabled={true}
                />
                <span className="ml-2">{T.t('gen_classrooms')}</span>
              </label>
              <label className="tick-radio position-relative d-inline-block pl-2 mr-2">
                <input
                  type="checkbox"
                  name="invigilator"
                  id="invigilator"
                  className="form-radio"
                  style={{
                    backgroundColor: '#ffb716',
                    color: '#ffb716',
                    borderColor: '#ffb716'
                  }}
                  defaultChecked
                  disabled={true}
                />
                <span className="ml-2">{T.t('gen_instructors')}</span>
              </label>
            </div>
          </div>
          <div className="aplan-table-matrix-wrapper">
            <div
              className="aplan-table-matrix-scroller"
              style={this.state.model.isExpanded ? { height: '100%' } : { height: '440px' }}
            >
              <table className="table table-borderless table-striped table-hover aplan-table-matrix mb-0">
                <thead>
                  <tr>
                    <th className="aplan-tm-sticky-col">
                      <div style={{ cursor: 'default' }}>#</div>
                    </th>
                    {tableColumnHeads}
                  </tr>
                </thead>
                <tbody>{timeTableRows}</tbody>
              </table>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-gray mt-2 mb-2 float-right"
            onClick={() => {
              this.state.model.isExpanded = !this.state.model.isExpanded;
              this.setState(this.state);
            }}
          >
            <i className="material-icons mr-2 text-left">{this.state.model.isExpanded ? 'remove' : 'add'}</i>
            <span>{this.state.model.isExpanded ? T.t('gen_collapse') : T.t('gen_expand')}</span>
          </button>
        </div>
      </div>
    );

    return <React.Fragment>{OverlapsSchedule}</React.Fragment>;
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.IExamPeriod_CourseProps
): Types.IExamPeriod_CourseProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IExamPeriod_CourseProps = Object.assign({}, ownProps, {
    courseList:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.course_hours &&
      store.state.examPeriodModal.course_hours.course_list,
    overlaps:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.course_hours &&
      store.state.examPeriodModal.course_hours.overlaps,
    storedCalendar: store.state.examPeriodModal && store.state.examPeriodModal.storedCalendar,
    filters: store.state.examPeriodModal && store.state.examPeriodModal.filters
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

// const equal = require("deep-equal");
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  // return false;
  if (next.state.examPeriodModal) {
    return (
      !!equal(
        prev.state.examPeriodModal && prev.state.examPeriodModal.course_hours,
        next.state.examPeriodModal && next.state.examPeriodModal.course_hours
      ) &&
      !!equal(
        prev.state.examPeriodModal && prev.state.examPeriodModal.storedCalendar,
        next.state.examPeriodModal && next.state.examPeriodModal.storedCalendar
      )
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(Overlaps);

export default container;
